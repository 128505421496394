import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { env } from '../env';

const isDev = env.env !== 'PROD';

Sentry.init({
  dsn: env.sentryDsn,
  integrations: [new Integrations.BrowserTracing()],
  debug: isDev,
  environment: env.env,
  tracesSampleRate: isDev ? 1.0 : 0.1,
});

export const logger = {
  error: (error, errorContext = '') => {
    if (isDev) {
      console.error(error, errorContext);
    }
    if (errorContext) {
      Sentry.setContext('errorContent', { errorContext });
    }
    Sentry.captureException(error);
  },
  debug: (...args) => {
    if (isDev) {
      console.debug.apply(null, args);
    }
  },
};

export default Sentry;
