import React from 'react';
import * as ViewComponent from './ViewIndex';
import { ITenantView } from 'models/Interfaces';
import TenantStatus from 'models/Lists/TenantStatus';
import SSOMetaFile from './SSOMetaFile';

const TenantView = (props : ITenantView) : React.ReactElement => {
  const {
    minWidth,
    Tenant,
    nolastItemBorder,
    summaryOnly
  } = props;
  const isPending = Tenant?.status === TenantStatus.Pending;
  const isPendingUnassign = Tenant?.status === TenantStatus.PendingUnassign;
  const isPendingUpdate = Tenant?.status === TenantStatus.PendingUpdate;
  const hasAssignedBy = (Tenant?.assignedOrganisation?.assignedByName || Tenant?.assignedOrganisation?.assignedByEmail);
  return (
    <>
      {!summaryOnly && (
        <>
          <ViewComponent.DataCenter id={Tenant?.dataCenterId} minWidth={minWidth} />
          <ViewComponent.PageItem name="DSN" minWidth={minWidth} value={Tenant?.dsn} copyToClipboard={true} />
          <ViewComponent.PageItem name="URL" minWidth={minWidth} value={Tenant?.url} copyToClipboard={true} />
          <ViewComponent.IdsSiteItem minWidth={minWidth} idsSiteId={Tenant?.identityServerSiteId} />        
        </>
      )}
      {
        (isPending || isPendingUnassign) && hasAssignedBy && 
        <ViewComponent.TenantAssignment Tenant={Tenant} indentTitle={true} minWidth={minWidth} />
      }
      { isPendingUpdate &&
        <ViewComponent.TenantUpdateLicence
          TenantId={Tenant.id}
          OrgId={Tenant.assignedOrganisation?.organisationId}
          indentTitle={true}
          minWidth="12rem" />
      }
      <ViewComponent.PageItem
        name="Site Purpose"
        minWidth={minWidth}
        value={Tenant?.purpose}
      />
      {Tenant?.purpose === 'IDN' && <ViewComponent.IdnTenantView Tenant={Tenant} minWidth={minWidth} indentTitle={true} />}
      <ViewComponent.PageItem
        name="Version of Entities"
        minWidth={minWidth}
        value={Tenant?.entitiesVersion}
      />   
      {!summaryOnly && (
        <>
          <ViewComponent.PageItem
            name="Database Name"
            minWidth={minWidth}
            value={Tenant?.databaseName}
            copyToClipboard={true}
          />
          <ViewComponent.PageItem
            name="Database Server"
            minWidth={minWidth}
            value={Tenant?.databaseServer}
          />
          <ViewComponent.PageItem name="Web Server" minWidth={minWidth} value={Tenant?.webServer} copyToClipboard={true} />
          <ViewComponent.PageItemCheckbox
            name="IP Restrictions"
            minWidth={minWidth}
            value={Tenant?.isIpRestrictions}
          />
          {Tenant?.isIpRestrictions && 
            <ViewComponent.PageItem
              name="Restrictions"
              minWidth={minWidth}
              value={Tenant?.ipRestrictions}
            />
          }
          <ViewComponent.PageItemCheckbox
            name="Auto Reminder"
            minWidth={minWidth}
            value={Tenant?.isAutoReminderEnabled}
          />
          <ViewComponent.PageItemCheckbox
            name="EF Auto Send Receive"
            minWidth={minWidth}
            value={Tenant?.isEfAutoSendReceiveEnabled}
          />
          <ViewComponent.PageItemCheckbox
            name="ESignatures"
            minWidth={minWidth}
            value={Tenant?.isESignaturesEnabled}
          />
          <ViewComponent.PageItemCheckbox
            name="SSO" 
            minWidth={minWidth}
            value={Tenant?.isSsoEnabled}
            icon={<SSOMetaFile identityServerSiteId={Tenant?.identityServerSiteId} DSN={Tenant?.dsn} />} 
          />
          <ViewComponent.PageItem name="Jobs Server" minWidth={minWidth} value={Tenant?.jobsServer} />
          <ViewComponent.PageItem
            name="ReportIt Server"
            minWidth={minWidth}
            value={Tenant?.reportItServer}
          />
          <ViewComponent.PageItem
            nolastItemBorder={Tenant?.customProperties && Tenant.customProperties.length > 0 ? false : true}
            name="Notes"
            minWidth={minWidth}
            value={Tenant?.notes}
          />
          <ViewComponent.CustomProperties
            customProperties={Tenant?.customProperties}
            minWidth={minWidth}
            nolastItemBorder={nolastItemBorder}
          />
        </>
      )}
    </>
  );
};

export default TenantView;
