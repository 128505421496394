import React from 'react';
import * as Redux from 'react-redux';
import '../popoutPanel.css';
import useFetchSimple from 'hooks/useFetchSimple';
import { VerbType } from 'models/Routes';
import WriteApiVersion from './WriteApiVersion';
import { updateApiVersion } from 'store/Actions/Sidebar';

const ApiVersionLookup = (): React.ReactElement => {
  const [ApiVersion, setApiVersion] = React.useState();
  const { data } = useFetchSimple(VerbType.GET, 'check/version');
  const dispatch = Redux.useDispatch();

  React.useEffect(() => {
    if (data) {
      setApiVersion(data);
      dispatch(updateApiVersion(data));
    }
  }, [data, dispatch]);

  return (
    <WriteApiVersion ApiVersion={ApiVersion} />
  );
}

export default ApiVersionLookup;
