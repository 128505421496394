import React, {PropsWithChildren} from 'react';
import * as Redux from 'react-redux';
import {setupPreviewPanel} from 'store/Actions/PreviewPanel';
import {refreshSearchResults} from 'store/Actions/SearchResults';
import {useFetch} from 'hooks';
import PanelSubHeader from 'views/Common/PanelSubHeader/PanelSubHeader';
import * as Component from 'components/ComponentsIndex';
import DataNotFound from 'error/DataNotFound';
import {ContainerColumn} from 'styles/styledComponents/styledComponents';
import {FormStatus} from 'views/Common/EditForm/Common';
import * as EditComponent from 'views/Common/EditForm/EditIndex';
import {ErrorDialog, handleError, useErrorState} from 'error/HandleErrors';
import {ApiHelperContext} from 'context/ApiHelperContext';
import Route, { VerbType } from 'models/Routes';
import notify, {MessageType, Message} from 'components/Notifications/toastify';
import { Props, Mode } from "models/types";
import { IPreviewPanelState } from "models/Interfaces";
import { IError, IFeature, IPackage, IPreviewPanel, ISwrResult } from "models/Interfaces";
import { getEditPackageErrors } from "lib/PackageHelper";
import WriteErrors from "../../Common/EditForm/WriteErrors";

const EditPackage: React.FC<Props> = (props: PropsWithChildren<any>) => {
  const {minWidth = '10rem'} = props;
  const {id} = Redux.useSelector<IPreviewPanelState>((state) => state.PreviewPanelReducer) as IPreviewPanel;
  const {data, error, loading} = useFetch(VerbType.GET, id ? `packages/${id}` : '');
  const [Package, setPackage] = React.useState<IPackage>();
  const [status, setStatus] = React.useState(FormStatus.INITIAL);
  const {errorDialog, errorMessage, errorTitle, setErrorDialog, setErrorMessage, setErrorTitle} = useErrorState();
  const dispatch = Redux.useDispatch();
  const context = React.useContext(ApiHelperContext)
  const apiHelper = context?.state.apiHelper

  // State controls for adding Features to this Package
  const [selectedFeatures, setSelectedFeatures] = React.useState<IFeature[]>([]);
  const [initialFeatures, setInitialFeatures] = React.useState<IFeature[]>([]);
  const featureFetchResults = useFetch(VerbType.GET, Package ? `Packages/${Package.code}/Features` : '') as ISwrResult;
  const Features = featureFetchResults?.data as IFeature[]

  React.useEffect(() => {
    setPackage(data);
    let selectedOptions: any = []
    if (Features && Features.length > 0) {
      selectedOptions = Features.map((Feature) => ({
        label: Feature.description,
        value: Feature.code,
      }));
    }
    setInitialFeatures(selectedOptions);
  }, [data, Features]);


  if (loading) return <Component.Loading/>;
  if (id > 0 && !Package) return <DataNotFound/>;
  if (error) return <DataNotFound/>;

  const errors = getEditPackageErrors(Package as IPackage);
  const isValid = Object.keys(errors).length === 0;

  function handleSave(): void {
    if (isValid && Package && apiHelper) {
      apiHelper.put(`Packages/update/${id}`, Package, handleSaveCallBack, callbackErrorFunc);
    }
    setStatus(FormStatus.DIRTY);
  }

  function handleSaveCallBack(data: IError): void {
    if (data && data.status >= 400) {
      callbackErrorFunc(data);
    } else {
      PackageFeatures();
      notify(Message.PackageUpdated, MessageType.Success);
    }
  }

  function callbackErrorFunc(error: IError): void {
    handleError(error, setErrorTitle, setErrorMessage, setErrorDialog);
  }

  const handleCloseDialog = (): void => {
    setErrorDialog(false);
  };

  function PackageFeatures(): void {
    if (isValid) {
      const labelsFromInitialFeatures = getLabelsFromFeatures(initialFeatures)
      const labelsFromSelectedFeatures = getLabelsFromFeatures(selectedFeatures)

      const intersectionAdd = selectedFeatures.filter((sf) => !labelsFromInitialFeatures.includes(sf.label!));

      if (intersectionAdd && intersectionAdd.length > 0) {
        intersectionAdd.map((Feature) => addFeatureToPackage(Feature.value));
      }

      let intersectionRemove: IFeature[] = []

      if (initialFeatures) {
        intersectionRemove = initialFeatures.filter((x) => !labelsFromSelectedFeatures.includes(x.label!));
      }

      if (intersectionRemove) {
        intersectionRemove.map((Feature) => removeFeatureFromPackage(Feature.value),
        );
      }

      if (intersectionAdd.length === 0 && intersectionRemove.length === 0) handleRefreshCallBack();
    }
  }

  function getLabelsFromFeatures(features: IFeature[]): string[] {
    const keys = new Array(features.length);
    for (let i = 0; i < features.length; i++) {
      keys[i] = features[i].label;
    }
    return keys;
  }

  const addFeatureToPackage = (value: any): void => {
    if (apiHelper && Package) {
      apiHelper.post(`Packages/${Package.code}/Features/${value}`, '', handleRefreshCallBack, callbackErrorFunc);
    }
  };

  const removeFeatureFromPackage = (value: any): void => {
    if (apiHelper && Package) {
      apiHelper.delete(`Packages/${Package.code}/Feature/${value}`, handleRefreshCallBack, callbackErrorFunc);
    }
  };

  function handleRefreshCallBack(): void {
    dispatch(setupPreviewPanel(Route.Packages, Mode.View, id));
    dispatch(refreshSearchResults(true));
  }

  function handleCancel(): void {
    dispatch(setupPreviewPanel(Route.Packages, Mode.View, id));
  }

  const errorAttributes = {errors} as PropsWithChildren<any>

  return (
    <>
      <div className="search-title-nav">
        <PanelSubHeader handleCancel={handleCancel} handleSave={handleSave} description='Edit Details'/>
      </div>
      {!isValid && FormStatus && (status === FormStatus.DIRTY) && <WriteErrors {...errorAttributes} />}
      <ContainerColumn style={{height: 'calc(100% - 3rem)', overflowY: 'auto'}}>
        <div className="entity-details-content full-width">
          <EditComponent.PackageEdit
            id={Package && Package.id}
            minWidth={minWidth}
            Package={Package}
            status={status}
            setPackage={setPackage}
            selectedFeatures={selectedFeatures}
            setSelectedFeatures={setSelectedFeatures}
            initialFeatures={initialFeatures}
          />
        </div>
      </ContainerColumn>
      <ErrorDialog
        errorDialog={errorDialog}
        handleCloseDialog={handleCloseDialog}
        errorTitle={errorTitle}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default EditPackage;
