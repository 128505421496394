import React, {useContext} from 'react';
import IconButton from '@material-ui/core/IconButton';
import {Tooltip } from  'components/ComponentsIndex';
import {ISelectedSiteContext} from "models/Interfaces";
import {SiteSelectedContext} from "context/SiteSelectedContext";
import {useHistory} from "react-router-dom";
import Route from "models/Routes";
import {setupPreviewPanel} from "store/Actions/PreviewPanel";
import {refreshSearchResults} from "store/Actions/SearchResults";
import * as Redux from "react-redux";
import notify, { MessageType, Message } from 'components/Notifications/toastify';

const ViewSiteButton = (props: any): React.ReactElement|null => {

  const context: ISelectedSiteContext | undefined  = useContext(SiteSelectedContext)
  const history = useHistory();
  const dispatch = Redux.useDispatch();

  const { showButton } = props

  if(!showButton) {
    return null
  }

  const handleViewSite = (e: any): void => {
    e?.stopPropagation()
    const setSelectedSite = context?.setSelectedSite
    const siteStatus = props.state.status.toLowerCase()

    if(siteStatus.includes('delete')) {
      notify(Message.SelectedSiteDeleted, MessageType.Error);
      dispatch(refreshSearchResults(true));

    } else if (setSelectedSite && props?.state) {
      setSelectedSite(props.state)
      let tab
      const siteStatus = props.state.status.toLowerCase()
      switch(true) {
      case siteStatus.includes('live'):
        tab = 0;
        break;
      case siteStatus.includes('pending'):
        tab = 1;
        break;
      case siteStatus.includes('new'):
      default:
        tab = 3;
      }

      dispatch(setupPreviewPanel(Route.Sites, 'view', props.state.tenantId, true));
      dispatch(refreshSearchResults(true, tab));
      history.push('/' + Route.Sites);
    }
  }

  return (
    <Tooltip title="View Site Details">
      <IconButton onClick={handleViewSite}>
        <i className="i-bpow-open-external" style={{fontSize: '1rem', color: '#333'}}/>
      </IconButton>
    </Tooltip>
  )
};

export default ViewSiteButton;
