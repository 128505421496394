import React, {PropsWithChildren, ReactElement} from 'react';
import { ContainerRow } from 'styles/styledComponents/styledComponents';
import { LicenseById, WriteErrors } from './EditIndex';
import { Packages, Licenses, FeatureAddOns } from './Lists/ListsIndex';
import { computeMaxUsers } from 'lib/licenseHelper'

const UpdateSiteLicense = (props: PropsWithChildren<any>): ReactElement => {
  const { minWidth = '10rem', state, setState, status, isValid, FormStatus, errors } = props;
 
  const handleChangeLicenseAddOn = (name: string, value: string): void => {
    const licenseId = state?.licenseId;
    const companies = name === 'companies' ? parseInt(value) : state?.companies;
    const people = name === 'people' ? parseInt(value) : state?.people;
    const incorporations = name === 'incorporations' ? parseInt(value) : state?.incorporations;
    const adminUsers = name === 'adminUsers' ? parseInt(value) : state?.adminUsers;
    const editUsers = name === 'editUsers' ? parseInt(value) : state?.editUsers;
    const edition = name === 'edition' ? value : state?.edition;
    const maxUsers = computeMaxUsers(state);

    setState((state: any) => ({ ...state, ...{licenseId, companies, people, incorporations, maxUsers, adminUsers, editUsers, edition}}));
  };

  return (
    <>      
      {!isValid && FormStatus && (status === FormStatus.DIRTY) && <WriteErrors errors={errors} nolastItemBorder={true} />}
      <ContainerRow className="item-container fullBorder">
        <div className="item-name fieldRequired" style={{ minWidth: minWidth }}>
          Package
        </div>
        <Packages
          required
          formStatus={status}
          id={state?.packageId}
          state={state}
          setState={setState}
          targetName="packageId"
          status={state?.status}
          packageSelector="Current"
          hasError={((FormStatus && status === FormStatus.DIRTY) && (!state?.packageId || !state))}
        />
      </ContainerRow>
      <ContainerRow className="item-container fullBorder noTopBorder">
        <div className="item-name" style={{ minWidth: minWidth }}>
          License
        </div>
        <Licenses
          id={state?.licenseId}
          state={state}
          setState={setState}
          targetName="licenseId"
          status={state?.status}
        />
      </ContainerRow>
      <LicenseById
        licenseId={state?.licenseId}
        state={state}
        setState={setState}
        summaryOnly={true}
        fullBorder={true}
        indentTitle={true}
        handleChangeLicenseAddOn={handleChangeLicenseAddOn}
        menuPlacement='top'
        status={status}
        hasError={((FormStatus && status === FormStatus.DIRTY) && (!state?.licenseDetails || !state))}
      />
      <FeatureAddOns
        addOnFeatures={state?.addOnFeatures || []}
        setState={setState}
        isMulti="true"
        targetName="addOnFeatures"
        minWidth={minWidth}
        fullBorder={true}
        noTopBorder={true}
        menuPlacement='top'
      />
    </>
  );
};

export default UpdateSiteLicense;
