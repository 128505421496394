import React from 'react';
import { ContainerRowRightAligned } from 'styles/styledComponents/styledComponents';
import { useOktaAuth } from '@okta/okta-react';
import HomeIcon from './Home/Home';
import PersonIcon from './Person/Person';
import './icons.css';

const HeaderbarIcons = (): React.ReactElement => {
  const { authState } = useOktaAuth() || {};
  return (
    <div className="app-button-inner-container">
      <span className="app-button-content">
        <span className="content-wrapper short-transition">
          <ContainerRowRightAligned>
            {authState?.isAuthenticated && 
              <>
                <HomeIcon />
                <PersonIcon />
              </>
            }
          </ContainerRowRightAligned>
        </span>
      </span>
    </div>
  );
}

export default HeaderbarIcons;
