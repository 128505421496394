import React, { PropsWithChildren, ReactElement, useCallback } from 'react';
import * as Redux from 'react-redux';
import * as ViewComponent from 'views/Common/ViewForm/ViewIndex';
import * as Component from 'components/ComponentsIndex';
import * as Dialog from 'components/Dialog/DialogIndex';
import { setupPreviewPanel, hidePreviewPanel } from 'store/Actions/PreviewPanel';
import { refreshSearchResults } from 'store/Actions/SearchResults';
import PanelSubHeader from 'views/Common/PanelSubHeader/PanelSubHeader';
import { useFetch } from 'hooks';
import DataNotFound from 'error/DataNotFound';
import { ContainerColumnPanelView } from 'styles/styledComponents/styledComponents';
import 'views/Common/PanelHeader/panelHeader.css';
import { useOktaAuth } from '@okta/okta-react';
import TenantView from 'views/Common/ViewForm/TenantView';
import { ADMIN, PRODOPS, SALES, hasAccessGroup, IMPLEMENTATION } from 'okta/HasAccess';
import { ErrorDialog, handleError, useErrorState } from 'error/HandleErrors';
import TenantOrganisations from './TenantOrganisations';
import informationIcons from 'lib/InformationIcons';
import { ApiHelperContext } from 'context/ApiHelperContext';
import TenantStatus from 'models/Lists/TenantStatus';
import Route, { VerbType } from 'models/Routes';
import notify, { MessageType, Message } from 'components/Notifications/toastify';
import { Props, Mode, eUpdateSiteLicense } from "models/types";
import {
  IPreviewPanelState,
  ISearchResultReducer,
  ISearchResultReducerState,
  IError,
  IPreviewPanel,
  ITenantLicenseUpdate
} from "models/Interfaces";
import { WriteSFSDataCenter } from 'views/Common/ViewForm/Common';
import LicenseEditions from 'models/Lists/LicenseEdition';
import { computeMaxUsers } from 'lib/licenseHelper';
import { getSiteUpdateErrors } from 'lib/ErrorsHelper';
import { FormStatus } from 'views/Common/EditForm/Common';
import { WriteTenantStatus } from '../../Common/ViewForm/Common';

const ViewTenant: React.FC<Props>  = (props: PropsWithChildren<any>) => {
  const { nolastItemBorder, minWidth = '11rem' } = props;
  const { id, section, refreshPanel } = Redux.useSelector<IPreviewPanelState>((state) => state.PreviewPanelReducer) as IPreviewPanel;
  const { reloadSearch } = Redux.useSelector<ISearchResultReducer>((state) => state.SearchResultsReducer) as ISearchResultReducerState;
  const { data, error, loading } = useFetch(VerbType.GET, id ? `tenants/${id}` : '', refreshPanel);
  const [Tenant, setTenant] = React.useState();
  const [DeleteDialog, setDeleteDialog] = React.useState(false);
  const [LicenseUpdateDialog, setLicenseUpdateDialog] = React.useState(false);
  const [CancelLicenseUpdateDialog, setCancelLicenseUpdateDialog] = React.useState(false);
  const [UnassignDialog, setUnassignDialog] = React.useState(false);
  const [AssignDialog, setAssignDialog] = React.useState(false);
  const [CancelDialog, setCancelDialog] = React.useState(false);
  const { errorDialog, errorMessage, errorTitle, setErrorDialog, setErrorMessage, setErrorTitle } = useErrorState();
  const [hideEditButton, setHideEditButton] = React.useState(false);
  const [hideDeleteButton, setHideDeleteButton] = React.useState(false);
  const { authState } = useOktaAuth() || {};
  const dispatch = Redux.useDispatch();
  const context = React.useContext(ApiHelperContext);
  const apiHelper = context?.state.apiHelper;
  const initState = {
    tenantStatus: TenantStatus.Pending,
    assignedByName: authState?.idToken?.claims?.name,
    assignedByEmail: authState?.idToken?.claims?.email
  }
  const [UnassignSite, setUnassignSite] = React.useState(initState);
  const [OpenUnassignSiteDialog, setOpenUnassignSiteDialog] = React.useState(false);
  const [UpdateSiteLicense, setUpdateSiteLicense] = React.useState({});
  const [OpenUpdateSiteDialog, setOpenUpdateSiteDialog] = React.useState(false);
  const [status, setStatus] = React.useState(FormStatus.UNTOUCHED);
  const errorsUpdateSite = getSiteUpdateErrors(UpdateSiteLicense as ITenantLicenseUpdate);
  const isValidUpdateSite = Object.keys(errorsUpdateSite).length === 0;

  const setUpdateSiteData = useCallback((data: { assignedOrganisation: any; }, Id: number): void => {
    const assignedOrg = data?.assignedOrganisation;
    const licenceDetails = assignedOrg?.licenseDetails;

    const addOnFeatures = assignedOrg 
      && assignedOrg.addOnFeatures 
      && (typeof assignedOrg.addOnFeatures === 'string') ?
      JSON.parse(assignedOrg.addOnFeatures) : [];

    const initUpdateSiteState = {
      status: eUpdateSiteLicense.Pending,
      assignedByName: authState?.idToken?.claims?.name,
      assignedByEmail: authState?.idToken?.claims?.email,
      packageId: assignedOrg?.packageId,
      licenseId: assignedOrg?.licenseId || null,
      tenantId: Id,
      organisationId: assignedOrg?.organisationId|| 0,
      companies: licenceDetails?.companies || 0,
      people : licenceDetails?.people || 0,
      incorporations : licenceDetails?.incorporations || 0,
      maxUsers : computeMaxUsers(licenceDetails) || 0,
      adminUsers : licenceDetails?.adminUsers || 0,
      editUsers : licenceDetails?.editUsers || 0,
      edition : licenceDetails?.edition || LicenseEditions.BlueprintOneWorld,
      addOnFeatures
    } as ITenantLicenseUpdate;
    setUpdateSiteLicense(initUpdateSiteState);
  }, [authState?.idToken?.claims?.email, authState?.idToken?.claims?.name]);

  React.useEffect(() => {
    if (data) {
      setTenant(data);
      (data?.status === TenantStatus.Pending || data?.status === TenantStatus.PendingUnassign || data?.status === TenantStatus.PendingUpdate) 
        ? setEditDeleteButtons(true)
        : setEditDeleteButtons(false);
      if (data?.status === TenantStatus.Live) setUpdateSiteData(data, id);
    }
  }, [data, id, refreshPanel, setUpdateSiteData]);

  if (loading) return <Component.Loading />;
  if (!Tenant) return <DataNotFound />;
  if (error) return <DataNotFound />;

  const currentTenant: any = Tenant;
  const orgAttr = {tenantId: currentTenant?.id} as PropsWithChildren<any>;

  const isPending = data?.status === TenantStatus.Pending;
  const isPendingUnassign = data?.status === TenantStatus.PendingUnassign;
  let isPendingLicenseUpdate = data?.status === TenantStatus.PendingUpdate;

  function setEditDeleteButtons(value: any): void {
    setHideEditButton(value);
    setHideDeleteButton(value);
  }

  function handleEdit(): void {
    dispatch(setupPreviewPanel(section, Mode.Edit, id));
  }

  function handleDeleteItem(): void {
    if(apiHelper && id) {
      apiHelper.delete(`Tenants/delete/${id}`, handleDeleteCallBack, callbackErrorFunc);
    }
  }

  function handleDeleteCallBack(): void {
    dispatch(hidePreviewPanel());
    dispatch(refreshSearchResults(true));
    notify(Message.SiteDeleted, MessageType.Info);
  }

  function handleAssigned(): void {
    if(apiHelper) {
      apiHelper.put(`Tenants/${id}/assignment/Assigned`, null, handleAssignedSuccess, callbackErrorFunc);
    }
    handleCloseAssignedDialog();
  }

  const completeLicenseUpdate = (): void => {
    const orgId = data?.assignedOrganisation?.organisationId;
    if(apiHelper && orgId) {
      apiHelper.put(`Tenants/${id}/${orgId}/siteupdatedetails?status=Approved`, null, handleLicenceCompletedSuccess, callbackErrorFunc);
    }
    setLicenseUpdateDialog(false);
  }

  const cancelLicenseUpdate = (): void => {
    const orgId = data?.assignedOrganisation?.organisationId;
    if(apiHelper && orgId) {
      apiHelper.put(`Tenants/${id}/${orgId}/siteupdatedetails?status=Cancelled`, null, handleLicenceUpdateCancelSuccess, callbackErrorFunc);
    }
    setCancelLicenseUpdateDialog(false);
  }

  function handleUnassigned(): void {
    const UnassignSite = { tenantStatus: 'New' }
    if (apiHelper && id) {
      apiHelper.put(`Tenants/${id}/assignment/Unassigned`, UnassignSite, handleUnassignedSuccess, callbackErrorFunc);
    }
    handleCloseUnassignedDialog();
  }

  function handleCancel(): void {
    if(apiHelper && id) {
      apiHelper.put(`Tenants/${id}/assignment/Cancelled`, null, handleCancelSuccess, callbackErrorFunc);
    }
    handleCloseCancelDialog();
  }

  const handleUnassignSiteDialog = (): void => {
    setOpenUnassignSiteDialog(true);
  };

  const handleUpdateSiteDialog = (): void => {
    setOpenUpdateSiteDialog(true);
  };

  const handleCloseUnassignSiteDialog = (): void => {
    setOpenUnassignSiteDialog(false);
    setErrorDialog(false);
    setUnassignSite(initState);
  };

  const handleCloseUpdateSiteDialog = (): void => {
    setOpenUpdateSiteDialog(false);
    setErrorDialog(false);
  };

  function handleUnassignSite(): void {
    const orgId = data?.assignedOrganisation?.organisationId;
    if (id && orgId) {
      unassignSite(orgId);
    } else {
      !id && notify(Message.SiteUnassignErrorSiteId, MessageType.Error);
      !orgId && notify(Message.SiteUnassignErrorClientId, MessageType.Error);
      !id && !orgId && notify(Message.SiteUnassignErrorClientAndSiteId, MessageType.Error);
    }
  }

  const unassignSite = (orgId: string | number): void => {
    if (apiHelper) {
      apiHelper.post(
        `Organisations/${orgId}/tenant/${id}/unassign`,
        UnassignSite,
        handleUnassignSiteSuccess,
        callbackErrorFunc
      );
      setOpenUnassignSiteDialog(false);
      setUnassignSite(initState);
    }
  }

  function handleUpdateSiteLicense(): void {
    const orgId = data?.assignedOrganisation?.organisationId;
    if (id && orgId) {
      updateSiteLicense(orgId);
    } else {
      !id && notify(Message.SiteUnassignErrorSiteId, MessageType.Error);
      !orgId && notify(Message.SiteUnassignErrorClientId, MessageType.Error);
      !id && !orgId && notify(Message.SiteUnassignErrorClientAndSiteId, MessageType.Error);
    }
  }

  const updateSiteLicense = (orgId: string | number): void => {
    if (isValidUpdateSite && UpdateSiteLicense && apiHelper) {
      apiHelper.post(
        `Tenants/siteupdatedetails`,
        UpdateSiteLicense,
        handleUpdateSiteSuccess,
        callbackErrorFunc
      );
    } else {
      setStatus(FormStatus.DIRTY);
    }
  }

  const callbackErrorFunc = (error: IError): void => {
    handleError(error, setErrorTitle, setErrorMessage, setErrorDialog);
  };

  const handleLicenceCompletedSuccess = (): void => {
    notify(Message.LicenseUpdateCompleted, MessageType.Success);
    isPendingLicenseUpdate = false;
    handleCallBack();
  }

  const handleLicenceUpdateCancelSuccess = (): void => {
    notify(Message.LicenseUpdateCancelled, MessageType.Success);
    isPendingLicenseUpdate = false;
    handleCallBack();
  }

  const handleAssignedSuccess = (): void => {
    notify(Message.SiteAssigned, MessageType.Success);
    handleCallBack();
  }

  const handleUnassignedSuccess = (): void => {
    notify(Message.SiteUnassigned, MessageType.Success);
    handleCallBack();
  }

  const handleCancelSuccess = (): void => {
    notify(Message.SiteUnAssignmentCancelled, MessageType.Info);
    handleCallBack();
  }

  const handleUnassignSiteSuccess = (): void => {
    notify(Message.SiteUnassignSite, MessageType.Info);
    handleCallBack();
  }

  const handleUpdateSiteSuccess = (data: IError): void => {
    if (data?.status >= 400) {
      callbackErrorFunc(data);
    } else {
      setStatus(FormStatus.UNTOUCHED);
      setOpenUpdateSiteDialog(false);
      setUpdateSiteLicense({});
      notify(Message.SiteUpdatedRequest, MessageType.Info);
      handleCallBack();
    }
  }

  const handleCallBack = (): void => {
    dispatch(setupPreviewPanel(Route.Sites, Mode.View, id, true));
    dispatch(refreshSearchResults(!reloadSearch));
    setStatus(FormStatus.UNTOUCHED);
  };

  const handleLicenseUpdateCancel= (): void => {
    setCancelLicenseUpdateDialog(true)
  }
  
  const handleLicenseUpdateCompleted = (): void => {
    setLicenseUpdateDialog(true)
  }

  const handleOpenDeleteDialog = (): void => { setDeleteDialog(true) };
  const handleCloseDeleteDialog = (): void => { setDeleteDialog(false) };
  const handleAssignedDialog = (): void => { setAssignDialog(true) };
  const handleCloseAssignedDialog = (): void => { setAssignDialog(false) };
  const handleOpenUnassignedDialog = (): void => { setUnassignDialog(true) };
  const handleCloseUnassignedDialog = (): void => { setUnassignDialog(false) };
  const handleCancelDialog = (): void => { setCancelDialog(true) };
  const handleCloseCancelDialog = (): void => { setCancelDialog(false) };
  const handleCloseErrorDialog = (): void => { setErrorDialog(false) };

  const canUpdateLicense = hasAccessGroup([ADMIN, SALES, IMPLEMENTATION], authState);
  const canUnassign = hasAccessGroup([ADMIN, PRODOPS, SALES, IMPLEMENTATION], authState);
  const showUnassign = canUnassign && data?.status === 'Live' && data?.assignedOrganisation?.organisationId;

  const deleteAccess = !showUnassign ? [ADMIN, PRODOPS] : [];
  const editAccess = [ADMIN, PRODOPS];

  const canApprove =  hasAccessGroup([ADMIN, PRODOPS], authState);

  const SiteDetails = (): ReactElement => {
    return (
      <TenantView
        Tenant={Tenant}
        minWidth={minWidth}
        nolastItemBorder={nolastItemBorder}
      />
    )
  }

  const additionalButtons = (): ReactElement => {
    return (
      <>
        {canUpdateLicense && showUnassign &&
          <Component.Button handleClick={handleUpdateSiteDialog} color="primary" label="License Update" minWidth='123px' />
        }
        {canUnassign && showUnassign &&
            <Component.Button handleClick={handleUnassignSiteDialog} color="primary" label="Unassign Site" minWidth='115px' />
        }
        {canApprove && (isPending || isPendingUnassign) && (
          <Component.Button
            handleClick={handleCancelDialog}
            color="primary"
            label="Cancel"
          />
        )}
        {canApprove && isPendingLicenseUpdate && (
          <Component.Button
            handleClick={handleLicenseUpdateCancel}
            color="primary"
            label="Cancel"
          />
        )}
        {canApprove && isPendingUnassign && (
          <Component.ButtonColoured
            handleClick={handleOpenUnassignedDialog}
            color="primary"
            label="Unassign Completed"
          />
        )}
        {canApprove && isPending && (
          <Component.ButtonColoured
            handleClick={handleAssignedDialog}
            color="primary"
            label="Setup Completed"
          />
        )}
        {canApprove && isPendingLicenseUpdate && (
          <Component.ButtonColoured
            handleClick={handleLicenseUpdateCompleted}
            color="primary"
            label="License Update Completed"
            minWidth="195px"
          />
        )}
      </>
    )
  }

  return (
    <>
      <div className="search-title-nav">
        <PanelSubHeader
          handleEdit={handleEdit}
          handleDelete={handleOpenDeleteDialog}
          deleteAccess={deleteAccess}
          editAccess={editAccess}
          hideEditButton={hideEditButton}
          hideDeleteButton={hideDeleteButton}
          description='Details'
          icons={informationIcons.WriteIcons('GSL')}
          additionalButton={additionalButtons}
          subTitle={WriteTenantStatus(currentTenant?.status)}
        />
      </div>
      <ContainerColumnPanelView style={{ height: 'calc(100% - 3rem)', overflowY: 'auto' }}>
        <div className="entity-details-content full-width">
          <Component.Accordion
            title="Site Details - (GW)"
            Component={SiteDetails}
            defaultExpanded={true}
          />
          {currentTenant?.id && (
            <>
              <TenantOrganisations {...orgAttr} />
              <ViewComponent.FeatureAddOns
                Tenant={Tenant}
                nolastItemBorder={nolastItemBorder}
                minWidth={minWidth}
              />
              <ViewComponent.PageItem
                name="SFS Data Center"
                minWidth={minWidth}
                value={WriteSFSDataCenter(currentTenant?.assignedOrganisation?.sfsDataCenter)}
              />
              <ViewComponent.PageItemDate
                name="Last Updated"
                minWidth={minWidth}
                value={currentTenant?.assignedOrganisation?.lastUpdated}
                format='datetime'
                nolastItemBorder={nolastItemBorder}
              />
            </>
          )}
        </div>
      </ContainerColumnPanelView>
      <Dialog.AlertDialog
        open={DeleteDialog}
        handleClose={handleCloseDeleteDialog}
        handleConfirm={handleDeleteItem}
        title="Delete Site"
        body="Are you sure you wish to delete this item?"
      />
      <Dialog.AlertDialog
        open={LicenseUpdateDialog}
        handleClose={(): void => setLicenseUpdateDialog(false)}
        handleConfirm={completeLicenseUpdate}
        title="License Update Completed"
        body="Are you sure you wish to complete license update request?"
      />
      <Dialog.AlertDialog
        open={CancelLicenseUpdateDialog}
        handleClose={(): void => setCancelLicenseUpdateDialog(false)}
        handleConfirm={cancelLicenseUpdate}
        title="Cancel License Update"
        body="Are you sure you wish to cancel license update request?"
      />
      <Dialog.AlertDialog
        open={UnassignDialog}
        handleClose={handleCloseUnassignedDialog}
        handleConfirm={handleUnassigned}
        title="Completed Site Unassignment"
        body="Are you sure you wish to continue?"
      />
      <Dialog.AlertDialog
        open={AssignDialog}
        handleClose={handleCloseAssignedDialog}
        handleConfirm={handleAssigned}
        title="Completed Site Setup"
        body="Are you sure you wish to continue?"
      />
      <Dialog.AlertDialog
        open={CancelDialog}
        handleClose={handleCloseCancelDialog}
        handleConfirm={handleCancel}
        title="Cancel Pending"
        body="Are you sure you wish to cancel Pending Site?"
      />
      <Dialog.FormDialog
        open={OpenUnassignSiteDialog}
        handleClose={handleCloseUnassignSiteDialog}
        handleConfirm={handleUnassignSite}
        title="Unassign Site"
        formComponent="unassignTenant"
        state={UnassignSite}
        setState={setUnassignSite}
        height='200px'
        maxwidth='sm'
      />
      <Dialog.FormDialog
        open={OpenUpdateSiteDialog}
        handleClose={handleCloseUpdateSiteDialog}
        handleConfirm={handleUpdateSiteLicense}
        title={`Site Update Request for ${currentTenant && currentTenant?.dsn}`}
        formComponent="UpdateSiteLicense"
        state={UpdateSiteLicense}
        setState={setUpdateSiteLicense}
        height='450px'
        maxwidth='md'
        buttonOK='Request Update'
        status={status}
        isValid={isValidUpdateSite}
        FormStatus={FormStatus}
        errors={errorsUpdateSite}
      />
      <ErrorDialog
        errorDialog={errorDialog}
        handleCloseDialog={handleCloseErrorDialog}
        errorTitle={errorTitle}
        errorMessage={errorMessage}
      />

    </>
  );
};

export default ViewTenant;
