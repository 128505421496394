import React from 'react';
import SidebarMarker from './Marker';
import { ContainerCenter } from 'styles/styledComponents/styledComponents';
import * as Redux from 'react-redux';
import { hidePreviewPanel } from 'store/Actions/PreviewPanel';
import { hideMenu } from 'store/Actions/Sidebar';
import '../sidebar.css';

interface IIcon {
  icon: string,
  marked: boolean
}

const Icon: React.FC<IIcon>  = (props: React.PropsWithChildren<any>)=> {
  const { icon, marked } = props;
  const dispatch = Redux.useDispatch();

  function collapseAll() : void {
    dispatch(hidePreviewPanel());
    dispatch(hideMenu());
  }

  return (
    <span className="icon-container" onClick={collapseAll}>
      <SidebarMarker marked={marked} />
      <ContainerCenter className="icon-inner-container full-size status-marker long-transition">
        <i className={icon} style={{ fontSize: '1.2rem' }} />
      </ContainerCenter>
    </span>
  );
};

export default Icon;
