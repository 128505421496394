import React from 'react';
import { useSelector } from "react-redux";
import { useFetch } from 'hooks';
import { ContainerColumn } from 'styles/styledComponents/styledComponents';
import AuditViews from './AuditViews';
import { VerbType } from 'models/Routes';
import {IPreviewPanelState} from "models/Interfaces";
import {IPreviewPanel} from "models/Interfaces";
import {Props} from "models/types";

const ViewAudit: React.FC<Props>  = () => {
  const { id } = useSelector<IPreviewPanelState>((state) => state.PreviewPanelReducer) as IPreviewPanel;
  const { data } = useFetch(VerbType.GET, id ? `Audit/${id}` : 'Audit?size=1000');
  const [audit, setAudit] = React.useState<any>();

  React.useEffect(() => {
    setAudit(data);
  }, [data]);
  
  if (!audit) return null;

  const viewAttr = {audit, category: audit.category, originalValue: JSON.parse(audit.originalValue), currentValue: JSON.parse(audit.currentValue)} as any
  return (
    <>
      <ContainerColumn style={{ height: 'calc(100% - 3rem)', overflowY: 'auto' }}>
        <div className="entity-details-content full-width">
          <AuditViews {...viewAttr}/>
        </div>
      </ContainerColumn>
    </>
  );
};

export default ViewAudit;
