export const CustomPropertyTitle = '25';
export const CustomPropertyDescription = '250';

// Features
export const Feature_Code = 150;
export const Feature_Description = 250;

// Licenses
export const License_Name = 150;

// Packages
export const Package_Code = '10';
export const Package_Name = '150';
export const Package_Description = '250';
export const Package_Family = '150';

// Tenants
export const Tenant_Name = '25';
export const Tenant_DatabaseServer = '250';
export const Tenant_DatabaseName = '250';
export const Tenant_DataFeedsListing = '250';
export const Tenant_DataFeedsSftpAccount = '25';
export const Tenant_DSN = '15';
export const Tenant_JobsServer = '25';
export const Tenant_IdentityServer = '25';
export const Tenant_IpRestrictions = '250';
export const Tenant_ReportItServer = '25';
export const Tenant_SFSDataCenter = '25';
export const Tenant_URL = '2048';
export const Tenant_WebServer = '250';
