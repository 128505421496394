import React, {PropsWithChildren, ReactElement} from 'react';
import * as Redux from 'react-redux';
import { setupPreviewPanel, hidePreviewPanel } from 'store/Actions/PreviewPanel';
import { refreshSearchResults } from 'store/Actions/SearchResults';
import PanelSubHeader from 'views/Common/PanelSubHeader/PanelSubHeader';
import { useFetch } from 'hooks';
import DataNotFound from 'error/DataNotFound';
import { ContainerColumnPanelView } from 'styles/styledComponents/styledComponents';
import 'views/Common/PanelHeader/panelHeader.css';
import * as Component from 'components/ComponentsIndex';
import { AlertDialog } from 'components/Dialog/DialogIndex';
import * as ViewComponent from 'views/Common/ViewForm/ViewIndex';
import { ADMIN, PRODOPS,  } from 'okta/HasAccess';
import { ErrorDialog, handleError, useErrorState } from 'error/HandleErrors';
import { ApiHelperContext } from 'context/ApiHelperContext';
import Route, { VerbType } from 'models/Routes';
import notify, { MessageType, Message } from 'components/Notifications/toastify';
import {IError, IPreviewPanel, ISearchResultReducerState} from "models/Interfaces";
import { Props , Mode } from "models/types";
import { IPreviewPanelState } from "models/Interfaces";

const IdsSite: React.FC<Props>  = (props: PropsWithChildren<any>) => {
  const { minWidth = '11rem' } = props;
  const {reloadSearch} = Redux.useSelector<any>((state) => state.SearchResultsReducer) as ISearchResultReducerState;
  const { id, refreshPanel } = Redux.useSelector<IPreviewPanelState>((state) => state.PreviewPanelReducer) as IPreviewPanel;
  const { data, error, loading } = useFetch(VerbType.GET, id ? `IdentityServerSites/${id}` : '', refreshPanel);
  const [IdsSite, setIdsSite] = React.useState();
  const [DeleteDialog, setDeleteDialog] = React.useState(false);
  const { errorDialog, errorMessage, errorTitle, setErrorDialog, setErrorMessage, setErrorTitle } = useErrorState();
  const dispatch = Redux.useDispatch();
  const context = React.useContext(ApiHelperContext)
  const apiHelper = context?.state.apiHelper
  
  React.useEffect(() => {
    setIdsSite(data);
  }, [data, id, refreshPanel]);

  if (loading) return <Component.Loading />;
  if (!IdsSite) return <DataNotFound />;
  if (error) return <DataNotFound />;

  function handleEdit(): void {
    dispatch(setupPreviewPanel(Route.IDS, Mode.Edit, id));
  }

  function handleDeleteItem(): void {
    if (apiHelper && id) {
      apiHelper.delete(`IdentityServerSites/${id}`, handleDeleteCallBack, callbackErrorFunc);
    }
  }

  function handleDeleteCallBack(): void {
    dispatch(hidePreviewPanel());
    dispatch(refreshSearchResults(!reloadSearch));
    notify(Message.IDSSiteDeleted, MessageType.Info);
  }

  const callbackErrorFunc = (error: IError): void => {
    handleError(error, setErrorTitle, setErrorMessage, setErrorDialog);
  };

  const handleOpenDeleteDialog = (): void => { setDeleteDialog(true) };
  const handleCloseDeleteDialog = (): void => { setDeleteDialog(false) };
  const handleCloseErrorDialog = (): void => { setErrorDialog(false) };

  const deleteAccess = [ADMIN, PRODOPS];
  const editAccess = [ADMIN, PRODOPS];

  const SiteDetails = (): ReactElement => {
    return (
      <ViewComponent.IdsSiteView IdsSite={IdsSite} minWidth={minWidth} nolastItemBorder={true} />
    )
  }

  const currentIdsSite: any = IdsSite
  const AssignedSites = (): ReactElement  => {
    return (
      <ViewComponent.SitesForIdsSite idsSiteId={currentIdsSite?.id} minWidth={minWidth} nolastItemBorder={true} />
    )
  }

  return (
    <>
      <div className="search-title-nav">
        <PanelSubHeader
          handleEdit={handleEdit}
          handleDelete={handleOpenDeleteDialog}
          deleteAccess={deleteAccess}
          editAccess={editAccess}
          description='Details'
        />
      </div>
      <ContainerColumnPanelView style={{ height: 'calc(100% - 3rem)', overflowY: 'auto' }}>
        <div className="entity-details-content full-width">
          <Component.Accordion
            title="IDS Site Details"
            Component={SiteDetails}
            defaultExpanded={true}
          />
          <ViewComponent.PageItemBreak />
          <Component.Accordion
            title="Assigned Sites"
            Component={AssignedSites}
            defaultExpanded={true}          
          />
        </div>
      </ContainerColumnPanelView>
      <AlertDialog
        open={DeleteDialog}
        handleClose={handleCloseDeleteDialog}
        handleConfirm={handleDeleteItem}
        title="Delete Site"
        body="Are you sure you wish to delete this item?"
      />
      <ErrorDialog
        errorDialog={errorDialog} 
        handleCloseDialog={handleCloseErrorDialog}
        errorTitle={errorTitle}
        errorMessage={errorMessage}
      />
      
    </>
  );
};

export default IdsSite;
