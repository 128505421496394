enum SfsDataCentre {
  PROD1 = 'PROD 1',
  PROD2 = 'PROD 2',
  PROD3 = 'PROD 3',
  PROD4 = 'PROD 4',
  PROD5 = 'PROD 5',
}

export enum SfsDataCentreLabel {
  PROD1 = 'Canada',
  PROD2 = 'USA',
  PROD3 = 'Germany',
  PROD4 = 'AUS',
  PROD5 = 'UK',
}

export default SfsDataCentre;
