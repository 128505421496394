import {
  SHOW_PREVIEWPANEL,
  HIDE_PREVIEWPANEL,
  SETUP_PREVIEWPANEL,
  EXTEND_PREVIEWPANEL,
} from '../ActionTypes/PreviewPanel';

export function showPreviewPanel(title) {
  const payload = {
    title,
    isPreviewPanelOpen: true,
    isPreviewPanelExtended: false,
  };
  return { type: SHOW_PREVIEWPANEL, payload };
}

export function extendPreviewPanel(isPreviewPanelExtended) {
  const payload = {
    isPreviewPanelExtended: !isPreviewPanelExtended,
  };
  return { type: EXTEND_PREVIEWPANEL, payload };
}

export function hidePreviewPanel() {
  const payload = {
    title: '',
    isPreviewPanelOpen: false,
    isPreviewPanelExtended: false,
  };
  return { type: HIDE_PREVIEWPANEL, payload };
}

export function setupPreviewPanel(section, type, id, refreshPanel) {
  const payload = {
    section,
    type,
    id,
    refreshPanel,
  };
  return { type: SETUP_PREVIEWPANEL, payload };
}
