import { makeStyles } from '@material-ui/core/styles';

const fontSize = '14px';
export const useStyles = makeStyles({
  root: {
    '& .MuiFormControl-fullWidth': {
      radius: '0px',
      backgroundColor: '#fff',
      width: '400px',
      fontSize: {fontSize},
    },
    '& .MuiInputBase-input': {
      height: '.08rem',
      fontSize: {fontSize},
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, 2px) scale(0.75)',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '0px',
    },
    '& .MuiInputLabel-formControl': {
      top: '-8px',
    },
  },
  option: {
    fontSize: {fontSize},
    '& > span': {
      marginRight: 10,
      fontSize: {fontSize},
      border: '1px thin #ababab',
    },
  },
});
