import React, {PropsWithChildren, ReactElement} from 'react';
import './panelHeader.css';

const PanelHeader = (props: PropsWithChildren<any>): ReactElement => {
  return (
    <>
      <div className="nav-panel-header bold" title={props.name}>
        {props.name}
      </div>
    </>
  );
};

export default PanelHeader;
