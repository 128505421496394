import { useMemo } from 'react';
import { ADMIN, PRODOPS } from 'okta/HasAccess';
import TenantStatus from 'models/Lists/TenantStatus';
import Route from 'models/Routes';
import { ITabs, TabType } from 'models/types';

const useHeaderTabs = (): ITabs => {
  const tabs: TabType[] = useMemo(
    (): TabType[] => [
      {
        text: 'Live Sites',
        tooltip: 'View Live Sites',
        url: Route.Sites,
        selector: TenantStatus.Live,
        searchTitle: 'Live Sites',
        addAccessOnly: [ADMIN, PRODOPS],
        paginatedResultsOnly: true,
      },
      {
        text: 'Pending',
        tooltip: 'View Sites for Approval and Decommissioning',
        url: Route.Sites,
        selector: TenantStatus.Pending,
        searchTitle: 'Pending Sites',
        addAccessOnly: [ADMIN, PRODOPS],
        paginatedResultsOnly: true,
      },
      {
        text: 'Unassigned Sites',
        tooltip: 'View Unassigned Sites',
        url: Route.Sites,
        selector: TenantStatus.Unassigned,
        searchTitle: 'Unassigned Sites',
        addAccessOnly: [ADMIN, PRODOPS],
        paginatedResultsOnly: true,
      },
      {
        text: 'All Sites',
        tooltip: 'View All Sites',
        url: Route.Sites,
        searchTitle: 'All Sites',
        addAccessOnly: [ADMIN, PRODOPS],
        paginatedResultsOnly: true,
      },
      {
        text: 'IDS Sites',
        tooltip: 'View IDS Sites',
        url: Route.IDS,
        showAddButton: true,
        searchTitle: 'IDS Sites',
        addAccessOnly: [ADMIN, PRODOPS],
        accessOnly: [ADMIN, PRODOPS],
        disableFilter: true,
        paginatedResultsOnly: true,
      },
    ],
    [],
  );
  return { tabs };
};

export default useHeaderTabs;
