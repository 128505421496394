import React from 'react';
import * as ViewComponent from './ViewIndex';
import { IIdsSite } from 'models/Interfaces';

interface IIdsSiteView { 
  minWidth?: string | undefined;
  IdsSite?: IIdsSite;
  nolastItemBorder?: boolean;
}

const IdsSiteView = (props : IIdsSiteView) : React.ReactElement => {  
  const { minWidth, IdsSite, nolastItemBorder } = props;  
  return (
    <>
      <ViewComponent.PageItem name="URL" minWidth={minWidth} value={IdsSite?.url} />
      <ViewComponent.PageItem name="Description" minWidth={minWidth} value={IdsSite?.description} />
      <ViewComponent.PageItem name="Location" minWidth={minWidth} value={IdsSite?.location} />
      <ViewComponent.PageItem name="Vault URL" minWidth={minWidth} value={IdsSite?.vault} />
      <ViewComponent.PageItem name="Consul URL" minWidth={minWidth} value={IdsSite?.consul} />
      <ViewComponent.PageItem
        name="IP Address"
        minWidth={minWidth}
        value={IdsSite?.ip}   
        nolastItemBorder={nolastItemBorder} 
      />
    </>
  );
};

export default IdsSiteView;
