import { makeStyles } from '@material-ui/core/styles';

const fontSize = '14px';
export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiAccordion-root': {
      borderBottom: '1px solid #ababab',
      boxShadow: 'none',
      margin: '0',
      cursor: 'pointer',
      background: '#e6e6e6',
    },
    '& .noBottomBorder': {
      borderBottom: 'none',
    },
    '& .MuiTypography-root': {
      fontSize: 'inherit',
      fontWeight: 600,
    },
    '& .MuiAccordionSummary-root': {
      boxShadow: 'none',
      minHeight: '40px',
      paddingRight: '1rem',
      paddingLeft: '0.5rem',
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      borderBottom: '1px solid #ababab',
      boxShadow: 'none',
      minHeight: '40px',
      paddingTop: '1px',
    },
    '& .MuiAccordionSummary-content': {
      margin: '0',
    },
    '& .MuiAccordionDetails-root': {
      padding: '0',
      fontFamily: theme.typography.fontFamily,
      fontSize: fontSize,
    },
  },
  heading: {
    '& .MuiIconButton-colorPrimary': {
      backgroundColor: 'transparent ! important',
    },
    '& .MuiFormControlLabel-label': {
      fontWeight: 600,
    },
  },
  body: {
    fontFamily: theme.typography.fontFamily,
    fontSize: fontSize,
    width: '100%',
  },
  wrapper: { justifyContent: 'space-between', display: 'flex', flexGrow: 1},
  icon: {
    fontFamily: theme.typography.fontFamily,
    fontSize: fontSize,
    justifyContent: 'space-between',
    fontWeight: 600,
    '& .MuiIconButton-edgeEnd': {
      marginRight: '-14px',
    },
    '& .MuiIconButton-root': {
      padding: '0rem 0.6rem',
    },
  },
}));
