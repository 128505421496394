import React, {createContext, FC, useRef, useState} from 'react'
import {IApiHelper, IApiHelperContext} from 'models/Interfaces'

export const ApiHelperContext = createContext<IApiHelperContext | undefined>(undefined)

interface HeaderProps {
  children: any;
}

export const ApiHelperContextProvider: FC<HeaderProps> = ({children}) => {
  const [apiHelper, setApiHelper] = useState<IApiHelper>()

  const controllerRef = useRef<AbortController | null>();
  apiHelper?.setControllerRef(controllerRef)

  return <ApiHelperContext.Provider
    value={{
      state: {
        apiHelper,
        controllerRef
      },
      setApiHelper,
    }}>
    {children}
  </ApiHelperContext.Provider>
}

export default ApiHelperContextProvider
