import React, {PropsWithChildren, ReactElement} from 'react';
import {useFetch, useWindowSize} from 'hooks';
import * as Component from 'components/ComponentsIndex';
import DataNotFound from 'error/DataNotFound';
import useColumnsSetup from '../../Common/SearchResults/ColumnsSetup';
import {useOktaAuth} from '@okta/okta-react';
import {AlertDialog, ConfirmDialog} from 'components/Dialog/DialogIndex';
import NotAuthorised from 'error/NotAuthorised';
import {ADMIN, hasAccessGroup, SALES, IMPLEMENTATION} from 'okta/HasAccess';
import DataImported from './DataImported';
import {handleError, useErrorState} from 'error/HandleErrors';
import {useStyles} from './Styles';
import './importDataGrid.css';
import {ApiHelperContext} from 'context/ApiHelperContext';
import Route, {VerbType} from 'models/Routes';
import notify, {Message, MessageType} from 'components/Notifications/toastify';
import MaterialTable, {MaterialTableProps} from 'material-table';
import {Pagination, tableIcons} from '../../Common/SearchResults/SearchIndex';
import {IError} from "models/Interfaces";

let containerRef: any;
let toolbarRef: any;

function escapeRegExp(value: any): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const ImportDataGrid = (props: PropsWithChildren<any>): ReactElement => {
  const {url, lastRun, filterComponent} = props;
  const columns = useColumnsSetup(
    url,
    'ASC',
  );
  const {authState} = useOktaAuth() || {};
  const classes = useStyles();
  const [uploadingData, setUploadingData] = React.useState(false);
  const [dataImported, setDataImported] = React.useState(false);
  const {data, loading} = useFetch(
    VerbType.GET,
    `${url}${lastRun ? '?lastRun=' + lastRun : ''}`,
    uploadingData,
  );
  const [Results, setResults] = React.useState<any>([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const {errorDialog, errorMessage, errorTitle, setErrorDialog, setErrorMessage, setErrorTitle} =
    useErrorState();
  const context = React.useContext(ApiHelperContext);
  const apiHelper = context?.state.apiHelper;
  const [maxBodyHeight, setMaxBodyHeight] = React.useState(0);
  containerRef = React.useRef(null);
  const requestSearch = (searchValue: any): void => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows =
      data &&
      data.importOrganisations &&
      data.importOrganisations.filter((row: any) => {
        return Object.keys(row).some((field) => {
          return searchRegex.test(row[field] && row[field].toString());
        });
      });
    setResults(filteredRows);
  };
  const size = useWindowSize();

  React.useEffect(() => {
    let height = containerRef?.current?.clientHeight;
    // Size of the Window - 13rem to work out spacing around the Body + top & bottom border line
    if (!height) {
      height = size?.height - 13 * 21 + 2;
    }
    const toolbarHeight = toolbarRef?.current ? toolbarRef.current.clientHeight : 64;
    setMaxBodyHeight(height - toolbarHeight);
  }, [url, size]);

  React.useEffect(() => {
    let results = [];
    if (url && url.includes(Route.Clients)) results = data?.importOrganisations;
    setResults(results);
  }, [data, url]);

  const handleOpenDialog = (): void => {
    setOpenDialog(true);
  };
  const handleCloseDialog = (): void => {
    setOpenDialog(false);
  };

  const handleImport = (): void => {
    setUploadingData(true);
    setOpenDialog(false);
    if (apiHelper) {
      apiHelper.get(
        `${url}?commitChanges=true${lastRun ? '&lastRun=' + lastRun : ''}`,
        handleSuccessCallBack,
        handleErrorCallBack,
      );
    }
  };

  function handleSuccessCallBack(): void {
    setUploadingData && setUploadingData(false);
    setDataImported && setDataImported(true);
    notify(Message.ClientDataImported, MessageType.Success);
  }

  function handleErrorCallBack(error: IError): void {
    handleError(error, setErrorTitle, setErrorMessage, setErrorDialog);
  }

  const handleCloseErrorDialog = (): void => {
    setUploadingData && setUploadingData(false);
  };
  
  const hasAccess = hasAccessGroup([ADMIN, SALES, IMPLEMENTATION], authState);

  if (!hasAccess) return <NotAuthorised/>;
  if (Results?.errors?.status) return <DataNotFound />;
  if (loading || uploadingData) return <Component.Loading/>;
  if (dataImported) return <DataImported/>;

  const tableProps = {
    icons: tableIcons,
    className: classes.root,
    padding: "dense",
    disableSelectionOnClick: true,
    componentsProps: {
      toolbar: {
        value: searchText,
        onChange: (event: any): void => requestSearch(event && event.target && event.target.value),
        clearSearch: (): void => requestSearch(''),
      },
    },
    title: "",
    data: Results,
    columns,
    options: {
      headerStyle: {position: 'sticky', top: -1},
      pageSize: 50,
      maxBodyHeight,
      search: true,
      columnsButton: false,
      paging: true,
      filtering: false,
      thirdSortClick: true,
      searchFieldAlignment: 'left',
    },
    localization: {
      toolbar: {
        searchPlaceholder: 'Search Companies',
      },
    },
    components: {
      Pagination: (props: any) => {
        const pageOptions = {
          onPageChange: props.onChangePage,
          rowsPerPageOptions: [5, 10, 50, 100, 250, 500],
          pageSize: props.rowsPerPage.toString() * 1,
        };
        return (
          <td className="PaginationContainer" style={{overflow: 'hidden'}}>
            <Pagination {...props} {...pageOptions} />
          </td>
        );
      },
    },
  } as MaterialTableProps<any>;

  return (
    <>
      {Results?.length > 0 && (
        <div
          style={{
            height: '4rem',
            width: '100%',
            display: 'flex',
            borderBottom: '1px solid #ababab',
          }}
        >
          <div style={{padding: '20px', display: 'flex', fontSize: '1rem'}}>
            If you&apos;re happy with the data please click import to add/update the following
            records into the application.
          </div>
          <div style={{paddingTop: '15px', paddingRight: '10px', display: 'flex'}}>
            <Component.ButtonColoured
              handleClick={handleOpenDialog}
              color="primary"
              label="Import"
            />
          </div>
        </div>
      )}
      <div className="container" ref={containerRef}>
        <div className={classes.search}>
          {Results?.length >= 0 && filterComponent && filterComponent()}
          {Results && (
            <MaterialTable {...tableProps} />
          )}
        </div>
      </div>
      <AlertDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        handleConfirm={handleImport}
        title="Import Data"
        body={`Are you sure you wish to import the data?`}
      />
      <ConfirmDialog
        open={errorDialog}
        handleClose={handleCloseErrorDialog}
        title={`${errorTitle ? errorTitle : 'Error'}`}
        body={`${errorMessage ? errorMessage : 'Sorry, something went wrong uploading the data'}`}
      />
    </>
  );
};

export default ImportDataGrid;
