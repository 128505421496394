import React, {PropsWithChildren, ReactElement, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import MaterialTable, {MaterialTableProps, MTableToolbar} from 'material-table';
import {useWindowSize} from 'hooks';
import DataNotFound from 'error/DataNotFound';
import * as Component from 'components/ComponentsIndex';
import {setupPreviewPanel, showPreviewPanel} from 'store/Actions/PreviewPanel';
import {Pagination, tableIcons, useColumnsSetup, useOrderByMapping, useStyles,} from './SearchIndex';
import {hasAccessGroup} from 'okta/HasAccess';
import {useOktaAuth} from '@okta/okta-react';
import dataExporter from 'lib/dataExporter';
import auditEvent from 'views/Auditing/auditEventHelper';
import './searchResults.css';
import {getItemsFromResult} from './SetData';
import {ApiHelperContext} from 'context/ApiHelperContext';
import Route from 'models/Routes';
import {Mode} from 'models/types';
import {
  IPreviewPanel,
  IPreviewPanelState,
  ISearchResultReducerState,
  ISelectedSiteContext
} from "models/Interfaces";
import {SiteSelectedContext} from "context/SiteSelectedContext";

const SearchResults = (props: PropsWithChildren<any>): ReactElement => {
  const {
    url,
    filterStatus,
    selector,
    keyField,
    hideAddbutton,
    addAccessOnly,
    filterComponent,
    searchTitle,
    disableRowClick,
    columnSetup,
    exportDesc,
    pathName,
    tabName,
    handleDebounceUpdate,
    updateSearchParams,
    SearchParams,
    resetPage,
    setResetPage,
    paginatedResultsOnly = false,
    defaultOrderBy = 'ASC',
  } = props;

  const {isPreviewPanelOpen, isPreviewPanelExtended} = useSelector<IPreviewPanelState>(
    (state) => state.PreviewPanelReducer,
  ) as IPreviewPanel;
  const {reloadSearch} = useSelector<any>((state) => state.SearchResultsReducer) as ISearchResultReducerState;
  const [Page, setPage] = React.useState<number|undefined|string>(SearchParams?.page);
  const [PageSize, setPageSize] = React.useState(SearchParams?.pageSize);
  const [Results, setResults] = React.useState<any>();
  const [selectedRow, setSelectedRow] = React.useState(-1);
  const [loading, setLoading] = React.useState(false);
  const [fullDataLoading, setFullDataLoading] = React.useState(false);
  const [HiddenURL, setHiddenURL] = React.useState<string>();
  const [HiddenColumns, setHiddenColumns] = React.useState({});
  const dispatch = useDispatch();
  const classes = useStyles();
  const {authState} = useOktaAuth() || {};
  const canAddData = addAccessOnly ? hasAccessGroup(addAccessOnly, authState) : false;
  const context = React.useContext(ApiHelperContext);
  const apiHelper = context?.state.apiHelper;
  const tableRef = React.useRef<any>(null);
  const currentSearchTerm = SearchParams?.searchterm ? SearchParams?.searchterm.toString().trim() : ''
  const siteSelectedContext: ISelectedSiteContext | undefined  = useContext(SiteSelectedContext)

  let fetchURL = url + (filterStatus ? '/' + filterStatus : '');
  if (selector) fetchURL += '?' + selector;

  const getHiddenDataSet = async (): Promise<any> => {
    if (apiHelper) {
      setFullDataLoading(true);
      const fullHiddenData = await apiHelper.get(HiddenURL, reloadSearch);
      setFullDataLoading(false);

      return getItemsFromResult(fullHiddenData, url);
    }
    return []
  };

  const SetPaginationVariables = (page: any, pageSize: any): string => {
    let params = '';
    const pageQS = page ? page.toString().trim() : 0;
    const pageSizeQS = pageSize ? pageSize.toString().trim() : 50;
    const searchTermQS = SearchParams?.searchterm ? SearchParams?.searchterm.toString().trim() : '';
    const searchTermQSEncoded = encodeURIComponent(searchTermQS);
    const orderByQS = useOrderByMapping(SearchParams?.orderby);
    const orderByDirectionQS = SearchParams?.direction
      ? SearchParams?.direction.toString().trim()
      : defaultOrderBy;
    let fullUrl = fetchURL;
    if (paginatedResultsOnly) {
      params = `${
        selector ? '&' : '?'
      }Page=${pageQS}&PageSize=${pageSizeQS}&SearchTerm=${searchTermQSEncoded}&SortBy=${orderByQS}&Direction=${orderByDirectionQS}`;
      const hiddenURL =
        fetchURL +
        `${
          selector ? '&' : '?'
        }SearchTerm=${searchTermQSEncoded}&SortBy=${orderByQS}&Direction=${orderByDirectionQS}&PageSize=0`;

      fullUrl = fetchURL + params;
      setHiddenURL(hiddenURL);
    }
    return fullUrl;
  };

  async function getPageData(pageUrl: string): Promise<any> {
    if (pageUrl && apiHelper) {
      setLoading(true);
      const data = await apiHelper.get(pageUrl);
      const selectedRowNumber = currentSearchTerm && data.totalCount > 0 ? 0 : -1
      setResults(data);
      setSelectedRow(selectedRowNumber);
      setLoading(false);
    }
  }

  React.useEffect(() => {
    const pageToUse = resetPage ? 0 : Page;
    const url = SetPaginationVariables(pageToUse, PageSize);
    const isSiteSelected = !!siteSelectedContext?.state.selectedSite
    const isClientSelected = !!siteSelectedContext?.state.selectedClient

    if (!loading || currentSearchTerm) {
      getPageData(url);
    }

    if((isClientSelected || isSiteSelected) && currentSearchTerm && tableRef?.current?.dataManager) {
      tableRef.current.dataManager.searchText = currentSearchTerm
      siteSelectedContext?.resetState()
    }
  }, [currentSearchTerm, reloadSearch, filterStatus, selector, PageSize, Page, SearchParams, resetPage]);

  const handleAdd = React.useCallback(() => {
    setSelectedRow(-1);
    dispatch(setupPreviewPanel(url, Mode.Add, 0, 0));
    dispatch(showPreviewPanel());
  }, [dispatch, url]);

  const handleHiddenColumns = React.useCallback(
    (column: any, hidden: any) => {
      const hiddenFieldsArray: any = HiddenColumns;
      hiddenFieldsArray[column.field] = hidden
      setHiddenColumns(hiddenFieldsArray);
      return (): void => {
        setHiddenColumns({});
      };
    },
    [HiddenColumns],
  );

  const exportName = `Export - ${url} ${
    selector?.includes('discontinued') && url === 'packages' ? '(discontinued)' : ''
  }`;
  const columns = useColumnsSetup(
    columnSetup ? columnSetup : url,
    SearchParams?.direction,
    SearchParams?.orderby,
    // HiddenColumns,
  );

  const handleSortBy = React.useCallback(
    (columnId: string | number, order: any) => {
      const Column = columns?.length > 0 && columns[columnId];
      updateSearchParams('orderby', Column?.field);
      updateSearchParams('direction', order);
      updateSearchParams('page', 0);
      setPage(0);
    },
    [columns, updateSearchParams],
  );

  const [ExportState, setExportState] = React.useState<any>('');
  const handleExportTo = (category: any): void => {
    const description = auditEvent.setDescription(pathName, tabName);
    setExportState('');
    setExportState((state: any) => ({...state, description}));
    setExportState((state: any) => ({...state, category}));
  };

  React.useEffect(() => {
    if (ExportState?.category && apiHelper) {
      apiHelper.post(Route.Audit, ExportState);
    }
  }, [ExportState, apiHelper]);

  const size = useWindowSize();
  const [maxBodyHeight, setMaxBodyHeight] = React.useState(0);
  const containerRef = React.useRef<any>(null);
  const toolbarRef = React.useRef<any>(null);

  React.useEffect(() => {
    let height = containerRef?.current?.clientHeight;
    // Size of the Window - 13rem to work out spacing around the Body + top & bottom border line
    if (!height) height = size?.height - 13 * 16 + 2;
    const toolbarHeight = toolbarRef?.current ? toolbarRef.current.clientHeight : 64;
    setMaxBodyHeight(height - toolbarHeight);
  }, [url, size]);

  // Set focus on Search bar
  React.useEffect(() => {
    const element: any = document.querySelector(`#SearchBar input`)
    if (element) {
      element.focus();
    }
  });

  useEffect(() => {
    if(resetPage) {
      updateSearchParams('page', 0)
      setPage(0)
      setResetPage(false)
      tableRef?.current.dataManager.changeCurrentPage(0)
    }
  }, [resetPage])

  const ensureColumnsAreHidden = (columns: any, hiddenFields: any): any => {
    const columnStructure: any = []
    columns.forEach((col: any) => {
      if(hiddenFields?.[col.field] !== undefined) {
        col.hidden = hiddenFields?.[col.field]
        col.hiddenByColumnsButton = true
      }
      columnStructure.push(col)
    })

    return columnStructure;
  }

  const checkedColumns = ensureColumnsAreHidden(columns, HiddenColumns)
  const itemsOnly = getItemsFromResult(Results, url);
  const tableProps = {
    title: "",
    icons: tableIcons,
    data: itemsOnly,
    isLoading: loading || fullDataLoading,
    columns: checkedColumns,
    onRowClick: (e, rowIndex) => {
      if (!disableRowClick) {
        keyField === 'code' && dispatch(setupPreviewPanel(url, Mode.View, rowIndex.code));
        keyField === 'id' && dispatch(setupPreviewPanel(url, Mode.View, rowIndex.id));
        dispatch(showPreviewPanel());
        setSelectedRow(rowIndex.tableData.id);
      }
    },
    onSearchChange: (value) => {
      updateSearchParams('page', 0);
      setPage(0);
      if (paginatedResultsOnly) {
        handleDebounceUpdate('searchterm', value);
      }
    },
    onOrderChange: (columnId, order) => paginatedResultsOnly && handleSortBy(columnId, order),
    onChangeColumnHidden: (column, hidden) => {
      handleHiddenColumns(column, hidden)
    },
    // https://material-table.com/#/docs/all-props
    options: {
      //grouping: true,
      headerStyle: {position: 'sticky', top: -1},
      maxBodyHeight,
      page: parseInt(Page + ''),
      pageSize: parseInt(PageSize),
      search: true,
      columnsButton: true,
      paging: true,
      filtering: false,
      thirdSortClick: false,
      exportCsv: async (columns: any) => {
        let exportDataSet = itemsOnly;
        if (paginatedResultsOnly) {
          exportDataSet = await getHiddenDataSet();
        }
        dataExporter.exportCsv(columns, exportDataSet, exportDesc ? exportDesc : exportName);
        handleExportTo('ExportToCSV');
      },
      exportPdf: async (columns: any) => {
        let exportDataSet = itemsOnly;
        if (paginatedResultsOnly) {
          exportDataSet = await getHiddenDataSet();
        }
        dataExporter.exportPdf(columns, exportDataSet, exportDesc ? exportDesc : exportName);
        handleExportTo('ExportToPDF');
      },
      exportButton: fullDataLoading ? false : {csv: true, pdf: true},
      exportAllData: !fullDataLoading,
      exportFileName: exportDesc ? exportDesc : exportName,
      emptyRowsWhenPaging: false,
      padding: 'dense',
      showEmptyDataSourceMessage: !loading,
      searchFieldAlignment: 'left',
      toolbarButtonAlignment: 'right',
      rowStyle: (rowData) => ({
        backgroundColor: selectedRow === rowData.tableData.id && '#5e7698',
        color: selectedRow === rowData.tableData.id && '#fff',
      }),
    },
    localization: {
      toolbar: {
        searchPlaceholder: searchTitle && `Search ${searchTitle}`,
      },
      body: {
        emptyDataSourceMessage: 'No records found',
      },
    },
    components: {
      Action: () => {
        return (
          canAddData &&
          !hideAddbutton &&
          !isPreviewPanelOpen && (
            <Component.ButtonColoured color="primary" label="Add" handleClick={handleAdd}/>
          )
        );
      },
      OverlayLoading: () => {
        return <Component.Loading/>;
      },
      Toolbar: (props) => (
        <div style={{height: '64px'}} ref={toolbarRef} id="SearchBar">
          <MTableToolbar {...props} />
        </div>
      ),
      Pagination: (props) => (
        <td className="PaginationContainer">
          <Pagination
            {...props}
            id="pager"
            page={parseInt(Page + '')}
            pageSize={parseInt(PageSize)}
            totalCount={paginatedResultsOnly && Results?.totalCount}
            onPageChange={(event: any, page: any): void => {
              props.onChangePage(event, page);
              setPage(parseInt(page));
              if (paginatedResultsOnly) {
                updateSearchParams('page', parseInt(page));
              }
            }}
            onChangeRowsPerPage={(event: any): void => {
              props.onChangeRowsPerPage(event);
              setPageSize(parseInt(event.target.value));
              setPage(0);
              if (paginatedResultsOnly) {
                updateSearchParams('page', 0);
                updateSearchParams('pageSize', parseInt(event.target.value));
              }
            }}
            rowsPerPageOptions={[50, 100, 250, 500]}
          />
        </td>
      ),
    },
    actions: [
      {
        icon: '',
        tooltip: 'Add Item',
        isFreeAction: true,
        onClick: (): string => '',
      },
    ],
  } as MaterialTableProps<any>;

  const writeTable = React.useMemo(
    () => (
      <div
        className={`${classes.search} ${isPreviewPanelOpen && classes.searchExpanded50} ${
          isPreviewPanelExtended && classes.searchExpanded75
        }`}
      >
        <MaterialTable tableRef={tableRef} {...tableProps}  />
      </div>
    ),
    [
      Results,
      canAddData,
      classes.search,
      classes.searchExpanded50,
      classes.searchExpanded75,
      columns,
      exportName,
      hideAddbutton,
      isPreviewPanelExtended,
      isPreviewPanelOpen,
      keyField,
      Page,
      PageSize,
      selectedRow,
      url,
      tabName,
    ],
  );

  if (Results?.errors?.status) return <DataNotFound/>;

  return (
    <div className="container" ref={containerRef}>
      {itemsOnly?.length >= 0 && filterComponent && filterComponent()}
      {writeTable}
    </div>
  );
};

export default SearchResults;
