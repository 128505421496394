import { SET_DATACENTER } from '../ActionTypes/DataCenter';

const initialState = {
  dataCenterId: '',
  dataCenterName: '',
};

function DataCenterReducer(state = initialState, action) {
  switch (action.type) {
  case SET_DATACENTER:
    return {
      ...state,
      dataCenterId: action.payload.dataCenterId,
      dataCenterName: action.payload.dataCenterName,
    };
  default:
    return state;
  }
}

export default DataCenterReducer;
