import React from 'react';
import {ContainerRow} from 'styles/styledComponents/styledComponents';

interface IPageItem {
  description?: string | undefined;
  name?: string | undefined;
  label?: string | undefined;
}

interface IPageItemList {
  name?: string | undefined;
  items: Array<IPageItem> | undefined;
  minWidth?: string | undefined;
  nolastItemBorder?: boolean | undefined;
  itemValue?: string | undefined;
  indentTitle?: boolean;
  dataTestId?: string;
}

const PageItemList = (props: IPageItemList): React.ReactElement => {
  const {name, items, minWidth, nolastItemBorder, itemValue, indentTitle = false, dataTestId} = props;
  const checkedItems = items && !Array.isArray(items) ? JSON.parse(items) : items
  return (
    <ContainerRow className={`item-container ${nolastItemBorder && 'no-last-border'}`}>
      <div className={`item-name ${indentTitle && 'item-name-indent'}`} style={{minWidth: minWidth}}>
        {name}
      </div>
      <div className="data-grid" key='page-item-list' data-testid={dataTestId}>
        {checkedItems &&
        Array.isArray(checkedItems) &&
        checkedItems.length > 0 &&
        checkedItems.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <div className="data-grid-item">
                <div className="item-value" role="listitem">
                  {itemValue === 'name' ?  item.name || item.Name : ''}
                  {itemValue === 'description' ? item.description || item.Description: ''}
                  {itemValue === 'label' ?  item.label || item.Label : ''}
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </ContainerRow>
  );
};

export default PageItemList;
