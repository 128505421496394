import React, {ReactElement} from 'react';
import Routes from 'navigation/Routes';
import { Provider } from 'react-redux';
import store from 'store/store';
import { ThemeProvider } from '@material-ui/styles';
import theme from 'styles/css/theme';
import 'styles/css/atlas.scss';
import 'styles/css/styles.scss';
import Sentry from 'lib/logger';
import { BrowserRouter } from 'react-router-dom';
import Layout from 'layout/Layout';
import ApiHelperContextProvider from 'context/ApiHelperContext';
import SiteSelectedContextProvider from "context/SiteSelectedContext";

const App = (): ReactElement => (
  <Sentry.ErrorBoundary fallback={<h1>Something went wrong.</h1>} showDialog>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SiteSelectedContextProvider>
          <ApiHelperContextProvider>
            <BrowserRouter>
              <Layout>
                <Routes />
              </Layout>
            </BrowserRouter>
          </ApiHelperContextProvider>
        </SiteSelectedContextProvider>
      </ThemeProvider>
    </Provider>
  </Sentry.ErrorBoundary>
);

export default Sentry.withProfiler(App);
