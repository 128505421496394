import React, {PropsWithChildren, ReactElement} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const TablePaginationActions =(props: PropsWithChildren<any>): ReactElement =>{
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: any): void => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: any): void => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: any): void => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: any): void => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const placeHolderWidth = (): string => {
    const displayCount = (page + 1) * rowsPerPage;
    if (page === 0) {
      if (count < 10) {
        return '55px';
      }
      if (count < 100) {
        return '65px';
      }
      if (count > 1000) {
        if (rowsPerPage >= 100) return '90px';
        return '80px';
      }
    }
    if (count < 100) {
      return '75px';
    }
    if (displayCount < 100) {
      if (rowsPerPage >= 100) return '75px';
      return '70px';
    }
    if (displayCount > 100 && displayCount < 1000) {
      return '105px';
    }
    if (displayCount > 1000) return '120px';
    return '95px';
  };

  return (
    <div id="pagerActions" className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? (
          <i className="i-bpow-chevron-right" style={{ fontSize: '1rem', color: '#333' }} />
        ) : (
          <i className="i-bpow-chevron-left" style={{ fontSize: '1rem', color: '#333' }} />
        )}
      </IconButton>
      <span className="countPlaceHolder" style={{ width: placeHolderWidth() }} />
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <i className="i-bpow-chevron-left" style={{ fontSize: '1rem', color: '#333' }} />
        ) : (
          <i className="i-bpow-chevron-right" style={{ fontSize: '1rem', color: '#333' }} />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const Pagination = (props: PropsWithChildren<any>): ReactElement => {
  const {
    count,
    totalCount,
    onPageChange,
    page,
    pageSize,
    onChangeRowsPerPage,
    rowsPerPageOptions,
  } = props;

  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions.map((value: any) => {
        return { label: `${value} rows`, value: parseInt(value) };
      })}
      colSpan={3}
      component="div"
      count={totalCount ? parseInt(totalCount) : parseInt(count)}
      rowsPerPage={parseInt(pageSize)}
      labelRowsPerPage=""
      page={page}
      SelectProps={{
        inputProps: { 'aria-label': '' },
        native: true,
      }}
      onPageChange={onPageChange}
      onRowsPerPageChange={onChangeRowsPerPage}
      ActionsComponent={TablePaginationActions}
    />
  );
}

export default Pagination;
