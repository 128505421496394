import React from 'react';
import Select from 'react-select';
import { editFormStyles } from './Common';
import PropTypes from 'prop-types';

const EditSelect = (props) => {
  const {
    value,
    selected,
    handleChange,
    name,
    options = [],
    placeholder = '',
    isMulti,
    required,
    disabled,
    hasError,
    menuPlacement,
  } = props;
  const classes = editFormStyles();
  const fontColor = '#000';
  const iconColor = 'rgba(0, 0, 0, 0.54)';

  const colourStyles = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? 0 : 0,
      boxShadow: state.isFocused ? 0 : 0,
      '&:hover': {
        border: state.isFocused ? 0 : 0,
      },
      color: fontColor,
    }),
    placeholder: (styles) => {
      return {
        ...styles,
        color: fontColor,
      };
    },
    dropdownIndicator: (styles) => {
      return {
        ...styles,
        color: iconColor,
      };
    },
    clearIndicator: (styles) => {
      return {
        ...styles,
        color: iconColor,
      };
    },
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      const color = '#e6e6e6';
      return {
        ...styles,
        backgroundColor: isDisabled ? null : isSelected ? color : isFocused ? color : null,
        color: '#000',
        fontSize: '100%',
        cursor: isDisabled ? 'not-allowed' : 'default',
        ':active': {
          ...styles[':active'],
          fontSize: '100%',
          backgroundColor: !isDisabled && (isSelected ? color : color),
        },
        ':hover': {
          ...styles[':hover'],
          backgroundColor: !isDisabled && (isSelected ? color : color),
        },
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: '#e6e6e6',
        font: '#333',
        fontSize: '14px',
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      backgroundColor: '#e6e6e6',
      font: '#333',
      fontSize: '14px',
    }),
    noOptionsMessage: (base) => ({
      ...base,
      color: '#333',
      backgroundColor: '#fff',
    }),
  };

  return (
    <>
      {isMulti && (
        <Select
          value={selected}
          name={name}
          onChange={handleChange}
          isMulti
          className={`${classes.selectField} ${hasError ? classes.error : ''}`}
          options={options}
          styles={colourStyles}
          isClearable="true"
          placeholder={placeholder}
          isDisabled={disabled}
          noOptionsMessage={() => 'No results found'}
          menuPlacement={menuPlacement ? menuPlacement : 'auto'}
        />
      )}
      {!isMulti &&
        (required ? (
          <Select
            value={options && options.length > 0 && options.filter((option) => option.value === value)}
            name={name}
            onChange={handleChange}
            className={`${classes.selectField} ${hasError ? classes.error : ''}`}
            required={required}
            options={options}
            styles={colourStyles}
            isClearable="true"
            placeholder={placeholder}
            isDisabled={disabled}
            menuPlacement={menuPlacement ? menuPlacement : 'auto'}
          />
        ) : (
          <Select
            value={options && options.length > 0 && options.filter((option) => option.value === value)}
            name={name}
            onChange={handleChange}
            className={`${classes.selectField}`}
            options={options}
            styles={colourStyles}
            isClearable="true"
            placeholder={placeholder}
            isDisabled={disabled}
            menuPlacement={menuPlacement ? menuPlacement : 'auto'}
          />
        ))}
    </>
  );
};

EditSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  handleChange: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  isMulti: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  hasError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  menuPlacement: PropTypes.string,
};

export default EditSelect;
