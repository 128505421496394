export const slugify = (text) => {
  const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;';
  const to = 'aaaaaeeeeeiiiiooooouuuunc------';

  const newText = text
    .split('')
    .map((letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)));

  return newText
    .toString() // Cast to string
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-y-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-'); // Replace multiple - with single -
};

/**
 * A function to take a string written in dot notation style, and use it to
 * find a nested object property inside of an object.
 *
 * @param propertyName
 * @param object
 * @returns {*}
 */
export const getProperty = (propertyName, object) => {
  const parts = propertyName.split('.');
  const length = parts.length;

  let property = object || this;

  for (let i = 0; i < length; i++) {
    if (property && {}.hasOwnProperty.call(property, parts[i])) {
      property = property[parts[i]];
    }
  }

  return property;
};

export const removeNewlines = (str) => {
  if (!isNaN(str)) {
    return str;
  }
  if (!str) {
    return '';
  }
  str = str.toString();
  str = str.replace(/\s{2,}/g, ' ');
  str = str.replace(/\t/g, ' ');
  str = str.replace(/"/g, '');
  str = str.trim().replace(/(\r\n|\n|\r)/g, '');
  return str;
};
