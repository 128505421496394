import React from 'react';
import {editFormStyles, FormStatus} from './Common';
import Input from '@material-ui/core/Input';

interface IReadOnlyText {
  min?: number;
  max?: number;
  value?: string | number;
  targetName: string;
  status?: string;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
}

const ReadOnlyText = (props: IReadOnlyText): React.ReactElement => {
  const {
    value,
    targetName,
    disabled,
    status,
    placeholder,
    min,
    max = 99999,
    type = 'text'
  } = props;
  const classes = editFormStyles();
  const inValid = value && min && (value < min || value > max)
  let inputProps = undefined
  if (type === 'number') {
    inputProps = {min, max, pattern: '[0-9]+'};
  }

  return (
    <Input
      name={targetName}
      type={type}
      inputProps={inputProps}
      className={`${classes.textField} ${classes.grayedOut} ${inValid && FormStatus && (status === FormStatus.DIRTY) ? classes.error : ''}`}
      readOnly={true}
      disabled={!!disabled}
      value={value || undefined}
      disableUnderline={true}
      placeholder={placeholder}
      style={{
        backgroundColor: disabled ? 'hsl(0, 0%, 95%)' : '',
      }}
    />
  );
};

export default ReadOnlyText;