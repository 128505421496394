import React from 'react';
import * as Component from 'components/ComponentsIndex';
import * as Dialog from 'components/Dialog/DialogIndex';
import { useFetch } from 'hooks';
import { VerbType } from 'models/Routes';
import Tooltip from 'components/Tooltip/Tooltip';

interface ISSOMetaFile { identityServerSiteId: string | undefined, DSN: string | undefined }

const SSOMetaFile = (props: ISSOMetaFile) : React.ReactElement => {
  const {identityServerSiteId, DSN} = props
  const [MetaDetails, setMetaDetails] = React.useState('');
  const [OpenDialog, setOpenDialog] = React.useState(false);

  const { data } = useFetch(VerbType.GET, identityServerSiteId ? `IdentityServerSites/${identityServerSiteId}` : '');
  React.useEffect(() => {
    setMetaDetails((state: any) => ({ ...state, url: data?.url, dsn: DSN }));
  }, [DSN, data]);

  const handleOpenDialog = (): void => {
    setOpenDialog(true);
  };
  const handleCloseDialog = (): void => {
    setOpenDialog(false);
  };
  return (
    <>
      <Tooltip title='Generate SSO Meta File'>
        <Component.Button handleClick={handleOpenDialog} color="primary" label="Meta File" minWidth='85px' />    
      </Tooltip>
      <Dialog.FormDialog
        open={OpenDialog}
        handleClose={handleCloseDialog}
        title="SSO Meta File"
        formComponent="SSOMetaFile"
        state={MetaDetails}
        setState={setMetaDetails}
        height='400px'
        maxwidth='lg'
        buttonDoneOnly={true}
      />
    </>
  );
};

export default SSOMetaFile;
