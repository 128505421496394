import React, {PropsWithChildren} from 'react';
import * as Redux from 'react-redux';
import { useFetch } from 'hooks'
import * as ViewComponent from 'views/Common/ViewForm/ViewIndex';
import * as Component from 'components/ComponentsIndex';
import { ContainerRow, ContainerCenter } from 'styles/styledComponents/styledComponents'
import { FormDialog } from 'components/Dialog/DialogIndex'
import { useOktaAuth } from '@okta/okta-react'
import { setupPreviewPanel } from 'store/Actions/PreviewPanel'
import { refreshSearchResults } from 'store/Actions/SearchResults'
import { ADMIN, hasAccessGroup, IMPLEMENTATION, PRODOPS, SALES } from 'okta/HasAccess'
import shortid from 'shortid'
import { FormStatus } from 'views/Common/EditForm/Common'
import { useHistory } from 'react-router-dom'
import { ErrorDialog, handleError, useErrorState } from 'error/HandleErrors'
import { ApiHelperContext } from 'context/ApiHelperContext'
import TenantStatus from 'models/Lists/TenantStatus'
import Route from 'models/Routes'
import { getAssignSiteErrors } from 'lib/ErrorsHelper'
import notify, { MessageType, Message } from 'components/Notifications/toastify';
import {IPreviewPanelState, ISearchResultReducerState} from "models/Interfaces";
import {IError, IPageItem, IPreviewPanel, ITenant} from "models/Interfaces";
import {Props} from "models/types";

const OrganisationTenants: React.FC<Props> = (props: PropsWithChildren<any>) => {
  const {reloadSearch} = Redux.useSelector<any>((state) => state.SearchResultsReducer) as ISearchResultReducerState;
  const { refreshPanel } = Redux.useSelector<IPreviewPanelState>((state) => state.PreviewPanelReducer) as IPreviewPanel;
  const { orgId, minWidth = '10rem', editMode, nolastItemBorder } = props;
  const { data, loading } = useFetch('GET', orgId && orgId > 0 ? `Organisations/${orgId}/tenants` : '', refreshPanel );
  const today = new Date();
  const context = React.useContext(ApiHelperContext);
  const apiHelper = context?.state.apiHelper;
  const [AssignOpen, setAssignOpen] = React.useState(false);
  const [Tenants, setTenants] = React.useState<ITenant[]>();
  const [status, setStatus] = React.useState(FormStatus.UNTOUCHED);
  const dispatch = Redux.useDispatch();
  const history = useHistory();
  const { errorDialog, errorMessage, errorTitle, setErrorDialog, setErrorMessage, setErrorTitle } = useErrorState();
  const { authState } = useOktaAuth() || {};

  const initialState = {    
    assignedOn: today,
    tenantStatus: TenantStatus.Pending,
    tenantPurpose: 'Production',
    edition: 'BlueprintOneWorld',
    assignedByName: authState?.idToken?.claims?.name,
    assignedByEmail: authState?.idToken?.claims?.email,
    adminUser: authState?.idToken?.claims?.email,
  };

  const [AssignSite, setAssignSite] = React.useState(initialState);
  const errors = getAssignSiteErrors(AssignSite as any);
  const isValid = Object.keys(errors).length === 0;

  React.useEffect(() => {
    setTenants('' as any);
  }, [orgId]);

  React.useEffect(() => {
    setTenants(data);
  }, [data]);

  const handleClickOpen = (): void => {
    setAssignOpen(true);
  };

  const handleClose = (): void => {
    setAssignOpen(false);
    setStatus(FormStatus.UNTOUCHED);
  };

  const handleAssignSite = (): void => {
    if (isValid && AssignSite && apiHelper) {
      apiHelper.post(`Organisations/${orgId}/tenant/assign`, AssignSite, callbackSuccessFunc, callbackErrorFunc);
    } else {
      setStatus(FormStatus.DIRTY);
    }
  };

  function callbackErrorFunc(error: IError): void {
    handleError(error, setErrorTitle, setErrorMessage, setErrorDialog);
  }

  const handleCloseDialog = (): void => {
    setErrorDialog(false);
  };

  function callbackSuccessFunc(data: IError): void {
    if (data?.status >= 400) {
      callbackErrorFunc(data);
    } else {
      setAssignOpen(false);
      setStatus(FormStatus.UNTOUCHED);
      setAssignSite(initialState);
      handleCallBack();
    }
  }

  const handleCallBack = (): void => {
    dispatch(setupPreviewPanel(Route.Clients, 'view', orgId, !refreshPanel));
    dispatch(refreshSearchResults(!reloadSearch, 1));
    history.push('/' + Route.Clients);
    notify(Message.SiteAssignedPending, MessageType.Success);
  };

  const editTenants = hasAccessGroup([ADMIN, PRODOPS, SALES], authState);
  const canAssignSites = hasAccessGroup([ADMIN, IMPLEMENTATION, PRODOPS, SALES], authState);
  const pageAtttibutes = {
    value:"No Sites assigned",
    minWidth,
    nolastItemBorder,
  } as IPageItem;

  return (
    <>
      {!editMode && canAssignSites && (
        <ContainerRow className="item-container" style={{ background: '#f6f6f6', padding: '10px' }}>
          <Component.ButtonColoured handleClick={handleClickOpen} color="primary" label="Assign Site" />
        </ContainerRow>
      )}
      {(editMode || !editTenants) && <ViewComponent.PageItemBreak />}
      {
        loading ? 
          <ContainerCenter className="item-container" style={{ background: '#f6f6f6', padding: '10px' }}>
            <Component.Loading /> 
          </ContainerCenter>
          :
          Tenants && Tenants.length > 0 ? (
            Tenants.map((Tenant) => (
              <React.Fragment key={shortid.generate()}>
                <Component.Accordion
                  itemId={Tenant.tenantId}
                  state={Tenant}
                  items="OrganisationTenants"
                  minWidth={minWidth}
                  editMode={editMode} 
                  copyText={Tenant.url}  
                />
              </React.Fragment>
            ))
          ) : (
            <ViewComponent.PageItem {...pageAtttibutes} />
          )
      }
      <FormDialog
        open={AssignOpen}
        handleClose={handleClose}
        handleConfirm={handleAssignSite}
        title="Assign Site"
        formComponent="assignTenant"
        state={AssignSite}
        setState={setAssignSite}
        buttonCancel='Cancel'
        buttonOK='Assign'
        status={status}
        isValid={isValid}
        FormStatus={FormStatus}
        errors={errors}
      />
      <ErrorDialog
        errorDialog={errorDialog} 
        handleCloseDialog={handleCloseDialog}
        errorTitle={errorTitle}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default OrganisationTenants;
