import React, {PropsWithChildren} from 'react';
import * as Redux from 'react-redux';
import { useFetch } from 'hooks';
import LicenseItemsWithUsage from 'views/Common/ViewForm/LicenseItemsWithUsage';
import {IPreviewPanelState} from "models/Interfaces";
import {Props} from "models/types";
import {ILicenseViewWithUsage, IPreviewPanel} from "models/Interfaces";
import { VerbType } from 'models/Routes';

const License: React.FC<Props> = (props: PropsWithChildren<any>) => {
  const {
    Id,
    nolastItemBorder,
    summaryOnly,
    minWidth = '10rem',
    setSummary
  } = props;
  const { refreshPanel } = Redux.useSelector<IPreviewPanelState>((state) => state.PreviewPanelReducer) as IPreviewPanel;
  const { data } = useFetch(VerbType.GET, Id ? `Tenants/${Id}/organisations` : '', refreshPanel);
  const [License, setLicense] = React.useState();
  const [LicenseUsage, setLicenseUsage] = React.useState();
  
  React.useEffect(() => {
    setLicenseUsage(data && data.length > 0 && data[0].licenseUsage);
    setLicense(data && data.length > 0&& data[0].licenseDetails);
    if (data && data.length > 0 && data[0].licenseId) {
      setSummary(`License - ${data[0].license} - (LS)`);
    } else {
      setSummary(`License - (LS)`);
    } 
  }, [data, refreshPanel, setSummary]);

  const attributes = {
    License,
    LicenseUsage,
    minWidth,
    summaryOnly,
    nolastItemBorder
  } as ILicenseViewWithUsage

  return (
    <>
      <LicenseItemsWithUsage {...attributes} />
    </>
  );
};

export default License;
