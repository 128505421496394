import React from 'react';
import '../popoutPanel.css';

const WriteApiVersion = (props: { ApiVersion: string | undefined; }): React.ReactElement => {
  const { ApiVersion } = props;
  return (
    <div className="alignRight">{ApiVersion && `v${ApiVersion} (api)`}</div>
  );
}

export default WriteApiVersion;
