import React, {PropsWithChildren} from 'react';
import { useFetch } from 'hooks';
import Select from '../Select';
import { IReactSelect } from 'models/Lists/Common';
import { VerbType } from 'models/Routes';

interface IPackages { 
  id?: string; 
  setState: React.Dispatch<React.SetStateAction<unknown>>;
  targetName: string;
  state?: any;
  packageSelector?: string;
  required?: boolean;
  hasError?: boolean;
}

interface IPackage { id: number; name: string; }

const Packages = (props: PropsWithChildren<any>): React.ReactElement => {
  const { id, setState, targetName, packageSelector, required, hasError } = props as IPackages;
  let url = 'Packages';
  if (packageSelector) url += `?packageSelector=${packageSelector}`;

  const { data } = useFetch(VerbType.GET, url);

  const handleChange = ((e: IReactSelect): void => {
    setState((state: string[]) => ({
      ...state,
      [targetName]: e?.value || '',
    }));
  });

  let options: unknown[] | null | undefined = [];

  if (data) {
    options = (data as IPackage[]).map((Package) => ({
      label: Package.name,
      value: Package.id,
    }));
  }

  return (
    <Select
      value={id}
      name={targetName}
      handleChange={handleChange}
      options={options}
      hasError={hasError}
      required={required}
    />
  );
};

export default Packages;
