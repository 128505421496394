import React from 'react';
import CopyToClipboard from './CopyToClipboard';
import { ContainerRow } from 'styles/styledComponents/styledComponents';
import './viewForm.css';
import {IPageItem} from "models/Interfaces";

const PageItemCompare = (props : IPageItem) : React.ReactElement => {
  const { name, value, newValue, nolastItemBorder, minWidth, icon, indentTitle = false, copyToClipboard, dataTestId} = props;
  const comparison = `${value} to ${newValue}`.trim();
  return (
    <ContainerRow className={`item-container ${nolastItemBorder && 'no-last-border'}`}>
      <div className={`item-name ${indentTitle && 'item-name-indent'} 
        ${copyToClipboard && 'displayFlex'}`} style={{ minWidth: minWidth }}>
        <div style={{width: '100%'}}>{name}</div>      
        {icon && <div style={{marginLeft: '3px', display: 'inline-block'}}>{icon}</div>}
        {copyToClipboard && (typeof newValue === 'string') && <CopyToClipboard value={newValue} />}
      </div>
      <div className="values-container">
        <div className="item-value" data-testid={dataTestId}>{comparison}</div>
      </div>
    </ContainerRow>
  );
};

export default PageItemCompare;
