import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const SpinnerContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '75%',
});

const Loading = () => {
  return (
    <SpinnerContainer>
      <CircularProgress data-cy="Spinner" />
    </SpinnerContainer>
  );
};

export default Loading;
