const {
  SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN,
  API_URL = process.env.REACT_APP_API_URL,
  ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT,
  APP_SERVER_APIVERSION = process.env.REACT_APP_APP_SERVER_APIVERSION,
  APP_NAME = process.env.REACT_APP_APP_NAME,
  APP_VERSION = process.env.REACT_APP_APP_VERSION,
  OKTA_CLIENTID = process.env.REACT_APP_OKTA_CLIENTID,
  OKTA_URL = process.env.REACT_APP_OKTA_URL,
} = (window as any)?.__env__ || {};

export const env: Environment = {
  apiUrl: API_URL,
  sentryDsn: SENTRY_DSN,
  env: ENVIRONMENT,
  apiVersion: APP_SERVER_APIVERSION,
  appName: APP_NAME,
  appVersion: APP_VERSION,
  okta_clientId: OKTA_CLIENTID,
  okta_url: OKTA_URL,
};

type Environment = {
  apiUrl: string;
  sentryDsn: string;
  env: string;
  apiVersion: number;
  appName: string;
  appVersion: string;
  okta_clientId: string;
  okta_url: string;
};
