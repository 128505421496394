import React, {PropsWithChildren} from 'react';
import PackageView from 'views/Common/ViewForm/PackageView';
import FeatureView from 'views/Common/ViewForm/FeatureView';
import LicenseView from 'views/Common/ViewForm/LicenseView';
import TenantView from 'views/Common/ViewForm/TenantView';
import OrganisationView from 'views/Common/ViewForm/OrganisationView';
import PageNotFound from 'error/PageNotFound';
import { PageItemBreak, PageTitle } from 'views/Common/ViewForm/ViewIndex';
import {ITenantView} from "models/Interfaces";
import {Props} from "models/types";

const GetView: React.FC<Props>  = (props: PropsWithChildren<any>) => {
  const {category, component} = props
  const tenantAttr = {Tenant: component} as ITenantView
  switch (true) {
  case category.includes('Package'):
    return <PackageView Package={component} />;
  case category.includes('Feature'):
    return <FeatureView Feature={component} />;
  case category.includes('License'):
    return <LicenseView License={component} />;
  case category.includes('Tenant'):
    return <TenantView {...tenantAttr} />;
  case category.includes('Organisation'):
    return <OrganisationView Organisation={component} />;
  default:
    return <PageNotFound />;
  }
}

const AuditViews: React.FC<Props> = (props: any) => {
  const { category, originalValue, currentValue } = props;
  return (
    <>
      <div>
        <PageTitle name="Original" />
        <GetView {...{category, originalValue} as PropsWithChildren<any>} />
        <PageItemBreak />
        <PageTitle name="Current" />
        <GetView {...{category, currentValue} as PropsWithChildren<any>}/>
      </div>
    </>
  );
};

export default AuditViews;
