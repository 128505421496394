import React from 'react';
import { ContainerRow } from 'styles/styledComponents/styledComponents';
import { TextArea } from './EditIndex';

interface IFields { 
  dsn?: string | undefined;
  url?: string | undefined;
}

interface ISSOMetaFile { 
  state? : IFields;
}

const SSOMetaFile = (props : ISSOMetaFile) : React.ReactElement => {
  const { state } = props;

  const MetaFile = `<EntityDescriptor ID="_8ce9ba4a-9e5f-4ab6-b361-0b380611590f" entityID="${state?.url}/${state?.dsn}" `+ 
   `p1:saml2="urn:oasis:names:tc:SAML:2.0:assertion" cacheDuration="PT1H" xmlns:p1="xmlns" xmlns="urn:oasis:names:tc:SAML:2.0:metadata"> \n`+
      `\t<SPSSODescriptor protocolSupportEnumeration="urn:oasis:names:tc:SAML:2.0:protocol"> \n`+
          `\t\t<SingleLogoutService Binding="urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST" Location="${state?.url}/" /> \n`+
          `\t\t<SingleLogoutService Binding="urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect" Location="${state?.url}/" /> \n`+
          `\t\t<AssertionConsumerService Binding="urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST" Location="${state?.url}/${state?.dsn}/SAML/saml/acs" index="1" /> \n`+
          `\t\t<AssertionConsumerService Binding="urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect" Location="${state?.url}/${state?.dsn}/SAML/saml/acs" index="2" /> \n`+
          `\t\t<AssertionConsumerService Binding="urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Artifact" Location="${state?.url}/${state?.dsn}/SAML/saml/acs" index="3" /> \n`+
      `\t</SPSSODescriptor> \n`+
  `</EntityDescriptor>`

  return (
    <>
      {(!state?.dsn || !state?.url) &&
        <ContainerRow className={`item-container`}>
          <div className="errors">
            <p>Please fix the following errors:</p>
            <ul>
              {!state?.dsn && <li style={{width: '100%'}}>Missing Site DSN</li>}
              {!state?.url && <li style={{width: '100%'}}>Missing Identity Server URL </li>}
            </ul>
          </div>
        </ContainerRow>
      }
      <ContainerRow className="item-container fullBorder">
        <TextArea
          value={MetaFile}
          rows={18}
        />
      </ContainerRow>
    </>
  );
};

export default SSOMetaFile;
