import React, { useEffect, useState } from 'react';
import * as ViewComponent from './ViewIndex';
import { ISite } from 'models/Interfaces';
import useFetch from 'hooks/useFetch';
import { VerbType } from 'models/Routes';

const TenantAssignment = (props: { minWidth?: string; Tenant: ISite; nolastItemBorder?: boolean; indentTitle?: boolean }): React.ReactElement => {
  const { minWidth, Tenant, nolastItemBorder, indentTitle } = props;
  const [AdminUser, setAdminUser] = useState<string>();
  const { data, error, loading } = useFetch(VerbType.GET, 'Okta/ImplementationUsers');
  const adminUser = Tenant?.assignedOrganisation?.adminUser ? `${Tenant?.assignedOrganisation?.adminUser}` : 'None';

  useEffect(() => {
    if (data && !loading && !error) {
      const adminDetails = data.filter( (p: { profile: { email: string; }; }) => p.profile.email === adminUser )[0];
      const fullname = adminDetails ? adminDetails.profile?.firstName + ' ' + adminDetails?.profile?.lastName : '';
      const details = fullname ? fullname + ' (' + adminUser + ')' : adminUser; 
      setAdminUser(details);
    }
  }, [adminUser, data, error, loading]);

  return (
    <>
      <ViewComponent.PageTitle
        name="Pending"
        backgroundColor="#5e7698"
        color="#fff"
        dataTestId="PageTitle"
      />
      <ViewComponent.PageItem name="Assigned By" minWidth={minWidth} value={Tenant?.assignedOrganisation?.assignedByName} 
        indentTitle={indentTitle} nolastItemBorder={nolastItemBorder} copyToClipboard={true} />
      <ViewComponent.PageEmailItem name="Email" minWidth={minWidth} value={Tenant?.assignedOrganisation?.assignedByEmail} 
        indentTitle={indentTitle} nolastItemBorder={nolastItemBorder} copyToClipboard={true} />
      <ViewComponent.PageItem name="Admin User" minWidth={minWidth} value={AdminUser} 
        indentTitle={indentTitle} nolastItemBorder={nolastItemBorder} copyToClipboard={true} />
    </>
  );
};

export default TenantAssignment;
