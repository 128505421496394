import { useMemo } from 'react';
import Route from 'models/Routes';
import { ITabs, TabType } from 'models/types';

const useHeaderTabs = (): ITabs => {
  const tabs: TabType[] = useMemo(
    (): TabType[] => [
      {
        text: 'Auditing',
        tooltip: 'View Audit Events',
        url: Route.Audit,
        searchTitle: 'Audit Events',
        paginatedResultsOnly: true,
      },
    ],
    [],
  );
  return { tabs };
};

export default useHeaderTabs;
