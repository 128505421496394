import { useMemo } from 'react';
import { ADMIN } from 'okta/HasAccess';
import Route from 'models/Routes';
import { ITabs, TabType } from 'models/types';

const useHeaderTabs = (): ITabs => {
  const tabs: TabType[] = useMemo(
    (): TabType[] => [
      {
        text: 'Unassigned',
        tooltip: 'View Unassigned Clients',
        url: Route.Clients,
        selector: 'organisationSelector=Unassigned',
        searchTitle: 'Unassigned Clients',
        addAccessOnly: [ADMIN],
        paginatedResultsOnly: true,
      },
      {
        text: 'Assigned',
        tooltip: 'View Assigned Clients',
        url: Route.Clients,
        selector: 'organisationSelector=Assigned',
        searchTitle: 'Assigned Clients',
        addAccessOnly: [ADMIN],
        paginatedResultsOnly: true,
      },
      {
        text: 'All Clients',
        tooltip: 'View All Clients',
        url: Route.Clients,
        selector: 'organisationSelector=All',
        searchTitle: 'All Clients',
        addAccessOnly: [ADMIN],
        paginatedResultsOnly: true,
      },
      {
        text: 'Self-Hosted',
        tooltip: 'View Self-Hosted Clients',
        url: Route.Clients,
        selector: 'hostingSelector=selfHostedOnly',
        searchTitle: 'Self-Hosted Clients',
        addAccessOnly: [ADMIN],
        paginatedResultsOnly: true,
      },
    ],
    [],
  );
  return { tabs };
};

export default useHeaderTabs;
