import React, {useState} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import IconButton from '@material-ui/core/IconButton';
import * as Component from 'components/ComponentsIndex';

interface ICopyToClipboard { value: string | undefined, handleClick? : () => void }

const CopyToValueClipboard = (props: ICopyToClipboard): React.ReactElement => {
  const COPIED = 'Copied';
  const COPY_TO_CLIPBOARD = 'Copy to Clipboard';

  const { value, handleClick } = props;
  const [title, setTitle] = useState(COPY_TO_CLIPBOARD)
  const handleOnCopy = () : void => {
    setTitle(COPIED)
  }
  const resetTitle = (): void => {
    if (title === COPIED) {
      setTitle(COPY_TO_CLIPBOARD)
    }
  };
  return (
    <>
      {value && 
        <div style={{ display: 'flex', placeContent: 'flex-end' }}>
          <Component.Tooltip title={title} onClose={resetTitle}>
            <CopyToClipboard text={value} onCopy={handleOnCopy}>
              <IconButton color="inherit" component="span" style={{ padding: '0px' }} onClick={handleClick}>
                <i className="i-bpow-copy" style={{ fontSize: '1rem', color: '#333' }} />
              </IconButton>     
            </CopyToClipboard>
          </Component.Tooltip> 
        </div>
      }
    </>
  );
};

export default CopyToValueClipboard;
