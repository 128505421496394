import React, {PropsWithChildren} from 'react';
import * as Redux from 'react-redux';
import { useFetch } from 'hooks';
import LicenseView from 'views/Common/ViewForm/LicenseView';
import {Props} from "models/types";
import {IPreviewPanelState} from "models/Interfaces";
import {IPreviewPanel} from "models/Interfaces";
import { VerbType } from 'models/Routes';

const LicenseItems: React.FC<Props> = (props: PropsWithChildren<any>) => {
  const {
    licenseId,
    nolastItemBorder,
    summaryOnly,
    setSummary,
    minWidth = '10rem',
  } = props;
  const { refreshPanel } = Redux.useSelector<IPreviewPanelState>((state) => state.PreviewPanelReducer) as IPreviewPanel;
  const { data } = useFetch(VerbType.GET, licenseId ? `Licenses/${licenseId}` : '', refreshPanel);
  const [License, setLicense] = React.useState();

  React.useEffect(() => {
    setLicense(data);
    data && data.name && setSummary(`License - ${data.name}`);
  }, [data, refreshPanel, setSummary]);

  return (
    <>
      <LicenseView
        License={License}
        minWidth={minWidth}
        summaryOnly={summaryOnly}
        nolastItemBorder={nolastItemBorder}
      />
    </>
  );
};

export default LicenseItems;
