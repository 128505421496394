import * as React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { ApiHelperContext } from 'context/ApiHelperContext';
import {IApiHelperContext, ISwrResult} from "models/Interfaces";

const useFetchSimple = (method: string, url: string): ISwrResult => {
  const [data, setData] = React.useState(null),
    [error, setError] = React.useState(null),
    [loading, setLoading] = React.useState(false),
    { authState, oktaAuth } = useOktaAuth() || {};

  const isAuth = authState?.isAuthenticated;
  const context: IApiHelperContext | undefined  = React.useContext(ApiHelperContext)
  const apiHelper = context?.state?.apiHelper;

  const _isMounted = React.useRef(true)

  React.useEffect((): any => {
    if (!url) return {};
    if (!isAuth && oktaAuth) oktaAuth?.signOut();

    setLoading(true);

    const successCallback = (data: any): void => {
      if (_isMounted?.current) {
        setData(data);
        setError(null);
        setLoading(false);
      }
    };
    const errorCallback = (): void => {
      if (_isMounted?.current) {
        setError(error);
        setData(null);
        setLoading(false);
      }
    };

    apiHelper?.getNoVersion(url, successCallback, errorCallback);

    return (): void => {
      setLoading(false);
    };
  }, [apiHelper, error, isAuth, method, oktaAuth, url]);

  return { data, error, loading };
};

export default useFetchSimple;
