import React, {PropsWithChildren} from 'react';
import * as Redux from 'react-redux';
import { useFetch } from 'hooks';
import OrganisationView from 'views/Common/ViewForm/OrganisationView';
import Route from 'models/Routes';
import {Props} from "models/types";
import {IPreviewPanelState} from "models/Interfaces";
import {IPreviewPanel} from "models/Interfaces";

const OrganisationItems: React.FC<Props> = (props: PropsWithChildren<any>) => {
  const { itemId, nolastItemBorder, summaryOnly, setSummary, minWidth = '10rem' } = props;
  const { id, refreshPanel } = Redux.useSelector<IPreviewPanelState>((state) => state.PreviewPanelReducer) as IPreviewPanel;
  const { data } = useFetch(
    'GET',
    itemId ? `Organisations/${itemId}` : id ? `Organisations/${id}` : Route.Clients,
    refreshPanel,
  );
  const [Organisation, setOrganisation] = React.useState();

  React.useEffect(() => {
    setOrganisation(data);
    data && data.name && setSummary(`Organisation - ${data.name} - (SF)`);
  }, [data, itemId, refreshPanel, setSummary]);

  return (
    <>
      <OrganisationView
        Organisation={Organisation}
        minWidth={minWidth}
        nolastItemBorder={nolastItemBorder}
        summaryOnly={summaryOnly}
      />
    </>
  );
};


export default OrganisationItems;
