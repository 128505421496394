enum Route {
  Audit = 'audit',
  CheckHealth = 'check/health',
  Clients = 'organisations',
  Features = 'features',
  Licenses = 'licenses',
  IDS = 'identityserversites',
  Packages = 'packages',
  Reporting = 'reporting',
  Sites = 'tenants',
  Salesforce = 'salesforce',
  SalesforceSOAP = 'SalesforceData/organisations/import',
  SalesforceREST = 'SalesforceDataRest/organisations/rest/import',
  Logon = "Logon"
}
export default Route;

export enum RouteNames {
  AppName = 'Entities Management Portal',
  Audit = 'Auditing',
  Clients = 'Client Management',
  Packages = 'Package Management',
  Reporting = 'Reporting',
  Salesforce = 'Salesforce',
  Sites = 'Site Management',
  System = 'System'
}

export enum VerbType {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
  PATCH = 'PATCH',
}
