import React, {PropsWithChildren, ReactElement} from 'react';
import Select from '../Select';
import { ContainerRow } from 'styles/styledComponents/styledComponents';
import { useFetch } from 'hooks';
import { VerbType } from 'models/Routes';
import {IFeature, IFeatureProps} from "../../../../models/Interfaces";

const FeatureAddOns = (props: PropsWithChildren<any>): ReactElement => {
  const {
    targetName,
    isMulti,
    setState,
    addOnFeatures,
    minWidth,
    fullBorder,
    noTopBorder,
    menuPlacement,
    required,
    hasError
  } = props as IFeatureProps;
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  function useOptions(url: any): any {
    const { data: FeaturesForPackage } = useFetch(VerbType.GET, url);
    return FeaturesForPackage;
  }

  // Set full list of Options from database
  const features = useOptions('Features');
  const featuresOptions =
    features &&
    features.length > 0 &&
    features.map((Feature: IFeature): any => ({
      label: Feature.description,
      value: Feature.code,
    }));

  // Set saved Options from database
  React.useEffect(() => {
    let parsedAddOn: any = addOnFeatures
    let features: any = []
    if (addOnFeatures && typeof addOnFeatures === 'string') {
      parsedAddOn = JSON.parse(addOnFeatures)
    }
    if (Array.isArray(parsedAddOn) && parsedAddOn.length > 0 ) {
      features = parsedAddOn.map((Feature: IFeature) => ({
        label: Feature.label || Feature.Label,
        value: Feature.value || Feature.Value,
      }))
    }
    setSelectedOptions(features);
  }, [addOnFeatures]);

  // Handle onChange event of the dropdown
  const updateMultiArray = (e: any): void => {
    const selectedArray = Array.isArray(e) ? e.map((x) => x) : [] as any;
    const featuresArray = selectedArray;
    setState((state: any): void => ({
      ...state,
      [targetName as string]: featuresArray,
    }));
    setSelectedOptions(selectedArray);
  };

  const requiredClass = required ? 'fieldRequired' : '';
  const selectAttr = {
    name: targetName,
    selected: selectedOptions,
    setState,
    handleChange: updateMultiArray,
    options: featuresOptions,
    isMulti,
    menuPlacement,
    hasError,
    required,
  } as PropsWithChildren<any>
  return (
    <>
      <ContainerRow className={`item-container ${requiredClass} ${fullBorder ? 'fullBorder' : ''} ${noTopBorder && 'noTopBorder'}`}>
        <div className="item-name" style={{ minWidth: minWidth }}>
          Feature Add-ons
        </div>
        <Select {...selectAttr}/>
      </ContainerRow>
    </>
  );
};

export default FeatureAddOns;
