import moment from 'moment'
import { logger } from './logger'
import { env } from '../env'
import { VerbType } from 'models/Routes'

export const BaseApi = `${env.apiUrl}v${env.apiVersion}/`
export const BaseApiNoVersion = env.apiUrl
let controllerRef

const makeRequest = (
  method,
  url,
  body,
  accessToken,
  callbackFunc,
  callbackErrorFunc,
  finalCallBack = null,
) => {
  const startTime = moment()
  if (controllerRef.current) {
    logger.debug('<< ApiHelperClass: Aborting previous request')
    //controllerRef.current.abort()
  }
  
  const controller = new AbortController()
  controllerRef.current = controller
  logger.debug('<< ApiHelperClass: New request controller set', controller)
  
  let endpoint = url
  if (!url.startsWith('http')) {
    endpoint = url
  }
  
  return fetch(endpoint, {
    method: method,
    //signal: controllerRef.current?.signal,
    body: body && JSON.stringify(body),
    cache: 'no-cache',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((response) => {
    const contentType = response.headers.get('content-type')
    if (contentType && contentType.includes('text')) {
      return response?.text()
    } else if (contentType && contentType.includes('json')) {
      return response?.json()
    } else {
      logger.debug('what content type?', { contentType, response })
      return []
    }
  }).then((data) => {
    const endTime = moment()
    const duration = endTime.diff(startTime, 'millisecond')
    const total = data?.length || data?.totalCount || 0
    logger.debug(
      `<< ApiHelperClass: ${method} /${url} took ${duration / 1000}s,  returned ${total} items`,
    )
    if (callbackFunc) {
      callbackFunc(data)
    } else {
      return data
    }
  }).catch((error) => {
    logger && logger.error(error, { event: 'ApiHelperClass', method, url })
    if (callbackErrorFunc) {
      callbackErrorFunc(error)
    } else {
      return error
    }
  }).finally(() => {
    if (finalCallBack) {
      finalCallBack()
    }
    controllerRef.current = null
  })
}

class ApiHelperClass {
  oktaAuth
  
  constructor (oktaAuth) {
    this.oktaAuth = oktaAuth
  }
  
  request (method, url, body = null, success, fail, finalCallback = null, noBaseUrl = false) {
    const fullUrl = noBaseUrl ? BaseApiNoVersion + url : BaseApi + url
    return makeRequest(
      method,
      fullUrl,
      body,
      this.oktaAuth?.getAccessToken(),
      success,
      fail,
      finalCallback,
    )
  }
  
  setControllerRef (ref) {
    controllerRef = ref
  }
  
  get (url, callbackFunc = null, callbackErrorFunc = null) {
    return this.request(VerbType.GET, url, null, callbackFunc, callbackErrorFunc)
  }
  
  getNoVersion (url, callbackFunc = null, callbackErrorFunc = null) {
    return this.request(VerbType.GET, url, null, callbackFunc, callbackErrorFunc, null, true)
  }
  
  put (url, body, callbackFunc = null, callbackErrorFunc = null) {
    return this.request(VerbType.PUT, url, body, callbackFunc, callbackErrorFunc)
  }
  
  post (url, body, callbackFunc = null, callbackErrorFunc = null) {
    return this.request(VerbType.POST, url, body, callbackFunc, callbackErrorFunc)
  }
  
  delete (url, callbackFunc, callbackErrorFunc) {
    return this.request(VerbType.DELETE, url, null, callbackFunc, callbackErrorFunc)
  }
}

export default ApiHelperClass
