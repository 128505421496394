import React from 'react';
import Button, { ButtonColoured } from '../Button/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import * as EditComponent from 'views/Common/EditForm/EditIndex';
import { useStyles } from './Styles';

function FormDialogRaw(props) {
  const {
    open,
    title,
    maxwidth,
    formComponent,
    handleClose,
    handleConfirm,
    state,
    setState,
    status,
    height,
    buttonCancel,
    buttonOK,    
    isValid,
    FormStatus,
    errors,
    buttonDoneOnly,
  } = props;
  const classes = useStyles();

  function getFormComponent(formComponent, state, setState, status, isValid, FormStatus, errors) {
    switch (formComponent) {
    case 'assignTenant':
      return (
        <EditComponent.AssignTenant
          state={state}
          setState={setState}
          status={status}
          isValid={isValid}
          FormStatus={FormStatus}
          errors={errors}
        />
      );
    case 'unassignTenant':
      return (
        <EditComponent.UnassignTenant
          state={state}
          setState={setState}
          status={status}
          isValid={isValid}
          FormStatus={FormStatus}
          errors={errors}
        />
      );
    case 'SSOMetaFile':
      return (
        <EditComponent.SSOMetaFile
          state={state}
          setState={setState}
          status={status}
          isValid={isValid}
          FormStatus={FormStatus}
          errors={errors}
        />
      );
    case 'UpdateSiteLicense':
      return (
        <EditComponent.UpdateSiteLicense
          state={state}
          setState={setState}
          status={status}
          isValid={isValid}
          FormStatus={FormStatus}
          errors={errors}
        />
      );
    default:
      return null;
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth={!maxwidth ? 'md' : maxwidth}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      classes={{
        paper: classes.paper,
      }}
    >
      <DialogTitle id="confirmation-dialog-title">
        {title}
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <i className="i-bpow-cross" style={{ fontSize: '1rem' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers style={{ height: height ? height : '650px' }}>
        {getFormComponent(formComponent, state, setState, status, isValid, FormStatus, errors)}
      </DialogContent>
      <DialogActions className="ui-widget-content">
        {buttonDoneOnly ?
          <ButtonColoured
            handleClick={handleClose}
            color="inherit"
            label='Done'
          />
          :
          <>
            <Button
              handleClick={handleClose}
              color="inherit"
              label={buttonCancel ? buttonCancel : 'Cancel'}
            />
            <ButtonColoured
              handleClick={handleConfirm}
              color="primary"
              label={buttonOK ? buttonOK : 'OK'}
            />
          </>
        }
        
      </DialogActions>
    </Dialog>
  );
}

function FormDialog(props) {
  const {
    title,
    handleConfirm,
    handleClose,
    open,
    maxwidth,
    formComponent,
    state,
    setState,
    status,
    height,
    buttonCancel,
    buttonOK,
    isValid,
    FormStatus,
    errors,
    buttonDoneOnly
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.formRoot}>
      <FormDialogRaw
        title={title}
        keepMounted
        formComponent={formComponent}
        open={open}
        handleConfirm={handleConfirm}
        handleClose={handleClose}
        maxwidth={maxwidth}
        state={state}
        setState={setState}
        status={status}
        height={height}
        buttonCancel={buttonCancel}
        buttonOK={buttonOK}
        isValid={isValid}
        FormStatus={FormStatus}
        errors={errors}
        buttonDoneOnly={buttonDoneOnly}
      />
    </div>
  );
}

export default FormDialog;
