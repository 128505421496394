import React from 'react';
import { editFormStyles, FormStatus } from './Common';

interface IEditTextArea { 
  value?: string | number | undefined;
  setState?: React.Dispatch<React.SetStateAction<unknown>>;
  targetName?: string;
  required?: boolean;
  status?: string;
  disabled?: boolean;
  upperCase?: boolean;
  rows?: number | undefined;
  cols?: number | undefined;
}

const EditTextArea = (props: IEditTextArea): React.ReactElement => {
  const {
    value,
    setState,
    targetName,
    required,
    status,
    disabled,
    upperCase,
    rows = 5,
    cols = 5,
  } = props;
  const classes = editFormStyles();

  const handleChange = ((e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    e.persist();
    setState && setState((state: string[]) => {
      return {
        ...state,
        [e.target.name]: upperCase ? e.target.value.toUpperCase() : e.target.value,
      };
    });
  });

  return (
    <>
      {required ? (
        <textarea
          name={targetName}
          className={`${classes.textField} ${!value && FormStatus && (status === FormStatus.DIRTY) && classes.error}`}
          onChange={handleChange}
          value={value}
          rows={rows}
          cols={cols}
        />
      ) : (
        <textarea
          name={targetName}
          className={`${classes.textField}`}
          onChange={handleChange}
          disabled={!!disabled}
          value={value}
          rows={rows}
          cols={cols}
        />
      )}
    </>
  );
};

export default EditTextArea;
