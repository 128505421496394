import React from 'react';
import dateHelper from 'lib/dateHelper';
import { getProperty } from 'lib/stringHelper';
import * as Column from '../Constants/Columns';
import { WriteTenantStatus } from '../ViewForm/Common';
import Route from 'models/Routes';

const DEFAULT_FIELD_VALUE = '-';
const DATE_FIELD = 'date';
const DATETIME_FIELD = 'datetime';
const BOOLEAN_FIELD = 'boolean';

const useColumnsSetup = (columns: string, defaultSort: string, orderBy: string | undefined = ''): any => {
  let columnList ;

  const setDefaultSorting = (column: any, orderBy: string | undefined): any => {
    if (column === orderBy) return defaultSort;
  };

  const tenants = [
    { field: Column.ID, title: 'Id', hidden: true, removable: false },
    {
      field: Column.Tenant_DSN,
      title: 'DSN',
      width: 25,
      removable: false,
      defaultSort: setDefaultSorting(Column.Tenant_DSN, orderBy),
    },
    {
      field: Column.Tenant_URL,
      title: 'URL',
      width: 200,
      defaultSort: setDefaultSorting(Column.Tenant_URL, orderBy),
    },
    {
      field: Column.Tenant_Organisation,
      title: 'Organisation',
      width: 75,
      defaultSort: setDefaultSorting(Column.Tenant_Organisation, orderBy),
    },
    {
      field: Column.Tenant_Status,
      title: 'Status',
      width: 50,
      defaultSort: setDefaultSorting(Column.Tenant_Status, orderBy),
      render: (data: any): string => {
        return WriteTenantStatus(data?.status);
      },
    },
    {
      field: Column.Tenant_Purpose,
      title: 'Purpose',
      width: 50,
      defaultSort: setDefaultSorting(Column.Tenant_Purpose, orderBy),
    },
    {
      field: Column.Tenant_Package,
      title: 'Package',
      width: 50,
      defaultSort: setDefaultSorting(Column.Tenant_Package, orderBy),
    },
    {
      field: Column.Tenant_License,
      title: 'License',
      width: 50,
      defaultSort: setDefaultSorting(Column.Tenant_License, orderBy),
      render: (data: any): string => {
        return (
          data?.assignedOrganisation?.licenseSummary?.replace(/[;]/g, ',') || DEFAULT_FIELD_VALUE
        );
      },
    },
  ];

  const tenantsAllInfo = [
    {
      field: Column.Organisation_Name,
      title: 'Client Name',
      width: 100,
      defaultSort: setDefaultSorting(Column.Organisation_Name, orderBy),
    },
    {
      field: Column.Organisation_SalesforceId,
      title: 'Organisation Id',
      width: 50,
      defaultSort: setDefaultSorting(Column.Organisation_SalesforceId, orderBy),
    },
    { field: Column.TenantDsn, defaultSort: setDefaultSorting(Column.TenantDsn, orderBy), title: 'DSN', width: 25 },
    { field: Column.TenantUrl, defaultSort: setDefaultSorting(Column.TenantUrl, orderBy), title: 'URL', width: 150 },
    {
      field: Column.LicenseSummary,
      title: 'License',
      width: 50,
      render: (data: any): string => {
        return data?.licenseSummary?.replace(/[;]/g, ',') || DEFAULT_FIELD_VALUE;
      },
      defaultSort: setDefaultSorting(Column.LicenseSummary, orderBy),
    },
    { field: Column.PackageName,  defaultSort: setDefaultSorting(Column.PackageName, orderBy), title: 'Package', width: 50 },
    {
      field: Column.Package_Features,
      title: 'Features',
      width: 100,
      render: (data: any): string => {
        return data?.features?.join(', ') || DEFAULT_FIELD_VALUE;
      },
      defaultSort: setDefaultSorting(Column.Package_Features, orderBy),
    },
  ];

  const tenantsAllInfoHiddenColumns = [
    {
      field: Column.Organisation_Status,
      title: 'Contract Status',
      width: 100,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Organisation_Status, orderBy),
    },
    {
      field: Column.Organisation_Number,
      title: 'Contract Number',
      width: 100,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Organisation_Number, orderBy),
    },
    {
      field: Column.Organisation_Region,
      title: 'Sub-Region',
      width: 100,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Organisation_Region, orderBy),
    },
    {
      field: Column.Organisation_Start,
      title: 'Contract Start',
      width: 50,
      type: DATE_FIELD,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Organisation_Start, orderBy),
    },
    {
      field: Column.Organisation_End,
      title: 'Contract End',
      width: 50,
      type: DATE_FIELD,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Organisation_End, orderBy),
    },
    {
      field: Column.Organisation_AccountManager,
      title: 'Account Manager',
      width: 100,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Organisation_AccountManager, orderBy),
    },
    {
      field: Column.Organisation_CsmTeam,
      title: 'CSM',
      width: 100,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Organisation_CsmTeam, orderBy),
    },
    {
      field: Column.Organisation_CsmManager,
      title: 'CSM Manager',
      width: 100,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Organisation_CsmManager, orderBy),
    },
    {
      field: Column.Organisation_SelfHosted,
      title: 'Self-Hosted',
      width: 50,
      type: BOOLEAN_FIELD,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Organisation_SelfHosted, orderBy),
    },
    {
      field: Column.Organisation_EnterpriseSite,
      title: 'Enterprise Site',
      width: 100,
      type: BOOLEAN_FIELD,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Organisation_EnterpriseSite, orderBy),
    },
    {
      field: Column.AddOnFeatures,
      title: 'Feature Addons',
      width: 150,
      hidden: true,
      hiddenByColumnsButton: true,
      render: (data: any): string => {
        return data?.addOnFeatures?.join(', ') || DEFAULT_FIELD_VALUE;
      },
      defaultSort: setDefaultSorting(Column.AddOnFeatures, orderBy),
    },
    {
      field: Column.PackageCode,
      title: 'Package Code',
      width: 25,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.PackageCode, orderBy),
    },
    {
      field: Column.PackageDescription,
      title: 'Package Description',
      width: 100,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.PackageDescription, orderBy),
    },
    {
      field: Column.License_Edition,
      title: 'Edition',
      width: 50,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.License_Edition, orderBy),
    },
    {
      field: Column.License_Companies,
      title: 'Companies',
      width: 25,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.License_Companies, orderBy),
    },
    {
      field: Column.License_People,
      title: 'People',
      width: 25,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.License_People, orderBy),
    },
    {
      field: Column.License_Incorps,
      title: 'Incorporations',
      width: 25,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.License_Incorps, orderBy),
    },
    {
      field: Column.License_Users,
      title: 'Edit Users',
      width: 25,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.License_Users, orderBy),
    },
    {
      field: Column.License_AdminUsers,
      title: 'Admin Users',
      width: 25,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.License_AdminUsers, orderBy),
    },
    {
      field: Column.License_DL,
      title: 'Data Library',
      width: 25,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.License_DL, orderBy),
    },
    {
      field: Column.License_DLDeveloper,
      title: 'Data Library developer',
      type: BOOLEAN_FIELD,
      width: 50,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.License_DLDeveloper, orderBy),
    },
    {
      field: Column.License_AssignedOn,
      title: 'Assigned On',
      width: 50,
      type: DATETIME_FIELD,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.License_AssignedOn, orderBy),
    },
  ];

  const tenantsByQuarter = [
    { field: Column.ID, title: 'Id', hidden: true, removable: false },
    {
      field: Column.AssignedOn,
      title: 'Assigned On',
      width: 50,
      type: DATETIME_FIELD,
      removable: false,
      defaultSort: setDefaultSorting(Column.AssignedOn, orderBy),
    },
    { field: Column.Tenant_DSN, defaultSort: setDefaultSorting(Column.Tenant_DSN, orderBy), title: 'DSN', width: 25, removable: false },
    { field: Column.Tenant_URL, defaultSort: setDefaultSorting(Column.Tenant_URL, orderBy), title: 'URL', width: 200 },
    { field: Column.Tenant_Organisation, defaultSort: setDefaultSorting(Column.Tenant_Organisation, orderBy), title: 'Organisation', width: 75 },
    { field: Column.Tenant_Purpose, defaultSort: setDefaultSorting(Column.Tenant_Purpose, orderBy), title: 'Purpose', width: 50 },
    { field: Column.Tenant_Package, defaultSort: setDefaultSorting(Column.Tenant_Package, orderBy), title: 'Package', width: 50 },
    {
      field: Column.Tenant_License,
      defaultSort: setDefaultSorting(Column.Tenant_License, orderBy),
      title: 'License',
      width: 50,
      render: (data: any): string => {
        return (
          data?.assignedOrganisation?.licenseSummary?.replace(/[;]/g, ',') || DEFAULT_FIELD_VALUE
        );
      },
    },
  ];

  const tenantsHiddenColumns: any = [
    {
      field: Column.Tenant_Database,
      title: 'Database Name',
      width: 100,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Tenant_Database, orderBy),
    },
    {
      field: Column.Tenant_DatabaseServer,
      title: 'Database Server',
      width: 100,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Tenant_DatabaseServer, orderBy),
    },
    {
      field: Column.Tenant_WebServer,
      title: 'Web Server',
      width: 100,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Tenant_WebServer, orderBy),
    },
    {
      field: Column.Tenant_HasIpRestrictions,
      title: 'IP Restrictions',
      type: BOOLEAN_FIELD,
      width: 50,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Tenant_HasIpRestrictions, orderBy),
    },
    {
      field: Column.Tenant_IpRestrictions,
      title: 'Restrictions',
      width: 100,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Tenant_IpRestrictions, orderBy),
    },
    {
      field: Column.Tenant_AutoReminder,
      title: 'Auto Reminder',
      type: BOOLEAN_FIELD,
      width: 50,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Tenant_AutoReminder, orderBy),
    },
    {
      field: Column.Tenant_EFAutoSend,
      title: 'EF Auto Send Receive',
      type: BOOLEAN_FIELD,
      width: 100,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Tenant_EFAutoSend, orderBy),
    },
    {
      field: Column.Tenant_ESignature,
      title: 'ESignatures',
      type: BOOLEAN_FIELD,
      width: 50,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Tenant_ESignature, orderBy),
    },
    {
      field: Column.Tenant_SSO,
      title: 'SSO',
      width: 50,
      type: BOOLEAN_FIELD,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Tenant_SSO, orderBy),
    },
    {
      field: Column.Tenant_JobServer,
      title: 'Jobs Server',
      width: 50,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Tenant_JobServer, orderBy),
    },
    {
      field: Column.Tenant_ReportIt,
      title: 'ReportIt Server',
      width: 100,
      type: BOOLEAN_FIELD,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Tenant_ReportIt, orderBy),
    },
    {
      field: Column.Tenant_Notes,
      title: 'Notes',
      width: 100,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Tenant_Notes, orderBy),
    },
  ];

  const features = [
    { field: Column.Feature_Code, defaultSort: setDefaultSorting(Column.Feature_Code, orderBy), title: 'Feature Code', width: 150, removable: false },
    { field: Column.Feature_Description, defaultSort: setDefaultSorting(Column.Feature_Description, orderBy), title: 'Feature Name', width: 150, removable: false },
    { field: Column.Feature_Start, defaultSort: setDefaultSorting(Column.Feature_Start, orderBy), title: 'Start Date', width: 50, type: DATE_FIELD },
    { field: Column.Feature_End, defaultSort: setDefaultSorting(Column.Feature_End, orderBy), title: 'Retired', width: 50, type: DATE_FIELD },
    {
      field: Column.Feature_Packages,
      title: 'Packages',
      width: 200,
      render: (data: any): string => {
        return data?.packages?.join(', ') || DEFAULT_FIELD_VALUE;
      },
      defaultSort: setDefaultSorting(Column.Feature_Packages, orderBy),
    },
  ];

  const packages = [
    { field: Column.Package_Code, defaultSort: setDefaultSorting(Column.Package_Code, orderBy), title: 'Package Code', width: 150, removable: false },
    { field: Column.Package_Name, defaultSort: setDefaultSorting(Column.Package_Name, orderBy), title: 'Package Name', width: 150, removable: false },
    { field: Column.Package_Description, defaultSort: setDefaultSorting(Column.Package_Description, orderBy), title: 'Package Description', width: 150 },
    { field: Column.Package_Start, defaultSort: setDefaultSorting(Column.Package_Start, orderBy), title: 'Start Date', width: 50, type: DATE_FIELD },
  ];

  const packagesDiscontinued = [
    { field: Column.Package_Code, defaultSort: setDefaultSorting(Column.Package_Code, orderBy), title: 'Package Code', width: 150, removable: false },
    { field: Column.Package_Name, defaultSort: setDefaultSorting(Column.Package_Name, orderBy), title: 'Package Name', width: 150, removable: false },
    { field: Column.Package_Description, defaultSort: setDefaultSorting(Column.Package_Description, orderBy), title: 'Package Description', width: 150 },
    { field: Column.Package_End, defaultSort: setDefaultSorting(Column.Package_End, orderBy), title: 'Discontinued', width: 50, type: DATE_FIELD },
  ];

  const packagesHiddenColumns = [
    {
      field: Column.Package_Features,
      title: 'Features',
      width: 200,
      render: (data: any): string => {
        return data?.features?.join(', ') || DEFAULT_FIELD_VALUE;
      },
      defaultSort: setDefaultSorting(Column.Package_Features, orderBy),
    },
    {
      field: Column.Package_Profile,
      title: 'Profile',
      width: 200,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Package_Profile, orderBy),
    },
  ];

  const audit = [
    {
      field: Column.Audit_AuditOn,
      title: 'Audit On',
      width: 50,
      type: DATETIME_FIELD,
      defaultSort: setDefaultSorting(Column.Audit_AuditOn, orderBy),
    },
    {
      field: Column.Audit_Description,
      title: 'Description',
      width: 200,
      removable: false,
      defaultSort: setDefaultSorting(Column.Audit_Description, orderBy),
    },
    {
      field: Column.Audit_UserName,
      title: 'User',
      width: 50,
      defaultSort: setDefaultSorting(Column.Audit_UserName, orderBy),
    },
    {
      field: Column.Audit_Category,
      title: 'Category',
      width: 50,
      defaultSort: setDefaultSorting(Column.Audit_Category, orderBy),
    },
  ];

  const organisations = [
    {
      field: Column.Organisation_Name,
      title: 'Client Name',
      width: 200,
      removable: false,
      defaultSort: setDefaultSorting(Column.Organisation_Name, orderBy),
    },
    {
      field: Column.Organisation_SalesforceId,
      title: 'Organisation Id',
      width: 100,
      defaultSort: setDefaultSorting(Column.Organisation_SalesforceId, orderBy),
    },
    {
      field: Column.Organisation_Region,
      title: 'Sub-Region',
      width: 100,
      defaultSort: setDefaultSorting(Column.Organisation_Region, orderBy),
    },
    {
      field: Column.Organisation_Status,
      title: 'Contract Status',
      width: 100,
      defaultSort: setDefaultSorting(Column.Organisation_Status, orderBy),
    },
    {
      field: Column.Organisation_Number,
      title: 'Contract Number',
      width: 100,
      defaultSort: setDefaultSorting(Column.Organisation_Number, orderBy),
    },
    {
      field: Column.Organisation_Start,
      title: 'Contract Start',
      width: 50,
      type: DATE_FIELD,
      defaultSort: setDefaultSorting(Column.Organisation_Start, orderBy),
    },
    {
      field: Column.Organisation_End,
      title: 'Contract End',
      width: 50,
      type: DATE_FIELD,
      defaultSort: setDefaultSorting(Column.Organisation_End, orderBy),
    },
    {
      field: Column.Organisation_AccountManager,
      title: 'Account Manager',
      width: 100,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Organisation_AccountManager, orderBy),
    },
    {
      field: Column.Organisation_CsmTeam,
      title: 'CSM',
      width: 100,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Organisation_CsmTeam, orderBy),
    },
    {
      field: Column.Organisation_CsmManager,
      title: 'CSM Manager',
      width: 100,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Organisation_CsmManager, orderBy),
    },
    {
      field: Column.Organisation_SelfHosted,
      title: 'Self-Hosted',
      width: 50,
      type: BOOLEAN_FIELD,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Organisation_SelfHosted, orderBy),
    },
    {
      field: Column.Organisation_EnterpriseSite,
      title: 'Enterprise Site',
      width: 100,
      type: BOOLEAN_FIELD,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Organisation_EnterpriseSite, orderBy),
    },
  ];

  const licenseOverUsage = [
    { field: Column.License_Name, defaultSort: setDefaultSorting(Column.License_Name, orderBy), title: 'Name', width: 100, removable: false },
    { field: Column.TenantDsn, defaultSort: setDefaultSorting(Column.TenantDsn, orderBy), title: 'DSN', width: 50 },
    {
      field: Column.LicenseUsage_TotalCompaniesOverLicense,
      title: 'No. of Companies over Limit',
      width: 75,
      defaultSort: setDefaultSorting(Column.LicenseUsage_TotalCompaniesOverLicense, orderBy),
    },
    { field: Column.License_Companies, defaultSort: setDefaultSorting(Column.License_Companies, orderBy), title: 'Companies Limit', width: 50 },
    {
      field: Column.LicenseUsage_ActiveCompanies,
      title: 'No. of Active Companies in use',
      width: 50,
      defaultSort: setDefaultSorting(Column.LicenseUsage_ActiveCompanies, orderBy),
    },
    {
      field: Column.LicenseUsage_TotalsUsersOverLicense,
      title: 'No. of Users over limit',
      width: 75,
      defaultSort: setDefaultSorting(Column.LicenseUsage_TotalsUsersOverLicense, orderBy),
    },
    { field: Column.License_Users, defaultSort: setDefaultSorting(Column.License_Users, orderBy), title: 'Max User limit', width: 50 },
    { field: Column.LicenseUsage_AdminUsers, defaultSort: setDefaultSorting(Column.LicenseUsage_AdminUsers, orderBy), title: 'No. of Admin users in use', width: 50 },
    { field: Column.License_AdminUsers, defaultSort: setDefaultSorting(Column.License_AdminUsers, orderBy), title: 'Admin user limit', width: 50 },
    { field: Column.LicenseUsage_EditUsers, defaultSort: setDefaultSorting(Column.LicenseUsage_EditUsers, orderBy), title: 'No. of Edit users in use', width: 50 },
    { field: Column.License_EditUsers, defaultSort: setDefaultSorting(Column.License_EditUsers, orderBy), title: 'Edit user limit', width: 50 },
  ];

  const licenseOverUsageHiddenColumns = [
    {
      field: Column.LicenseUsage_TotalCompanies,
      title: 'Usage: Total Licensed Companies',
      width: 75,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.LicenseUsage_TotalCompanies, orderBy),
    },
    {
      field: Column.License_People,
      title: 'Licensed: People',
      width: 75,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.License_People, orderBy),
    },
    {
      field: Column.License_Incorps,
      title: 'Licensed: Incorporations',
      width: 75,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.License_Incorps, orderBy),
    },
    {
      field: Column.License_DL,
      title: 'Licensed: Data Library',
      width: 75,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.License_DL, orderBy),
    },
    {
      field: Column.LicenseUsage_TotalUsers,
      title: 'Usage: Total Users',
      width: 75,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.LicenseUsage_TotalUsers, orderBy),
    },
    {
      field: Column.LicenseUsage_ReadOnlyUsers,
      title: 'Usage: Read-Only Users',
      width: 75,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.LicenseUsage_ReadOnlyUsers, orderBy),
    },
    {
      field: Column.LicenseUsage_ActiveOnlyCompanies,
      title: 'Usage: Active Only Companies',
      type: BOOLEAN_FIELD,
      width: 75,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.LicenseUsage_ActiveOnlyCompanies, orderBy),
    },
    {
      field: Column.LicenseUsage_MaxCompaniesUsed,
      title: 'Usage: Max Companies Used',
      width: 75,
      type: DATETIME_FIELD,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.LicenseUsage_MaxCompaniesUsed, orderBy),
    },
    {
      field: Column.LicenseUsage_AuditedLoginsFor3Months,
      title: 'Usage: Audited Logins (3 months)',
      type: DATETIME_FIELD,
      width: 75,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.LicenseUsage_AuditedLoginsFor3Months, orderBy),
    },
    {
      field: Column.LicenseUsage_UniqueLoginsFor3Months,
      title: 'Usage: Unique Logins (3 months)',
      type: DATETIME_FIELD,
      width: 75,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.LicenseUsage_UniqueLoginsFor3Months, orderBy),
    },
    {
      field: Column.Organisation_SalesforceId,
      title: 'Organisation Id',
      width: 50,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Organisation_SalesforceId, orderBy),
    },
    {
      field: Column.Organisation_CsmManager,
      title: 'CSM Manager',
      width: 75,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Organisation_CsmManager, orderBy),
    },
    {
      field: Column.Organisation_AccountManager,
      title: 'Account Manager',
      width: 75,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Organisation_AccountManager, orderBy),
    },
    {
      field: Column.TenantUrl,
      title: 'URL',
      width: 100,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.TenantUrl, orderBy),
    },
    {
      field: Column.Organisation_Number,
      title: 'Contract Number',
      width: 75,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Organisation_Number, orderBy),
    },
    {
      field: Column.Organisation_Status,
      title: 'Contract Status',
      width: 75,
      hidden: true,
      hiddenByColumnsButton: true,
      defaultSort: setDefaultSorting(Column.Organisation_Status, orderBy),
    },
  ];
  switch (columns) {
  case Route.Audit:
    columnList = audit;
    break;
  case Route.Sites:
    columnList = tenants.concat(tenantsHiddenColumns);
    break;
  case 'tenants-quarter':
    columnList = tenantsByQuarter.concat(tenantsHiddenColumns);
    break;
  case 'tenants-allinfo':
    columnList = tenantsAllInfo.concat(tenantsAllInfoHiddenColumns);
    break;
  case Route.Features:
    columnList = features;
    break;
  case Route.Packages:
    columnList = packages.concat(packagesHiddenColumns);
    break;
  case 'packages-discontinued':
    columnList = packagesDiscontinued.concat(packagesHiddenColumns);
    break;
  case Route.Licenses:
    columnList = [
      { field: Column.License_Name, defaultSort: setDefaultSorting(Column.License_Name, orderBy), title: 'Name', width: 150, removable: false },
      { field: Column.License_Edition, defaultSort: setDefaultSorting(Column.License_Edition, orderBy), title: 'Edition', width: 50 },
      { field: Column.License_Companies, defaultSort: setDefaultSorting(Column.License_Companies, orderBy), title: 'Companies', width: 50 },
      { field: Column.License_People, defaultSort: setDefaultSorting(Column.License_People, orderBy), title: 'People', width: 50 },
      { field: Column.License_Incorps, defaultSort: setDefaultSorting(Column.License_Incorps, orderBy), title: 'Incorporations', width: 50 },
      { field: Column.License_Start, defaultSort: setDefaultSorting(Column.License_Start, orderBy), title: 'Start Date', width: 50, type: DATE_FIELD },
      { field: Column.License_End, defaultSort: setDefaultSorting(Column.License_End, orderBy), title: 'Retired', width: 50, type: DATE_FIELD },
      {
        field: Column.License_Users,
        defaultSort: setDefaultSorting(Column.License_Users, orderBy),
        title: 'Edit Users',
        width: 50,
        hidden: true,
        hiddenByColumnsButton: true,
      },
      {
        field: Column.License_AdminUsers,
        defaultSort: setDefaultSorting(Column.License_AdminUsers, orderBy),
        title: 'Admin Users',
        width: 50,
        hidden: true,
        hiddenByColumnsButton: true,
      },
      {
        field: Column.License_DL,
        defaultSort: setDefaultSorting(Column.License_DL, orderBy),
        title: 'Data Library',
        width: 50,
        hidden: true,
        hiddenByColumnsButton: true,
      },
      {
        field: Column.License_DLDeveloper,
        defaultSort: setDefaultSorting(Column.License_DLDeveloper, orderBy),
        title: 'Data Library Developer',
        type: BOOLEAN_FIELD,
        width: 50,
        hidden: true,
        hiddenByColumnsButton: true,
      },
    ];
    break;
  case 'license-overusage':
    columnList = licenseOverUsage.concat(licenseOverUsageHiddenColumns);
    break;
  case Route.Clients:
    columnList = organisations;
    break;
  case 'organisations-packages':
    columnList = [
      { field: Column.Organisation_Name, defaultSort: setDefaultSorting(Column.Organisation_Name, orderBy), title: 'Client Name', width: 150, removable: false },
      { field: Column.Organisation_SalesforceId, defaultSort: setDefaultSorting(Column.Organisation_SalesforceId, orderBy), title: 'Organisation Id', width: 100 },
      { field: Column.PackageCode, defaultSort: setDefaultSorting(Column.PackageCode, orderBy), title: 'Package Code', width: 50, removable: false },
      { field: Column.PackageName, defaultSort: setDefaultSorting(Column.PackageName, orderBy), title: 'Package Name', width: 100, removable: false },
      {
        field: Column.Package_Features,
        title: 'Features',
        width: 250,
        render: (data: any): string => {
          return data?.features?.join(', ') || DEFAULT_FIELD_VALUE;
        },
        defaultSort: setDefaultSorting(Column.Package_Features, orderBy),
      },
      {
        field: Column.AddOnFeatures,
        title: 'Feature Addons',
        width: 150,
        hidden: true,
        hiddenByColumnsButton: true,
        render: (data: any): string => {
          return data?.addOnFeatures?.join(', ') || DEFAULT_FIELD_VALUE;
        },
        defaultSort: setDefaultSorting(Column.AddOnFeatures, orderBy),
      },
      {
        field: Column.PackageDescription,
        title: 'Package Description',
        width: 150,
        hidden: true,
        hiddenByColumnsButton: true,
        defaultSort: setDefaultSorting(Column.PackageDescription, orderBy),
      },
      {
        field: Column.Organisation_Region,
        title: 'Sub-Region',
        width: 100,
        hidden: true,
        hiddenByColumnsButton: true,
        defaultSort: setDefaultSorting(Column.Organisation_Region, orderBy),
      },
      {
        field: Column.Organisation_Status,
        title: 'Contract Status',
        width: 100,
        hidden: true,
        hiddenByColumnsButton: true,
        defaultSort: setDefaultSorting(Column.Organisation_Status, orderBy),
      },
      {
        field: Column.Organisation_Number,
        title: 'Contract Number',
        width: 100,
        hidden: true,
        hiddenByColumnsButton: true,
        defaultSort: setDefaultSorting(Column.Organisation_Number, orderBy),
      },
      {
        field: Column.Organisation_Start,
        title: 'Contract Start',
        width: 50,
        type: DATE_FIELD,
        hidden: true,
        hiddenByColumnsButton: true,
        defaultSort: setDefaultSorting(Column.Organisation_Start, orderBy),
      },
      {
        field: Column.Organisation_End,
        title: 'Contract End',
        width: 50,
        type: DATE_FIELD,
        hidden: true,
        hiddenByColumnsButton: true,
        defaultSort: setDefaultSorting(Column.Organisation_End, orderBy),
      },
      {
        field: Column.Organisation_AccountManager,
        title: 'Account Manager',
        width: 100,
        hidden: true,
        hiddenByColumnsButton: true,
        defaultSort: setDefaultSorting(Column.Organisation_AccountManager, orderBy),
      },
      {
        field: Column.Organisation_CsmTeam,
        title: 'CSM',
        width: 100,
        hidden: true,
        hiddenByColumnsButton: true,
        defaultSort: setDefaultSorting(Column.Organisation_CsmTeam, orderBy),
      },
      {
        field: Column.Organisation_CsmManager,
        title: 'CSM Manager',
        width: 100,
        hidden: true,
        hiddenByColumnsButton: true,
        defaultSort: setDefaultSorting(Column.Organisation_CsmManager, orderBy),
      },
      {
        field: Column.Organisation_SelfHosted,
        title: 'Self-Hosted',
        width: 50,
        type: BOOLEAN_FIELD,
        hidden: true,
        hiddenByColumnsButton: true,
        defaultSort: setDefaultSorting(Column.Organisation_SelfHosted, orderBy),
      },
      {
        field: Column.Organisation_EnterpriseSite,
        title: 'Enterprise Site',
        width: 100,
        type: BOOLEAN_FIELD,
        hidden: true,
        hiddenByColumnsButton: true,
        defaultSort: setDefaultSorting(Column.Organisation_EnterpriseSite, orderBy),
      },
    ];
    break;
  case 'organisations-license':
    columnList = [
      { field: Column.Organisation_Name, defaultSort: setDefaultSorting(Column.Organisation_Name, orderBy), title: 'Client Name', width: 150, removable: false },
      { field: Column.Organisation_SalesforceId, defaultSort: setDefaultSorting(Column.Organisation_SalesforceId, orderBy), title: 'Organisation Id', width: 100 },
      { field: Column.LicenseName, defaultSort: setDefaultSorting(Column.LicenseName, orderBy), title: 'License', width: 150, removable: false },
      { field: Column.License_Edition, defaultSort: setDefaultSorting(Column.License_Edition, orderBy), title: 'Edition', width: 50 },
      { field: Column.License_Companies, defaultSort: setDefaultSorting(Column.License_Companies, orderBy), title: 'Companies', width: 50 },
      { field: Column.License_People, defaultSort: setDefaultSorting(Column.License_People, orderBy), title: 'People', width: 50 },
      { field: Column.License_Incorps, defaultSort: setDefaultSorting(Column.License_Incorps, orderBy), title: 'Incorporations', width: 50 },
      { field: Column.License_Users, defaultSort: setDefaultSorting(Column.License_Users, orderBy), title: 'Edit Users', width: 50 },
      { field: Column.License_AdminUsers, defaultSort: setDefaultSorting(Column.License_AdminUsers, orderBy), title: 'Admin Users' },
      {
        field: Column.License_DL,
        title: 'Data Library',
        width: 50,
        hidden: true,
        hiddenByColumnsButton: true,
        defaultSort: setDefaultSorting(Column.License_DL, orderBy),
      },
      {
        field: Column.License_DLDeveloper,
        title: 'Data Library Developer',
        type: BOOLEAN_FIELD,
        width: 50,
        hidden: true,
        hiddenByColumnsButton: true,
        defaultSort: setDefaultSorting(Column.License_DLDeveloper, orderBy),
      },
    ];
    break;
  case 'organisations-hosted':
  case 'organisations-selfhosted':
    columnList = [
      {
        field: Column.Organisation_SelfHosted,
        title: 'Self-Hosted',
        width: 50,
        type: BOOLEAN_FIELD,
        defaultSort: setDefaultSorting(Column.Organisation_SelfHosted, orderBy),
      },
      { field: Column.Organisation_Name, defaultSort: setDefaultSorting(Column.Organisation_Name, orderBy), title: 'Client Name', width: 200, removable: false },
      { field: Column.Organisation_SalesforceId, defaultSort: setDefaultSorting(Column.Organisation_SalesforceId, orderBy), title: 'Organisation Id', width: 100 },
      { field: Column.Organisation_Region, defaultSort: setDefaultSorting(Column.Organisation_Region, orderBy), title: 'Sub-Region', width: 100 },
      { field: Column.Organisation_Number, defaultSort: setDefaultSorting(Column.Organisation_Number, orderBy), title: 'Contract Number', width: 100 },
      { field: Column.Organisation_Status, defaultSort: setDefaultSorting(Column.Organisation_Status, orderBy), title: 'Contract Status', width: 100 },
      {
        field: Column.Organisation_Start,
        title: 'Contract Start',
        width: 50,
        type: DATE_FIELD,
        hidden: true,
        hiddenByColumnsButton: true,
        defaultSort: setDefaultSorting(Column.Organisation_Start, orderBy),
      },
      {
        field: Column.Organisation_End,
        title: 'Contract End',
        width: 50,
        type: DATE_FIELD,
        hidden: true,
        hiddenByColumnsButton: true,
        defaultSort: setDefaultSorting(Column.Organisation_End, orderBy),
      },
      {
        field: Column.Organisation_AccountManager,
        title: 'Account Manager',
        width: 100,
        hidden: true,
        hiddenByColumnsButton: true,
        defaultSort: setDefaultSorting(Column.Organisation_AccountManager, orderBy),
      },
      {
        field: Column.Organisation_CsmTeam,
        title: 'CSM',
        width: 100,
        hidden: true,
        hiddenByColumnsButton: true,
        defaultSort: setDefaultSorting(Column.Organisation_CsmTeam, orderBy),
      },
      {
        field: Column.Organisation_CsmManager,
        title: 'CSM Manager',
        width: 100,
        hidden: true,
        hiddenByColumnsButton: true,
        defaultSort: setDefaultSorting(Column.Organisation_CsmManager, orderBy),
      },
      {
        field: Column.Organisation_EnterpriseSite,
        title: 'Enterprise Site',
        width: 100,
        type: BOOLEAN_FIELD,
        hidden: true,
        hiddenByColumnsButton: true,
        defaultSort: setDefaultSorting(Column.Organisation_EnterpriseSite, orderBy),
      },
    ];
    break;
  case Route.IDS:
    columnList = [
      { 
        field: Column.IDS_Description,
        title: 'Description', 
        width: 50, 
        removable: false,
        defaultSort: setDefaultSorting(Column.IDS_Description, orderBy),
      },
      { 
        field: Column.IDS_Url, 
        title: 'Url', 
        width: 150, 
        removable: false,
        defaultSort: setDefaultSorting(Column.IDS_Url, orderBy),
      },
      { 
        field: Column.IDS_Location, 
        title: 'Location', 
        width: 100,
        defaultSort: setDefaultSorting(Column.IDS_Location, orderBy),
      },
      { 
        field: Column.IDS_VAULT, 
        title: 'Vault URL', 
        width: 150,
        defaultSort: setDefaultSorting(Column.IDS_VAULT, orderBy),
      },
      { 
        field: Column.IDS_CONSUL, 
        title: 'Consul URL', 
        width: 150,
        defaultSort: setDefaultSorting(Column.IDS_CONSUL, orderBy),
      },
      {
        field: Column.IDS_IP,
        title: 'IP Address',
        width: 100,
        hidden: true,
        hiddenByColumnsButton: true,
        defaultSort: setDefaultSorting(Column.IDS_IP, orderBy),
      },
    ];
    break;
  case Route.Salesforce:
  case Route.SalesforceREST:
  case Route.SalesforceSOAP:
    columnList = [
      { field: 'importId', hidden: true, identity: true },
      { field: 'importType', title: 'Import Type', width: 150 },
      { field: 'name', title: 'Name', width: 200 },
      { field: 'salesforceOrgId', title: 'Salesforce Id', width: 155 },
      { field: 'subregion', title: 'Sub-Region', width: 150 },
      { field: 'contractNumber', title: 'Contract Number', width: 185 },
      { field: 'contractStatus', title: 'Contract Status', width: 175 },
      {
        field: 'contractStart',
        title: 'Contract Start',
        width: 165,
        type: 'date',
        valueFormatter: (params: any): any => {
          return dateHelper.formatDateTime(params.value);
        },
      },
      {
        field: 'contractEnd',
        title: 'Contract End',
        width: 165,
        type: 'date',
        valueFormatter: (params: any): any => {
          return dateHelper.formatDateTime(params.value);
        },
      },
    ];
    break;
  default:
    columnList = [{ field: Column.Name, title: 'Name', width: 100, removable: false }];
    break;
  }

  return columnList.map((def: any): any => {
    if (def.type === DATE_FIELD || def.type === DATETIME_FIELD) {
      def.render = (rowData: any): string => {
        let date = getProperty(def.field, rowData);
        if (def.type === DATE_FIELD) date = dateHelper.formatDate(date);
        if (def.type === DATETIME_FIELD) date = dateHelper.formatDateTime(date);
        return date ?? DEFAULT_FIELD_VALUE;
      };
    } else if (def.type === BOOLEAN_FIELD) {
      def.render = (rowData: any): any => {
        const value = getProperty(def.field, rowData);
        if (!value || typeof value === 'object') {
          return DEFAULT_FIELD_VALUE;
        }
        return <input type="checkbox" checked={true} disabled className="checkbox" />;
      };
    } else if (!def.render) {
      def.render = (rowData: any): string => {
        const value = getProperty(def.field, rowData);
        if (!value || typeof value === 'object') {
          return DEFAULT_FIELD_VALUE;
        }
        return value;
      };
    }
    return def;
  });
};

export default useColumnsSetup;
