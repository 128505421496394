import React, {ReactElement} from 'react';
import * as Redux from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Component from 'components/ComponentsIndex';
import { refreshSearchResults } from 'store/Actions/SearchResults';
import Route from 'models/Routes'

const DataImported = (): ReactElement => {
  const dispatch = Redux.useDispatch();
  const history = useHistory();

  const handleClientManagement = (): void => {
    dispatch(refreshSearchResults(true, 0));
    history.push('/' + Route.Clients);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div style={{ padding: '40px 15px', textAlign: 'center' }}>
            <h2>Data Imported</h2>
            <div>
              Salesforce data was imported. Go to Client Management to view the newly imported
              records.
            </div>
            <div style={{ marginTop: '15px', marginBottom: '15px' }}>
              <Component.ButtonColoured
                handleClick={handleClientManagement}
                label="Client Management"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default DataImported;
