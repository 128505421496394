import React from 'react';
import { ContainerRow, Container } from 'styles/styledComponents/styledComponents';
import './viewForm.css';
import '../EditForm/checkbox.css';

interface IPageItemCheckbox { 
  minWidth?: string | undefined;
  value: boolean | undefined;
  nolastItemBorder?: boolean;  
  name: string;
  indentTitle?: boolean;
  icon?: any | undefined;
}

const PageItemCheckbox = (props : IPageItemCheckbox) : React.ReactElement => {
  const { minWidth, value, nolastItemBorder, name, indentTitle = false, icon } = props;
  return (
    <ContainerRow className={`item-container ${nolastItemBorder && 'no-last-border'}`}>
      <div className={`item-name ${indentTitle && 'item-name-indent'} ${icon && 'lessHeightPadding'}`}
        style={{minWidth: minWidth, placeContent: 'center space-between', placeItems: 'space-between', alignItems: 'right', flexDirection: 'row', display: 'flex'}}>           
        <div style={{justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>{name}</div>
        <div>         
          {icon}
        </div>
      </div>
      {value && (
        <div className="values-container">
          <Container style={{ flex: '1', padding: '0rem 0.5rem', height: '100%' }}>
            <input type="checkbox" checked={value} disabled className="checkbox" />
          </Container>
        </div>
      )}
    </ContainerRow>
  );
};

export default PageItemCheckbox;
