import React, {PropsWithChildren} from 'react';
import { ContainerRow } from 'styles/styledComponents/styledComponents';
import { TextField } from './EditIndex';

const IdsSiteEdit = (props: PropsWithChildren<any>) : React.ReactElement => {
  const { minWidth, IdsSite, status, setState } = props;
  return (
    <>
      <ContainerRow className="item-container">
        <div className="item-name fieldRequired" style={{ minWidth: minWidth }}>
          URL
        </div>
        <TextField
          required
          status={status}
          value={IdsSite?.url}
          setState={setState}
          targetName="url"
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Description
        </div>
        <TextField
          setState={setState}
          targetName="description"
          value={IdsSite?.description}
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Location
        </div>
        <TextField
          setState={setState}
          targetName="location"
          value={IdsSite?.location}
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name fieldRequired" style={{ minWidth: minWidth }}>
          Vault URL
        </div>
        <TextField
          required
          status={status}
          setState={setState}
          targetName="vault"
          value={IdsSite?.vault}
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name fieldRequired" style={{ minWidth: minWidth }}>
          Consul URL
        </div>
        <TextField
          required
          status={status}
          setState={setState}
          targetName="consul"
          value={IdsSite?.consul}
        />
      </ContainerRow> 
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          IP Address
        </div>
        <TextField
          setState={setState}
          targetName="ip"
          value={IdsSite?.ip}
        />
      </ContainerRow> 
    </>
  );
};

export default IdsSiteEdit;
