import React, {useState} from 'react'
import {IAssignedOrganisation, ISelectedSiteContext, ITenant} from 'models/Interfaces'


export const SiteSelectedContext = React.createContext<ISelectedSiteContext | undefined>(undefined)

interface HeaderProps {
  children: any;
}

export const SiteSelectedContextProvider: React.FC<HeaderProps> = ({children}) => {
  const [selectedSite, setSelectedSite] = useState<ITenant>()
  const [selectedClient, setSelectedClient] = useState<IAssignedOrganisation>()

  const resetState = (): void => {
    setSelectedSite(undefined)
    setSelectedClient(undefined)
  }
  return <SiteSelectedContext.Provider
    value={{
      state: {
        selectedSite,
        selectedClient,
      },
      resetState,
      setSelectedSite,
      setSelectedClient,
    }}>
    {children}
  </SiteSelectedContext.Provider>
}

export default SiteSelectedContextProvider
