import React, {PropsWithChildren} from 'react';
import * as Redux from 'react-redux';
import * as Component from 'components/ComponentsIndex';
import * as EditComponent from 'views/Common/EditForm/EditIndex';
import { setupPreviewPanel } from 'store/Actions/PreviewPanel';
import { refreshSearchResults } from 'store/Actions/SearchResults';
import { useFetch } from 'hooks';
import PanelSubHeader from 'views/Common/PanelSubHeader/PanelSubHeader'
import DataNotFound from 'error/DataNotFound';
import { ContainerColumn } from 'styles/styledComponents/styledComponents';
import { FormStatus } from 'views/Common/EditForm/Common';
import { ErrorDialog, handleError, useErrorState } from 'error/HandleErrors';
import { ApiHelperContext } from 'context/ApiHelperContext';
import Route, { VerbType } from 'models/Routes';
import notify, { MessageType, Message } from 'components/Notifications/toastify';
import { Props, Mode } from "models/types";
import { IApiHelperContext, IError, ILicense, IPreviewPanel, ISwrResult } from "models/Interfaces";
import { IPreviewPanelState } from "models/Interfaces";
import { getEditLicenceError } from "lib/ErrorsHelper";

const EditLicense: React.FC<Props>  = (props: PropsWithChildren<any>) => {
  const { minWidth = '10.75rem' } = props;
  const { id, section } = Redux.useSelector<IPreviewPanelState>((state) => state.PreviewPanelReducer) as IPreviewPanel;
  const fetchLicenseResult: ISwrResult  = useFetch(VerbType.GET, id ? `licenses/${id}` : '');
  const [License, setLicense] = React.useState<ILicense>();
  const [status, setStatus] = React.useState(FormStatus.INITIAL);
  const { errorDialog, errorMessage, errorTitle, setErrorDialog, setErrorMessage, setErrorTitle } = useErrorState();
  const dispatch = Redux.useDispatch();
  const context: IApiHelperContext | undefined = React.useContext(ApiHelperContext)
  const apiHelper = context?.state.apiHelper
  const { error, loading } = fetchLicenseResult;
  const licenceData = fetchLicenseResult?.data as ILicense

  React.useEffect(() => {
    setLicense(licenceData);
  }, [licenceData]);

  if (loading) return <Component.Loading />;
  if (id > 0 && !License) return <DataNotFound />;
  if (error) return <DataNotFound />;

  const errors = getEditLicenceError(License as ILicense);
  const isValid = Object.keys(errors).length === 0;

  function handleSave(): void {
    if (isValid && License && apiHelper) {
      apiHelper.put(`Licenses/update`, License, handleSaveCallBack, callbackErrorFunc);
    }
    setStatus(FormStatus.DIRTY);
  }

  function handleSaveCallBack(data: IError): void {
    if (data && data.status >= 400) {
      callbackErrorFunc(data);
    } else {
      dispatch(setupPreviewPanel(Route.Licenses, Mode.View, data && data.id));
      dispatch(refreshSearchResults(true));
      notify(Message.LicenseUpdated, MessageType.Success);
    }
  }

  function callbackErrorFunc(error: IError): void {
    handleError(error, setErrorTitle, setErrorMessage, setErrorDialog);
  }

  const handleCloseDialog = (): void => {
    setErrorDialog(false);
  };

  function handleCancel(): void {
    dispatch(setupPreviewPanel(section, Mode.View, id));
  }

  const errorAttributes = { errors } as PropsWithChildren<any>
  const editProds = {
    id: License?.id,
    minWidth,
    License,
    status,
    setLicense
  } as PropsWithChildren<any>

  return (
    <>
      <div className="search-title-nav">
        <PanelSubHeader handleCancel={handleCancel} handleSave={handleSave} description='Edit Details' />
      </div>
      {!isValid && FormStatus && (status === FormStatus.DIRTY)  && <EditComponent.WriteErrors {...errorAttributes} />}
      <ContainerColumn style={{ height: 'calc(100% - 3rem)', overflowY: 'auto' }}>
        <div className="entity-details-content full-width">
          <EditComponent.LicenseEdit {...editProds} />
        </div>
      </ContainerColumn>
      <ErrorDialog
        errorDialog={errorDialog} 
        handleCloseDialog={handleCloseDialog}
        errorTitle={errorTitle}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default EditLicense;
