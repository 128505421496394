import * as yup from 'yup';
import {IOrganisation} from "models/Interfaces";

export const validateOrgNameAndStart = (organisation: IOrganisation): any => {
  const orgSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    contractStart: yup.string().required('Contract start is required'),
  });

  try {
    orgSchema.validateSync(organisation, {abortEarly: false, stripUnknown: true});
    return [];
  } catch (err: any) {
    return err.errors;
  }
}
