// The label part is the name of the Okta Grouping from the Production setup
export const ADMIN = 'okta-emp-admin';
export const SUPPORT = 'okta-emp-support';
export const MARKETING = 'okta-emp-marketing';
export const PRODOPS = 'okta-emp-production';
export const PRODUCT = 'okta-emp-product';
export const SALES = 'okta-emp-sales';
export const CSM = 'okta-emp-csm';
export const IMPLEMENTATION = 'okta-emp-implementation';

export const hasAccess = (accessGroup: string, authState: any): any => {
  const groups = authState?.accessToken?.claims.groups;
  return groups && groups.includes(accessGroup);
};

export const hasAccessGroup = (accessGroups: any, authState: any): boolean => {
  if (!accessGroups) return true;
  const groups = authState?.accessToken?.claims.groups;
  let hasAccess = false;
  groups &&
    groups.length > 0 &&
    groups.map((group: any): void => accessGroups && accessGroups.includes(group) && (hasAccess = true));
  return hasAccess;
};
