import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStyles } from './Styles';

export default function AutoComplete(props) {
  const { options = [], value, handleChange, label, variant } = props;
  const classes = useStyles();
  return (
    <Autocomplete
      value={value}
      onChange={(e, newValue) => {
        handleChange(newValue);
      }}
      classes={{
        root: classes.root,
        option: classes.option,
      }}
      getOptionLabel={(option) => option.label}
      options={options}
      renderInput={(params) => (
        <TextField {...params} label={label} variant={variant ? variant : 'outlined'} />
      )}
    />
  );
}
