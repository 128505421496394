import React from 'react';
import { useFetch } from 'hooks';
import Select from '../Select';
import { IReactSelect } from 'models/Lists/Common';
import Route, { VerbType } from 'models/Routes';

interface IIdsSites { 
  idsSiteId?: string; 
  setState: React.Dispatch<React.SetStateAction<unknown>>;
  targetName: string;
  required?: boolean;
  hasError?: boolean;
}
interface IIdsSite { id: number; url: string; }

const IdsSites = (props: IIdsSites): React.ReactElement => {
  const { idsSiteId, setState, targetName, required, hasError } = props;
  const { data } = useFetch(VerbType.GET, Route.IDS);
  
  const handleChange = ((e: IReactSelect): void => {
    setState((state: string[]) => ({
      ...state,
      [targetName]: e?.value || '',
    }));
  });

  let options: unknown[] | null | undefined = [];

  if (data) {
    options = (data as IIdsSite[])?.map((Site) => ({
      label: Site.url,
      value: Site.id,
    }));
  }

  return (
    <Select
      value={idsSiteId}
      name={targetName}
      handleChange={handleChange}
      options={options}
      hasError={hasError}
      required={required}
    />
  );
};

export default IdsSites;
