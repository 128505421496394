import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AppRoutes from './RoutesSetup/RoutesSetup';
import ProtectedRoute from './ProtectedRoute';
import { LoginCallback, useOktaAuth } from '@okta/okta-react';
import { oktaSignInConfig } from 'okta/config';
import PageNotFound from 'error/PageNotFound';
import NotAuthorised from 'error/NotAuthorised';
import { ApiHelperContext } from 'context/ApiHelperContext';
import ApiHelperClass from 'lib/apiHelperClass';

function Routes() {
  const appRoutes = AppRoutes();
  const { oktaAuth } = useOktaAuth() || {};
  const context = React.useContext(ApiHelperContext);
  const apiHelper = context.state.apiHelper;

  React.useEffect(() => {
    if (oktaAuth && !apiHelper) {
      context.setApiHelper(new ApiHelperClass(oktaAuth));
    }
  }, [oktaAuth, apiHelper, context]);

  return (
    <Route>
      <Switch>
        {Object.keys(appRoutes).map((key) => {
          const appRoutesGroup = appRoutes[key];
          return appRoutesGroup.map((appRoute, index) => (
            <ProtectedRoute {...appRoute} key={index} oktaSignInConfig={oktaSignInConfig} />
          ));
        })}
        <Route path="/login/callback" component={LoginCallback} />
        <Route path="/notauthorised" component={NotAuthorised} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </Route>
  );
}

export default Routes;
