import React, {PropsWithChildren, ReactElement} from 'react';
import { ContainerRow } from 'styles/styledComponents/styledComponents';
import { editFormStyles } from './Common';
import IconButton from '@material-ui/core/IconButton';
import * as Component from 'components/ComponentsIndex';
import * as FieldLengths from '../Constants/FieldLengths';

const CustomProperties = (props: PropsWithChildren<any>): ReactElement => {
  const {
    CustomProperties,
    addCustomProperty,
    removeCustomProperty,
    AddNewCustomProperties,
    nolastItemBorder,
    setCustomProperties,
    setAddNewCustomProperties,
    minWidth = '10rem',
  } = props;
  const classes = editFormStyles();

  const handleChangeCustomProperty = (id: any, event: any): void => {
    const value = event && event.target && event.target.value;
    const actionIndex = CustomProperties && CustomProperties.findIndex((item: any) => item.id === id);
    setCustomProperties(
      CustomProperties &&
        CustomProperties.length > 0 &&
        CustomProperties.map((item: any, index: any) => {
          if (index !== actionIndex) {
            return item;
          }
          return {
            ...item,
            value: value,
          };
        }),
    );
  };

  const handleChangeNewCustomProperty = (id: any, type: any, event: any): void => {
    const value = event && event.target && event.target.value;
    const newState = [...AddNewCustomProperties];
    newState[id] = { ...newState[id], [type]: value };
    setAddNewCustomProperties(newState);
  };

  return (
    <>
      {
        //Existing Custom Properties saved within the database
        CustomProperties &&
          CustomProperties.length > 0 &&
          CustomProperties.map((Property: any, index: any): ReactElement => {
            return (
              <ContainerRow className="item-container" key={`name${index}`}  style={{ borderTop: 'thin solid #ababab' }}>
                <input
                  name={Property.name}
                  className={`${classes.textField}`}
                  disabled={true}
                  defaultValue={Property.name}
                  onChange={(event): void => handleChangeCustomProperty(Property.id, event)}
                  style={{
                    minWidth: minWidth,
                    fontWeight: 600,
                    borderRight: 'thin solid #ababab',
                    backgroundColor: '#f2f2f2',
                    color: '#333',
                  }}
                />
                <div className="values-container">
                  <input
                    name={Property.value}
                    className={`item-value ${classes.textField}`}
                    onChange={(event): void => handleChangeCustomProperty(Property.id, event)}
                    defaultValue={Property.value}
                    style={{ minWidth: minWidth }}
                    key={`value${index}`}
                    maxLength={parseInt(FieldLengths.CustomPropertyDescription)}
                  />
                </div>
                <Component.Tooltip title={`Remove '${Property.name}' for this Site`}>
                  <IconButton
                    color="inherit"
                    component="span"
                    onClick={(): void => removeCustomProperty(Property.name, Property.id, false)}
                  >
                    <i className="i-bpow-cross" style={{ fontSize: '0.7rem', color: 'rgba(0, 0, 0, 0.9)' }} />
                  </IconButton>
                </Component.Tooltip>
              </ContainerRow>
            );
          })
      }
      {
        //New Custom Properties not yet saved within the database
        AddNewCustomProperties &&
          AddNewCustomProperties.length > 0 &&
          AddNewCustomProperties.map((Property: any, index: any): ReactElement => {
            return (
              <ContainerRow className="item-container" key={`newname${index}`}>
                <input
                  name="name"
                  className={`${classes.textField}`}
                  defaultValue={Property.name}
                  onChange={(event): void => handleChangeNewCustomProperty(index, 'name', event)}
                  style={{
                    minWidth: minWidth,
                    fontWeight: 600,
                    borderRight: 'thin solid #ababab',
                    backgroundColor: '#f2f2f2',
                    color: '#333',
                  }}
                  maxLength={parseInt(FieldLengths.CustomPropertyTitle)}
                />
                <div className="values-container">
                  <input
                    name="value"
                    defaultValue={Property.value}
                    className={`item-value ${classes.textField}`}
                    onChange={(event): void => handleChangeNewCustomProperty(index, 'value', event)}
                    style={{ minWidth: minWidth }}
                    key={`newvalue${index}`}
                    maxLength={parseInt(FieldLengths.CustomPropertyDescription)}
                  />
                </div>
                <Component.Tooltip title={`Remove '${Property.name}' for this Site`}>
                  <IconButton
                    color="inherit"
                    component="span"
                    onClick={(): void => removeCustomProperty(Property.name, index, true)}
                  >
                    <i className="i-bpow-cross" style={{ fontSize: '0.7rem', color: 'rgba(0, 0, 0, 0.9)' }} />
                  </IconButton>
                </Component.Tooltip>
              </ContainerRow>
            );
          })
      }
      <ContainerRow className={`item-container-coloured ${nolastItemBorder && 'no-last-border'}`}>
        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
          <Component.ButtonColoured
            handleClick={addCustomProperty}
            color="primary"
            label="Add Custom Property"
          />
        </div>
      </ContainerRow>
    </>
  );
};

export default CustomProperties;
