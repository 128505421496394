import React, {PropsWithChildren} from 'react';
import { useFetch } from 'hooks';
import * as ViewComponent from 'views/Common/ViewForm/ViewIndex';
import * as Component from 'components/ComponentsIndex';
import {Props} from "models/types";
import {IOrganisation} from "models/Interfaces";
import { VerbType } from 'models/Routes';

const TenantOrganisations: React.FC<Props> = (props: PropsWithChildren<any>) => {
  const { tenantId, minWidth = '11rem' } = props;
  const { data } = useFetch(VerbType.GET, tenantId ? `Tenants/${tenantId}/organisations` : '');
  const [Organisations, setOrganisations] = React.useState<IOrganisation[]>();

  React.useEffect(() => {
    setOrganisations(data);
  }, [data]);

  const pageItemAttr = {value:"No Organisation assigned", minWidth } as PropsWithChildren<any>
  return (
    <>
      {Organisations && Organisations.length > 0 ? (
        Organisations.map((Organisation, index) => (
          <React.Fragment key={index}>
            <Component.Accordion
              title="Organisation"
              itemId={Organisation.organisationId}
              items="Organisation"
              minWidth={minWidth}
              copyText={Organisation.organisationName}
            />
            <Component.Accordion
              title="License"
              itemId={tenantId}
              items="LicenseWithUsage"
              minWidth={minWidth}
            />
            <Component.Accordion
              title="Package"
              itemId={Organisation.packageId}
              items="Package"
              minWidth={minWidth}
              copyText={Organisation.package}
            />
          </React.Fragment>
        ))
      ) : (
        <ViewComponent.PageItem {...pageItemAttr} />
      )}
    </>
  );
};

export default TenantOrganisations;
