import * as Column from '../Constants/Columns';

const useOrderByMapping = (OrderBy: string): string => {
  let orderBy;
  switch (OrderBy) {
  // Site Management
  case Column.Tenant_DSN:
    orderBy = 'DSN';
    break;
  case Column.Tenant_URL:
    orderBy = 'URL';
    break;
  case Column.Tenant_Organisation:
    orderBy = 'ORGANISATION';
    break;
  case Column.Tenant_Purpose:
    orderBy = 'PURPOSE';
    break;
  case Column.Tenant_Status:
    orderBy = 'STATUS';
    break;
  case Column.Tenant_Package:
    orderBy = 'PACKAGE';
    break;
  case Column.Tenant_License:
    orderBy = 'LICENSE';
    break;
  case Column.Tenant_Database:
    orderBy = 'DATABASE';
    break;
  case Column.Tenant_DatabaseServer:
    orderBy = 'DATABASESERVER';
    break;
  case Column.Tenant_WebServer:
    orderBy = 'WEBSERVER';
    break;
  case Column.Tenant_IpRestrictions:
    orderBy = 'IPRESTRICTIONS';
    break;
  case Column.Tenant_HasIpRestrictions:
    orderBy = 'ISIPRESTRICTIONS';
    break;
  case Column.Tenant_AutoReminder:
    orderBy = 'AUTOREMINDER';
    break;
  case Column.Tenant_EFAutoSend:
    orderBy = 'EFAUTOSEND';
    break;
  case Column.Tenant_ESignature:
    orderBy = 'ESIGNATURE';
    break;
  case Column.Tenant_SSO:
    orderBy = 'SSO';
    break;
  case Column.Tenant_JobServer:
    orderBy = 'JOBSERVER';
    break;
  case Column.Tenant_ReportIt:
    orderBy = 'REPORTIT';
    break;
  case Column.Tenant_Notes:
    orderBy = 'NOTES';
    break;

  // Auditing
  case Column.Audit_AuditOn:
    orderBy = 'AuditOn';
    break;
  case Column.Audit_Description:
    orderBy = 'Description';
    break;
  case Column.Audit_UserName:
    orderBy = 'UserName';
    break;
  case Column.Audit_Category:
    orderBy = 'Category';
    break;

  // Client Management
  case Column.Organisation_Name:
    orderBy = 'Name';
    break;
  case Column.Organisation_SalesforceId:
    orderBy = 'SalesforceOrgId';
    break;
  case Column.Organisation_Region:
    orderBy = 'Subregion';
    break;
  case Column.Organisation_Status:
    orderBy = 'ContractStatus';
    break;
  case Column.Organisation_Number:
    orderBy = 'ContractNumber';
    break;
  case Column.Organisation_Start:
    orderBy = 'ContractStart';
    break;
  case Column.Organisation_End:
    orderBy = 'ContractEnd';
    break;
  case Column.Organisation_AccountManager:
    orderBy = 'AccountManager';
    break;
  case Column.Organisation_CsmTeam:
    orderBy = 'CsmTeam';
    break;
  case Column.Organisation_CsmManager:
    orderBy = 'CsmManager';
    break;
  case Column.Organisation_SelfHosted:
    orderBy = 'IsSelfHosted';
    break;
  case Column.Organisation_EnterpriseSite:
    orderBy = 'HasEnterpriseSite';
    break;

  default:
    orderBy = '';
    break;
  }
  return orderBy;
};

export default useOrderByMapping;
