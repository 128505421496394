export type Props = {
  text: string;
  color: string;
};

export type TabType = {
  text: string;
  tooltip: string;
  url: string;
  selector?: string;
  paginatedResultsOnly?: boolean;
  showAddButton?: boolean;
  disableFilter?: boolean;
  searchTitle?: string;
  addAccessOnly?: string[];
  accessOnly?: string[];
};

export type ReportType = {
  reportId: string;
  label: string;
  url: string;
  selector?: string;
  columnSetup: string;
  paginatedResultsOnly?: boolean;
};

export interface ITabs {
  tabs: TabType[];
}

export interface IReport {
  reports: ReportType[];
}
export interface ITabs {
  tabs: TabType[];
}

export enum Mode {
  View = 'view',
  Edit = 'edit',
  Add = 'add',
}

export enum eUpdateSiteLicense {
  Pending = 'Pending',
  Approved = 'Approved',
  Cancelled = 'Cancelled',
}
        
