import React, {PropsWithChildren} from 'react';
import * as Redux from 'react-redux';
import * as Component from 'components/ComponentsIndex';
import useReports from './ReportsSetup';
import SearchResults from 'views/Common/SearchResults/SearchResults';
import ReportsInformation from './ReportsInformation';
import {useSearchParams} from 'hooks';
import {Props} from "models/types";
import {ISearchResultReducer, ISearchResultReducerState} from "models/Interfaces";

const GetResults: React.FC<Props> = (props: PropsWithChildren<any>) => {
  const {Report, filterOptions, filters, pathName} = props
  let selectorURL = '';
  if (Report && Report.selector) selectorURL += `&${Report.selector}`;
  let hostingFilter = 'Both'
  if (filters && filters.selfHosted && !filters.hosted) {
    hostingFilter = `selfHostedOnly`;
  }
  if (filters && filters.hosted && !filters.selfHosted) {
    hostingFilter = `HostedOnly`;
  }
  if (filters) selectorURL += `&hostingSelector=${hostingFilter}`;

  const {handleDebounceUpdate, updateSearchParams, SearchParams} = useSearchParams();
  if (Report && Report.url) {
    return (
      <SearchResults
        keyField="id"
        url={Report.url}
        selector={selectorURL}
        filterStatus={Report.status}
        addAccessOnly={false}
        disableRowClick={true}
        searchTitle={Report.label}
        columnSetup={Report.columnSetup}
        filterComponent={filterOptions}
        exportDesc={Report.label}
        tabName={Report.label}
        pathName={pathName}
        handleDebounceUpdate={handleDebounceUpdate}
        updateSearchParams={updateSearchParams}
        SearchParams={SearchParams}
        paginatedResultsOnly={Report.paginatedResultsOnly}
      />
    )
  }
  const atrritubes = {} as PropsWithChildren<any>
  return (<ReportsInformation {...atrritubes}/>)
}

const Reporting: React.FC<Props> = () => {
  const {reports} = useReports();
  const [Report, setReport] = React.useState(null);
  const [filters, setFilters] = React.useState();
  const {pathName} = Redux.useSelector<ISearchResultReducer>((state) => state.SearchResultsReducer) as ISearchResultReducerState;

  const handleCheckBoxFilter = (e: any): void => {
    setFilters((state: any) => {
      return {
        ...state,
        [e.target.name]: e.target.checked,
      };
    });
  };

  const styles = {
    width: '200px',
    marginTop: '15px',
    height: '35px',
    float: 'right',
    position: 'relative',
    zIndex: '1',
    padding: '9px',
    display: 'flex'
  } as any;

  const mainFilters: any = filters
  const hostedFilters: React.FC<Props> = () => {
    return (
      <>
        <div style={styles}>
          <div style={{paddingRight: '10px', fontWeight: 600}}>Self-hosted</div>
          <input
            type="checkbox"
            checked={mainFilters && mainFilters.selfHosted}
            name='selfHosted'
            className="checkbox"
            onChange={handleCheckBoxFilter}
          />
          <div style={{paddingLeft: '10px', paddingRight: '10px', fontWeight: 600}}>Hosted</div>
          <input
            type="checkbox"
            checked={mainFilters && mainFilters.hosted}
            name='hosted'
            className="checkbox"
            onChange={handleCheckBoxFilter}
          />
        </div>
      </>
    );
  };

  const handleChangeReport = (report: any): void => {
    setReport(report);
  }

  const mainReport: any = Report
  const bShowHostedFilters = (mainReport && mainReport.reportId !== 'OVERUSAGE');
  const filterOptions = bShowHostedFilters && hostedFilters;
  const attributes = {Report, filterOptions, filters, pathName} as PropsWithChildren<any>
  return (
    <>
      <div className="nav-panel-header bold alignLeft" style={{paddingLeft: '0px', height: '3rem'}} title='Reporting'>
        <div style={{paddingLeft: '24px'}}>
          <Component.AutoComplete
            options={reports}
            value={Report}
            handleChange={handleChangeReport}
            label="Reports"
            variant="outlined"
          />
        </div>
      </div>
      <GetResults {...attributes} />
    </>
  );
}

export default Reporting;
