import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  button: {
    color: '#333',
    border: '1px solid #A9A9A9',
    background: '#fff',
    marginLeft: '8px',
    padding: '0px 15px',
    textTransform: 'none',
    cursor: 'pointer',
    fontWeight: '400',
    fontSize: '14px',
    height: '32px',
    minWidth: '80px',
    borderRadius: '2px',
  },
  mainButton: {
    backgroundColor: theme.typography.primaryColor,
    fontSize: '14px',
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.typography.primaryHoverColor,
    },
  },
}));

export const ButtonColoured = (props) => {
  const { handleClick, label, color, minWidth } = props;
  const classes = useStyles();
  return (
    <>
      <Button
        onClick={handleClick}
        color={color ? color : 'inherit'}
        className={`${classes.button} ${classes.mainButton}`}
        autoFocus
        style={{ minWidth: minWidth }}
      >
        {label}
      </Button>
    </>
  );
};

const ButtonSimple = (props) => {
  const { handleClick, label, color, minWidth } = props;
  const classes = useStyles();
  return (
    <>
      <Button
        onClick={handleClick}
        color={color ? color : 'inherit'}
        className={classes.button}
        style={{ minWidth: minWidth }}
      >
        {label}
      </Button>
    </>
  );
};
export default ButtonSimple;
