import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useStyles } from './Common';

interface IAlert { 
  name : string | undefined;
  server?: string | undefined;
  enabled?: boolean | undefined;  
}

interface IAutoReminderAlerts { 
  alerts: Array<IAlert> | undefined;
}

const AutoReminderAlerts = (props : IAutoReminderAlerts) : React.ReactElement => {
  const { alerts } = props;
  const classes = useStyles();
  return (
    <div style={{ padding: '1rem', backgroundColor: '#f6f6f6' }}>
      <TableContainer>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Server</TableCell>
              <TableCell align="center">Enabled</TableCell>
              <TableCell>Task Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alerts &&
              alerts.length > 0 &&
              alerts.map((Alert) => (
                <TableRow key={Alert.name}>
                  <TableCell component="th" scope="row">
                    {Alert.server}
                  </TableCell>
                  <TableCell align="center">
                    <input type="checkbox" checked={Alert.enabled} disabled className="checkbox" />
                  </TableCell>
                  <TableCell>{Alert.name}</TableCell>
                </TableRow>
              ))}
            {(!alerts || alerts?.length === 0) && (
              <TableRow key="none">
                <TableCell component="th" colSpan={3} scope="row">
                  No records found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AutoReminderAlerts;
