import React, {ReactElement, useState} from 'react';
import * as Redux from 'react-redux';
import PageHeaderIcons from 'views/Common/PageHeaderTabs/PageHeaderTabs';
import SearchResults from 'views/Common/SearchResults/SearchResults';
import useHeaderTabs from './HeaderTabsSetup';
import { useSearchParams } from 'hooks';
import Route from 'models/Routes';
import {Props, TabType} from "models/types";
import {ISearchResultReducer, ISearchResultReducerState} from "models/Interfaces";
import {IGetResultProps} from "models/Interfaces";

const GetResults = (props: IGetResultProps): ReactElement|null =>{
  const { tabsDetails, pathName, resetPage, setResetPage } = props as IGetResultProps
  const { handleDebounceUpdate, updateSearchParams, SearchParams } = useSearchParams();

  switch (tabsDetails?.url) {
  case Route.Licenses:
    return (
      <SearchResults
        keyField="id"
        resetPage={resetPage}
        setResetPage={setResetPage}
        url={tabsDetails.url}
        addAccessOnly={tabsDetails.addAccessOnly}
        searchTitle={tabsDetails.searchTitle}
        paginatedResultsOnly={tabsDetails.paginatedResultsOnly}
        tabName={tabsDetails.text}
        pathName={pathName}
        handleDebounceUpdate={handleDebounceUpdate}
        updateSearchParams={updateSearchParams}
        SearchParams={SearchParams} 
      />
    );
  case Route.Packages:
    return (
      <SearchResults
        keyField="code"
        url={tabsDetails.url}
        resetPage={resetPage}
        setResetPage={setResetPage}
        selector={`packageSelector=${tabsDetails.selector}`}
        hideAddbutton={tabsDetails.selector === 'discontinued'}
        addAccessOnly={tabsDetails.addAccessOnly}
        filterStatus={tabsDetails.status}
        searchTitle={tabsDetails.searchTitle}
        columnSetup={tabsDetails.selector === 'discontinued' && 'packages-discontinued'}
        paginatedResultsOnly={tabsDetails.paginatedResultsOnly}
        tabName={tabsDetails.text}
        pathName={pathName}
        handleDebounceUpdate={handleDebounceUpdate}
        updateSearchParams={updateSearchParams}
        SearchParams={SearchParams} 
      />
    );
  case Route.Features:
    return (
      <SearchResults
        keyField="code"
        resetPage={resetPage}
        setResetPage={setResetPage}
        url={tabsDetails.url}
        addAccessOnly={tabsDetails.addAccessOnly}
        searchTitle={tabsDetails.searchTitle}
        paginatedResultsOnly={tabsDetails.paginatedResultsOnly}
        tabName={tabsDetails.text}
        pathName={pathName}
        handleDebounceUpdate={handleDebounceUpdate}
        updateSearchParams={updateSearchParams}
        SearchParams={SearchParams} 
      />
    );
  default:
    return null;
  }
}

const PackageManagement: React.FC<Props> = () => {
  const { pathName, startTab = 0 } = Redux.useSelector<ISearchResultReducer>((state) => state.SearchResultsReducer) as ISearchResultReducerState;
  const { tabs } = useHeaderTabs()
  const defaultValue: TabType = tabs[startTab as number]
  const [tabsDetails, setTabDetails] = React.useState(defaultValue);
  const [resetPage, setResetPage] = useState(false)

  const GetDataForView = (tab: any):void => {
    setTabDetails(tab);
    setResetPage(true)
  };

  const attributes = { tabs, GetDataForView, startTab}
  const parameters = {tabsDetails, pathName, resetPage, setResetPage } as IGetResultProps
  return (
    <>
      <PageHeaderIcons {...attributes} />
      <GetResults {...parameters} />
    </>
  );
}

export default PackageManagement;
