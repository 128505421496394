import React, {ReactElement} from 'react';
import * as Redux from 'react-redux';
import PageHeaderIcons from 'views/Common/PageHeaderTabs/PageHeaderTabs';
import ImportDataGrid from './ImportDataGrid/ImportDataGrid';
import HeaderTabs from './HeaderTabsSetup';
import {ISearchResultReducerState} from "models/Interfaces";

const SalesForce = (): ReactElement => {
  const { startTab = 0 } = Redux.useSelector<any>((state) => state.SearchResultsReducer) as ISearchResultReducerState;
  const { tabs } = HeaderTabs();
  const [url, setUrl] = React.useState(tabs[startTab as number].url);
  const [LastRun, setLastRun] = React.useState(3);
  const GetDataForView = (tab: any): void => {
    setUrl(tab?.url);
  };
  const handleOnChange = (event: any): void => {
    setLastRun(event.target.value);
  };

  const zIndex: any = '1'

  const options = [
    { value: '1', label: '1 Month' },
    { value: '2', label: '2 Months' },
    { value: '3', label: '3 Months' },
    { value: '4', label: '4 Months' },
    { value: '5', label: '5 Months' },
    { value: '6', label: '6 Months' },
  ];

  const selectOptions = { options } as any

  const filterOptions = (): ReactElement => {
    return (
      <div
        style={{
          width: '300px',
          float: 'right',
          position: 'absolute',
          zIndex,
          marginTop: '17px',
          left: '350px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div style={{ paddingRight: '10px', fontWeight: 600 }}>Last Run From:</div>
        <select
          value={LastRun}
          style={{
            width: '100px',
            border: '1px solid rgb(171, 171, 171)',
            marginRight: '10px',
            height: '33px',
            position: 'relative',
            zIndex,
            padding: '4px',
          }}
          name="auditFilter"
          onChange={handleOnChange}
          {...selectOptions}
        >
          {options?.length > 0 &&
            options.map((option:any, index: number) => {
              return (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              );
            })}
        </select>
      </div>
    );
  };

  return (
    <>
      <PageHeaderIcons tabs={tabs} GetDataForView={GetDataForView} startTab={startTab} />
      <ImportDataGrid keyField="code" url={url} lastRun={LastRun} filterComponent={filterOptions} />
    </>
  );
}

export default SalesForce;
