import * as yup from 'yup';
import { IIdsSite } from 'models/Interfaces';

export const getIDSSiteErrors = (IDSSite: IIdsSite | undefined): any => {
  IDSSite = IDSSite || ({} as IIdsSite);
  const schema = yup.object().shape({
    url: yup.string().required('URL is required').url(),
    vault: yup.string().required('Vault URL is required').url(),
    consul: yup.string().required('Consul URL is required').url(),
  });

  try {
    schema.validateSync(IDSSite, { abortEarly: false, stripUnknown: true });
    return [];
  } catch (err: any) {
    return err.errors;
  }
};
