import React from 'react';
import { ContainerRow } from 'styles/styledComponents/styledComponents';
import { TextField, DatePicker } from './EditIndex';
import { IIdnSite } from 'models/Interfaces';

interface IIdnSiteEdit {
  minWidth?: string;
  Tenant: IIdnSite;
  status?: string;
  setTenant: React.Dispatch<React.SetStateAction<unknown>>;
  indentTitle?: false | undefined;
}

const IdnSiteEdit = (props: IIdnSiteEdit) : React.ReactElement => {
  const { minWidth, Tenant, status, setTenant, indentTitle = false } = props;
  return (
    <>
      <ContainerRow className="item-container">
        <div className={`item-name fieldRequired ${indentTitle ? 'item-name-indent' : ''}`} style={{ minWidth: minWidth }}>
          Alpha Task Ref
        </div>
        <TextField
          required
          status={status}
          value={Tenant?.alphaTaskRef}
          setState={setTenant}
          targetName="alphaTaskRef"
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className={`item-name ${indentTitle ? 'item-name-indent' : ''}`} style={{ minWidth: minWidth }}>
          Business Consultant
        </div>
        <TextField
          setState={setTenant}
          targetName="businessConsultant"
          value={Tenant?.businessConsultant}
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className={`item-name ${indentTitle ? 'item-name-indent' : ''}`} style={{ minWidth: minWidth }}>
          Project Manager
        </div>
        <TextField
          setState={setTenant}
          targetName="projectManager"
          value={Tenant?.projectManager}
        />
      </ContainerRow>     
      <ContainerRow className="item-container">
        <div className={`item-name ${indentTitle ? 'item-name-indent' : ''}`} style={{ minWidth: minWidth }}>
          Setup Date
        </div>
        <DatePicker
          value={Tenant?.setupDate}
          setState={setTenant}
          targetName="setupDate"
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className={`item-name ${indentTitle ? 'item-name-indent' : ''}`} style={{ minWidth: minWidth }}>
          Duration of Site
        </div>
        <TextField
          setState={setTenant}
          targetName="duration"
          value={Tenant?.duration}
        />
      </ContainerRow> 
      <ContainerRow className="item-container">
        <div className={`item-name ${indentTitle ? 'item-name-indent' : ''}`} style={{ minWidth: minWidth }}>
          Close Down Date
        </div>
        <DatePicker
          value={Tenant?.closeDownDate}
          setState={setTenant}
          targetName="closeDownDate"
        />
      </ContainerRow>
    </>
  );
};

export default IdnSiteEdit;
