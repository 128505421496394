import React from 'react';
import * as ViewComponent from './ViewIndex';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useStyles } from './Common';
import {ILicenseViewWithUsage} from 'models/Interfaces';

const LicenseViewWithUsage = (props : ILicenseViewWithUsage) : React.ReactElement => {
  const { minWidth, License, LicenseUsage } = props;
  const classes = useStyles();
  const LSLabel = <div style={{display: 'inline-block', paddingLeft: '3px'}}>(LS)</div>;
  return (
    <>
      <TableContainer>
        <Table className={classes.tableSplit} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Licensed</TableCell>
              <TableCell>Usage{LSLabel}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <ViewComponent.PageItem name="Companies" minWidth={minWidth} value={License?.companies} />
                <ViewComponent.PageItem name="People" minWidth={minWidth} value={License?.people} />
                <ViewComponent.PageItem
                  name="Incorporations"
                  minWidth={minWidth}
                  value={License?.incorporations}
                />
                <ViewComponent.PageItem
                  name="Max Users"
                  minWidth={minWidth}
                  value={License?.users}
                  nolastItemBorder={false}
                />
                <ViewComponent.PageItem
                  name="Admin Users"
                  minWidth={minWidth}
                  value={License?.adminUsers}
                  nolastItemBorder={false}
                />
                <ViewComponent.PageItem
                  name="Edit Users"
                  minWidth={minWidth}
                  value={License?.editUsers}
                  nolastItemBorder={false}
                />
                <ViewComponent.PageItem
                  name="Edition"
                  minWidth={minWidth}
                  value={License?.edition}
                  nolastItemBorder={false}
                />
              </TableCell>
              <TableCell>
                <ViewComponent.PageItem name="Registered To" minWidth={minWidth} value={LicenseUsage?.registeredTo} />
                <ViewComponent.PageItem name="Account Name" minWidth={minWidth} value={LicenseUsage?.accountName} />
                <ViewComponent.PageItem name="Total Users" minWidth={minWidth} value={LicenseUsage?.totalUsers} />
                <ViewComponent.PageItem name="Admin Users" minWidth={minWidth} value={LicenseUsage?.adminUsers} />  
                <ViewComponent.PageItem name="Edit Users" minWidth={minWidth} value={LicenseUsage?.editUsers} />
                <ViewComponent.PageItem name="Read-Only Users" minWidth={minWidth} value={LicenseUsage?.readOnlyUsers} />
                <ViewComponent.PageItem name="Active Companies" minWidth={minWidth} value={LicenseUsage?.activeCompanies} />
                <ViewComponent.PageItem name="Total Companies" minWidth={minWidth} value={LicenseUsage?.totalCompanies} />
                <ViewComponent.PageItemCheckbox name="Active Only Companies" minWidth={minWidth} value={LicenseUsage?.activeOnlyCompanies} />
                <ViewComponent.PageItem name="Max Companies Used" minWidth={minWidth} value={LicenseUsage?.maxCompaniesUsed} />
                <ViewComponent.PageItem name="Audited Logins for last 3 months" minWidth={minWidth} value={LicenseUsage?.auditedLoginsFor3Months} />
                <ViewComponent.PageItem name="Unique Logins for last 3 months" minWidth={minWidth} value={LicenseUsage?.uniqueLoginsFor3Months} />
                <ViewComponent.PageItemDate name="Last Audit Login" minWidth={minWidth} value={LicenseUsage?.lastAuditLogin} format='datetime' />
                <ViewComponent.PageItemDate name="Last Login" minWidth={minWidth} value={LicenseUsage?.lastLogin} nolastItemBorder={true} format='datetime' />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default LicenseViewWithUsage;
