import React, {PropsWithChildren, SetStateAction} from 'react';
import * as Redux from 'react-redux';
import { setupPreviewPanel } from 'store/Actions/PreviewPanel';
import { refreshSearchResults } from 'store/Actions/SearchResults';
import { useFetch } from 'hooks';
import PanelSubHeader from 'views/Common/PanelSubHeader/PanelSubHeader';
import * as Component from 'components/ComponentsIndex';
import DataNotFound from 'error/DataNotFound';
import { ContainerColumn } from 'styles/styledComponents/styledComponents';
import { FormStatus } from 'views/Common/EditForm/Common';
import { ErrorDialog, handleError, useErrorState } from 'error/HandleErrors';
import * as ViewComponent from 'views/Common/ViewForm/ViewIndex';
import * as EditComponent from 'views/Common/EditForm/EditIndex';
import * as ListComponent from 'views/Common/EditForm/Lists/ListsIndex';
import TenantOrganisations from '../View/TenantOrganisations';
import informationIcons from 'lib/InformationIcons';
import { ApiHelperContext } from 'context/ApiHelperContext';
import Route, { VerbType } from 'models/Routes';
import notify, { MessageType, Message } from 'components/Notifications/toastify';
import { Props, Mode } from "models/types";
import { IPreviewPanelState } from "models/Interfaces";
import { IError, IPreviewPanel } from "models/Interfaces";
import { WriteSFSDataCenter } from 'views/Common/ViewForm/Common';
import {validateTenant} from "lib/validators/tenant";
import { WriteTenantStatus } from '../../Common/ViewForm/Common';

const EditTenant: React.FC<Props> = (props: PropsWithChildren<any>) => {
  const {minWidth = '11rem'} = props;
  const {id} = Redux.useSelector<IPreviewPanelState>((state) => state.PreviewPanelReducer) as IPreviewPanel;
  const {data, error, loading} = useFetch(VerbType.GET, id ? `tenants/${id}` : '');
  const [Tenant, setTenant] = React.useState();
  const [CustomProperties, setCustomProperties] = React.useState([]);
  const [AddNewCustomProperties, setAddNewCustomProperties] = React.useState([]);
  const [RemoveCustomProperties, setRemoveCustomProperties] = React.useState([]);
  const [status, setStatus] = React.useState(FormStatus.INITIAL);
  const {errorDialog, errorMessage, errorTitle, setErrorDialog, setErrorMessage, setErrorTitle} = useErrorState();
  const dispatch = Redux.useDispatch();
  const context = React.useContext(ApiHelperContext);
  const apiHelper = context?.state.apiHelper;

  React.useEffect(() => {
    setTenant(data);
    setCustomProperties(data?.customProperties);
    setTenant((state: any) => ({
      ...state,
      addOnFeatures: data?.assignedOrganisation.addOnFeatures && JSON.parse(data.assignedOrganisation.addOnFeatures),
    }));
    data && !data.purpose && setTenant((state: any) => ({...state, purpose: 'Production'}));
  }, [data]);

  if (loading) return <Component.Loading/>;
  if (id > 0 && !Tenant) return <DataNotFound/>;
  if (error) return <DataNotFound/>;

  const errors = validateTenant(Tenant, true);
  const isValid = errors.length === 0;

  function handleSave(): void {
    if (isValid && Tenant && apiHelper) {
      apiHelper.put('Tenants/update', Tenant, handleSaveCallBack, callbackErrorFunc);
    }
    setStatus(FormStatus.DIRTY);
  }

  function handleSaveCallBack(data: IError): void {
    if (data && data.status >= 400) {
      callbackErrorFunc(data);
    } else {
      addCustomProperties();
      removeCustomProperties();
      if (!AddNewCustomProperties || AddNewCustomProperties.length === 0) handleCallBack();
      notify(Message.SiteUpdated, MessageType.Success);
    }
  }

  function callbackErrorFunc(error: IError): void {
    handleError(error, setErrorTitle, setErrorMessage, setErrorDialog);
  }

  const handleCloseDialog = (): void => {
    setErrorDialog(false);
  };

  function handleCancel(): void {
    dispatch(setupPreviewPanel(Route.Sites, Mode.View, id));
  }

  function addCustomProperties(): void {
    // Add new Custom Properties
    AddNewCustomProperties &&
    AddNewCustomProperties.length > 0 &&
    AddNewCustomProperties.map((Property) =>
      apiHelper?.post(`Tenants/${id}/properties`, Property, handleCallBack),
    );

    // Updating existing Custom Properties
    CustomProperties &&
    CustomProperties.length > 0 &&
    CustomProperties.map((Property) =>
      apiHelper?.post(`Tenants/${id}/properties`, Property, handleCallBack),
    );
  }

  function removeCustomProperties(): void {
    RemoveCustomProperties &&
    RemoveCustomProperties.length > 0 &&
    RemoveCustomProperties.map((Name) =>
      apiHelper?.delete(`Tenants/${id}/properties/${Name}`, handleCallBack, callbackErrorFunc),
    );
  }

  function handleCallBack(): void {
    dispatch(setupPreviewPanel(Route.Sites, Mode.View, id));
    dispatch(refreshSearchResults(true));
  }

  const removeCustomProperty = (name: any, id: any, newProperty: any): void => {
    let filteredArray
    if (!newProperty) {
      setRemoveCustomProperties((names) => names.concat(name));
      filteredArray = CustomProperties && CustomProperties.filter((item: any): boolean => item.id !== id);
      setCustomProperties(filteredArray);
    } else {
      filteredArray = AddNewCustomProperties.filter((item: any): boolean => item.id !== id);
      setAddNewCustomProperties(filteredArray);
    }
  };

  const addCustomProperty = (): void => {
    const lastId = !AddNewCustomProperties ? 0 : AddNewCustomProperties.length;
    const handleSetProps = (names: any[]): any => {
      return Array.isArray(names)
        ? names.concat([{id: lastId, name: '', value: ''}])
        : [{id: lastId, name: '', value: ''}]
    }
    setAddNewCustomProperties(handleSetProps as SetStateAction<any>);
  };

  const currentTenant: any = Tenant;
  const tenantOrgAttr = { tenantId: currentTenant?.id} as PropsWithChildren<any>;

  return (
    <>
      <div className="search-title-nav">
        <PanelSubHeader
          handleCancel={handleCancel}
          handleSave={handleSave} description='Edit Details'
          icons={informationIcons.WriteIcons('GSL')}
          subTitle={WriteTenantStatus(currentTenant?.status)}
        />
      </div>
      {!isValid && FormStatus && (status === FormStatus.DIRTY) && <EditComponent.WriteErrors errors={errors}/>}
      <ContainerColumn style={{height: 'calc(100% - 3rem)', overflowY: 'auto'}}>
        <div className="entity-details-content full-width">
          <EditComponent.TenantEdit
            minWidth={minWidth}
            Tenant={Tenant}
            status={status}
            setTenant={setTenant}
            CustomProperties={CustomProperties}
            setCustomProperties={setCustomProperties}
            addCustomProperty={addCustomProperty}
            removeCustomProperty={removeCustomProperty}
            setAddNewCustomProperties={setAddNewCustomProperties}
            AddNewCustomProperties={AddNewCustomProperties}
          />
          {currentTenant?.id && (
            <>
              <TenantOrganisations {...tenantOrgAttr}/>
              <ListComponent.FeatureAddOns
                addOnFeatures={currentTenant?.addOnFeatures}
                setState={setTenant}
                isMulti="true"
                targetName="addOnFeatures"
                minWidth={minWidth}
              />
              <ViewComponent.PageItem
                name="SFS Data Center"
                minWidth={minWidth}
                value={WriteSFSDataCenter(currentTenant?.assignedOrganisation?.sfsDataCenter)}
              />
              <ViewComponent.PageItemDate
                name="Last Updated"
                minWidth={minWidth}
                value={currentTenant?.assignedOrganisation?.lastUpdated}
                format='datetime'
              />
            </>
          )}
        </div>
      </ContainerColumn>
      <ErrorDialog
        errorDialog={errorDialog}
        handleCloseDialog={handleCloseDialog}
        errorTitle={errorTitle}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default EditTenant;
