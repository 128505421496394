import React from 'react';
import * as Redux from 'react-redux';
import '../popoutPanel.css';
import { NavLink } from 'react-router-dom';
import { hideMenu } from 'store/Actions/Sidebar';
import { useOktaAuth } from '@okta/okta-react';
import { env } from '../../../../env';
import usePanelItems from './PanelItems';
import shortid from 'shortid';
import { hasAccessGroup } from 'okta/HasAccess';
import ApiVersionLookup from './ApiVersionLookup';
import WriteApiVersion from './WriteApiVersion';

const AboutPanel = (props: React.PropsWithChildren<any>): React.ReactElement => {
  const dispatch = Redux.useDispatch();
  const handleHideMenu = (): void => {
    dispatch(hideMenu());
  };
  const { ApiVersion } = Redux.useSelector((state: any) => state.SidebarReducer);
  const { authState } = useOktaAuth() || {};
  const isAuth = authState?.isAuthenticated;
  const { items } = usePanelItems();

  return (
    <div className={`secondary-nav-root ${props.isPanelOpen && 'opened'}`}>
      <div className="full-size menu-container" style={{ opacity: '1' }}>
        <div className="panel-root full-size">
          <div className="nav-panel-header bold">About {env.appName} </div>
          <div className="panel-content">
            <div className="sub-header bold">Version</div>
            <div className="item-row medium-size no-hover no-pointer">
              <div className="alignLeft">v{env.appVersion}</div>
              {isAuth && 
              !ApiVersion ? 
                <ApiVersionLookup />
                :
                <WriteApiVersion ApiVersion={ApiVersion} />
              }
            </div>
            {authState?.isAuthenticated &&
              items &&
              items.length > 0 &&
              items.map((item) => {
                return (
                  hasAccessGroup(item.accessOnly, authState) && (
                    <React.Fragment key={shortid.generate()}>
                      <NavLink to={item.link}>
                        <div className="item-row medium-size" onClick={handleHideMenu}>
                          <span className="trimmed">{item.name}</span>
                        </div>
                      </NavLink>
                    </React.Fragment>
                  )
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutPanel;
