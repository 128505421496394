import React, {ReactElement} from 'react';
import Package from '../../PackageManagement/View/Package/Package';
import Feature from '../../PackageManagement/View/Feature/Feature';
import License from '../../PackageManagement/View/License/License';
import Tenant from '../../SiteManagement/View/Tenant';
import IdsSite from '../../SiteManagement/View/IdsSite';
import Organisation from '../../ClientManagement/View/Organisation';
import Audit from '../../Auditing/View/Audit';
import PageNotFound from 'error/PageNotFound';
import Route from 'models/Routes';

const ViewPanel = (section: string): ReactElement => {
  const props = {} as any
  switch (section.toLowerCase()) {
  case Route.Packages:
    return <Package {...props} />;
  case Route.Features:
    return <Feature {...props} />;
  case Route.Licenses:
    return <License {...props} />;
  case Route.Sites:
    return <Tenant {...props} />;
  case Route.Clients:
    return <Organisation {...props} />;
  case Route.Audit:
    return <Audit {...props} />;
  case Route.IDS:
    return <IdsSite {...props} />;
  default:
    return <PageNotFound />;
  }
}

export default ViewPanel;
