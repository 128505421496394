import React from 'react';
import { ContainerRow } from 'styles/styledComponents/styledComponents';
import { ICustomProperty } from 'models/Interfaces';

interface ICustomProperties { 
  customProperties: Array<ICustomProperty> | undefined;
  nolastItemBorder?: boolean;
  minWidth?: string | undefined;  
}

const CustomProperties = (props : ICustomProperties) : React.ReactElement => {
  const { customProperties, nolastItemBorder, minWidth = '10rem' } = props;
  return (
    <>
      {customProperties &&
        customProperties.length > 0 &&
        customProperties.map((Property: ICustomProperty, index: number) => {
          return (
            <React.Fragment key={index}>
              <ContainerRow
                className={`item-container ${nolastItemBorder && 'no-last-border'}`}
              >
                <div className="item-name" style={{ minWidth: minWidth }}>
                  {Property.name}
                </div>
                <div className="values-container">
                  <div className="item-value">{Property.value}</div>
                </div>
              </ContainerRow>
            </React.Fragment>
          );
        })}
    </>
  );
};

export default CustomProperties;
