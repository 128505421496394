import { makeStyles } from '@material-ui/core/styles';
import { IFormStatus } from 'models/Interfaces';

export const minWidth = '9rem';

export const FormStatus = {
  UNTOUCHED: 'UNTOUCHED',
  DIRTY: 'DIRTY',
} as IFormStatus;

export const editFormStyles = makeStyles((theme) => ({
  grayedOut: {
    backgroundColor: '#efefef'
  },
  textField: {
    flex: 1,
    width: '100%',
    border: '0px #A9A9A9 solid',
    padding: '0.6rem 0.5rem',
    fontSize: '14px',
    fontFamily: theme.typography.fontFamily,
    '& .MuiInputBase-input': {
      padding: '0px',
      color: 'rgb(0, 0, 0)',
      fontSize: '14px',
      fontFamily: theme.typography.fontFamily,
    },
    '& .Mui-disabled': {
      color: 'hsl(0, 0%, 60%)',
    },
  },
  dateField: {
    flex: 1,
    border: '0px #A9A9A9 solid',
    color: 'rgb(0, 0, 0)',
    '& .MuiInputBase-root': {
      paddingLeft: '0.5rem',
      height: '100%',
      fontFamily: theme.typography.fontFamily,
      fontSize: '14px',
    },
    '& .MuiInputBase-input': {
      padding: '0px',
      color: 'rgb(0, 0, 0)',
      fontSize: '14px',
    },
  },
  numberField: {
    '& .MuiInputBase-input': {
      padding: '0px',
      color: 'rgb(0, 0, 0)',
      fontSize: '14px',
      fontFamily: theme.typography.fontFamily,
    },
  },
  selectField: {
    flex: 1,
    border: '0px #A9A9A9 solid',
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    '& .error': {
      border: '2px red solid ! important',
    },
  },
  error: {
    border: '2px red solid ! important',
  },
}));
