import React, {ReactElement} from 'react';
import * as Redux from 'react-redux';
import { hidePreviewPanel, extendPreviewPanel } from 'store/Actions/PreviewPanel';
import PanelHeader from '../PanelHeader/PanelHeader';
import ViewPanel from './ViewPanels';
import EditPanel from './EditPanels';
import AddPanel from './AddPanels';
import { ContainerColumn } from 'styles/styledComponents/styledComponents';
import IconButton from '@material-ui/core/IconButton';
import './previewPanel.css';
import Route from 'models/Routes';
import { Mode } from 'models/types';
import {IPreviewPanel, IPreviewPanelState} from "../../../models/Interfaces";

function getPanel(section: string, type: string): ReactElement|null {
  switch (type) {
  case Mode.View:
    return ViewPanel(section);
  case Mode.Add:
    return AddPanel(section);
  case Mode.Edit:
    return EditPanel(section);
  default:
    return null;
  }
}

function getTitle(section: string): string|null {
  switch (section) {
  case Route.Packages:
    return 'Packages';
  case Route.Features:
    return 'Features';
  case Route.Licenses:
    return 'Licenses';
  case Route.Sites:
    return 'Sites';
  case Route.Clients:
    return 'Organisations';
  case 'products':
    return 'Products';
  case Route.Audit:
    return 'Audit';
  default:
    return null;
  }
}

const PreviewPanel = (): ReactElement =>{
  const { section, type, isPreviewPanelOpen, isPreviewPanelExtended } = Redux.useSelector<IPreviewPanelState>(
    (state) => state.PreviewPanelReducer,
  ) as IPreviewPanel;
  const dispatch = Redux.useDispatch();
  return (
    <>
      <ContainerColumn className="preview-panel">
        <div className="search-title-nav">
          <PanelHeader name={getTitle(section)} />
        </div>
        <div
          style={{
            display: isPreviewPanelOpen ? 'inline-block' : 'none',
            right: '0px',
            top: '4px',
            position: 'absolute',
          }}
        >
          <IconButton
            color="inherit"
            component="span"
            onClick={(): void => {
              dispatch(extendPreviewPanel(isPreviewPanelExtended));
            }}
          >
            {isPreviewPanelExtended ? (
              <i className="i-bpow-chevron-right" style={{ fontSize: '1rem', color: '#333' }} />
            ) : (
              <i className="i-bpow-chevron-left" style={{ fontSize: '1rem', color: '#333' }} />
            )}
          </IconButton>
          <IconButton
            color="inherit"
            component="span"
            onClick={(): void => {
              dispatch(hidePreviewPanel());
            }}
          >
            <i className="i-bpow-cross" style={{ fontSize: '1rem', color: '#333' }} />
          </IconButton>
        </div>
        {getPanel(section, type)}
      </ContainerColumn>
    </>
  );
}

export default PreviewPanel;
