import React, {PropsWithChildren} from 'react';
import * as Redux from 'react-redux';
import { useFetch } from 'hooks';
import TenantView from 'views/Common/ViewForm/TenantView';
import {Props} from "models/types";
import {IPreviewPanelState} from "models/Interfaces";
import {IPreviewPanel} from "models/Interfaces";
import { VerbType } from 'models/Routes';

const ViewTenant : React.FC<Props>= (props: PropsWithChildren<any>) => {
  const {
    tenantId,
    nolastItemBorder,
    summaryOnly,
    setSummary,
    minWidth = '11rem',
  } = props;
  const { refreshPanel } = Redux.useSelector<IPreviewPanelState>((state) => state.PreviewPanelReducer) as IPreviewPanel;
  const { data } = useFetch(VerbType.GET, tenantId ? `Tenants/${tenantId}` : '', refreshPanel);
  const [Tenant, setTenant] = React.useState();

  React.useEffect(() => {
    setTenant(data);
    data && data.url && setSummary(`Site - ${data.url}`);
  }, [data, refreshPanel, setSummary]);

  return (
    <>
      <TenantView
        Tenant={Tenant}
        minWidth={minWidth}
        nolastItemBorder={nolastItemBorder}
        summaryOnly={summaryOnly}
      />
    </>
  );
};

export default ViewTenant;
