import React, {PropsWithChildren} from 'react';
import Select from '../Select';
import { IReactSelect } from 'models/Lists/Common';
import EntitesVersion from 'models/Lists/EntitesVersion';

interface IEntitesVersion { 
  entitiesVersion?: string;
  setState: React.Dispatch<React.SetStateAction<unknown>>;
  targetName: string;
  required?: boolean;
  disabled?: boolean;
  hasError?: boolean;
}

const EntitesVersions = (props: PropsWithChildren<any>): React.ReactElement => {
  const { entitiesVersion, setState, targetName, required, disabled, hasError } = props as IEntitesVersion
  
  const handleChange = ((e: IReactSelect): void => {
    setState((state: string[]) => ({
      ...state,
      [targetName]: e?.value || '',
    }));
  });
  
  const options = [
    { value: EntitesVersion.UK, label: 'UK' },
    { value: EntitesVersion.AUS, label: 'AUS' },
    { value: EntitesVersion.USA, label: 'USA' },
  ]
  
  return (
    <Select
      value={entitiesVersion}
      name={targetName}
      handleChange={handleChange}
      options={options}
      required={required}
      disabled={disabled}
      hasError={hasError}
    />
  )
}

export default EntitesVersions;
