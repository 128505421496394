import { REFRESH_RESULTS, SET_PATH } from '../ActionTypes/SearchResults';

export function refreshSearchResults(reloadSearch, startTab) {
  const payload = {
    reloadSearch,
    startTab,
  };
  return { type: REFRESH_RESULTS, payload };
}

export function setPath(path, pathName) {
  const payload = {
    path,
    pathName,
  };
  return { type: SET_PATH, payload };
}
