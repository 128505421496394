import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  search: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    color: '#333',
    '& .MuiFormControl-root': {
      border: '1px solid #ababab',
      minWidth: '350px',
      paddingLeft: '10px',
    },
    '& .MuiTableCell-head': {
      fontSize: '14px',
    },
    '& .MuiInputBase-input': {
      color: 'rgb(0, 0, 0)',
      fontSize: '14px',
      fontFamily: theme.typography.fontFamily,
    },
    '& .MuiTablePagination-caption': {
      color: 'rgb(0, 0, 0)',
      fontSize: '14px',
      fontFamily: theme.typography.fontFamily,
    },
    '& .MuiTableRow-head > th': {
      backgroundColor: '#e6e6e6',
      border: '1px solid #ababab',
      fontWeight: '600',
      fontSize: '14px',
      padding: '0 0.5rem',
      height: '2rem',
    },
    '& .MuiTableRow-head > th:first-child': {
      borderLeft: 'none !important',
    },
    '& .MuiTableRow-root:nth-child(odd)': {
      backgroundColor: '#fff',
    },
    '& .MuiTableRow-root:nth-child(even)': {
      backgroundColor: '#f2f2f2',
    },
    '& .MuiTableRow-root > td:first-child': {
      borderLeft: 'none !important',
    },
    '& .MuiTableBody-root tr:hover': {
      backgroundColor: '#e6e6e6',
    },
    '& .MuiInput-underline:after': {
      display: 'none !important',
    },
    '& .MuiInput-underline:before': {
      display: 'none !important',
    },
    '& .MuiTablePagination-toolbar': {
      minHeight: '62px',
    },
    '& .MuiTableRow-root > td:not(.PaginationContainer)': {
      border: '1px solid #ababab',
    },
    '& .MuiTableFooter-root': {
      background: '#fff',
      position: 'fixed',
      bottom: '18px',
      height: '4rem',
      width: 'calc(100% - 5rem - 1px)',
      borderTop: '1px solid #ababab',
      borderRight: '1px solid #ababab',
      zIndex: '1',
    },
  },
  searchExpanded50: {
    '& .MuiTableFooter-root': {
      background: '#fff',
      position: 'fixed',
      bottom: '18px',
      height: '4rem',
      width: 'calc(50% - 1px) !important',
      transition: 'all 0.5s ease-in-out',
      borderTop: '1px solid #ababab',
      borderRight: '1px solid #ababab',
      zIndex: '1',
    },
  },
  searchExpanded75: {
    '& .MuiTableFooter-root': {
      background: '#fff',
      position: 'fixed',
      bottom: '18px',
      height: '4rem',
      width: 'calc(28% - 1px) !important',
      transition: 'all 0.5s ease-in-out',
      borderTop: '1px solid #ababab',
      borderRight: '1px solid #ababab',
      zIndex: '1',
    },
  },
  toolbar: {
    '& .MTableToolbar-actions': {
      minWidth: '180px',
    },
  },
}));
