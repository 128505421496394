import React, {PropsWithChildren} from 'react';
import PageItem from './PageItem';
import useSWR from "../../../hooks/useSWR";

interface ISiteView {
  id?: string | undefined;
  minWidth?: string | undefined;
  targetName?: string | undefined;
}

interface ISwrResponse {
  error: boolean,
  loading: any,
  data?: {
    description?: string
  }
}

const DataCenter = (props: ISiteView): React.ReactElement => {
  const {id, minWidth} = props;
  const {data} = useSWR(`DataCenters/${id}`) as ISwrResponse;
  const itemAttr = {
    name: "Data Center",
    value: data?.description,
    minWidth
  } as PropsWithChildren<any>

  return (
    <PageItem {...itemAttr}/>
  );
};

export default DataCenter;
