import { env } from '../env';

const CLIENT_ID = env.okta_clientId,
  OKTA_URL = env.okta_url,
  REDIRECT_URI = `${window.location.origin}/login/callback`,
  oktaAuthConfig = {
    /*
     * Note: If your app is configured to use the Implicit Flow
     * Instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
     * You will need to add `pkce: false`
     */
    issuer: `${OKTA_URL}/oauth2/default`,
    clientId: CLIENT_ID,
    redirectUri: REDIRECT_URI,
  },
  oktaSignInConfig = {
    baseUrl: OKTA_URL,
    clientId: CLIENT_ID,
    redirectUri: REDIRECT_URI,
    logo: 'logo.png',
    authParams: {
      /*
       * If your app is configured to use the Implicit Flow
       * instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
       * you will need to uncomment the below line
       * pkce: false
       */
    },
  };

export { oktaAuthConfig, oktaSignInConfig };
