import React from 'react';
import PanelHeader from './Common/PanelHeader/PanelHeader';
import * as Component from 'components/ComponentsIndex';
import DataNotFound from 'error/DataNotFound';
import { ContainerColumnPanelView, ContainerRow } from 'styles/styledComponents/styledComponents';
import { useFetchSimple } from 'hooks';
import * as ViewComponent from './Common/ViewForm/ViewIndex';
import shortid from 'shortid';
import { VerbType } from 'models/Routes';

const CheckHealth = () => {
  const minWidth = '10rem';
  const { data, error, loading } = useFetchSimple(VerbType.GET, 'check/health'),
    [webApiHealthCheck, setWebApiHealthCheck] = React.useState();

  React.useEffect(() => {
    setWebApiHealthCheck(data);
  }, [data]);

  if (loading) return <Component.Loading />;
  if (!webApiHealthCheck || error) return <DataNotFound />;

  const entryKeys = Object.keys(webApiHealthCheck.entries);
  const entries = entryKeys.map(key => [key, webApiHealthCheck.entries[key]]);

  return (
    <>
      <PanelHeader name="Check Health" />
      <ContainerColumnPanelView style={{ height: 'calc(100% - 3rem)', overflowY: 'auto' }}>
        <div className="entity-details-content full-width" />
        <ContainerRow className="item-container no-last-border">
          <div className="entity-details-content full-width">
            <ViewComponent.PageItemBreak />
            <div className="item-title bottom-border">Web-Api</div>
            <ViewComponent.PageItem
              name="Status"
              value={`${webApiHealthCheck.status}`}
              minWidth={minWidth}
            />
            <ViewComponent.PageItem
              name="Response Time"
              value={`${webApiHealthCheck.totalDuration}`}
              minWidth={minWidth}
            />
            <ViewComponent.PageItemBreak />
            {entries.map(entry => (
              <React.Fragment key={shortid.generate()}>
                <div className="item-title bottom-border">{`${entry[0]}`}</div>
                <ViewComponent.PageItem
                  name="Status"
                  value={!entry[1].description? entry[1].status : entry[1].status + " - " + entry[1].description}
                  key={shortid.generate()}
                  minWidth={minWidth}
                />
                <ViewComponent.PageItem
                  name="Response Time"
                  value={entry[1].duration}
                  minWidth={minWidth}
                />
              </React.Fragment>
            ))}
          </div>
        </ContainerRow>
      </ContainerColumnPanelView>
    </>
  );
};

export default CheckHealth;
