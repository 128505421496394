import styled from 'styled-components';

export const AppContainer = styled.div`
  place-content: stretch space-between;
  align-items: stretch;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  max-height: 100%;
`;

export const Container = styled.div`
  fxlayoutalign: start center;
  place-content: center flex-start;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
`;

export const ContainerCenterSpace = styled.div`
  fxlayoutalign: space-between center;
  place-content: center space-between;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
`;

export const ContainerCenter = styled.div`
  fxlayoutalign: center center;
  place-content: center;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
`;

export const ContainerRow = styled.div`
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: stretch flex-start;
  align-items: stretch;
`;

export const ContainerRowRightAligned = styled.div`
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: flex-end;
  align-items: stretch;
`;

export const SidebarContainer = styled.div`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  border-right: 1px solid rgba(0, 0, 0, 0.33);
`;

export const ContainerColumn = styled.div`
  fxlayout: column;
  fxlayoutalign: space-between stretch;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: stretch space-between;
  align-items: stretch;
  max-width: 100%;
`;

export const ContainerColumnPanelView = styled.div`
  fxlayout: column;
  fxlayoutalign: space-between stretch;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: stretch flex-start;
  align-items: stretch;
  max-width: 100%;
`;

export const SidebarItem = styled.div`
  &:hover {
    cursor: pointer;
    transition: all 400ms ease-in-out;
  }
`;
