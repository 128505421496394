import React from 'react';
import * as ViewComponent from './ViewIndex';
import { IOrganisation } from 'models/Interfaces';

interface IOrganisationView { 
  minWidth?: string | undefined;
  Organisation?: IOrganisation;
  summaryOnly?: boolean;
  nolastItemBorder?: boolean;
}

const OrganisationView = (props : IOrganisationView) : React.ReactElement => {
  const { minWidth, Organisation, summaryOnly, nolastItemBorder } = props;
  return (
    <>
      {!summaryOnly && (
        <>
          <ViewComponent.PageItem
            name="Client Name"
            value={Organisation?.name}
            minWidth={minWidth}
            copyToClipboard={true}
          />
        </>
      )}
      <ViewComponent.PageItem
        name="Organisation Id"
        value={Organisation?.salesforceOrgId}
        minWidth={minWidth}
        copyToClipboard={true}
      />
      <ViewComponent.PageItem
        name="Sub-Region"
        value={Organisation?.subregion}
        minWidth={minWidth}
      />
      <ViewComponent.PageItem
        name="Contract Status"
        value={Organisation?.contractStatus}
        minWidth={minWidth}
      />
      <ViewComponent.PageItem
        name="Contract Number"
        value={Organisation?.contractNumber}
        minWidth={minWidth}
        copyToClipboard={true}
      />
      <ViewComponent.PageItemDate
        name="Contract Start"
        value={Organisation?.contractStart}
        minWidth={minWidth}
        copyToClipboard={true}
      />
      <ViewComponent.PageItemDate
        name="Contract End"
        value={Organisation?.contractEnd}
        minWidth={minWidth}
        copyToClipboard={true}
      />
      <ViewComponent.PageItem
        name="Account Manager"
        value={Organisation?.accountManager}
        minWidth={minWidth}
        copyToClipboard={true}
      />
      <ViewComponent.PageItem
        name="CSM"
        value={Organisation?.csmManager}
        minWidth={minWidth}
        copyToClipboard={true}
      />
      <ViewComponent.PageItem
        name="CSM Team"
        value={Organisation?.csmTeam}
        minWidth={minWidth}
      />
      <ViewComponent.PageItemCheckbox
        name="Self-Hosted"
        value={Organisation?.isSelfHosted}
        minWidth={minWidth}
      />
      <ViewComponent.PageItemCheckbox
        name="Enterprise Site"
        value={Organisation?.hasEnterpriseSite}
        minWidth={minWidth}
        nolastItemBorder={nolastItemBorder}
      />
    </>
  );
};

export default OrganisationView;
