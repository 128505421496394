import React, {ReactElement} from 'react';
import Package from '../../PackageManagement/Edit/Package';
import Feature from '../../PackageManagement/Edit/Feature';
import License from '../../PackageManagement/Edit/License';
import Tenant from '../../SiteManagement/Edit/Tenant';
import IdsSite from '../../SiteManagement/Edit/IdsSite';
import Organisation from '../../ClientManagement/Edit/Organisation';
import PageNotFound from 'error/PageNotFound';
import Route from 'models/Routes';

const EditPanel = (section: string): ReactElement => {
  const props = {} as any
  switch (section.toLowerCase()) {
  case Route.Packages:
    return <Package  {...props}/>;
  case Route.Features:
    return <Feature  {...props}/>;
  case Route.Licenses:
    return <License  {...props}/>;
  case Route.Sites:
    return <Tenant  {...props}/>;
  case Route.IDS:
    return <IdsSite  {...props}/>;
  case Route.Clients:
    return <Organisation  {...props}/>;
  default:
    return <PageNotFound />;
  }
}

export default EditPanel;