import React, {PropsWithChildren} from 'react';
import Select from '../Select';
import { IReactSelect } from 'models/Lists/Common';
import TenantPurpose from 'models/Lists/TenantPurpose';

interface ITenantPurpose { 
  purpose?: string;
  setState: React.Dispatch<React.SetStateAction<unknown>>;
  targetName: string;
  required?: boolean;
  disabled?: boolean;
  hasError?: boolean;
}

const TenantPurposes = (props: PropsWithChildren<any>): React.ReactElement => {
  const { purpose, setState, targetName, required, disabled, hasError } = props as ITenantPurpose;

  const handleChange = ((e: IReactSelect): void => {
    setState((state: string[]) => ({
      ...state,
      [targetName]: e?.value ||  '',
    }));
  });

  const options = [
    { value:  TenantPurpose.Production, label: 'Production' },
    { value:  TenantPurpose.IDN, label: 'IDN' },
    { value:  TenantPurpose.Test, label: 'Test' },
    { value:  TenantPurpose.Eval, label: 'Eval' },
  ];

  return (
    <Select
      value={purpose}
      name={targetName}
      handleChange={handleChange}
      options={options}
      required={required}
      disabled={disabled}
      hasError={hasError}
    />
  );
};

export default TenantPurposes;
