import React from 'react';
import { Container } from 'styles/styledComponents/styledComponents';
import '../sidebar.css';
import './sidebarText.css';

interface ISidebarText { text: string, extraClass?: string, onClick?: () => void }

const SidebarText = (props: ISidebarText) : React.ReactElement => {
  const { text, extraClass, onClick } = props;
  return (
    <div className={`sliding-part-container long-transition ${extraClass}`} onClick={onClick}>
      <span className={`content-container status-marker long-transition ${extraClass}`}>
        <Container className={`text-inner-container full-height long-transition ${extraClass}`}>
          <span className="trimmed">{text}</span>
        </Container>
      </span>
    </div>
  );
};
export default SidebarText;
