import { useCallback, useState } from 'react';

const initState = {
  page: 0,
  pageSize: 50,
  searchterm: ''
};

const useSearchParams = () => {
  const [SearchParams, setSearchParams] = useState(initState);
  const handleDebounceUpdate = (name, value) => {
    debounceHandler(name, value);
  };
  const updateSearchParams = (name, value) => {
    setSearchParams((state) => {
      return { ...state, [name]: value };
    });
  };
  const debounceHandler = useCallback(updateSearchParams, []);
  return { handleDebounceUpdate, updateSearchParams, SearchParams };
};

export default useSearchParams;
