import {
  SHOW_PREVIEWPANEL,
  HIDE_PREVIEWPANEL,
  SETUP_PREVIEWPANEL,
  EXTEND_PREVIEWPANEL,
} from '../ActionTypes/PreviewPanel';

const initialState = {
  isPreviewPanelOpen: false,
  isPreviewPanelExtended: false,
  title: '',
  section: '',
  type: '',
  id: 0,
  refreshPanel: false,
};

function PreviewPanelReducer(state = initialState, action) {
  switch (action.type) {
  case SHOW_PREVIEWPANEL:
    return {
      ...state,
      isPreviewPanelOpen: action.payload.isPreviewPanelOpen,
      title: action.payload.title,
      isPreviewPanelExtended: action.payload.isPreviewPanelExtended,
    };
  case EXTEND_PREVIEWPANEL:
    return {
      ...state,
      isPreviewPanelExtended: action.payload.isPreviewPanelExtended,
    };
  case HIDE_PREVIEWPANEL: {
    return {
      ...state,
      isPreviewPanelOpen: action.payload.isPreviewPanelOpen,
      title: action.payload.title,
      section: '',
      type: '',
      isPreviewPanelExtended: false,
    };
  }
  case SETUP_PREVIEWPANEL: {
    return {
      ...state,
      section: action.payload.section,
      type: action.payload.type,
      id: action.payload.id,
      refreshPanel: action.payload.refreshPanel,
      isPreviewPanelExtended: action.payload.isPreviewPanelExtended,
    };
  }
  default:
    return state;
  }
}

export default PreviewPanelReducer;
