import React from 'react';
import * as ViewComponent from './ViewIndex';
import { IFeature } from 'models/Interfaces';

interface IFeatureView { 
  minWidth?: string | undefined;
  Feature?: IFeature;
  nolastItemBorder?: boolean;
  summaryOnly?: boolean;
}

const FeatureView = (props : IFeatureView) : React.ReactElement => { 
  const { minWidth, Feature, nolastItemBorder, summaryOnly } = props;
  return (
    <>
      <ViewComponent.PageItem name="Feature Code" value={Feature?.code} minWidth={minWidth} />
      <ViewComponent.PageItem name="Feature Name" value={Feature?.description} minWidth={minWidth} />
      {!summaryOnly && (
        <>
          <ViewComponent.PageItemDate
            name="Start Date"
            value={Feature?.availableFrom}
            minWidth={minWidth}
          />
          <ViewComponent.PageItemDate
            name="Retired"
            value={Feature?.availableUntil}
            minWidth={minWidth}
          />
        </>
      )}
      <ViewComponent.PackagesForFeature
        featureCode={Feature?.code}
        nolastItemBorder={nolastItemBorder}
      />
    </>
  );
};

export default FeatureView;
