import React from 'react';
import { useHistory } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton';
import Tooltip from 'components/Tooltip/Tooltip';
import Icon from '../Icon/Icon';

const HomeIcon = (): React.ReactElement => {
  const history = useHistory();
  const handleClick = (): void => {
    history.push("/");
  };
  return (
    <Tooltip title='Home Page'>
      <IconButton onClick={handleClick}>
        <Icon className="i-bpow-home" />
      </IconButton>  
    </Tooltip>
  );
}

export default HomeIcon;
