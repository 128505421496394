import React from 'react';
import { ContainerRow } from 'styles/styledComponents/styledComponents';
import * as EditComponent from 'views/Common/EditForm/EditIndex';
import * as ListComponent from 'views/Common/EditForm/Lists/ListsIndex';
import * as FieldLengths from '../Constants/FieldLengths';

const PackageEdit = (props: React.PropsWithChildren<any>) : React.ReactElement => {
  const {
    id,
    minWidth,
    Package,
    status,
    setPackage,
    selectedFeatures,
    setSelectedFeatures,
    initialFeatures,
  } = props;

  return (
    <>
      <ContainerRow className="item-container">
        <div className="item-name fieldRequired" style={{ minWidth: minWidth }}>
          Package Code
        </div>
        <EditComponent.TextField
          disabled={!(id <= 0 || !id)}
          required={id <= 0 || !id}
          status={status}
          value={Package?.code || ''}
          setState={setPackage}
          upperCase
          targetName="code"
          maxLength={parseInt(FieldLengths.Package_Code)}
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name fieldRequired" style={{ minWidth: minWidth }}>
          Package Name
        </div>
        <EditComponent.TextField
          required={true}
          status={status}
          value={Package?.name || ''}
          setState={setPackage}
          targetName="name"
          maxLength={parseInt(FieldLengths.Package_Name)}
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name fieldRequired" style={{ minWidth: minWidth }}>
          Package Description
        </div>
        <EditComponent.TextField
          required={true}
          status={status}
          value={Package?.description || ''}
          setState={setPackage}
          targetName="description"
          maxLength={parseInt(FieldLengths.Package_Description)}
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name fieldRequired" style={{ minWidth: minWidth }}>
          Start Date
        </div>
        <EditComponent.DatePicker
          required={true}
          status={status}
          value={Package?.availableFrom}
          setState={setPackage}
          targetName="availableFrom"
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Discontinued
        </div>
        <EditComponent.DatePicker
          value={Package?.availableUntil}
          setState={setPackage}
          targetName="availableUntil"
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Profile
        </div>
        <EditComponent.TextArea
          value={Package?.profile || ''}
          setState={setPackage}
          targetName="profile"
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Features
        </div>
        <ListComponent.FeaturesForPackage
          packageCode={Package?.code}
          state={selectedFeatures}
          setState={setSelectedFeatures}
          initialFeatures={initialFeatures}
          targetName="featuresForPackage"
          isMulti="true"
        />
      </ContainerRow>
    </>
  );
};

export default PackageEdit;
