import React, {PropsWithChildren, ReactElement} from 'react';
import Select from '../Select';
import { useFetch } from 'hooks';
import { VerbType } from 'models/Routes';
import {IPackage} from "models/Interfaces";

const PackagesForFeature = (props: PropsWithChildren<any>): ReactElement => {
  const { targetName, isMulti, setState, initialPackages, required, hasError } = props as any;
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  function useOptions(url: string): any {
    const { data: PackagesForFeature } = useFetch(VerbType.GET, url);
    return PackagesForFeature;
  }

  const packages = useOptions('Packages?packageSelector=Current');
  const packagesOptions =
    packages &&
    packages.length > 0 &&
    packages.map((Package: IPackage) => ({
      label: Package.name,
      value: Package.code,
    }));

  React.useEffect(() => {
    setState(Array.isArray(selectedOptions) ? selectedOptions.map((x: any) => x.value) : []);
  }, [selectedOptions, setState]);

  React.useEffect(() => {
    setState(initialPackages);
    setSelectedOptions(initialPackages);
  }, [initialPackages, setState]);

  // handle onChange event of the dropdown
  const updateMultiArray = (e: any): void => {
    setState(Array.isArray(e) ? e.map((x) => x) : []);
    const optionList = Array.isArray(e) ? e.map((x) => x) : []
    setSelectedOptions(optionList as any);
  };

  return (
    <>
      <Select
        name={targetName}
        selected={selectedOptions}
        handleChange={updateMultiArray}
        options={packagesOptions}
        isMulti={isMulti}
        hasError={hasError}
        required={required}
        {...{ setState }}
      />
    </>
  );
};

export default PackagesForFeature;
