import React, {ReactElement} from 'react';
import * as Redux from 'react-redux';
import PageHeaderIcons from 'views/Common/PageHeaderTabs/PageHeaderTabs';
import SearchResults from 'views/Common/SearchResults/SearchResults';
import useHeaderTabs from './HeaderTabsSetup';
import {useOktaAuth} from '@okta/okta-react';
import NotAuthorised from 'error/NotAuthorised';
import {ADMIN, CSM, hasAccessGroup, IMPLEMENTATION, PRODOPS, SALES, SUPPORT} from 'okta/HasAccess';
import {useSearchParams} from 'hooks';
import * as ListComponent from 'views/Common/EditForm/Lists/ListsIndex';
import {ISearchResultReducer, ISearchResultReducerState} from "models/Interfaces";
import {IAuditCategories} from "models/Interfaces";
import {Props} from "models/types";

const GetResults = (props: any): ReactElement => {
  const {handleDebounceUpdate, updateSearchParams, SearchParams} = useSearchParams();
  const {tabsDetails, filterOptions, pathName, bIncludeSystem, category} = props
  let selector = `includeSystem=${bIncludeSystem}`;
  if (category) selector += `&category=${category}`;

  return (
    <SearchResults
      keyField="id"
      url={tabsDetails.url}
      hideAddbutton={true}
      filterComponent={filterOptions}
      selector={selector}
      searchTitle={tabsDetails.searchTitle}
      disableRowClick={true}
      tabName={tabsDetails.text}
      pathName={pathName}
      handleDebounceUpdate={handleDebounceUpdate}
      updateSearchParams={updateSearchParams}
      SearchParams={SearchParams}
      paginatedResultsOnly={tabsDetails.paginatedResultsOnly}
      defaultOrderBy='DESC'
    />
  );
}

const Auditing: React.FC<Props> = () => {
  const {tabs} = useHeaderTabs();
  const [tabsDetails, setTabDetails] = React.useState(tabs[0]);
  const {pathName} = Redux.useSelector<ISearchResultReducer>((state) => state.SearchResultsReducer) as ISearchResultReducerState;
  const GetDataForView = (tab: any): void => {
    setTabDetails(tab)
  };
  const [IncludeSystem, setIncludeSystem] = React.useState(false);
  const [Filter, setFilter] = React.useState<any>();
  const filterStyle = {
    width: '400px',
    height: '35px',
    float: 'right',
    position: 'relative',
    zIndex: '1',
    display: 'flex',
    alignItems: 'center',
    marginTop: '15px',
  } as any
  const catAttr = {setState: setFilter, selected: Filter?.category, targetName: "category"} as IAuditCategories

  const filterOptions: React.FC<Props> = () => {
    return (
      <div style={filterStyle}>
        <ListComponent.AuditCategories {...catAttr} />
        <div style={{paddingRight: '10px', fontWeight: 600}}>Include System Events</div>
        <input
          type="checkbox"
          checked={IncludeSystem}
          name='includeSystem'
          className="checkbox"
          onChange={(e): void => {
            setIncludeSystem(e.target.checked)
          }}
        />
      </div>
    );
  };

  const {authState} = useOktaAuth() || {};
  const headAttr = {tabs, GetDataForView}
  const hasAccess = hasAccessGroup([ADMIN, CSM, IMPLEMENTATION, PRODOPS, SALES, SUPPORT], authState);
  if (!hasAccess) return <NotAuthorised/>;

  const resultAttr = {tabsDetails, filterOptions, pathName, bIncludeSystem: IncludeSystem, category: Filter?.category}
  return (
    <>
      <PageHeaderIcons {...headAttr} />
      <GetResults {...resultAttr} />
    </>
  );
}

export default Auditing;
