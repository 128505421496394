import React, {PropsWithChildren} from 'react';
import * as Redux from 'react-redux';
import {hidePreviewPanel, setupPreviewPanel} from 'store/Actions/PreviewPanel';
import {refreshSearchResults} from 'store/Actions/SearchResults';
import PanelSubHeader from 'views/Common/PanelSubHeader/PanelSubHeader';
import {ContainerColumn} from 'styles/styledComponents/styledComponents';
import {FormStatus} from 'views/Common/EditForm/Common';
import * as EditComponent from 'views/Common/EditForm/EditIndex';
import {ErrorDialog, handleError, useErrorState} from 'error/HandleErrors';
import informationIcons from 'lib/InformationIcons';
import {ApiHelperContext} from 'context/ApiHelperContext';
import Route from 'models/Routes';
import {Props} from "models/types";
import {IError, IOrganisation, IOrganisationEdit, ISearchResultReducerState} from "models/Interfaces";
import {validateOrgNameAndStart} from "lib/validators/organisation";

const Organisation: React.FC<Props> = (props: PropsWithChildren<any>) => {
  const {minWidth = '10rem'} = props;
  const [Organisation, setOrganisation] = React.useState<IOrganisation>();
  const [status, setStatus] = React.useState(FormStatus.UNTOUCHED);
  const {reloadSearch} = Redux.useSelector<any>((state) => state.SearchResultsReducer) as ISearchResultReducerState;
  const {errorDialog, errorMessage, errorTitle, setErrorDialog, setErrorMessage, setErrorTitle} = useErrorState();
  const dispatch = Redux.useDispatch();
  const errors = validateOrgNameAndStart(Organisation as IOrganisation);
  const isValid = Object.keys(errors).length === 0;
  const context = React.useContext(ApiHelperContext)
  const apiHelper = context?.state.apiHelper

  function handleSave(): void {
    if (isValid && Organisation && apiHelper) {
      apiHelper.post('Organisations/add', Organisation, handleSaveCallBack, callbackErrorFunc);
    }
    setStatus(FormStatus.DIRTY);
  }

  function handleSaveCallBack(data: IError): void {
    if (data && data.status >= 400) {
      callbackErrorFunc(data);
    } else {
      dispatch(setupPreviewPanel(Route.Clients, 'view', data && data.id));
      dispatch(refreshSearchResults(!reloadSearch));
    }
  }

  function callbackErrorFunc(error: IError): void {
    handleError(error, setErrorTitle, setErrorMessage, setErrorDialog);
  }

  const handleCloseDialog = (): void => {
    setErrorDialog(false);
  };

  function handleCancel(): void {
    dispatch(hidePreviewPanel());
  }

  const orgEditAttributes = {
    minWidth,
    Organisation,
    status,
    setOrganisation,
  } as IOrganisationEdit

  return (
    <>
      <div className="search-title-nav">
        <PanelSubHeader
          handleCancel={handleCancel}
          handleSave={handleSave}
          description='Add Details'
          icons={informationIcons.WriteIcons('SL')}
        />
      </div>
      {!isValid && FormStatus && (status === FormStatus.DIRTY) && <EditComponent.WriteErrors errors={errors}/>}
      <ContainerColumn style={{height: 'calc(100% - 3rem)', overflowY: 'auto'}}>
        <div className="entity-details-content full-width">
          <EditComponent.OrganisationEdit {...orgEditAttributes} />
        </div>
      </ContainerColumn>
      <ErrorDialog
        errorDialog={errorDialog}
        handleCloseDialog={handleCloseDialog}
        errorTitle={errorTitle}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default Organisation;
