import React from 'react';
import * as Component from 'components/ComponentsIndex';

const WriteIcon = (props) => {
  return <div style={{ display: 'inline-block' }}>{props?.description}</div>;
};

const informationIcons = {
  WriteIcons: (ShowIcons) => {
    return (
      <Component.Tooltip
        title={
          <div style={{ whiteSpace: 'pre-line' }}>
            {ShowIcons.includes('G') && (
              <>
                <WriteIcon description="(GW) - Gateway data" />
                <br />
              </>
            )}
            {ShowIcons.includes('S') && (
              <>
                <WriteIcon description="(SF) - Salesforce data" />
                <br />
              </>
            )}
            {ShowIcons.includes('L') && (
              <>
                <WriteIcon description="(LS) - Live Sites data" />
              </>
            )}
          </div>
        }
      >
        <i className="i-bpow-info" style={{ fontSize: '1rem' }} />
      </Component.Tooltip>
    );
  },
};

export default informationIcons;
