import React, {ReactElement, useContext, useState} from 'react';
import * as Redux from 'react-redux';
import PageHeaderTabs from 'views/Common/PageHeaderTabs/PageHeaderTabs';
import SearchResults from 'views/Common/SearchResults/SearchResults';
import useHeaderTabs from './HeaderTabsSetup';
import { useOktaAuth } from '@okta/okta-react';
import { SALES, hasAccess } from 'okta/HasAccess';
import { useSearchParams } from 'hooks';
import {Props, TabType} from "models/types";
import {IGetResultProps, ISelectedSiteContext} from "models/Interfaces";
import {ISearchResultReducer, ISearchResultReducerState} from "models/Interfaces";
import {SiteSelectedContext} from "context/SiteSelectedContext";

const GetResults = (props: IGetResultProps): ReactElement =>{
  const  {tabsDetails, pathName,  resetPage, setResetPage } = props
  const { handleDebounceUpdate, updateSearchParams, SearchParams } = useSearchParams();
  const context: ISelectedSiteContext | undefined  = useContext(SiteSelectedContext)
  const selectedClient = context?.state.selectedClient

  React.useEffect(() => {
    if(selectedClient) {
      updateSearchParams('searchterm', selectedClient?.organisationName);
    }
  }, [selectedClient, updateSearchParams]);

  return (
    <SearchResults
      keyField="id"
      resetPage={resetPage}
      setResetPage={setResetPage}
      url={tabsDetails.url}
      selector={tabsDetails.selector}
      filterStatus={tabsDetails.status}
      addAccessOnly={tabsDetails.addAccessOnly}
      searchTitle={tabsDetails.searchTitle}
      tabName={tabsDetails.text}
      pathName={pathName}
      handleDebounceUpdate={handleDebounceUpdate}
      updateSearchParams={updateSearchParams}
      SearchParams={SearchParams}
      paginatedResultsOnly={tabsDetails.paginatedResultsOnly}
    />
  );
}

const ClientManagement: React.FC<Props> = () => {
  const { startTab, pathName } = Redux.useSelector<ISearchResultReducer>((state) => state.SearchResultsReducer) as ISearchResultReducerState;
  const [resetPage, setResetPage] = useState(false);
  const { authState } = useOktaAuth() || {};
  const isSalesUser = hasAccess(SALES, authState);

  let defaultTab;
  if (!isSalesUser) defaultTab = 1;
  if (isSalesUser) defaultTab = 0;
  if (typeof startTab !== "undefined") defaultTab = startTab;
  
  const { tabs } = useHeaderTabs();
  const defaultValue: TabType = tabs[defaultTab as number]
  const [tabsDetails, setTabDetails] = React.useState(defaultValue);
  const GetDataForView = (tab: any): void => {
    setTabDetails(tab);
    setResetPage(true)
  };
  const parameters = {tabsDetails, pathName, resetPage, setResetPage} as IGetResultProps
  const attributes = {tabs, GetDataForView, startTab: defaultTab, resetPage}
  return (
    <>
      <PageHeaderTabs {...attributes} />
      <GetResults {...parameters} />
    </>
  );
}

export default ClientManagement;
