import React, {PropsWithChildren, ReactElement} from 'react';
import {useFetch} from 'hooks';
import LicenseEdit from './LicenseEdit';
import {VerbType} from 'models/Routes';

const LicenseById = (props: PropsWithChildren<any>): ReactElement => {
  const {
    licenseId,
    addOnLicense,
    state,
    setState,
    summaryOnly,
    fullBorder,
    indentTitle,
    handleChangeLicenseAddOn,
    minWidth,
    menuPlacement,
    status
  } = props;
  const {data} = useFetch(VerbType.GET, licenseId ? `Licenses/${licenseId}` : '');

  React.useEffect(() => {
    if (licenseId) {
      const companies = data && data.companies ? data.companies : 0;
      const people = data && data.people ? data.people : 0;
      const incorporations = data && data.incorporations ? data.incorporations : 0;
      const users = data && data.users ? data.users : 0;
      const adminUsers = data && data.adminUsers ? data.adminUsers : 0;
      const editUsers = data && data.adminUsers ? data.adminUsers : 0;
      const edition = data && data.edition;
      setState((state: any) => ({...state, companies}));
      setState((state: any) => ({...state, people}));
      setState((state: any) => ({...state, incorporations}));
      setState((state: any) => ({...state, users}));
      setState((state: any) => ({...state, maxUsers : users}));
      setState((state: any) => ({...state, adminUsers}));
      setState((state: any) => ({...state, editUsers}));
      setState((state: any) => ({...state, edition}));
    }
  }, [data, licenseId, setState]);

  React.useEffect(() => {
    if (addOnLicense) {
      const addOns = (addOnLicense === String) ? JSON.parse(addOnLicense) : addOnLicense;
      setState((state: any) => ({...state, companies: addOns.companies}));
      setState((state: any) => ({...state, people: addOns.people}));
      setState((state: any) => ({...state, incorporations: addOns.incorporations}));
      setState((state: any) => ({...state, users: addOns.users}));
      setState((state: any) => ({...state, maxUsers : addOns.users}));
      setState((state: any) => ({...state, adminUsers: addOns.adminUsers}));
      setState((state: any) => ({...state, editUsers: addOns.editUsers}));
      setState((state: any) => ({...state, addOnLicense: addOns.edition}));
      setState((state: any) => ({...state, addOnLicense: addOns}));
    }
  }, [addOnLicense, setState]);
  
  const editAttr = {id: state?.id, License: state, setLicense: setState, summaryOnly: summaryOnly} as any
  return (
    <LicenseEdit
      {...editAttr}
      fullBorder={fullBorder}
      indentTitle={indentTitle}
      handleChangeLicenseAddOn={handleChangeLicenseAddOn}
      minWidth={minWidth}
      menuPlacement={menuPlacement}
      status={status}
    />
  );
};

export default LicenseById;
