import React from 'react';

interface ISidebarMarker {
  marked: boolean
}

const SidebarMarker: React.FC<ISidebarMarker>  = (props: React.PropsWithChildren<any>)=> {
  return (
    <div className={`icon-status-marker long-transition ${props.marked && 'current'}`} />
  );
}
export default SidebarMarker;