import React, {PropsWithChildren} from 'react';
import { useFetch } from 'hooks';
import * as Component from 'components/ComponentsIndex';
import FeatureCodes from 'views/Common/ViewForm/FeatureCodes';
import DataNotFound from 'error/DataNotFound';
import {IPackage} from "models/Interfaces";
import {Props} from "models/types";
import { VerbType } from 'models/Routes';

const FeatureCodesById: React.FC<Props> = (props: PropsWithChildren<any>) => {
  const { packageId, id, nolastItemBorder } = props;
  const { data, error, loading } = useFetch(VerbType.GET, packageId ? `Packages/${packageId}` : '');
  const [Packages, setPackages] = React.useState<IPackage>();

  React.useEffect(() => {
    setPackages(data);
  }, [data, id]);

  if (loading) return <Component.Loading />;
  if (error) return <DataNotFound />;

  return (
    <>
      <FeatureCodes packageCode={Packages && Packages.code} nolastItemBorder={nolastItemBorder} />
    </>
  );
};

export default FeatureCodesById;
