import React, {PropsWithChildren} from 'react';
import * as Redux from 'react-redux';
import {hidePreviewPanel, setupPreviewPanel} from 'store/Actions/PreviewPanel';
import {refreshSearchResults} from 'store/Actions/SearchResults';
import PanelSubHeader from 'views/Common/PanelSubHeader/PanelSubHeader';
import {ContainerColumn} from 'styles/styledComponents/styledComponents';
import {FormStatus} from 'views/Common/EditForm/Common';
import * as EditComponent from 'views/Common/EditForm/EditIndex';
import {ErrorDialog, handleError, useErrorState} from 'error/HandleErrors';
import {ApiHelperContext} from 'context/ApiHelperContext';
import Route from 'models/Routes';
import notify, {MessageType, Message} from 'components/Notifications/toastify';
import { Props, Mode } from "models/types";
import {IAddPackageProps, IPreviewPanelState} from "models/Interfaces";
import {IApiHelperContext, IError, IFormStatus, IFeature, IPackage} from "models/Interfaces";
import {validatePackage} from "lib/validators/package";

const formStatus: IFormStatus = FormStatus
const AddPackage: React.FC<Props> = (props: PropsWithChildren<any>) => {
  const {minWidth = '10rem'} = props as IAddPackageProps;
  const {id} = Redux.useSelector<IPreviewPanelState>((state) => state.PreviewPanelReducer) as IPackage;
  const [Package, setPackage] = React.useState<IPackage>();
  const [status, setStatus] = React.useState(formStatus.INITIAL);
  const [selectedFeatures, setSelectedFeatures] = React.useState<IFeature[]>([]);
  const [initialFeatures, setInitialFeatures] = React.useState<IFeature[]>([]);
  const {errorDialog, errorMessage, errorTitle, setErrorDialog, setErrorMessage, setErrorTitle} = useErrorState();
  const dispatch = Redux.useDispatch();
  const errors: IPackage = validatePackage(Package as IPackage);
  const isValid = Object.keys(errors).length === 0;
  const context: IApiHelperContext | undefined = React.useContext(ApiHelperContext)
  const apiHelper = context?.state.apiHelper

  function handleSave(): void {
    if (isValid && Package && apiHelper) {
      apiHelper.post('Packages/add', Package, handleSaveCallBack, callbackErrorFunc);
    }
    setStatus(FormStatus.DIRTY);
  }

  function handleSaveCallBack(data: IError): void {
    if (data.status >= 400) {
      callbackErrorFunc(data);
    } else {
      addFeatures();
      notify(Message.PackageAdded, MessageType.Success);
    }
  }

  function callbackErrorFunc(error: IError): void {
    handleError(error, setErrorTitle, setErrorMessage, setErrorDialog);
  }

  const handleCloseDialog = (): void => {
    setErrorDialog(false);
  };

  function addFeatures(): void {
    if (isValid && Package) {
      if (selectedFeatures && selectedFeatures.length > 0) {
        selectedFeatures.map((Feature) => addFeatureToPackage(Feature.value));
      }

      if (selectedFeatures.length === 0) handleRefreshCallBack();
    }
  }

  const addFeatureToPackage = (value: any): void => {
    if (Package && apiHelper) {
      apiHelper.post(`Packages/${Package.code}/Features/${value}`, '', handleRefreshCallBack, callbackErrorFunc);
    }
  };

  function handleRefreshCallBack(): void {
    dispatch(setupPreviewPanel(Route.Packages, Mode.View, Package && Package.code));
    dispatch(refreshSearchResults(true));
  }

  function handleCancel(): void {
    dispatch(hidePreviewPanel());
  }

  const writeErrorProps = { errors } as PropsWithChildren<any>

  return (
    <>
      <div className="search-title-nav">
        <PanelSubHeader handleCancel={handleCancel} handleSave={handleSave} description='Add Details'/>
      </div>
      {!isValid && formStatus && (status === formStatus.DIRTY) && <EditComponent.WriteErrors {...writeErrorProps}/>}
      <ContainerColumn style={{height: 'calc(100% - 3rem)', overflowY: 'auto'}}>
        <div className="entity-details-content full-width">
          <EditComponent.PackageEdit
            id={id}
            minWidth={minWidth}
            Package={Package}
            status={status}
            setPackage={setPackage}
            selectedFeatures={selectedFeatures}
            setSelectedFeatures={setSelectedFeatures}
            setInitialFeatures={setInitialFeatures}
          />
        </div>
      </ContainerColumn>
      <ErrorDialog
        errorDialog={errorDialog}
        handleCloseDialog={handleCloseDialog}
        errorTitle={errorTitle}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default AddPackage;
