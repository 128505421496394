import React, {PropsWithChildren} from 'react';
import * as Redux from 'react-redux';
import { setupPreviewPanel, hidePreviewPanel } from 'store/Actions/PreviewPanel';
import { refreshSearchResults } from 'store/Actions/SearchResults';
import PanelSubHeader from 'views/Common/PanelSubHeader/PanelSubHeader';
import { ContainerColumn } from 'styles/styledComponents/styledComponents';
import { FormStatus } from 'views/Common/EditForm/Common';
import { ErrorDialog, handleError, useErrorState } from 'error/HandleErrors';
import * as EditComponent from 'views/Common/EditForm/EditIndex';
import informationIcons from 'lib/InformationIcons';
import { ApiHelperContext } from 'context/ApiHelperContext';
import Route from 'models/Routes';
import notify, { MessageType, Message } from 'components/Notifications/toastify';
import { Props, Mode } from "models/types";
import { IDataCenterReducerState, IError } from "models/Interfaces";
import {validateTenant} from "lib/validators/tenant";

const AddTenant: React.FC<Props> = (props: PropsWithChildren<any>) => {
  const { minWidth = '11rem' } = props;
  const { dataCenterId } = Redux.useSelector<any>((state) => state.DataCenterReducer) as IDataCenterReducerState;
  const [Tenant, setTenant] = React.useState();
  const [CustomProperties, setCustomProperties] = React.useState([]);
  const [AddNewCustomProperties, setAddNewCustomProperties] = React.useState([]);
  const [RemoveCustomProperties, setRemoveCustomProperties] = React.useState([]);
  const [status, setStatus] = React.useState(FormStatus.INITIAL);
  const { errorDialog, errorMessage, errorTitle, setErrorDialog, setErrorMessage, setErrorTitle } = useErrorState();
  const dispatch = Redux.useDispatch();
  const context = React.useContext(ApiHelperContext)
  const apiHelper = context?.state.apiHelper
  
  React.useEffect(() => {
    setTenant((state: any) => ({ ...state, dataCenterId: dataCenterId }));
    setTenant((state: any) => ({ ...state, status: 'New' }));
    setTenant((state: any) => ({ ...state, purpose: 'Production' }));
  }, [dataCenterId]);

  const errors = validateTenant(Tenant);
  const isValid = Object.keys(errors).length === 0;

  function handleSave(): void {
    if (isValid && Tenant && apiHelper) {
      apiHelper.post('tenants/add', Tenant, handleSaveCallBack, callbackErrorFunc);
    }
    setStatus(FormStatus.DIRTY);
  }

  function handleSaveCallBack(data: IError): void {
    if (data && data.status >= 400) {
      callbackErrorFunc(data);
    } else {
      const id = data && data.id;
      addCustomProperties(id);
      removeCustomProperties(id);
      handleCallBack(id);
      notify(Message.SiteAdded, MessageType.Success);
    }
  }

  function callbackErrorFunc(error: IError): void {
    handleError(error, setErrorTitle, setErrorMessage, setErrorDialog);
  }

  const handleCloseDialog = (): void => {
    setErrorDialog(false);
  };

  function handleCancel(): void {
    dispatch(hidePreviewPanel())
  }

  function handleCallBack(id: any): void {
    dispatch(setupPreviewPanel(Route.Sites, Mode.View, id));
    dispatch(refreshSearchResults(true));
  }

  function addCustomProperties(id: any): void {
    // Add new Custom Properties
    if(AddNewCustomProperties &&
      apiHelper &&
      AddNewCustomProperties.length > 0) {

      AddNewCustomProperties.map((Property) =>
        apiHelper.post(`Tenants/${id}/properties`, Property),
      );
    }

    // Updating existing Custom Properties
    if(CustomProperties &&
      CustomProperties.length > 0 &&
      apiHelper) {
      CustomProperties.map((Property) =>
        apiHelper.post(`Tenants/${id}/properties`, Property),
      );
    }
  }

  function removeCustomProperties(id: any): void {
    if(RemoveCustomProperties &&
      RemoveCustomProperties.length > 0 &&
      apiHelper) {
      RemoveCustomProperties.map((Name) =>
        apiHelper.delete(`Tenants/${id}/properties/${Name}`, handleCallBack, callbackErrorFunc),
      );
    }
  }

  const removeCustomProperty = (name: any, id:any, newProperty:any): void => {
    let filteredArray
    if (!newProperty) {
      setRemoveCustomProperties((names) => names.concat(name));
      filteredArray = CustomProperties && CustomProperties.filter((item: any) => item.id !== id);
      setCustomProperties(filteredArray);
    } else {
      filteredArray = AddNewCustomProperties.filter((item: any) => item.id !== id);
      setAddNewCustomProperties(filteredArray);
    }
  };

  const addCustomProperty = (): void => {
    const lastId = !AddNewCustomProperties ? 0 : AddNewCustomProperties.length;
    const handleSetProps = (names: any[]): any => {
      return Array.isArray(names)
        ? names.concat([{id: lastId, name: '', value: ''}])
        : [{id: lastId, name: '', value: ''}]
    }
    setAddNewCustomProperties(handleSetProps);
  };

  return (
    <>
      <div className="search-title-nav">
        <PanelSubHeader handleCancel={handleCancel} handleSave={handleSave} description='Add Details' icons={informationIcons.WriteIcons('GSL')} />
      </div>
      {!isValid && FormStatus && (status === FormStatus.DIRTY) && <EditComponent.WriteErrors errors={errors} />}
      <ContainerColumn style={{ height: 'calc(100% - 3rem)', overflowY: 'auto' }}>
        <div className="entity-details-content full-width">
          <EditComponent.TenantEdit 
            minWidth={minWidth}
            Tenant={Tenant}
            status={status}
            setTenant={setTenant}
            CustomProperties={CustomProperties}
            setCustomProperties={setCustomProperties}
            addCustomProperty={addCustomProperty}
            removeCustomProperty={removeCustomProperty}
            setAddNewCustomProperties={setAddNewCustomProperties}
            AddNewCustomProperties={AddNewCustomProperties}
          />
        </div>
      </ContainerColumn>
      <ErrorDialog
        errorDialog={errorDialog} 
        handleCloseDialog={handleCloseDialog}
        errorTitle={errorTitle}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default AddTenant;
