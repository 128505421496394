import React, {ReactElement} from 'react';
import {useHistory} from 'react-router-dom';
import OktaSignInWidget from './OktaSignInWidget';
import {useOktaAuth} from '@okta/okta-react';
import * as Redux from 'react-redux';
import {hidePreviewPanel} from 'store/Actions/PreviewPanel';
import {ApiHelperContext} from 'context/ApiHelperContext';
import ApiHelperClass from 'lib/apiHelperClass';

const Login = ({config}: any): ReactElement | null => {
  const dispatch = Redux.useDispatch();
  const history = useHistory();
  const context = React.useContext(ApiHelperContext);

  const {oktaAuth, authState} = useOktaAuth() || {},
    onSuccess = async (tokens: any): Promise<any> => {
      await oktaAuth.handleLoginRedirect(tokens);
      const helper = new ApiHelperClass(oktaAuth);
      context?.setApiHelper(helper);
    },
    onError = (err: any): void => {
      console.log('error logging in', err);
    };

  if (!authState) return null;

  function collapseAll(): void {
    dispatch(hidePreviewPanel());
  }

  const RedirectLogin = (): void => {
    collapseAll();
    history.push('/');
  };

  if (authState?.isAuthenticated) {
    RedirectLogin()
  }

  return <OktaSignInWidget config={config} onSuccess={onSuccess} onError={onError}/>;
};
export default Login;
