import React, {PropsWithChildren, ReactElement} from 'react'
import {ContainerRow} from 'styles/styledComponents/styledComponents';
import {IWriteErrors} from "models/Interfaces";

const WriteErrors = (props: PropsWithChildren<any>): ReactElement => {
  const {errors, nolastItemBorder} = props as IWriteErrors
  return (
    <>
      <ContainerRow className={`item-container ${nolastItemBorder ? 'no-last-border' : ''}`}>
        <div className="errors">
          <p>Please fix the following errors:</p>
          <ul>
            {Object.keys(errors).map((key, index) => {
              return <li key={index}>{errors[key]}</li>;
            })}
          </ul>
        </div>
      </ContainerRow>
    </>
  );
};

export default WriteErrors;
