import React, {PropsWithChildren} from 'react';
import Select from '../Select';
import { IReactSelect } from 'models/Lists/Common';
import SfsDataCentre, { SfsDataCentreLabel } from 'models/Lists/SfsDataCentre';

interface ISfsDataCentre { 
  sfsDataCenter?: string;
  setState: React.Dispatch<React.SetStateAction<unknown>>;
  targetName: string;
  required?: boolean;
  disabled?: boolean;
  menuPlacement?: string;
  hasError?: boolean;
}

const SfsDataCentres = (props: PropsWithChildren<any>): React.ReactElement => {
  const { sfsDataCenter, setState, targetName, required, disabled, menuPlacement, hasError } = props as ISfsDataCentre
  
  const handleChange = ((e: IReactSelect): void => {
    setState((state: string[]) => ({
      ...state,
      [targetName]: e?.value || '',
    }));
  });
  
  const options = [
    { value: SfsDataCentre.PROD1, label: SfsDataCentreLabel.PROD1 },
    { value: SfsDataCentre.PROD2, label: SfsDataCentreLabel.PROD2 },
    { value: SfsDataCentre.PROD3, label: SfsDataCentreLabel.PROD3 },
    { value: SfsDataCentre.PROD4, label: SfsDataCentreLabel.PROD4 },
    { value: SfsDataCentre.PROD5, label: SfsDataCentreLabel.PROD5 },
  ]
  
  return (
    <Select
      value={sfsDataCenter}
      name={targetName}
      handleChange={handleChange}
      options={options}
      required={required}
      disabled={disabled}
      menuPlacement={menuPlacement}
      hasError={hasError}
    />
  )
}

export default SfsDataCentres;
