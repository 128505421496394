import React, { PropsWithChildren } from 'react';
import * as Redux from 'react-redux';
import { setupPreviewPanel, hidePreviewPanel } from 'store/Actions/PreviewPanel';
import { refreshSearchResults } from 'store/Actions/SearchResults';
import PanelSubHeader from 'views/Common/PanelSubHeader/PanelSubHeader';
import { useFetch } from 'hooks';
import * as Component from 'components/ComponentsIndex';
import DataNotFound from 'error/DataNotFound';
import { ContainerColumnPanelView } from 'styles/styledComponents/styledComponents';
import FeatureView from 'views/Common/ViewForm/FeatureView';
import { AlertDialog } from 'components/Dialog/DialogIndex';
import { ADMIN } from 'okta/HasAccess';
import { ErrorDialog, handleError, useErrorState } from 'error/HandleErrors';
import { ApiHelperContext } from 'context/ApiHelperContext'
import notify, { MessageType, Message } from 'components/Notifications/toastify';
import { Props, Mode } from "models/types";
import { IPreviewPanelState } from "models/Interfaces";
import { IError, IPreviewPanel } from "models/Interfaces";
import  { VerbType } from 'models/Routes';

const ViewFeature: React.FC<Props>  = (props: PropsWithChildren<any>) => {
  const { nolastItemBorder, minWidth = '10rem' } = props;
  const { id, section, refreshPanel } = Redux.useSelector<IPreviewPanelState>((state) => state.PreviewPanelReducer) as IPreviewPanel;
  const { data, error, loading } = useFetch(VerbType.GET, id ? `Features/${id}` : '', refreshPanel);
  const [Feature, setFeature] = React.useState();
  const [openDialog, setOpenDialog] = React.useState(false);
  const dispatch = Redux.useDispatch();
  const context = React.useContext(ApiHelperContext)
  const apiHelper = context?.state.apiHelper
  
  const { errorDialog, errorMessage, errorTitle, setErrorDialog, setErrorMessage, setErrorTitle } = useErrorState();

  React.useEffect(() => {
    setFeature(data);
  }, [data, id, refreshPanel]);

  if (loading) return <Component.Loading />;
  if (!Feature) return <DataNotFound />;
  if (error) return <DataNotFound />;

  function handleEdit(): void {
    dispatch(setupPreviewPanel(section, Mode.Edit, id));
  }

  function handleDeleteItem(): void {
    if(apiHelper) {
      apiHelper.delete(`Features/${id}`, handleDeleteCallBack, callbackErrorFunc);
    }
  }

  function handleDeleteCallBack(): void {
    dispatch(hidePreviewPanel());
    dispatch(refreshSearchResults(true));
    notify(Message.FeatureDeleted, MessageType.Info);
  }

  function callbackErrorFunc(error: IError): void {
    handleError(error, setErrorTitle, setErrorMessage, setErrorDialog);
  }

  const handleOpenDialog = (): void => {
    setOpenDialog(true);
  };

  const handleCloseDialog = (): void => {
    setOpenDialog(false);
  };

  const deleteAccess = [ADMIN];
  const editAccess = [ADMIN];

  return (
    <>
      <div className="search-title-nav">
        <PanelSubHeader
          handleEdit={handleEdit}
          handleDelete={handleOpenDialog}
          deleteAccess={deleteAccess}
          editAccess={editAccess}
          description='Details'
        />
      </div>
      <ContainerColumnPanelView style={{ height: 'calc(100% - 3rem)', overflowY: 'auto' }}>
        <div className="entity-details-content full-width" id="viewcontainer">
          <FeatureView minWidth={minWidth} Feature={Feature} nolastItemBorder={nolastItemBorder} />
        </div>
      </ContainerColumnPanelView>
      <AlertDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        handleConfirm={handleDeleteItem}
        title="Delete Feature"
        body="Are you sure you wish to delete this item?"
      />
      <ErrorDialog
        errorDialog={errorDialog} 
        handleCloseDialog={handleCloseDialog}
        errorTitle={errorTitle}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default ViewFeature;
