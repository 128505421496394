import React, {PropsWithChildren, ReactElement, useContext, useState} from 'react';
import * as Redux from 'react-redux';
import PageHeaderTabs from 'views/Common/PageHeaderTabs/PageHeaderTabs';
import SearchResults from 'views/Common/SearchResults/SearchResults';
import useHeaderTabs from './HeaderTabsSetup';
import { useOktaAuth } from '@okta/okta-react';
import { hasAccess, PRODOPS } from 'okta/HasAccess';
import { useSearchParams } from 'hooks';
import { setDataCenter } from 'store/Actions/DataCenter';
import { refreshSearchResults } from 'store/Actions/SearchResults';
import useSWR from "hooks/useSWR";
import TenantStatus from 'models/Lists/TenantStatus';
import {Props, TabType} from "models/types";
import {IDataCenter, IDataCenterReducerState, IPreviewPanel, ISelectedSiteContext} from "models/Interfaces";
import {IPreviewPanelState} from "models/Interfaces";
import {SiteSelectedContext} from "context/SiteSelectedContext";

const GetResults: React.FC<Props> = (props: PropsWithChildren<any>) => {
  const { tabsDetails, startTab, filterOptions, pathName, dataCenterId,  resetPage, setResetPage } = props
  let selectorURL = '';
  if (tabsDetails.status) selectorURL += tabsDetails.status && `tenantStatus=${tabsDetails.status}`;
  if (dataCenterId) selectorURL += `${selectorURL ? '&' : ''}dataCenterId=${dataCenterId}`;
  if (tabsDetails.selector) selectorURL += `${selectorURL ? '&' : ''}tenantSelector=${tabsDetails.selector}`;
  const { handleDebounceUpdate, updateSearchParams, SearchParams } = useSearchParams();
  const context: ISelectedSiteContext | undefined  = useContext(SiteSelectedContext)
  const selectedSite = context?.state.selectedSite

  let bHideAddButton = (tabsDetails.selector !== TenantStatus.Unassigned);
  if (tabsDetails.showAddButton) bHideAddButton = false;

  React.useEffect(() => {
    if(selectedSite) {
      updateSearchParams('searchterm', selectedSite.url);
    }
  }, [selectedSite, updateSearchParams]);

  return (
    <SearchResults
      keyField="id"
      resetPage={resetPage || !!selectedSite}
      setResetPage={setResetPage}
      url={tabsDetails.url}
      hideAddbutton={bHideAddButton}
      addAccessOnly={tabsDetails.addAccessOnly}
      selector={selectorURL}
      searchTitle={tabsDetails.searchTitle}
      startTab={startTab}
      filterComponent={filterOptions}
      tabName={tabsDetails.text}
      pathName={pathName}
      paginatedResultsOnly={tabsDetails.paginatedResultsOnly}
      handleDebounceUpdate={handleDebounceUpdate}
      updateSearchParams={updateSearchParams}
      SearchParams={SearchParams}
    />
  );
}

const SiteManagement: React.FC<Props> = () => {
  const { dataCenterId } = Redux.useSelector((state: any) => state.DataCenterReducer) as IDataCenterReducerState;
  const { startTab, pathName } = Redux.useSelector((state: any) => state.SearchResultsReducer);
  const { refreshPanel, isPreviewPanelOpen } = Redux.useSelector<IPreviewPanelState>((state) => state.PreviewPanelReducer) as IPreviewPanel;
  const { authState } = useOktaAuth() || {};
  const [resetPage, setResetPage] = useState(false)
  const isProdOpsUser = hasAccess(PRODOPS, authState);

  let defaultTab;
  if (!isProdOpsUser) defaultTab = 0;
  if (isProdOpsUser) defaultTab = 1;
  if (typeof startTab !== "undefined") defaultTab = startTab;

  const { tabs } = useHeaderTabs();
  const [tabsDetails, setTabDetails] = React.useState(tabs[defaultTab]);

  const GetDataForView = (tab: TabType): void => {
    setResetPage(true)
    setTabDetails(tab);
  };
  
  const { data, loading } = useSWR(`DataCenters`);

  const [DataCenters, setDataCenters] = React.useState([]);
  const dispatch = Redux.useDispatch();

  React.useEffect(() => {
    if(!loading) {
      setDataCenters(data || []);
    }
  }, [data, loading]);

  const handleChange = (e: any): void => {
    dispatch(setDataCenter(e?.currentTarget?.value));
    dispatch(refreshSearchResults(!refreshPanel));
  };

  const bDisableDataCenterFilter = tabsDetails?.disableFilter;

  const filterOptions = (): ReactElement|undefined => {
    let filters;
    !isPreviewPanelOpen && !bDisableDataCenterFilter ?
      filters =   
      <select
        value={parseInt(dataCenterId)}
        style={{
          width: '150px',
          border: '1px solid rgb(171, 171, 171)',
          marginRight: '10px',
          marginTop: '15px',
          height: '35px',
          float: 'right',
          position: 'relative',
          zIndex: '1',
          padding: '5px',
        } as any}
        name="auditFilter"
        onChange={handleChange}
      >
        <option value="" hidden>Select Data Center</option>
        <option value="" />
        {DataCenters && DataCenters.length > 0 && DataCenters.map((option: IDataCenter) => {
          return (
            <option key={option.id} value={option.id}>
              {option.description}
            </option>
          );
        })}
      </select>
      : <></>;
    return filters;
  };

  const attributes = { tabsDetails, startTab, filterOptions, pathName, dataCenterId, resetPage, setResetPage } as PropsWithChildren<any>
  const pageHeaderAttr = {tabs, GetDataForView, startTab: defaultTab, resetPage}
  return (
    <>
      <PageHeaderTabs {...pageHeaderAttr} />
      {<GetResults {...attributes}/>}
    </>
  );
}

export default SiteManagement;
