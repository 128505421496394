import React, {PropsWithChildren, ReactElement} from 'react';
import Select from '../Select';
import { useFetch } from 'hooks';
import { VerbType } from 'models/Routes';
import {IFeature} from "models/Interfaces";

const FeaturesForPackage = (props: PropsWithChildren<any>): ReactElement => {
  const { targetName, isMulti, setState, initialFeatures, required, hasError } = props;
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  function useOptions(url: string): any {
    const { data: FeaturesForPackage } = useFetch(VerbType.GET, url);
    return FeaturesForPackage;
  }

  const features = useOptions('Features');
  const featuresOptions =
    features &&
    features.length > 0 &&
    features.map((Feature: IFeature) => ({
      label: Feature.description,
      value: Feature.code,
    }));

  React.useEffect(() => {
    setState(Array.isArray(selectedOptions) ? selectedOptions.map((x: IFeature) => (x)) : []);
  }, [selectedOptions, setState]);

  React.useEffect(() => {
    setState(initialFeatures);
    setSelectedOptions(initialFeatures);
  }, [initialFeatures, setState]);

  // handle onChange event of the dropdown
  const updateMultiArray = (e: any): void => {
    setState(Array.isArray(e) ? e.map((x: any) => x) : []);
    const optionList = Array.isArray(e) ? e.map((x: any) => x) : [] as any
    setSelectedOptions(optionList);
  };

  const selectAttr = {
    name:targetName,
    selected:selectedOptions,
    setState,
    handleChange:updateMultiArray,
    options:featuresOptions,
    isMulti,
    hasError,
    required,
  } as any
  return (
    <>
      <Select {...selectAttr}/>
    </>
  );
};

export default FeaturesForPackage;
