import React, { ReactElement } from 'react';
import Select from '../Select';
import useFetch from 'hooks/useFetch';
import { VerbType } from 'models/Routes';

interface IImplementationUsers {
  selected?: string;
  state: any;
  setState: React.Dispatch<React.SetStateAction<unknown>>;
  targetName: string;
}

type Profile = {
  firstName: string;
  lastName: string;
  email: string;
};

interface IOktaUser {
  id: string;
  status: string;
  profile: Profile;
}

const ImplementationUsers = (props: IImplementationUsers): ReactElement => {
  const { selected, state, setState, targetName } = props;
  const [Options, setOptions] = React.useState();
  const { data, error, loading } = useFetch(VerbType.GET, 'Okta/ImplementationUsers');

  React.useEffect(() => {
    if (data && !loading && !error) mapOptions(data);
  }, [data, error, loading]);

  const handleChange = (e: any): void => {
    const leftBracket = e?.label?.indexOf("(") >= 0 ? e?.label?.indexOf("(") : e?.label?.length;
    const userName = e?.label?.substr(0, leftBracket).trim();
    setState({
      ...state,
      [targetName]: e?.value || '',
      [`${targetName}Name`]: userName || '',
    });
  };

  function mapOptions (data: any): void {
    const options =
      data &&
      data.length > 0 &&
      data.map((OktaUser: IOktaUser) => ({
        label: OktaUser.profile.firstName +  ' ' + OktaUser.profile.lastName + ' (' + (OktaUser.profile.email)+ ')',
        value: OktaUser.profile.email,
      }));
    setOptions(options);
  }
  
  return (
    <>
      <Select
        value={selected}
        name={targetName}
        handleChange={handleChange}
        options={Options}
        placeholder={!Options ? loading ? 'Loading...': 'No Users found' : ''}
      />
    </>
  );
};

export default ImplementationUsers;
