import React from 'react';
import * as ViewComponent from './ViewIndex';
import { ILicense } from 'models/Interfaces';

interface ILicenseView { 
  minWidth?: string | undefined;
  License?: ILicense;
  summaryOnly?: boolean;
  nolastItemBorder?: boolean;
}

const LicenseView = (props : ILicenseView) : React.ReactElement => {  
  const { minWidth, License, nolastItemBorder, summaryOnly } = props;
  return (
    <>
      {!summaryOnly && (
        <>
          <ViewComponent.PageItem name="Name" minWidth={minWidth} value={License?.name} />
          <ViewComponent.PageItem name="Edition" minWidth={minWidth} value={License?.edition} />
        </>
      )}
      <ViewComponent.PageItem name="Companies" minWidth={minWidth} value={License?.companies} />
      <ViewComponent.PageItem name="People" minWidth={minWidth} value={License?.people} />
      <ViewComponent.PageItem
        name="Incorporations"
        minWidth={minWidth}
        value={License?.incorporations}
      />
      {!summaryOnly && (
        <>
          <ViewComponent.PageItemDate
            name="Start Date"
            minWidth={minWidth}
            value={License?.availableFrom}
          />
          <ViewComponent.PageItemDate
            name="Retired"
            minWidth={minWidth}
            value={License?.availableUntil}
          />
        </>
      )}
      <ViewComponent.PageItem name="Max Users" minWidth={minWidth} value={License?.users} />
      <ViewComponent.PageItem
        name="Admin Users"
        minWidth={minWidth}
        value={License?.adminUsers}
        nolastItemBorder={nolastItemBorder}
      />
      <ViewComponent.PageItem name="Edit Users" minWidth={minWidth} value={License?.editUsers} />      
      {!summaryOnly && (
        <>
          <ViewComponent.PageItem
            name="Data Library"
            minWidth={minWidth}
            value={License?.dataLibrary}
          />
          <ViewComponent.PageItemCheckbox
            nolastItemBorder={nolastItemBorder}
            name="Data Library Developer"
            minWidth={minWidth}
            value={License?.dataLibraryDeveloper}
          />
        </>
      )}
    </>
  );
};

export default LicenseView;
