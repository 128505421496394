import * as yup from 'yup';

export const validateTenant = (tenant: any, withPurpose = false): any => {
  const dataStructure: any = {
    dsn: yup.string().required('DSN is required'),
    url: yup.string().required('URL is required').url(),
    status: yup.string().required('Site Status is required'),
  }

  if (withPurpose) {
    dataStructure.purpose = yup.string().required('Site Purpose is required')
  }

  const tenantSchema = yup.object().shape(dataStructure);

  try {
    tenantSchema.validateSync(tenant, {abortEarly: false, stripUnknown: true});
    return [];
  } catch (err: any) {
    return err.errors;
  }
}
