import * as yup from 'yup';
import { IPackage } from 'models/Interfaces';

export const getEditPackageErrors = (Package: IPackage): any => {
  const packageSchema = yup.object().shape({
    code: yup.string().required('Package Code is required'),
    name: yup.string().required('Package Name is required'),
    description: yup.string().required('Description is required'),
    availableFrom: yup.string().required('Start Date is required'),
  });

  try {
    packageSchema.validateSync(Package, { abortEarly: false, stripUnknown: true });
    return [];
  } catch (err: any) {
    return err.errors;
  }
};
