import React from 'react';
import { ContainerRow } from 'styles/styledComponents/styledComponents';
import './viewForm.css';

const PageTitle = (props : { name?: string; backgroundColor?: string, color?: string, dataTestId?: string }) : React.ReactElement => {
  return (
    <ContainerRow className="item-container">
      <div className="item-title" data-testid={props.dataTestId} style={{ backgroundColor: props?.backgroundColor, color: props?.color }}>{props?.name}</div>
    </ContainerRow>
  );
};

export default PageTitle;
