import React from 'react';
import { ConfirmDialog } from 'components/Dialog/DialogIndex';
import notify, { MessageType } from 'components/Notifications/toastify';

export const handleError = (errorObj, setErrorTitle, setErrorMessage, setErrorDialog) => {
  let title = errorObj && errorObj.title;
  let message = errorObj && errorObj.detail ? errorObj.detail : 'Sorry, something went wrong.';
  setErrorTitle && setErrorTitle(title);
  setErrorMessage && setErrorMessage(message);
  setErrorDialog && setErrorDialog(true);
  notify(message, MessageType.Error);
};

export const useErrorState = () => {
  const [errorDialog, setErrorDialog] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [errorTitle, setErrorTitle] = React.useState();
  return { errorDialog, errorMessage, errorTitle, setErrorDialog, setErrorMessage, setErrorTitle };
};

export const ErrorDialog = (props) => {
  const { errorDialog, handleCloseDialog, errorTitle, errorMessage } = props;
  return (
    <ConfirmDialog
      open={errorDialog}
      handleClose={handleCloseDialog}
      title={`${errorTitle ? errorTitle : 'Error'}`}
      body={`${errorMessage ? errorMessage : 'Sorry, something went wrong'}`}
    />
  );
};
