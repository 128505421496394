import React, {PropsWithChildren} from 'react';
import {ContainerRow} from 'styles/styledComponents/styledComponents';
import * as EditComponent from './EditIndex';
import {LicenseEditions} from './Lists/ListsIndex';
import * as FieldLengths from '../Constants/FieldLengths';
import ReadOnlyText from "./ReadOnlyText";
import {computeMaxUsers} from "lib/licenseHelper";
import {Props} from "models/types";

const LicenseEdit: React.FC<Props>  = (props: PropsWithChildren<any>) : React.ReactElement => {
  const {
    id,
    minWidth = '10rem',
    License,
    status,
    setLicense,
    summaryOnly,
    fullBorder,
    indentTitle,
    handleChangeLicenseAddOn,
    menuPlacement,
  } = props;
  return (
    <>
      {!summaryOnly && (
        <>
          <ContainerRow className={`item-container ${fullBorder ? 'fullBorder noTopBorder' : ''}`}>
            <div
              className={`item-name fieldRequired ${indentTitle ? 'item-name-indent' : ''}`}
              style={{ minWidth: minWidth }}
            >
              Name
            </div>
            <EditComponent.TextField
              required={id <= 0 || !id}
              status={status}
              value={License?.name}
              setState={setLicense}
              targetName="name"
              maxLength={FieldLengths.License_Name}
            />
          </ContainerRow>
        </>
      )}
      <ContainerRow className={`item-container ${fullBorder ? 'fullBorder noTopBorder' : ''}`}>
        <div
          className={`fieldRequired item-name ${indentTitle ? 'item-name-indent' : ''}`}
          style={{ minWidth: minWidth }}
        >
          Companies
        </div>
        <EditComponent.NumberField
          required={true}
          status={status}
          value={License?.companies || undefined}
          setState={setLicense}
          targetName="companies"
          placeholder="Please put 99999 for 'Unlimited'"
          handleChangeFunc={handleChangeLicenseAddOn}
        />
      </ContainerRow>
      <ContainerRow className={`item-container ${fullBorder ? 'fullBorder noTopBorder' : ''}`}>
        <div
          className={`item-name ${indentTitle ? 'item-name-indent' : ''}`}
          style={{ minWidth: minWidth }}
        >
          People
        </div>
        <EditComponent.NumberField
          value={License?.people}
          setState={setLicense}
          targetName="people"
          placeholder="Please put 99999 for 'Unlimited'"
          handleChangeFunc={handleChangeLicenseAddOn}
        />
      </ContainerRow>
      <ContainerRow className={`item-container ${fullBorder ? 'fullBorder noTopBorder' : ''}`}>
        <div
          className={`item-name ${indentTitle ? 'item-name-indent' : ''}`}
          style={{ minWidth: minWidth }}
        >
          Incorporations
        </div>
        <EditComponent.NumberField
          value={License?.incorporations}
          setState={setLicense}
          targetName="incorporations"
          placeholder="Please put 99999 for 'Unlimited'"
          handleChangeFunc={handleChangeLicenseAddOn}
        />
      </ContainerRow>
      {!summaryOnly && (
        <>
          <ContainerRow className={`item-container ${fullBorder ? 'fullBorder noTopBorder' : ''}`}>
            <div
              className={`item-name fieldRequired ${indentTitle ? 'item-name-indent' : ''}`}
              style={{ minWidth: minWidth }}
            >
              Start Date
            </div>
            <EditComponent.DatePicker
              required={true}
              status={status}
              value={License?.availableFrom}
              setState={setLicense}
              targetName="availableFrom"
            />
          </ContainerRow>
          <ContainerRow className={`item-container ${fullBorder ? 'fullBorder noTopBorder' : ''}`}>
            <div
              className={`item-name ${indentTitle ? 'item-name-indent' : ''}`}
              style={{ minWidth: minWidth }}
            >
              Retired
            </div>
            <EditComponent.DatePicker
              value={License?.availableUntil}
              setState={setLicense}
              targetName="availableUntil"
            />
          </ContainerRow>
        </>
      )}
      <ContainerRow className={`item-container ${fullBorder ? 'fullBorder noTopBorder' : ''}`}>
        <div
          className={`item-name ${indentTitle ? 'item-name-indent' : ''}`}
          style={{ minWidth: minWidth }}
        >
          Max Users
        </div>
        <ReadOnlyText
          value={computeMaxUsers(License)}
          status={status}
          type="number"
          targetName="users"
          placeholder="Please put 99999 for 'Unlimited'"
        />
      </ContainerRow>
      <ContainerRow className={`item-container ${fullBorder ? 'fullBorder noTopBorder' : ''}`}>
        <div
          className={`item-name fieldRequired ${indentTitle ? 'item-name-indent' : ''}`}
          style={{ minWidth: minWidth }}
        >
          Admin Users
        </div>
        <EditComponent.NumberField
          value={License?.adminUsers || undefined}
          setState={setLicense}
          required={true}
          status={status}
          targetName="adminUsers"
          placeholder="Please put 99999 for 'Unlimited'"
          handleChangeFunc={handleChangeLicenseAddOn}
        />
      </ContainerRow>
      <ContainerRow className={`item-container ${fullBorder ? 'fullBorder noTopBorder' : ''}`}>
        <div
          className={`item-name ${indentTitle ? 'item-name-indent' : ''}`}
          style={{ minWidth: minWidth }}
        >
          Edit Users
        </div>
        <EditComponent.NumberField
          value={License?.editUsers || undefined}
          setState={setLicense}
          targetName="editUsers"
          placeholder="Please put 99999 for 'Unlimited'"
          handleChangeFunc={handleChangeLicenseAddOn}
        />
      </ContainerRow>
      <ContainerRow className={`item-container ${fullBorder ? 'fullBorder noTopBorder' : ''}`}>
        <div
          className={`item-name ${indentTitle ? 'item-name-indent' : ''}`}
          style={{ minWidth: minWidth }}
        >
          Edition
        </div>
        <LicenseEditions
          setState={setLicense}
          targetName="edition"
          value={License?.edition}
          menuPlacement={menuPlacement}
        />
      </ContainerRow>
      {!summaryOnly && (
        <>
          <ContainerRow className={`item-container ${fullBorder ? 'fullBorder noTopBorder' : ''}`}>
            <div
              className={`item-name ${indentTitle ? 'item-name-indent' : ''}`}
              style={{ minWidth: minWidth }}
            >
              Data Library
            </div>
            <EditComponent.NumberField
              value={License?.dataLibrary}
              setState={setLicense}
              targetName="dataLibrary"
            />
          </ContainerRow>
          <ContainerRow className={`item-container ${fullBorder ? 'fullBorder noTopBorder' : ''}`}>
            <div
              className={`item-name ${indentTitle ? 'item-name-indent' : ''}`}
              style={{ minWidth: minWidth }}
            >
              Data Library Developer
            </div>
            <EditComponent.CheckBox
              value={License?.dataLibraryDeveloper}
              setState={setLicense}
              targetName="dataLibraryDeveloper"
            />
          </ContainerRow>
        </>
      )}
    </>
  );
};

export default LicenseEdit;
