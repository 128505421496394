import styled, { keyframes, css } from 'styled-components';
import { get } from './utils';

const theme =
  (key, fallback = 'inherit') =>
    (props = {}) =>
      get(props.theme, key, fallback);

const resets = css`
  box-shadow: none;
  color: inherit;
  margin: inherit;
  padding: inherit;
`;

const formStyles = css`
  ${resets};
  box-sizing: border-box;
`;

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
`;

export const animationFadeInUp = css`
  animation: ${fadeInUp} 0.4s ease;
  animation-fill-mode: both;
`;

const fadeOutDown = keyframes`
  from {
    opacity: 1;
    transform: none;
  }

  to {
    opacity: 0;
    display: none;
    transform: translate3d(0, 10%, 0);
  }
`;

export const animationFadeOutDown = css`
  animation: ${fadeOutDown} 0.4s ease;
  animation-fill-mode: both;
`;

const load = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SlackFeedback = styled.div`
  position: fixed;
  font-family: ${theme('fontFamily', 'inherit')};
  z-index: 99999998;
  bottom: 0px;
  left: 3rem;
  margin-left: 6px;
  text-align: left;
  font-weight: ${theme('content.fontWeight', 400)};

  * {
    box-sizing: border-box;
  }

  textarea {
    min-height: 250px;
    font-family: ${theme('fontFamily', 'inherit')};
  }
`;

const Loader = styled.div`
  margin: 50px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: ${theme('loader.width')} solid rgba(255, 255, 255, 0.2);
  border-right: ${theme('loader.width')} solid rgba(255, 255, 255, 0.2);
  border-bottom: ${theme('loader.width')} solid rgba(255, 255, 255, 0.2);
  border-left: ${theme('loader.width')} solid ${theme('loader.color')};
  transform: translateZ(0);
  animation: ${load} 0.5s infinite linear;
  border-radius: 50%;
  width: ${theme('loader.size')};
  height: ${theme('loader.size')};

  &:after {
    border-radius: 50%;
    width: ${theme('loader.width')};
    height: ${theme('loader.size')};
  }
`;

const Container = styled.div`
  display: none;
  background: ${theme('colors.background')};
  position: relative;
  z-index: 999999999;
  border-radius: 4px;
  width: ${theme('content.width', '360px')};
  bottom: ${theme('content.bottom', '50px')};
  right: 0;
  box-shadow: ${theme('content.boxShadow')};
  ${animationFadeOutDown};

  &.active {
    ${animationFadeInUp};
    display: block;
  }
`;

const CloseButton = styled.div`
  cursor: pointer;
  font-weight: ${theme('closeButton.fontWeight', 'normal')};
  opacity: ${theme('closeButton.opacity', 1)};
  color: ${theme('closeButton.color', 'white')};
  margin-left: auto;
  font-size: 14px;

  &:hover {
    opacity: ${theme('closeButton.hoverOpacity', 1)};
    color: ${theme('closeButton.hoverColor', 'inherit')};
  }
`;

const Header = styled.div`
  display: flex;
  color: ${theme('header.color')};
  background: ${theme('colors.secondary')};
  padding: 0.75em 1em;
  font-weight: ${theme('header.fontWeight', 'normal')};
  border-radius: 3px 3px 0 0;
  font-size: ${theme('header.fontSize', '14px')};
  align-items: center;

  > img {
    margin-right: 0.5em;
  }
`;

const Content = styled.div`
  padding: ${theme('content.padding')};
`;

const Tabs = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 0.85em;

  > li {
    flex: 1 0 calc(100% / 3);
    user-select: none;
    background: ${theme('tab.backgroundColor')};
    color: ${theme('tab.color')};
    text-align: center;
    padding: ${theme('tab.padding', '0.75em')};
    font-size: 14px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: ${theme('tab.border')};

    &.selected {
      border-color: ${theme('colors.primary')};
      background: ${theme('colors.primary')};
      color: white;
      position: relative;
    }

    &:hover:not(.selected) {
      border: ${theme('tab.border')};
    }

    &:first-of-type {
      border-radius: 4px 0 0 4px;
      margin-right: -1px;
    }

    &:last-of-type {
      border-radius: 0 4px 4px 0;
      margin-left: -1px;
    }
  }
`;
const Label = styled.label`
  color: ${theme('colors.primary')};
  display: block;
  font-size: ${theme('label.fontSize')};
  margin: 5px 0;
`;

const FormElement = styled.input`
  ${formStyles};

  width: 100%;
  color: ${theme('input.color', '#000000')};
  border: ${theme('input.border', 'none')};
  border-radius: ${theme('input.borderRadius', '3px')};
  padding: ${theme('input.padding', 0)};
  font-size: ${theme('input.fontSize', '14px')};
  background: ${theme('input.backgroundColor', '#ffffff')};
  margin-bottom: 0.75em;
  outline: none;
  resize: none;

  &:focus {
    border: 1px solid ${theme('colors.primary')};
    box-shadow: ${theme('input.boxShadow', 'none')};
  }

  &[disabled],
  &.disabled {
    background: ${theme('input.backgroundColor', '#ffffff')};
    pointer-events: none;
    color: ${theme('input.color')};
  }
`;

const SubmitButton = styled.button`
  display: block;
  width: 100%;
  padding: ${theme('button.padding', '1em 0.75em')};
  text-align: center;
  background: ${theme('colors.primary')};
  color: ${theme('button.color', 'white')};
  font-weight: ${theme('button.fontWeight', 400)};
  outline: none;
  border: none;
  font-size: ${theme('button.fontSize', '14px')};
  letter-spacing: 0.5px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.2s, box-shadow 0.2s;

  &[disabled],
  &.disabled {
    pointer-events: none;
    opacity: ${theme('button.opacity')};
  }

  &.sent {
    background: ${theme('colors.success')};
    pointer-events: none;
    opacity: 1;
  }

  &.error {
    background: ${theme('colors.error')};
    pointer-events: none;
    opacity: 1;
  }

  &:hover {
    background: ${theme('colors.primary')};
    box-shadow: none;
  }
`;
const Select = styled.div`
  margin-bottom: 0.5em;
`;

export {
  CloseButton,
  Container,
  Content,
  FormElement,
  Header,
  Label,
  Loader,
  Select,
  SlackFeedback,
  SubmitButton,
  Tabs,
};
