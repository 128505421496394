import React, {useEffect, useRef} from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

const OktaSignInWidget = (props: any): any => {
  const {config, onSuccess, onError} = props
  const widgetRef = useRef<any>();
  useEffect(() => {
    if (!widgetRef.current) {
      return
    }
    const widget = new OktaSignIn(config);
    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
        scopes: ['openid', 'email', 'profile', 'groups'],
      })
      .then(onSuccess)
      .catch(onError);
    return (): void => widget.remove();
  }, [config, onError, onSuccess]);

  return <div ref={widgetRef}/>;
};
export default OktaSignInWidget;
