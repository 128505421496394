import React from 'react';
import * as Redux from 'react-redux';
import * as Component from 'components/ComponentsIndex';
import { ContainerCenter } from 'styles/styledComponents/styledComponents';
import { hidePreviewPanel } from 'store/Actions/PreviewPanel';
import SidebarText from '../../SlidingText/SidebarText';
import { SidebarItem } from 'styles/styledComponents/styledComponents';
import { ReactComponent as DiligentLogo } from './Diligent.svg';
import { env } from 'env';
import '../../sidebar.css';

interface IDiligentIcon {
  isPanelOpen : boolean,
  openSidebarPanel : () => void,
  hideMenu : () => void,
  isSidebarOpen : boolean
}

const DiligentIcon: React.FC<IDiligentIcon>  = (props: React.PropsWithChildren<any>)=> {
  const { isPanelOpen, openSidebarPanel, hideMenu, isSidebarOpen } = props;
  const dispatch = Redux.useDispatch();
  return (    
    <Component.Tooltip title={env.appName}>
      <SidebarItem
        className={`nav-item-wrapper ${isSidebarOpen && 'expanded'}`}
        onClick={() : void => {
          !isPanelOpen ? dispatch(openSidebarPanel()) : dispatch(hideMenu())
        }}
      >
        <span className={`icon-container ${isPanelOpen && 'icon-panel-hover'}`}
          onClick={() : void => {
            dispatch(hidePreviewPanel());
          }}
        >
          <ContainerCenter className="icon-inner-container full-size status-marker long-transition">
            <DiligentLogo />
          </ContainerCenter>
        </span>
        <SidebarText text={env.appName} />
      </SidebarItem>
    </Component.Tooltip>
  );
}

export default DiligentIcon;
