import React from 'react';
import { ButtonColoured } from '../Button/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useStyles, DialogTitleBar, DialogContent, DialogActions } from './Styles';

const ConfirmDialog = (props) => {
  const { handleClose, title, body, open, width } = props;
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.root}
        fullWidth={true}
        maxWidth={!width ? 'sm' : width}
        style={{ borderRadius: '0px' }}
      >
        <DialogTitleBar onClose={handleClose}>{title}</DialogTitleBar>
        <DialogContent dividers>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" component="div">
              <pre style={{ fontFamily: 'inherit' }}>{body}</pre>
            </DialogContentText>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <ButtonColoured handleClick={handleClose} color="primary" label="OK" />
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ConfirmDialog;
