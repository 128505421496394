import React, { ReactElement } from 'react';
import * as ViewComponent from 'views/Common/ViewForm/ViewIndex';
import { NewLicenceData, LicenceData } from './types';
import { SetValue, GetPackageDescription, NewAddonFeatures ,RemoveAddonFeatures } from './helper';

interface UpdateComparisonProps {
  NewLicenceData: NewLicenceData;
  CurrentLicenceData: LicenceData;
  indentTitle?: boolean;
  minWidth?: string;
}

const UpdateComparison = (props: UpdateComparisonProps): ReactElement => {
  const { NewLicenceData, CurrentLicenceData, minWidth, indentTitle } = props; 
  return (
    <>
      <ViewComponent.PageTitle
        name="Licence Update Changes"
        backgroundColor="#5e7698"
        color="#fff"
        dataTestId="PageTitle"
      />
      <ViewComponent.PageItemCompare
        name="Package"
        value={GetPackageDescription(SetValue(CurrentLicenceData.packageCode, 'string'))}
        newValue={GetPackageDescription(NewLicenceData.packageId)} 
        minWidth={minWidth}
        indentTitle={indentTitle}
        dataTestId="Package"
      />
      { 
        NewLicenceData?.companies !== CurrentLicenceData?.licenseDetails?.companies &&
        <ViewComponent.PageItemCompare
          name="Companies"
          value={SetValue(CurrentLicenceData?.licenseDetails?.companies, typeof CurrentLicenceData?.licenseDetails?.companies)}
          newValue={NewLicenceData?.companies} 
          minWidth={minWidth}
          indentTitle={indentTitle}
          dataTestId="Companies"
        />
      }
      { 
        NewLicenceData?.people !== CurrentLicenceData?.licenseDetails?.people &&
        <ViewComponent.PageItemCompare
          name="People"
          value={SetValue(CurrentLicenceData?.licenseDetails?.people, typeof CurrentLicenceData?.licenseDetails?.people)}
          newValue={NewLicenceData?.people} 
          minWidth={minWidth}
          indentTitle={indentTitle}
          dataTestId="People"
        />
      }
      { 
        NewLicenceData?.incorporations !== CurrentLicenceData?.licenseDetails?.incorporations &&
        <ViewComponent.PageItemCompare
          name="Incorporations"
          value={SetValue(CurrentLicenceData?.licenseDetails?.incorporations, typeof CurrentLicenceData?.licenseDetails?.incorporations)}
          newValue={NewLicenceData?.incorporations} 
          minWidth={minWidth}
          indentTitle={indentTitle}
          dataTestId="Incorporations"
        />
      }
      { 
        NewLicenceData?.maxUsers !== CurrentLicenceData?.licenseDetails?.users &&
        <ViewComponent.PageItemCompare
          name="Max Users"
          value={SetValue(CurrentLicenceData?.licenseDetails?.users, typeof CurrentLicenceData?.licenseDetails?.users)}
          newValue={NewLicenceData?.maxUsers} 
          minWidth={minWidth}
          indentTitle={indentTitle}
          dataTestId="MaxUsers"
        />
      }
      { 
        NewLicenceData?.adminUsers !== CurrentLicenceData?.licenseDetails?.adminUsers &&
        <ViewComponent.PageItemCompare
          name="Admin Users"
          value={SetValue(CurrentLicenceData?.licenseDetails?.adminUsers, typeof CurrentLicenceData?.licenseDetails?.adminUsers)}
          newValue={NewLicenceData?.adminUsers} 
          minWidth={minWidth}
          indentTitle={indentTitle}
          dataTestId="AdminUsers"
        />
      }
      { 
        NewLicenceData?.editUsers !== CurrentLicenceData?.licenseDetails?.editUsers &&
        <ViewComponent.PageItemCompare
          name="Edit Users"
          value={SetValue(CurrentLicenceData?.licenseDetails?.editUsers, typeof CurrentLicenceData?.licenseDetails?.editUsers)}
          newValue={NewLicenceData?.editUsers} 
          minWidth={minWidth}
          indentTitle={indentTitle}
          dataTestId="EditUsers"
        />
      }
      {
        NewLicenceData?.edition !== CurrentLicenceData?.licenseDetails?.edition &&
        <ViewComponent.PageItemCompare
          name="Edition"
          value={SetValue(CurrentLicenceData?.licenseDetails?.edition, typeof CurrentLicenceData?.licenseDetails?.edition)}
          newValue={NewLicenceData?.edition} 
          minWidth={minWidth}
          indentTitle={indentTitle}
          dataTestId="Edition"
        />
      }
      <ViewComponent.PageItemList
        name="New - Feature Add-ons"
        items={NewAddonFeatures(CurrentLicenceData?.addOnFeatures, JSON.stringify(NewLicenceData?.addOnFeatures))}
        minWidth={minWidth}
        itemValue="label"
        indentTitle={indentTitle}
        dataTestId="NewFeatureAddons"
      />
      <ViewComponent.PageItemList
        name="Remove - Feature Add-ons"
        items={RemoveAddonFeatures(CurrentLicenceData?.addOnFeatures, JSON.stringify(NewLicenceData?.addOnFeatures))}
        minWidth={minWidth}
        itemValue="label"
        indentTitle={indentTitle}
        dataTestId="RemoveFeatureAddons"
      />
      <ViewComponent.PageItem
        name="Assigned By"
        value={SetValue(NewLicenceData?.assignedByName, typeof NewLicenceData?.assignedByName)} 
        minWidth={minWidth}
        indentTitle={indentTitle}
        copyToClipboard={true}
        dataTestId="AssignedBy"
      />
      <ViewComponent.PageEmailItem
        name="Email"
        value={SetValue(NewLicenceData?.assignedByEmail, typeof NewLicenceData?.assignedByEmail)} 
        minWidth={minWidth}
        indentTitle={indentTitle}
        copyToClipboard={true}
        dataTestId="Email"
      />
    </>
  );
};

export default UpdateComparison;
