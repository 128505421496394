import React, {PropsWithChildren, ReactElement} from 'react';
import * as Redux from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { hidePreviewPanel } from 'store/Actions/PreviewPanel';
import shortid from 'shortid';
import Tooltip from 'components/Tooltip/Tooltip';
import { hasAccessGroup } from 'okta/HasAccess';
import { useOktaAuth } from '@okta/okta-react';
import {IPreviewPanel, IPreviewPanelState} from "../../../models/Interfaces";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    boxShadow: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: '#f5f5f5',
    borderBottom: '1px solid #ababab',
    height: '3rem',
    '& .MuiTabs-indicator': {
      height: '4px',
    },
    '& .Mui-selected': {
      fontWeight: '600',
    },
  },
}));

const PageHeaderTabs = (props: PropsWithChildren<any>): ReactElement => {
  const { tabs, GetDataForView, startTab } = props;
  const { isPreviewPanelOpen } = Redux.useSelector<IPreviewPanelState>((state) => state.PreviewPanelReducer) as IPreviewPanel;
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const dispatch = Redux.useDispatch();
  const { authState } = useOktaAuth() || {};

  const handleChange = (e: any, newValue: any): void => {
    setValue(newValue);
  };

  React.useEffect(() => {
    setValue(startTab ? startTab : 0);
  }, [startTab]);

  return (
    <div>
      <AppBar position="sticky" className={classes.root}>
        <div
          style={{
            display: isPreviewPanelOpen ? 'inline-block' : 'none',
            right: '0px',
            top: '5px',
            height: '48px',
            position: 'absolute',
          }}
        >
          <IconButton
            color="inherit"
            component="span"
            onClick={(): void => {
              dispatch(hidePreviewPanel());
            }}
          >
            <i className="i-bpow-chevron-right" style={{ fontSize: '1rem', color: '#333' }} />
          </IconButton>
        </div>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          className={classes.root}
        >
          {tabs &&
            tabs.map((tab: any ): any => {
              return (
                hasAccessGroup(tab.accessOnly, authState) && (
                  <Tab
                    key={shortid.generate()}
                    label={
                      <Tooltip title={tab.tooltip}>
                        <span>{tab.text}</span>
                      </Tooltip>
                    }
                    onClick={(): void => {
                      dispatch(hidePreviewPanel());
                      GetDataForView(tab);
                    }}
                  />
                )
              );
            })}
        </Tabs>
      </AppBar>
    </div>
  );
}

export default PageHeaderTabs;
