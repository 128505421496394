import React, {PropsWithChildren} from 'react';
import * as Redux from 'react-redux';
import { setupPreviewPanel } from 'store/Actions/PreviewPanel';
import { refreshSearchResults } from 'store/Actions/SearchResults';
import { useFetch } from 'hooks';
import PanelSubHeader from 'views/Common/PanelSubHeader/PanelSubHeader';
import Loading from 'components/Loading/Loading';
import DataNotFound from 'error/DataNotFound';
import { ContainerColumn } from 'styles/styledComponents/styledComponents';
import { FormStatus } from 'views/Common/EditForm/Common';
import * as EditComponent from 'views/Common/EditForm/EditIndex';
import { ErrorDialog, handleError, useErrorState } from 'error/HandleErrors';
import { ApiHelperContext } from 'context/ApiHelperContext'
import Route, { VerbType } from 'models/Routes';
import notify, { MessageType, Message } from 'components/Notifications/toastify';
import { Props, Mode } from "models/types";
import { IApiHelperContext, IError, IFeature, IPackage, IPreviewPanel, ISwrResult } from "models/Interfaces";
import { IPreviewPanelState } from "models/Interfaces";
import {validateFeature} from "lib/validators/feature";

const EditFeature: React.FC<Props>  = (props: PropsWithChildren<any>) => {
  const { id } = Redux.useSelector<IPreviewPanelState>((state) => state.PreviewPanelReducer) as IPreviewPanel;
  const { minWidth = '10rem' } = props;
  const featureFetchResult: ISwrResult = useFetch(VerbType.GET, id ? `features/${id}` : '');
  const { data, error, loading } = featureFetchResult;

  // State controls for adding Packages to this Feature
  const [selectedPackages, setSelectedPackages] = React.useState<any[]>([]);
  const [initialPackages, setInitialPackages] = React.useState<any[]>([]);
  const [Feature, setFeature] = React.useState<IFeature|undefined>();
  const [status, setStatus] = React.useState(FormStatus.UNTOUCHED);
  const { errorDialog, errorMessage, errorTitle, setErrorDialog, setErrorMessage, setErrorTitle } = useErrorState();
  const dispatch = Redux.useDispatch();
  const context: IApiHelperContext | undefined  = React.useContext(ApiHelperContext)
  const apiHelper = context?.state.apiHelper
  
  React.useEffect(() => {
    setFeature(data);
  }, [data]);

  const fetchResult: ISwrResult = useFetch(VerbType.GET, Feature ? `Features/${Feature.code}/Packages` : '');
  const Packages = fetchResult?.data as IPackage[]

  React.useEffect(() => {
    setFeature(data);
    let selectedOptions: any[] = []
    if(Packages && Packages?.length > 0) {
      selectedOptions = Packages.map((Package: IPackage) => ({
        label: Package.name,
        value: Package.code,
      }))
    }
    setInitialPackages(selectedOptions);
  }, [data, Packages]);

  if (loading) return <Loading />;
  if (id > 0 && !Feature) return <DataNotFound />;
  if (error) return <DataNotFound />;

  const errors = validateFeature(Feature);
  const isValid = Object.keys(errors).length === 0;

  function handleSave(): void {
    if (isValid && Feature && apiHelper) {
      apiHelper.put('Features/update', Feature, handleSaveCallBack, callbackErrorFunc);
    }
    setStatus(FormStatus.DIRTY);
  }

  function handleSaveCallBack(data: IError): void {
    if (data && data.status >= 400) {
      callbackErrorFunc(data);
    } else {
      FeaturePackages();    
      notify(Message.FeatureUpdated, MessageType.Success);   
    }
  }

  function callbackErrorFunc(error: IError): void {
    handleError(error, setErrorTitle, setErrorMessage, setErrorDialog);
  }

  const handleCloseDialog = (): void => {
    setErrorDialog(false);
  };

  function FeaturePackages(): void {
    if (isValid) {
      const intersectionAdd = selectedPackages && selectedPackages.filter((e) => initialPackages && !initialPackages.includes({value: e}));
      if (intersectionAdd && intersectionAdd.length > 0) {
        intersectionAdd.map((Package) => addPackageToFeature(Package));
      } else {
        selectedPackages && selectedPackages.map((Package) => addPackageToFeature(Package));
      }

      const intersectionRemove = initialPackages && initialPackages
        .filter((x) => !initialPackages.includes(x))
        .concat(initialPackages.filter((x) => selectedPackages && !selectedPackages.includes(x.value)));

      intersectionRemove && intersectionRemove.map((Package) =>
        removePackageFromFeature(Package.value),
      );

      if (intersectionAdd.length === 0 && intersectionRemove.length === 0) handleRefreshCallBack();
    }
  }

  const addPackageToFeature = (value: any): void => {
    if(apiHelper && Feature) {
      apiHelper.post(`Packages/${value}/Features/${Feature.code}`, '', handleRefreshCallBack, callbackErrorFunc);
    }
  };

  const removePackageFromFeature = (value: any): void => {
    if(apiHelper && Feature) {
      apiHelper.delete(`Packages/${value}/Feature/${Feature.code}`, handleRefreshCallBack, callbackErrorFunc);
    }
  };

  function handleCancel(): void {
    dispatch(setupPreviewPanel(Route.Features, Mode.View, id));
  }

  function handleRefreshCallBack(): void {
    dispatch(setupPreviewPanel(Route.Features, Mode.View, Feature && Feature.code));
    dispatch(refreshSearchResults(true));    
  }

  const errorAttributes = { errors } as PropsWithChildren<any>
  return (
    <>
      <div className="search-title-nav">
        <PanelSubHeader handleCancel={handleCancel} handleSave={handleSave} description='Edit Details' />
      </div>
      {!isValid && FormStatus && (status === FormStatus.DIRTY) && <EditComponent.WriteErrors {...errorAttributes} />}
      <ContainerColumn style={{ height: 'calc(100% - 3rem)', overflowY: 'auto' }}>
        <div className="entity-details-content full-width">
          <EditComponent.FeatureEdit
            id={Feature && Feature.id}
            minWidth={minWidth}
            Feature={Feature}
            status={status}
            setFeature={setFeature}
            selectedPackages={selectedPackages}
            setSelectedPackages={setSelectedPackages}
            initialPackages={initialPackages}
          />
        </div>
      </ContainerColumn>
      <ErrorDialog
        errorDialog={errorDialog} 
        handleCloseDialog={handleCloseDialog}
        errorTitle={errorTitle}
        errorMessage={errorMessage}
      />
    </>
  );
}

export default EditFeature;
