import React, {PropsWithChildren, ReactElement} from 'react';
import { ContainerRow } from 'styles/styledComponents/styledComponents';
import { DataCenters, Tenants, Packages, Licenses, FeatureAddOns, TenantStatus, TenantPurpose, ImplementationUsers } from './Lists/ListsIndex';
import EditDatePicker from './DatePicker';
import EntitesVersion from './Lists/EntitiesVersion';
import SfsDataCentre from './Lists/SfsDataCentre';
import { LicenseById, WriteErrors } from './EditIndex';
import { computeMaxUsers } from 'lib/licenseHelper'

const AssignTenant = (props: PropsWithChildren<any>): ReactElement => {
  const { minWidth = '10rem', state, setState, status, isValid, FormStatus, errors } = props;

  const handleChangeLicenseAddOn = (name: string, value: string): void => {
    const licenseId = state?.licenseId;
    const companies = name === 'companies' ? parseInt(value) : state?.companies;
    const people = name === 'people' ? parseInt(value) : state?.people;
    const incorporations = name === 'incorporations' ? parseInt(value) : state?.incorporations;
    const adminUsers = name === 'adminUsers' ? parseInt(value) : state?.adminUsers;
    const editUsers = name === 'editUsers' ? parseInt(value) : state?.editUsers;
    const edition = name === 'edition' ? value : state?.edition;
    const users = computeMaxUsers(state);
    let jsonLicenseAddOns = { users };
    if (!licenseId || licenseId === '' || licenseId === 0) {
      jsonLicenseAddOns = {
        companies,
        people,
        incorporations,
        users,
        adminUsers,
        editUsers,
        edition
      } as any;
    }
    setState((state: any) => ({ ...state, users, addOnLicense: JSON.stringify(jsonLicenseAddOns) }));
  };

  return (
    <>
      {!isValid && FormStatus && (status === FormStatus.DIRTY) && <WriteErrors errors={errors} nolastItemBorder={true} />}
      <ContainerRow className="item-container fullBorder">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Admin User
        </div>
        <ImplementationUsers
          state={state}
          setState={setState}
          targetName="adminUser"
          selected={state?.adminUser}
        />
      </ContainerRow>
      <ContainerRow className="item-container fullBorder noTopBorder">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Data Centre
        </div>
        <DataCenters
          state={state}
          setState={setState}
          targetName="dataCenterId"
          id={state?.dataCenterId}
        />
      </ContainerRow>
      <ContainerRow className="item-container fullBorder noTopBorder">
        <div className="item-name fieldRequired" style={{ minWidth: minWidth }}>
          Site
        </div>
        <Tenants
          required
          formStatus={status}
          id={state?.tenantId}
          state={state}
          setState={setState}
          targetName="tenantId"
          status={state?.status}
          tenantSelector="Unassigned"
          dataCenterFilter={state?.dataCenterId}
          hasError={((FormStatus && status === FormStatus.DIRTY) && (!state?.tenantId || !state))}
        />
      </ContainerRow>
      <ContainerRow className="item-container fullBorder noTopBorder">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Site Status
        </div>
        <TenantStatus
          state={state}
          setState={setState}
          targetName="tenantStatus"
          status={state?.tenantStatus}
          disabled={true}
        />
      </ContainerRow>
      <ContainerRow className="item-container fullBorder noTopBorder">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Site Purpose
        </div>
        <TenantPurpose
          state={state}
          setState={setState}
          targetName="tenantPurpose"
          purpose={state?.tenantPurpose}
        />
      </ContainerRow>
      <ContainerRow className="item-container fullBorder noTopBorder">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Version of Entities
        </div>
        <EntitesVersion
          state={state}
          setState={setState}
          targetName="entitiesVersion"
          entitiesVersion={state?.entitiesVersion && state.entitiesVersion}
        />
      </ContainerRow>
      <ContainerRow className="item-container fullBorder noTopBorder">
        <div className="item-name fieldRequired" style={{ minWidth: minWidth }}>
          Package
        </div>
        <Packages
          required
          formStatus={status}
          id={state?.packageId}
          state={state}
          setState={setState}
          targetName="packageId"
          status={state?.status}
          packageSelector="Current"
          hasError={((FormStatus && status === FormStatus.DIRTY) && (!state?.packageId || !state))}
        />
      </ContainerRow>
      <ContainerRow className="item-container fullBorder noTopBorder">
        <div className="item-name" style={{ minWidth: minWidth }}>
          License
        </div>
        <Licenses
          id={state?.licenseId}
          state={state}
          setState={setState}
          targetName="licenseId"
          status={state?.status}
        />
      </ContainerRow>
      <LicenseById
        licenseId={state?.licenseId}
        state={state}
        setState={setState}
        summaryOnly={true}
        fullBorder={true}
        indentTitle={true}
        handleChangeLicenseAddOn={handleChangeLicenseAddOn}
        menuPlacement='top'
        status={status}
      />
      <FeatureAddOns
        addOnFeatures={state?.addOnFeatures}
        setState={setState}
        isMulti="true"
        targetName="addOnFeatures"
        minWidth={minWidth}
        fullBorder={true}
        noTopBorder={true}
        menuPlacement='top'
      />
      <ContainerRow className="item-container fullBorder noTopBorder">
        <div className="item-name" style={{ minWidth: minWidth }}>
          SFS Data Center
        </div>
        <SfsDataCentre
          state={state}
          setState={setState}
          targetName="sfsDataCenter"
          sfsDataCenter={state?.sfsDataCenter}
          menuPlacement='top'
        />
      </ContainerRow>
      <ContainerRow className="item-container fullBorder noTopBorder">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Assigned On
        </div>
        <EditDatePicker
          value={state?.assignedOn}
          setState={setState}
          targetName="assignedOn"
        />
      </ContainerRow>
    </>
  );
};

export default AssignTenant;
