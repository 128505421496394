import React, {PropsWithChildren} from 'react';
import './reports.css';
import { ContainerCenter } from 'styles/styledComponents/styledComponents';
import {Props} from "models/types";
  
const ReportsInformation: React.FC<Props> = (props: PropsWithChildren<any>) => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <ContainerCenter>
              <div style={{padding: '40px 15px', textAlign: 'center'}}>
                <div>                
                  <i className="empty-state-icon i-bpow-reports" />
                </div>
                <div style={{color: 'grey', marginTop: '15px', marginBottom:'15px', maxWidth: '20rem', textAlign: 'center'}}>
                  <div>
                  Select a report from the dropdown to get started. <br /><br />
                  Use the search for additional filtering.
                  </div>
                </div>
              </div>
            </ContainerCenter>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportsInformation
