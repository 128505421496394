import React, {PropsWithChildren, ReactElement} from 'react';
import { TextField } from '../EditIndex';
import Select from '../Select';
import { IReactSelect } from 'models/Lists/Common';
import ContractStatus from 'models/Lists/ContractStatus';

interface IContractStatus { 
  status?: string;
  setState: React.Dispatch<React.SetStateAction<unknown>>;
  targetName: string;
  required?: boolean;
  disabled?: boolean;
  menuPlacement?: string;
  hasError?: boolean;
}

const ContractStatuses = (props: IContractStatus): ReactElement => {
  const { status, setState, targetName, disabled, required, hasError } = props as IContractStatus;

  const handleChange = ((e: IReactSelect): void => {
    setState((state: string[]) => ({
      ...state,
      [targetName]: e?.value || '',
    }));
  });

  const options = [
    { value: ContractStatus.Active, label: 'Active' },
    { value: ContractStatus.Pending, label: 'Pending' },
    { value: ContractStatus.Overdue, label: 'Overdue' },
    { value: ContractStatus.Closed, label: 'Closed' },
    { value: ContractStatus.ActiveCancelling, label: 'Active Cancelling' },
    { value: ContractStatus.ClosedReplaced, label: 'Closed Replaced' },
    { value: ContractStatus.ClosedCancelled, label: 'Closed Cancelled' },
    { value: ContractStatus.NeedsReview, label: 'Needs Review' },
    { value: ContractStatus.PendingDeletion, label: 'Pending Deletion' },
    { value: ContractStatus.Upgrade, label: 'Upgrade' },
    { value: ContractStatus.InApprovalProcess, label: 'In Approval Process' },
    { value: ContractStatus.Activated, label: 'Activated' },
    { value: ContractStatus.Draft, label: 'Draft' },
  ];

  const selectAttr = {
    value: status,
    name: targetName,
    handleChange,
    options,
    hasError,
    required,
  } as PropsWithChildren<any>
  return (
    <>
      {disabled && <TextField value={status} disabled={true} />}
      {!disabled && (
        <Select {...selectAttr}/>
      )}
    </>
  );
};

export default ContractStatuses;
