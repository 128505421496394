import { ILicense } from 'models/Interfaces';

export const computeMaxUsers = (licence: ILicense | undefined): string => {
  const editUsers: any = licence?.editUsers ?? 0;
  const adminUsers: any = licence?.adminUsers ?? 0;
  let users: number = (isNaN(editUsers) ? 0 : editUsers * 1) + (isNaN(adminUsers) ? 0 : adminUsers * 1);

  if (users > 99999) users = 99999;

  if (licence) {
    licence.users = users;
    licence.maxUsers = users;
  }

  return users.toString();
};
