import React from 'react';

const Icon = (props: { className: string }): React.ReactElement => {
  const { className } = props;
  return (
    <i
      className={className}
      aria-controls="menu-button"
      aria-haspopup="true"
      style={{
        fontSize: '1.375rem',
        color: '#fff',
        fontWeight: 400,
        cursor: 'pointer',
      }}
    />
  );
}

export default Icon;
