import React from 'react';
import Select from '../Select';
import { IReactSelect } from 'models/Lists/Common';
import LicenseEdition from 'models/Lists/LicenseEdition';

interface ILicenseEditions { 
  value?: string;
  setState: React.Dispatch<React.SetStateAction<unknown>>;
  targetName: string;
  hasError?: boolean;
  required?: boolean;
  menuPlacement?: string | undefined;
}

const LicenseEditions = (props: ILicenseEditions): React.ReactElement => {
  const { value, setState, targetName, hasError, required, menuPlacement } = props;

  const handleChange = ((e: IReactSelect): void => {
    setState((state: string[]) => ({
      ...state,
      [targetName]: e?.value || '',
    }));
  });

  const options = [
    { value: LicenseEdition.BlueprintOneWorld, label: 'BlueprintOneWorld' },
    { value: LicenseEdition.Blueprint2000, label: 'Blueprint2000' },
    { value: LicenseEdition.CoAct, label: 'CoAct' },
    { value: LicenseEdition.BlueprintExpress, label: 'BlueprintExpress' },
    { value: LicenseEdition.BoardPadConnect, label: 'BoardPadConnect' },    
    { value: LicenseEdition.EntitiesHostedServices, label: 'Entities Hosted Services' },
  ];
 
  return (
    <Select
      value={value}
      name={targetName}
      handleChange={handleChange}
      options={options}
      hasError={hasError}
      required={required}
      menuPlacement={menuPlacement}
    />
  );
};

export default LicenseEditions;
