import React, {PropsWithChildren, ReactElement} from 'react';
import { useFetch } from 'hooks';
import * as Component from 'components/ComponentsIndex';
import { ContainerRow } from 'styles/styledComponents/styledComponents';
import shortid from 'shortid';
import DataNotFound from 'error/DataNotFound';
import { VerbType } from 'models/Routes';

const FeatureCodes = (props: PropsWithChildren<any>): ReactElement => {
  const { packageCode, nolastItemBorder, minWidth = '10rem' } = props;
  const { data, error, loading } = useFetch(
    VerbType.GET,
    packageCode ? `Packages/${packageCode}/Features` : '',
  );
  const [Features, setFeatures] = React.useState<any>();

  React.useEffect(() => {
    setFeatures(data);
  }, [data]);

  if (loading) return <Component.Loading />;
  if (error) return <DataNotFound />;

  return (
    <>
      {Features &&
        Features.length > 0 &&
        Features.map((Features: any) => {
          return (
            <React.Fragment key={shortid.generate()}>
              <ContainerRow className={`item-container ${nolastItemBorder && 'no-last-border'}`}>
                <div className="item-name" style={{ minWidth: minWidth }} />
                <div className="values-container">
                  <div className="item-value">{Features.description}</div>
                </div>
              </ContainerRow>
            </React.Fragment>
          );
        })}
    </>
  );
};

export default FeatureCodes;
