import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { AlertDialog } from 'components/Dialog/DialogIndex';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import * as Component from 'components/ComponentsIndex';
import Tooltip from 'components/Tooltip/Tooltip';
import Icon from '../Icon/Icon';

export const useStyles = makeStyles((theme) => ({
  menu: {
    margin: 0,
    padding: theme.spacing(2),
    fontFamily: theme.typography.fontFamily,
    boxShadow: '0 2px 4px 0 rgb(0 0 0 / 3%), 0 4px 5px 0 rgb(0 0 0 / 4%)',
    overflow: 'visible',
    border: '1px solid #ababab',
    '& .MuiPaper-root': {
      borderRadius: '0px',
    },
    '& .MuiList-padding': {
      padding: '0px',
    },
    '& .MuiMenuItem-root': {
      fontSize: '14px',
      minWidth: '176px',
    },
    '& .MuiMenuItem-root:not(:last-child)': {
      borderBottom: '1px solid #ababab',
    },
  },
}));

const PersonIcon = (): React.ReactElement => {
  const { oktaAuth, authState } = useOktaAuth() || {};
  const [openSignOutDialog, setSignOutDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userName = authState && authState.idToken && authState.idToken.claims.name;
  const classes = useStyles();

  const handleClick = (event: any): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleCloseDialog = (): void => {
    setSignOutDialog(false);
  };

  const handleSignOutDialog = (): void => {
    setSignOutDialog(true);
    setAnchorEl(null);
  };

  const signOut = async (): Promise<void> => {
    await oktaAuth.signOut();
  };

  return (
    <>
      <Tooltip title='User'>
        <IconButton onClick={handleClick}>
          <Icon className="i-bpow-profile" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        className={classes.menu}
      >
        <MenuItem
          disabled
          style={{ backgroundColor: '#f6f6f6', cursor: 'default', opacity: '1' }}
        >
          {userName}
        </MenuItem>
        <MenuItem onClick={handleSignOutDialog}>
          <Component.Tooltip title="Click here to Sign Out">
            <div style={{ width: '100%', height: '100%' }}>Sign out</div>
          </Component.Tooltip>
        </MenuItem>
      </Menu>
      <AlertDialog
        open={openSignOutDialog}
        handleClose={handleCloseDialog}
        handleConfirm={signOut}
        title="Sign out"
        body="This action will sign you out.  Do you want to continue?"
      />
    </>
  );
}

export default PersonIcon;
