import React, {PropsWithChildren, ReactElement} from 'react';
import { ContainerRow } from 'styles/styledComponents/styledComponents';
import * as EditComponent from './EditIndex';
import * as ListComponent from './Lists/ListsIndex';
import * as FieldLengths from '../Constants/FieldLengths';
import CustomPropertiesEdit from './CustomProperties';
import EntitesVersion from './Lists/EntitiesVersion';
import { ADMIN, PRODOPS, hasAccessGroup } from 'okta/HasAccess';
import { useOktaAuth } from '@okta/okta-react';

const TenantEdit = (props: PropsWithChildren<any>): ReactElement => {
  const {
    minWidth,
    Tenant,
    status,
    setTenant,
    CustomProperties,
    setCustomProperties,
    addCustomProperty,
    removeCustomProperty,
    AddNewCustomProperties,
    setAddNewCustomProperties,
  } = props;
  const { authState } = useOktaAuth() || {};
  const tenantAsStateAttr = { state :Tenant} as any
  return (
    <>
      <ContainerRow className="item-container">
        <div className="item-name fieldRequired" style={{ minWidth: minWidth }}>
          Site Status
        </div>
        <ListComponent.TenantStatus
          required
          formStatus={status}
          state={Tenant}
          setState={setTenant}
          targetName="status"
          status={Tenant?.status ? Tenant.status : 'New'}
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Data Centre
        </div>
        <ListComponent.DataCenters
          state={Tenant}
          setState={setTenant}
          targetName="dataCenterId"
          id={Tenant?.dataCenterId}
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name fieldRequired" style={{ minWidth: minWidth }}>
          DSN
        </div>
        <EditComponent.TextField
          required
          status={status}
          value={Tenant?.dsn}
          setState={setTenant}
          targetName="dsn"
          maxLength={parseInt(FieldLengths.Tenant_DSN)}
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name fieldRequired" style={{ minWidth: minWidth }}>
          URL
        </div>
        <EditComponent.TextField
          required
          status={status}
          value={Tenant?.url}
          setState={setTenant}
          targetName="url"
          maxLength={parseInt(FieldLengths.Tenant_URL)}
        />
      </ContainerRow>
      {
        hasAccessGroup([ADMIN, PRODOPS], authState) &&
        <ContainerRow className="item-container">
          <div className="item-name" style={{ minWidth: minWidth }}>
            IDS Site
          </div>
          <ListComponent.IdsSites
            {...tenantAsStateAttr}
            setState={setTenant}
            targetName="identityServerSiteId"
            idsSiteId={Tenant?.identityServerSiteId}
          />
        </ContainerRow>
      }      
      <ContainerRow className="item-container">
        <div className="item-name fieldRequired" style={{ minWidth: minWidth }}>
          Site Purpose
        </div>
        <ListComponent.TenantPurpose
          required
          formStatus={status}
          state={Tenant}
          setState={setTenant}
          targetName="purpose"
          purpose={Tenant?.purpose}
        />
      </ContainerRow>
      {Tenant?.purpose === 'IDN' && <EditComponent.IdnSiteEdit {...props} indentTitle={true} /> }
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Version of Entities
        </div>
        <EntitesVersion
          state={Tenant}
          setState={setTenant}
          targetName="entitiesVersion"
          entitiesVersion={Tenant?.entitiesVersion && Tenant.entitiesVersion}
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Database Name
        </div>
        <EditComponent.TextField
          value={Tenant?.databaseName}
          setState={setTenant}
          targetName="databaseName"
          maxLength={parseInt(FieldLengths.Tenant_DatabaseName)}
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Database Server
        </div>
        <EditComponent.TextField
          value={Tenant?.databaseServer}
          setState={setTenant}
          targetName="databaseServer"
          maxLength={parseInt(FieldLengths.Tenant_DatabaseServer)}
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Web Server
        </div>
        <EditComponent.TextField
          value={Tenant?.webServer}
          setState={setTenant}
          targetName="webServer"
          maxLength={parseInt(FieldLengths.Tenant_WebServer)}
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          IP Restrictions
        </div>
        <EditComponent.CheckBox
          value={Tenant?.isIpRestrictions}
          {...tenantAsStateAttr}
          setState={setTenant}
          targetName="isIpRestrictions"
        />
      </ContainerRow>
      {Tenant?.isIpRestrictions && 
        <ContainerRow className="item-container">
          <div className="item-name" style={{ minWidth: minWidth }}>
            Restrictions
          </div>
          <EditComponent.TextField
            value={Tenant?.ipRestrictions}
            setState={setTenant}
            targetName="ipRestrictions"
            maxLength={parseInt(FieldLengths.Tenant_IpRestrictions)}
          />
        </ContainerRow>
      }
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          AutoReminder
        </div>
        <EditComponent.CheckBox
          value={Tenant?.isAutoReminderEnabled}
          {...tenantAsStateAttr}
          setState={setTenant}
          targetName="isAutoReminderEnabled"
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          EF Auto Send Receive
        </div>
        <EditComponent.CheckBox
          value={Tenant?.isEfAutoSendReceiveEnabled}
          {...tenantAsStateAttr}
          setState={setTenant}
          targetName="isEfAutoSendReceiveEnabled"
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          ESignatures
        </div>
        <EditComponent.CheckBox
          value={Tenant?.isESignaturesEnabled}
          {...tenantAsStateAttr}
          setState={setTenant}
          targetName="isESignaturesEnabled"
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          SSO
        </div>
        <EditComponent.CheckBox
          value={Tenant?.isSsoEnabled}
          {...tenantAsStateAttr}
          setState={setTenant}
          targetName="isSsoEnabled"
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Jobs Server
        </div>
        <EditComponent.TextField
          value={Tenant?.jobsServer}
          setState={setTenant}
          targetName="jobsServer"
          maxLength={parseInt(FieldLengths.Tenant_JobsServer)}
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          ReportIt Server
        </div>
        <EditComponent.TextField
          value={Tenant?.reportItServer}
          setState={setTenant}
          targetName="reportItServer"
          maxLength={parseInt(FieldLengths.Tenant_ReportItServer)}
        />
      </ContainerRow>
      <ContainerRow className={`item-container ${Tenant?.customProperties && Tenant.customProperties.length > 0 ? 'no-last-border': ''}`}>
        <div className="item-name" style={{ minWidth: minWidth }}>
          Notes
        </div>
        <EditComponent.TextField value={Tenant?.notes} setState={setTenant} targetName="notes" />
      </ContainerRow>
      <CustomPropertiesEdit
        CustomProperties={CustomProperties}
        setCustomProperties={setCustomProperties}
        minWidth={minWidth}
        addCustomProperty={addCustomProperty}
        removeCustomProperty={removeCustomProperty}
        setAddNewCustomProperties={setAddNewCustomProperties}
        AddNewCustomProperties={AddNewCustomProperties}
      />
    </>
  );
};

export default TenantEdit;
