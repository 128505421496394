import React from 'react';
import { editFormStyles, FormStatus } from './Common';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { IconButton } from '@material-ui/core';
import { DATE_PICKER_DATE_FORMAT } from 'lib/dateHelper'

interface IEditDatePicker {
  value: string | undefined; 
  setState: React.Dispatch<React.SetStateAction<unknown>>;
  targetName: string; 
  required?: boolean;
  status?: string;
  disabled?: boolean;
}
const EditDatePicker = (props: IEditDatePicker) : React.ReactElement => {
  const { value, setState, targetName, required, status, disabled } = props;
  const classes = editFormStyles();
  
  function handleDateChange(name: string, newDate: string) : void {
    setState((state: string[]) => {
      return {
        ...state,
        [name]: newDate,
      };
    });
  }

  function handleClear(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) : void {
    e.stopPropagation();
    handleDateChange(targetName, '');
  }

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {required ? (
          <DatePicker
            disableToolbar
            autoOk={true}
            disabled={disabled}
            variant="inline"
            format={DATE_PICKER_DATE_FORMAT}
            margin="none"
            name={targetName}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <IconButton onClick={(e) : void => handleClear(e)}>
                  <i className="i-bpow-cross" style={{ fontSize: '0.8rem' }} />
                </IconButton>
              ),
            }}
            className={`${classes.dateField} ${!value && FormStatus && (status === FormStatus.DIRTY) && classes.error}`}
            value={value ? value : null}
            onChange={(date) : void => handleDateChange(targetName, date)}
          />
        ) : (
          <DatePicker
            disableToolbar
            autoOk={true}
            disabled={disabled}
            variant="inline"
            format={DATE_PICKER_DATE_FORMAT}
            margin="none"
            name={targetName}
            InputProps={{
              disableUnderline: true,
              endAdornment: !disabled && (
                <IconButton onClick={(e) : void => handleClear(e)}>
                  <i className="i-bpow-cross" style={{ fontSize: '0.8rem' }} />
                </IconButton>
              ),
            }}
            className={`${classes.dateField}`}
            value={value ? value : null}
            onChange={(date) : void => handleDateChange(targetName, date)}
          />
        )}
      </MuiPickersUtilsProvider>
    </>
  );
};

export default EditDatePicker;
