import React, {PropsWithChildren} from 'react';
import { useFetch } from 'hooks';
import Select from '../Select';
import { IReactSelect } from 'models/Lists/Common';
import { VerbType } from 'models/Routes';

interface ILicenses { 
  id?: string; 
  setState: React.Dispatch<React.SetStateAction<unknown>>;
  targetName: string;
  state?: any;
  required?: boolean;
  hasError?: boolean;
}
interface ILicense { id: number; name: string; }

const Licenses = (props: PropsWithChildren<any>): React.ReactElement => {
  const { id, setState, targetName, required, hasError } = props as ILicenses;
  const { data } = useFetch(VerbType.GET, 'Licenses');

  const handleChange = ((e: IReactSelect): void => {
    setState((state: string[]) => ({
      ...state,
      [targetName]: e?.value || '',
    }));
  });

  let options: unknown[] | null | undefined = [];

  if (data) {
    options = (data as ILicense[]).map((License) => ({
      label: License.name,
      value: License.id,
    }));
  }

  return (
    <Select
      value={id}
      name={targetName}
      handleChange={handleChange}
      options={options}
      hasError={hasError}
      required={required}
    />
  );
};

export default Licenses;
