import * as views from 'views/Index';
import Login from 'okta/Login';
import Route from 'models/Routes';
import { hasAccessGroup, ADMIN, CSM, IMPLEMENTATION, PRODOPS, SALES, SUPPORT, MARKETING } from 'okta/HasAccess';
import { useOktaAuth } from '@okta/okta-react';

const DEFAULT_PATH = '/';

const AppRoutes = () => {
  const { authState } = useOktaAuth() || {};

  function DefaultComponent () {
    const defaultPath = views.Tenants;    
    if (!authState) return defaultPath;

    switch (true) {
    case hasAccessGroup([ADMIN, PRODOPS, IMPLEMENTATION, SUPPORT], authState):
      return views.Tenants;
    case hasAccessGroup([CSM, SALES], authState):
      return views.Clients;
    case hasAccessGroup(MARKETING, authState):
      return views.Reporting;
    default:
      return defaultPath;
    }
  }

  return {
    shared: [
      {
        exact: true,
        component: Login,
        path: DEFAULT_PATH + Route.Logon,
      },
      {
        exact: true,
        component: DefaultComponent(),
        path: DEFAULT_PATH,
      },
      {
        exact: true,
        component: views.CheckHealth,
        path: DEFAULT_PATH + Route.CheckHealth,
      },
      {
        exact: true,
        component: views.Packages,
        path: DEFAULT_PATH + Route.Packages,
      },
      {
        exact: true,
        component: views.Tenants,
        path: DEFAULT_PATH + Route.Sites,
      },
      {
        exact: true,
        component: views.Clients,
        path: DEFAULT_PATH + Route.Clients,
      },
      {
        exact: true,
        component: views.Auditing,
        path: DEFAULT_PATH + Route.Audit,
      },
      {
        exact: true,
        component: views.Reporting,
        path: DEFAULT_PATH + Route.Reporting,
      },
      {
        exact: true,
        component: views.Salesforce,
        path: DEFAULT_PATH + Route.Salesforce,
      },
    ],
  };
};
export default AppRoutes;