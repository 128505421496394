import React from 'react';
import * as Redux from 'react-redux';
import { ContainerCenterSpace } from 'styles/styledComponents/styledComponents';
import HeaderbarIcons from './Icons/Icons';
import './header.css';

const Header = (): React.ReactElement => {
  const { pathName } = Redux.useSelector((state: any) => state.SearchResultsReducer);
  return (
    <div className="top-bar-container">
      <div className="top-bar-root">
        <ContainerCenterSpace className="top-bar short-transition elevated-high">
          <div className="trimmed full-width">{pathName}</div>
          <HeaderbarIcons />
        </ContainerCenterSpace>
      </div>
    </div>
  );
}

export default Header;