import React from 'react';
import { ContainerRow } from 'styles/styledComponents/styledComponents';
import './viewForm.css';
import IconButton from '@material-ui/core/IconButton';
import CopyToClipboard from './CopyToClipboard';

interface IPageEmailItem { 
  name: string | undefined;
  value?: string | number | undefined;
  nolastItemBorder?: boolean;
  minWidth?: string | undefined;  
  icon?: string;
  indentTitle?: boolean;
  copyToClipboard?: boolean;
  dataTestId?: string;
}

const PageEmailItem = (props : IPageEmailItem) : React.ReactElement => {
  const { name, value, nolastItemBorder, minWidth, icon, indentTitle = false, copyToClipboard, dataTestId } = props;
  const sendMail = () : void => {
    const mailto = `mailto:${value}?subject=Test subject&body=Body content`;
    window.location.href = mailto;
  }
  return (
    <ContainerRow className={`item-container ${nolastItemBorder && 'no-last-border'}`}>
      <div className={`item-name ${indentTitle && 'item-name-indent'} 
        ${copyToClipboard && 'displayFlex'}`} style={{ minWidth: minWidth }}>
        <div style={{width: '100%'}}>{name}</div>
        {icon && <div style={{marginLeft: '3px', display: 'inline-block'}}>{icon}</div>}
        {copyToClipboard && (typeof value === 'string') && <CopyToClipboard value={value} />}
      </div>
      <div className="values-container">
        <div className="item-value" onClick={sendMail} data-testid={dataTestId}>{value}
          <IconButton
            color="inherit"
            component="span"
            onClick={sendMail}
            style={{ padding: '0px', marginLeft: '10px' }}
          >
            <i className="i-bpow-mail" style={{ fontSize: '1.5rem', color: 'rgba(0, 0, 0, 0.9)' }} />
          </IconButton>
        </div>        
      </div>
    </ContainerRow>
  );
};

export default PageEmailItem;
