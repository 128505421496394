import React from 'react';
import AuditCategory from 'models/Lists/AuditCategory';
import {IAuditCategories} from "models/Interfaces";


const AuditCategories = (props: IAuditCategories): React.ReactElement => {
  const { selected, setState, targetName } = props;

  const handleChange = ((e: React.ChangeEvent<HTMLSelectElement>): void => {
    setState((state: string[]) => ({
      ...state,
      [targetName]: e?.currentTarget?.value,
    }));
  });

  const options = [
    { value: AuditCategory.SalesforceOrganisationBulkImportCompleted, label: 'Salesforce Organisation Import' },
    { value: AuditCategory.ExportToPDF, label: 'Export to PDF' },
    { value: AuditCategory.ExportToCSV, label: 'Export to CSV' },
    { value: AuditCategory.EmailSent, label: 'Email Sent' },
    { value: AuditCategory.EmailFailed, label: 'Email Failed' },
    { value: AuditCategory.OrganisationAssignedToSite, label: 'Organisation Assigned to Site' },
    { value: AuditCategory.OrganisationUnassignedFromSite, label: 'Organisation Unassigned from Site' },
    { value: AuditCategory.SiteAssignmentCompleted, label: 'Site Assignment Completed' },
    { value: AuditCategory.SiteAssignmentCancelled, label: 'Site Assignment Cancelled' },
    { value: AuditCategory.SiteCreated, label: 'Site Created' },
    { value: AuditCategory.SiteUpdated, label: 'Site Updated' },
    { value: AuditCategory.SiteDeleted, label: 'Site Deleted' },
    { value: AuditCategory.PackageCreated, label: 'Package Created' },
    { value: AuditCategory.PackageUpdated, label: 'Package Updated' },
    { value: AuditCategory.PackageDeleted, label: 'Package Deleted' },
    { value: AuditCategory.FeatureCreated, label: 'Feature Created' },
    { value: AuditCategory.FeatureUpdated, label: 'Feature Updated' },
    { value: AuditCategory.FeatureDeleted, label: 'Feature Deleted' },
    { value: AuditCategory.FeatureAddedToPackage, label: 'Feature Added to Package' },
    { value: AuditCategory.FeatureRemovedFromPackage, label: 'Feature Removed from Package' }, 
    { value: AuditCategory.LicenseCreated, label: 'License Created' },
    { value: AuditCategory.LicenseUpdated, label: 'License Updated' },
    { value: AuditCategory.LicenseDeleted, label: 'License Deleted' },   
    { value: AuditCategory.OrganisationCreated, label: 'Organisation Created' },
    { value: AuditCategory.OrganisationUpdated, label: 'Organisation Updated' },
    { value: AuditCategory.OrganisationDeleted, label: 'Organisation Deleted' },
    { value: AuditCategory.IdentityServerSiteCreated, label: 'IDS Site Created' },
    { value: AuditCategory.IdentityServerSiteUpdated, label: 'IDS Site Updated' },
    { value: AuditCategory.IdentityServerSiteDeleted, label: 'IDS Site Deleted' },    
    { value: AuditCategory.OrganisationSiteUpdated, label: 'Organisation Site Updated' },
    { value: AuditCategory.OrganisationSiteDeleted, label: 'Organisation Site Deleted' },
    { value: AuditCategory.FeaturesLicensePackageFileImportStarted, label: 'BHS Import Started' },
    { value: AuditCategory.FeaturesLicensePackageFileImportCompleted, label: 'BHS File Import Completed' },    
  ];

  return (
    <select
      value={selected}
      style={{
        width: '210px',
        border: '1px solid rgb(171, 171, 171)',
        marginRight: '10px',
        height: '35px',
        float: 'right',
        position: 'relative',
        zIndex: 1,
        padding: '5px',
      }}
      onChange={handleChange}
    >
      <option value="" hidden>Category</option>
      <option value=""></option>
      {options &&
        options.length > 0 &&
        options.map((option, index) => {
          return (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          );
        })}
    </select>
  );
};

export default AuditCategories;
