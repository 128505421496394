import Route from 'models/Routes';
import { SetStateAction } from 'react';
interface ISetDataOptions {
  tenants?: any;
  organisations?: any;
  audits?: any;
}
const SetData = (data: ISetDataOptions, url: string, setResults: SetStateAction<any>): void => {
  switch (url) {
  case Route.Sites:
    setResults(data?.tenants);
    break;
  case Route.Audit:
    setResults(data?.audits);
    break;
  case Route.Clients:
    setResults(data?.organisations);
    break;
  default:
    setResults(data);
    break;
  }
};

export const getItemsFromResult = (data: ISetDataOptions, url: string): any => {
  if (!data) {
    return [];
  }
  switch (url) {
  case Route.Sites:
    return data?.tenants || [];
  case Route.Audit:
    return data?.audits || [];
  case Route.Clients:
    return data?.organisations || [];
  default:
    return Array.isArray(data) ? data : [];
  }
};

export default SetData;
