import React from 'react';
import Button, { ButtonColoured } from '../Button/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useStyles, DialogTitleBar, DialogContent, DialogActions } from './Styles';

const AlertDialog = (props) => {
  const { handleConfirm, handleClose, title, body, open, width } = props;
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.root}
        fullWidth={true}
        maxWidth={!width ? 'sm' : width}
        style={{ borderRadius: '0px' }}
      >
        <DialogTitleBar onClose={handleClose}>{title}</DialogTitleBar>
        <DialogContent dividers>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" component="div">
              {body}
            </DialogContentText>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button handleClick={handleClose} color="inherit" label="No" />
          <ButtonColoured handleClick={handleConfirm} color="primary" label="Yes" />
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AlertDialog;
