import React, {PropsWithChildren} from 'react';
import * as Redux from 'react-redux';
import { setupPreviewPanel, hidePreviewPanel } from 'store/Actions/PreviewPanel';
import { refreshSearchResults } from 'store/Actions/SearchResults';
import PanelSubHeader from 'views/Common/PanelSubHeader/PanelSubHeader';
import { ContainerColumn } from 'styles/styledComponents/styledComponents';
import { FormStatus } from 'views/Common/EditForm/Common';
import * as EditComponent from 'views/Common/EditForm/EditIndex';
import { ErrorDialog, handleError, useErrorState } from 'error/HandleErrors';
import { ApiHelperContext } from 'context/ApiHelperContext';
import Route from 'models/Routes';
import notify, { MessageType, Message } from 'components/Notifications/toastify';
import { Props, Mode } from "models/types";
import {IApiHelperContext, IError, IFeature} from "models/Interfaces";
import {validateFeature} from "lib/validators/feature";

const AddFeature: React.FC<Props>  = (props: PropsWithChildren<any>)=> {
  const { minWidth = '10rem' } = props;
  const [Feature, setFeature] = React.useState<IFeature>();
  const [status, setStatus] = React.useState(FormStatus.UNTOUCHED);

  // State controls for adding Packages to this Feature
  const [selectedPackages, setSelectedPackages] = React.useState([]);
  const [initialPackages, setInitialPackages] = React.useState([]);

  const { errorDialog, errorMessage, errorTitle, setErrorDialog, setErrorMessage, setErrorTitle } = useErrorState();
  const dispatch = Redux.useDispatch();

  const errors = validateFeature(Feature);
  const isValid = Object.keys(errors).length === 0;
  const context: IApiHelperContext | undefined  = React.useContext(ApiHelperContext)
  const apiHelper = context?.state.apiHelper

  function handleSave(): void {
    if (isValid && Feature && apiHelper) {
      apiHelper.post('Features/add', Feature, handleSaveCallBack, callbackErrorFunc);
    }
    setStatus(FormStatus.DIRTY);
  }

  function handleSaveCallBack(data: IError): void {
    if (data && data.status >= 400) {
      callbackErrorFunc(data);
    } else {
      addPackages();
      notify( Message.FeatureAdded, MessageType.Success);
    }
  }

  function callbackErrorFunc(error: IError): void {
    handleError(error, setErrorTitle, setErrorMessage, setErrorDialog);
  }

  const handleCloseDialog = (): void => {
    setErrorDialog(false);
  };

  function addPackages(): void {
    if (isValid) {
      const intersectionAdd = selectedPackages && selectedPackages.filter((e) => initialPackages && !initialPackages.includes(e));
      if (intersectionAdd && intersectionAdd.length > 0) {
        intersectionAdd && intersectionAdd.map((value: string) =>
          addPackageToFeature(value),
        );
      } else {
        selectedPackages && selectedPackages.map((value: string) =>
          addPackageToFeature(value),
        );
      }
      if (intersectionAdd.length === 0) handleRefreshCallBack();
    }
  }

  const addPackageToFeature = (value: any): void => {
    if (apiHelper && Feature) {
      apiHelper.post(`Packages/${value}/Features/${Feature.code}`, '', handleRefreshCallBack, callbackErrorFunc);
    }
  };

  function handleRefreshCallBack(): void {
    dispatch(setupPreviewPanel(Route.Features, Mode.View, Feature && Feature.code));
    dispatch(refreshSearchResults(true));
  }

  function handleCancel(): void {
    dispatch(hidePreviewPanel());
  }
  const errorParams = { errors } as PropsWithChildren<any>
  return (
    <>
      <div className="search-title-nav">
        <PanelSubHeader handleCancel={handleCancel} handleSave={handleSave} description='Add Details' />
      </div>
      {!isValid && FormStatus && (status === FormStatus.DIRTY) && <EditComponent.WriteErrors {...errorParams} />}
      <ContainerColumn style={{ height: 'calc(100% - 3rem)', overflowY: 'auto' }}>
        <div className="entity-details-content full-width">
          <EditComponent.FeatureEdit
            minWidth={minWidth}
            Feature={Feature}
            status={status}
            setFeature={setFeature}
            selectedPackages={selectedPackages}
            setSelectedPackages={setSelectedPackages}
            setInitialPackages={setInitialPackages}
          />
        </div>
      </ContainerColumn>
      <ErrorDialog
        errorDialog={errorDialog} 
        handleCloseDialog={handleCloseDialog}
        errorTitle={errorTitle}
        errorMessage={errorMessage}
      />
    </>
  );
}

export default AddFeature;
