import React, {ReactElement} from 'react';
import Package from '../../PackageManagement/Add/Package';
import Feature from '../../PackageManagement/Add/Feature';
import License from '../../PackageManagement/Add/License';
import Tenant from '../../SiteManagement/Add/Tenant';
import IdsSite from '../../SiteManagement/Add/IdsSite';
import Organisation from '../../ClientManagement/Add/Organisation';
import PageNotFound from 'error/PageNotFound';
import Route from 'models/Routes';

const AddPanel = (section: string): ReactElement => {
  const props = {} as any
  switch (section.toLowerCase()) {
  case Route.Packages:
    return <Package {...props}/>;
  case Route.Features:
    return <Feature {...props}/>;
  case Route.Licenses:
    return <License {...props}/>;
  case Route.Sites:
    return <Tenant {...props}/>;
  case Route.IDS:
    return <IdsSite {...props}/>;
  case Route.Clients:
    return <Organisation {...props}/>;
  default:
    return <PageNotFound/>;
  }
}

export default AddPanel
