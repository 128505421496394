enum ContractStatus {
  Active = 'Active',
  Pending = 'Pending',
  Overdue = 'Overdue',
  Closed = 'Closed',
  ActiveCancelling = 'Active Cancelling',
  ClosedReplaced = 'Closed Replaced',
  ClosedCancelled = 'Closed Cancelled',
  NeedsReview = 'Needs Review',
  PendingDeletion = 'Pending Deletion',
  Upgrade = 'Upgrade',
  InApprovalProcess = 'In Approval Process',
  Activated = 'Activated',
  Draft = 'Draft',
}

export default ContractStatus;
