import React from 'react';
import './checkbox.css';
import { Container } from 'styles/styledComponents/styledComponents';

interface IEditCheckBox { 
  value?: boolean; 
  setState: React.Dispatch<React.SetStateAction<unknown>>;
  targetName: string;
}

const EditCheckBox = (props: IEditCheckBox): React.ReactElement => {
  const { value, setState, targetName } = props;

  const handleCheckboxChange = ((e: React.ChangeEvent<HTMLInputElement>): void => {
    setState((state: string[]) => ({
      ...state,
      [targetName]: e.target.checked,
    }));
  });

  return (
    <Container style={{ flex: '1', padding: '0rem 0.5rem' }}>
      <input
        type="checkbox"
        checked={value}
        name={targetName}
        className="checkbox"
        onChange={handleCheckboxChange}
      />
    </Container>
  );
};

export default EditCheckBox;
