import React, {PropsWithChildren, ReactElement} from 'react';
import * as Redux from 'react-redux';
import Select from '../Select';
import useFetch from "hooks/useFetch";
import { VerbType } from 'models/Routes';
import {IDataCenter} from "models/Interfaces";

const DataCenters = (props: PropsWithChildren<any>): ReactElement => {
  const { id, state, setState, targetName, disabled, required, hasError } = props;
  const { dataCenterId } = Redux.useSelector((state: any) => state.DataCenterReducer);
  const { data } = useFetch(VerbType.GET, 'DataCenters');

  const handleChange = (e: any): void => {
    setState({
      ...state,
      [targetName]: e ? e.value : '',
    });
  };

  const options =
    data &&
    data.length > 0 &&
    data.map((DataCenter: IDataCenter) => ({
      label: DataCenter.description,
      value: DataCenter.id,
    }));

  const selectedDataCenter = id ? id : dataCenterId;

  return (
    <>
      <Select
        value={parseInt(selectedDataCenter)}
        name={targetName}
        handleChange={handleChange}
        options={options}
        disabled={disabled}
        hasError={hasError}
        required={required}
        placeholder={!data ? 'No options found' : ''}
      />
    </>
  );
};

export default DataCenters;
