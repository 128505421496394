import React from 'react';
import { ContainerRow } from 'styles/styledComponents/styledComponents';
import './viewForm.css';

const PageItemBreak = () : React.ReactElement => {
  return (
    <ContainerRow className="item-container" style={{ background: '#f6f6f6' }}>
      <div style={{ height: '2rem' }} />
    </ContainerRow>
  );
};

export default PageItemBreak;
