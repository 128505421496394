enum AuditCategory {
  SalesforceOrganisationBulkImportCompleted = 'SalesforceOrganisationBulkImportCompleted',
  ExportToPDF = 'ExportToPDF',
  ExportToCSV = 'ExportToCSV',
  EmailSent = 'EmailSent',
  EmailFailed = 'EmailFailed',
  OrganisationAssignedToSite = 'OrganisationAssignedToSite',
  OrganisationUnassignedFromSite = 'OrganisationUnassignedFromSite',
  OrganisationTenantUpdated = 'OrganisationTenantUpdated',
  OrganisationTenantDeleted = 'OrganisationTenantDeleted',
  SiteAssignmentCompleted = 'SiteAssignmentCompleted',
  SiteAssignmentCancelled = 'SiteAssignmentCancelled',
  SiteCreated = 'SiteCreated',
  SiteUpdated = 'SiteUpdated',
  SiteDeleted = 'SiteDeleted',
  PackageCreated = 'PackageCreated',
  PackageUpdated = 'PackageUpdated',
  PackageDeleted = 'PackageDeleted',
  FeatureCreated = 'FeatureCreated',
  FeatureUpdated = 'FeatureUpdated',
  FeatureDeleted = 'FeatureDeleted',
  FeatureAddedToPackage = 'FeatureAddedToPackage',
  FeatureRemovedFromPackage = 'FeatureRemovedFromPackage',
  LicenseCreated = 'LicenseCreated',
  LicenseUpdated = 'LicenseUpdated',
  LicenseDeleted = 'LicenseDeleted',
  OrganisationCreated = 'OrganisationCreated',
  OrganisationUpdated = 'OrganisationUpdated',
  OrganisationDeleted = 'OrganisationDeleted',
  IdentityServerSiteCreated = 'IdentityServerSiteCreated',
  IdentityServerSiteUpdated = 'IdentityServerSiteUpdated',
  IdentityServerSiteDeleted = 'IdentityServerSiteDeleted',
  OrganisationSiteUpdated = 'OrganisationSiteUpdated',
  OrganisationSiteDeleted = 'OrganisationSiteDeleted',
  FeaturesLicensePackageFileImportStarted = 'FeaturesLicensePackageFileImportStarted',
  FeaturesLicensePackageFileImportCompleted = 'FeaturesLicensePackageFileImportCompleted',
}

export default AuditCategory;
