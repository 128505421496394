import { OPEN_MENU, OPEN_PANEL, COLLAPSE_ALL, UPDATE_APIVERSION } from '../ActionTypes/Sidebar';

const initialState = {
  isSidebarOpen: false,
  isPanelOpen: false,
  ApiVersion: '',
};

function SidebarReducer(state = initialState, action) {
  switch (action.type) {
  case OPEN_MENU:
    return {
      ...state,
      isPanelOpen: false,
      isSidebarOpen: true,
    };
  case OPEN_PANEL: {
    return {
      ...state,
      isSidebarOpen: false,
      isPanelOpen: action.payload.isPanelOpen,
    };
  }
  case COLLAPSE_ALL: {
    return {
      ...state,
      isSidebarOpen: false,
      isPanelOpen: false,
    };
  }
  case UPDATE_APIVERSION: {
    return {
      ...state,
      ApiVersion: action.payload.ApiVersion,
    };
  }
  default:
    return state;
  }
}

export default SidebarReducer;
