import moment from 'moment';
import { logger } from './logger';

const DATE_FORMAT = 'DD MMM YYYY';
const DATETIME_FORMAT = 'DD MMM YYYY h:mm:ss a';
export const DATE_PICKER_DATE_FORMAT = 'dd MMM yyyy';

const dateHelper = {
  formatDate: (date) => {
    try {
      if (!date) {
        return null;
      }
      return moment(date).format(DATE_FORMAT);
    } catch (e) {
      logger.error(e, 'dateHelper:formatDate Error formatting data ' + date);
      return date;
    }
  },
  formatDateTime: (date) => {
    try {
      if (!date) {
        return null;
      }
      return moment(date).format(DATETIME_FORMAT) + ' (UTC)';
    } catch (e) {
      logger.error(e, 'dateHelper:formatDateTime Error formatting data ' + date);
      return date;
    }
  },
};

export default dateHelper;
