import React, {PropsWithChildren, ReactElement} from 'react';
import * as Redux from 'react-redux';
import '../PanelHeader/panelHeader.css';
import './panelSubHeader.css';
import { makeStyles } from '@material-ui/core/styles';
import * as Component from 'components/ComponentsIndex';
import { hasAccessGroup } from 'okta/HasAccess';
import { useOktaAuth } from '@okta/okta-react';
import { Mode } from 'models/types';
import {IPreviewPanel, IPreviewPanelState} from "../../../models/Interfaces";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const PanelSubHeader = (props: PropsWithChildren<any>): ReactElement => {
  const {
    handleSave,
    handleCancel,
    handleEdit,
    handleDelete,
    deleteAccess,
    editAccess,
    hideEditButton = false,
    hideDeleteButton = false,
    description,
    additionalButton,
    icons,
    subTitle
  } = props;
  const { id, type } = Redux.useSelector<IPreviewPanelState>((state) => state.PreviewPanelReducer) as IPreviewPanel;
  const classes = useStyles();
  const { authState } = useOktaAuth() || {};
  const canEdit = editAccess ? hasAccessGroup(editAccess, authState) : false;
  const canDelete = deleteAccess ? hasAccessGroup(deleteAccess, authState) : false;
  const iconStyles = { marginLeft: '0px', fontWeight: '600' } as any

  return (
    <>
      <div className={`nav-panel-subheader ${classes.root}`}>
        {description && (
          <div className="alignLeft" style={iconStyles}>
            {description}
            {icons && <div style={{ marginLeft: '10px' }}>{icons}</div>}
            {subTitle && <div style={{ marginLeft: '10px' }}>{subTitle}</div>}
          </div>
        )}
        <div className="alignRight">
          {type === Mode.View && (
            <>
              {additionalButton && additionalButton()}
              {canDelete && !hideDeleteButton && (
                <Component.Button handleClick={handleDelete} color="primary" label="Delete" />
              )}
              {canEdit && !hideEditButton && (
                <Component.ButtonColoured handleClick={handleEdit} color="primary" label="Edit" />
              )}
            </>
          )}
          {type === Mode.Add && (
            <>
              {additionalButton && additionalButton()}
              <Component.Button handleClick={handleCancel} color="primary" label="Cancel" />
              <Component.ButtonColoured handleClick={handleSave} color="primary" label="Save" />
            </>
          )}
          {type === Mode.Edit && (
            <>
              {additionalButton && additionalButton()}
              {(id || id > 0) && (
                <Component.Button handleClick={handleCancel} color="primary" label="Cancel" />
              )}
              <Component.ButtonColoured handleClick={handleSave} color="primary" label="Save" />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PanelSubHeader;
