import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import cx from 'classnames';
import merge from 'deepmerge';
import defaultTranslations from './translations';
import {
  CloseButton,
  Container,
  Content,
  FormElement,
  Header,
  Label,
  SlackFeedback as StyledSlackFeedback,
  SubmitButton,
  Tabs,
} from './styles';
import defaultTheme from './default';

const BUG = 'bug';
const IMPROVEMENT = 'improvement';
const FEATURE = 'feature';

class SlackFeedback extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      sending: false,
      sent: false,
      error: false,
      selectedType: props.defaultSelectedType || this.props.feedbackTypes[0].value,
      message: '',
    };

    // Create reference to container
    this.SlackFeedback = React.createRef();
  }

  componentDidUpdate(previousProps, previousState) {
    if (this.props.open !== previousState.open) {
      this.activate();
    }
  }

  translate = (key) => {
    const { translations } = this.props;

    return typeof translations === 'object' && key in translations
      ? translations[key]
      : defaultTranslations[key] || '';
  };

  handleChange = (key) => (event) =>
    this.setState({
      [key]: event.target.value,
    });

  toggle = () => {
    if (this.state.open) {
      this.close();
    } else {
      this.activate();
    }
  };

  activate = () => {
    this.setState({ open: true });
    document.addEventListener('click', this.handleClickOutside);
  };

  handleClickOutside = (event) => {
    if (event.defaultPrevented) return;

    var classes = event.target.classList;
    if (!classes || !classes.contains('FeedbackIcon'))
      classes = event.target.parentElement?.classList;

    var isIconClick = false;
    if (classes?.contains('FeedbackIcon')) isIconClick = true;

    if (
      this.SlackFeedback &&
      this.SlackFeedback.current &&
      !this.SlackFeedback.current.contains(event.target) &&
      !isIconClick
    ) {
      this.close();
    }
  };

  close = () => {
    this.setState(
      {
        open: false,
        message: '',
      },
      () => {
        this.props.onClose();
      },
    );
    document.removeEventListener('click', this.handleClickOutside);
  };

  selectType = (value) => () =>
    this.setState({
      selectedType: value,
    });

  resetSentState = () => {
    this.setState(
      {
        message: '',
      },
      () => {
        setTimeout(() => {
          this.setState({ sent: false });
          this.close();
        }, this.props.sentTimeout);
      },
    );
  };

  onSubmitSuccess = () => {
    this.setState(
      {
        sending: false,
        sent: true,
        error: false,
        message: '',
      },
      () => this.resetSentState(),
    );
  };

  onSubmitError = (error) =>
    this.setState(
      {
        sending: false,
        error: this.determineErrorType(error),
      },
      () => {
        setTimeout(() => {
          this.setState({ error: null });
        }, this.props.errorTimeout);
      },
    );

  determineErrorType = (err) => {
    if (!err) return this.translate('error.unexpected');

    if (typeof err === 'string') return err;

    switch (err.status) {
    case 400:
      return this.translate('error.badrequest');
    case 403:
      return this.translate('error.forbidden');
    case 404:
      return this.translate('error.notfound');
    case 410:
      return this.translate('error.archived');
    case 500:
      return this.translate('error.internal');
    default:
      return this.translate('error.unexpected');
    }
  };

  send = () => {
    const { selectedType } = this.state;
    this.setState({ sending: true });

    const payload = {
      username: this.props.user,
      type: selectedType,
      text: this.state.message,
    };

    // Submit the payload
    this.props.onSubmit(payload, this.onSubmitSuccess.bind(this), this.onSubmitError.bind(this));
  };

  render() {
    // Return nothing if the component has been disabled
    if (this.props.disabled) return null;

    const { open, sending, sent, error, selectedType } = this.state;

    let submitText = this.translate('submit.text');

    if (sent) submitText = this.translate('submit.sent');
    if (sending && !sent) submitText = this.translate('submit.sending');
    if (error) submitText = error;

    const theme = merge(defaultTheme, this.props.theme);

    return (
      <ThemeProvider theme={theme}>
        <StyledSlackFeedback ref={this.SlackFeedback} className={cx({ active: open })}>
          <Container className={cx('fadeInUp', { active: open })}>
            <Header>
              {this.translate('header.title')}
              <CloseButton className="close" onClick={this.close}>
                {this.translate('close')}
              </CloseButton>
            </Header>

            <Content>
              <Label>{this.translate('label.type')}</Label>
              <Tabs>
                {this.props.feedbackTypes.map((type) => (
                  <li
                    key={type.value}
                    className={cx({
                      selected: selectedType === type.value,
                    })}
                    title={type.label}
                    onClick={this.selectType(type.value)}
                  >
                    {type.label}
                  </li>
                ))}
              </Tabs>

              <Label>{this.translate('label.message')}</Label>
              <FormElement
                as="textarea"
                name="message"
                value={this.state.message}
                placeholder={this.translate('placeholder')}
                onChange={this.handleChange('message')}
              />

              <SubmitButton
                disabled={sending || !this.state.message}
                className={cx({
                  sent,
                  error,
                })}
                onClick={this.send}
              >
                {submitText}
              </SubmitButton>
            </Content>
          </Container>
        </StyledSlackFeedback>
      </ThemeProvider>
    );
  }
}

SlackFeedback.propTypes = {
  defaultSelectedType: PropTypes.string,
  disabled: PropTypes.bool,
  errorTimeout: PropTypes.number,
  feedbackTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  open: PropTypes.bool,
  activate: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  sentTimeout: PropTypes.number,
  showIcon: PropTypes.bool,
  theme: PropTypes.object,
  translations: PropTypes.object,
  user: PropTypes.string,
};

SlackFeedback.defaultProps = {
  defaultSelectedType: null,
  disabled: false,
  errorTimeout: 8 * 1000,
  feedbackTypes: [
    { value: BUG, label: defaultTranslations['feedback.type.bug'] },
    {
      value: IMPROVEMENT,
      label: defaultTranslations['feedback.type.improvement'],
    },
    { value: FEATURE, label: defaultTranslations['feedback.type.feature'] },
  ],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onOpen: () => {},
  sentTimeout: 2 * 1000,
  showIcon: true,
  theme: defaultTheme,
  translations: defaultTranslations,
  user: '',
};

SlackFeedback.defaultTheme = defaultTheme;

export default SlackFeedback;
