import React from 'react';
import * as ViewComponent from 'views/Common/ViewForm/ViewIndex';

interface ITenant {
  url?: string;
  status?: string;
}

const TenantSummaryView = (props: { minWidth: string; Tenant: ITenant; nolastItemBorder: boolean; }): React.ReactElement => {
  const { minWidth, Tenant, nolastItemBorder } = props;
  const summary = Tenant?.url + ' (' + Tenant?.status + ')';
  return (
    <ViewComponent.PageItem name="" minWidth={minWidth} value={summary} nolastItemBorder={nolastItemBorder} />
  );
};

export default TenantSummaryView;
