import React from 'react';
import { ContainerRow } from 'styles/styledComponents/styledComponents';
import { TextArea } from './EditIndex';

interface IFields { 
  instructions?: string | undefined;
}

interface IUnassignTenant { 
  minWidth? : string | undefined;
  state? : IFields;
  setState: React.Dispatch<React.SetStateAction<unknown>>;
}

const UnassignTenant = (props : IUnassignTenant) : React.ReactElement => {
  const { minWidth, state, setState } = props;
  return (
    <>      
      <ContainerRow className="item-container fullBorder">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Data Instructions
        </div>
        <TextArea
          value={state?.instructions}
          setState={setState}
          targetName="instructions"
        />
      </ContainerRow>
    </>
  );
};

export default UnassignTenant;
