import React from 'react';
import { editFormStyles, FormStatus } from './Common';

interface IEditTextField { 
  value?: string | number | undefined;
  setState?: React.Dispatch<React.SetStateAction<unknown>>;
  targetName?: string;
  required?: boolean;
  status?: string;
  disabled?: boolean;
  upperCase?: boolean;
  minWidth?: string;
  maxLength?: number;
  type?: string;
}

const EditTextField = (props: IEditTextField): React.ReactElement => {
  const { value, setState, targetName, required, status, disabled, upperCase, minWidth, maxLength, type } = props;
  const classes = editFormStyles();

  const handleChange = ((e: React.ChangeEvent<HTMLInputElement>): void => {
    e.persist();
    setState && setState((state: string[]) => {
      return {
        ...state,
        [e.target.name]: upperCase ? e.target.value.toUpperCase() : e.target.value,
      };
    });
  });

  return (
    <>
      {required ? (
        <input
          name={targetName}
          className={`${classes.textField} ${!value && FormStatus && (status === FormStatus.DIRTY) ? classes.error : ''}`}
          onChange={handleChange}
          value={value}
          style={{ minWidth: minWidth }}
          maxLength={maxLength}
          type={type ? type : 'text'}
        />
      ) : (
        <input
          name={targetName}
          className={`${classes.textField}`}
          onChange={handleChange}
          disabled={!!disabled}
          value={value}
          style={{ minWidth: minWidth }}
          maxLength={maxLength}
          type={type ? type : 'text'}
        />
      )}
    </>
  );
};

export default EditTextField;
