import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export enum MessageType {
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warn = 'warn',
}

export enum Message {
  SiteAssignedPending = 'Site Assigned as Pending',
  SiteAssignedPendingFromUnassigned = 'Site Assigned as Pending (Unassigned)',
  FeatureAdded = 'Feature Added',
  LicenseAdded = 'License Added',
  PackageAdded = 'Package Added',
  FeatureUpdated = 'Feature Updated',
  LicenseUpdated = 'License Updated',
  PackageUpdated = 'Package Updated',
  FeatureDeleted = 'Feature Deleted',
  LicenseDeleted = 'License Deleted',
  PackageDeleted = 'Package Deleted',
  ClientDataImported = 'Client Data Imported',
  IDSSiteAdded = 'IDSSite Added',
  IDSSiteUpdated = 'IDSSite Updated',
  IDSSiteDeleted = 'IDSSite Deleted',
  SiteAdded = 'Site Added',
  SiteUpdated = 'Site Updated',
  SiteUpdatedRequest = 'Site Update Request sent',
  SiteDeleted = 'Site Deleted',
  SelectedSiteDeleted = 'Selected Site Has Been Deleted',
  SiteAssigned = 'Site Assigned',
  LicenseUpdateCompleted = 'License Update Completed',
  LicenseUpdateCancelled = 'License Update Cancelled',
  SiteUnassigned = 'Site Unassigned',
  SiteAssignmentCancelled = 'Site Assignment Cancelled',
  SiteUnAssignmentCancelled = 'Site Unassignment Cancelled',
  SiteUnassignSite = 'Live Site set to Pending (Unassigned)',
  SiteUnassignErrorSiteId = 'Incorrect Site Id for Unassignment',
  SiteUnassignErrorClientId = 'Incorrect Client Id for Unassignment',
  SiteUnassignErrorClientAndSiteId = 'Incorrect Site Id and Client Id for Unassignment',
  CopyToClipboard = 'Copied to Clipboard',
}

toast.configure({
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 4000,
  transition: Slide,
  pauseOnHover: true,
});

export default function notify(message: string, type: MessageType): void {
  switch (type) {
  case MessageType.Success:
    toast.success(message);
    break;
  case MessageType.Error:
    toast.error(message);
    break;
  case MessageType.Info:
    toast.info(message);
    break;
  case MessageType.Warn:
    toast.warn(message);
    break;
  default:
    toast(message);
    break;
  }
}
