import {useContext, useEffect, useRef, useState} from 'react'
import {useOktaAuth} from '@okta/okta-react'
import {ApiHelperContext} from 'context/ApiHelperContext'
import {IApiHelperContext, ISwrResult} from "models/Interfaces";

const useFetch = (method: string, url: string, reload?: boolean): ISwrResult => {
  const [data, setData] = useState(null),
    [error, setError] = useState(null),
    [loading, setLoading] = useState(false),
    { authState, oktaAuth } = useOktaAuth() || {}
  
  const isAuth = authState?.isAuthenticated
  const context: IApiHelperContext | undefined  = useContext(ApiHelperContext)
  const apiHelper = context?.state?.apiHelper

  const _isMounted = useRef(true)
  
  useEffect((): any => {
    if (!url) return {}
    if (!isAuth && oktaAuth) oktaAuth.signOut()

    setLoading(true)
    
    const successCallback = (data: any): void => {
      if (_isMounted?.current) {
        setData(data)
        setError(null)
        setLoading(false)
      }
    }
    
    const errorCallback = (error: any): void => {
      if (_isMounted?.current) {
        setError(error)
        setData(null)
        setLoading(false)
      }
    }

    apiHelper?.request(method, url, null, successCallback, errorCallback)
    
    return (): void => {
      setLoading(false)
    }
  }, [method, url, reload, isAuth, oktaAuth, apiHelper])
  
  return { data, error, loading }
}

export default useFetch
