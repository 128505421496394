import React, {PropsWithChildren} from 'react';
import OrganisationTenantView from 'views/Common/ViewForm/OrganisationTenantView';
import {Props} from "models/types";

const OrganisationTenantItems: React.FC<Props> = (props: PropsWithChildren<any>) => {
  const {
    state: Tenant,
    nolastItemBorder,
    summaryOnly,
    setSummary,
    minWidth = '10rem',
    editMode,
  } = props;

  return (
    <>
      <OrganisationTenantView
        Tenant={Tenant}
        minWidth={minWidth}
        nolastItemBorder={nolastItemBorder}
        summaryOnly={summaryOnly}
        setSummary={setSummary}
        editMode={editMode}
      />
    </>
  );
};

export default OrganisationTenantItems;
