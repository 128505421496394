import React, { PropsWithChildren, ReactElement, useState, useEffect } from 'react';
import * as Redux from 'react-redux';
import { setupPreviewPanel } from 'store/Actions/PreviewPanel';
import { refreshSearchResults } from 'store/Actions/SearchResults';
import * as Component from 'components/ComponentsIndex';
import FeatureAddOns from './FeatureAddOns';
import { ContainerRow } from 'styles/styledComponents/styledComponents';
import { FormDialog } from 'components/Dialog/DialogIndex';
import { useOktaAuth } from '@okta/okta-react';
import { ADMIN, SALES, PRODOPS, hasAccessGroup, IMPLEMENTATION } from 'okta/HasAccess';
import './viewForm.css';
import dateHelper from 'lib/dateHelper';
import { WriteTenantStatus } from './Common';
import { ApiHelperContext } from 'context/ApiHelperContext';
import TenantStatus from 'models/Lists/TenantStatus';
import Route from 'models/Routes';
import notify, { MessageType, Message } from 'components/Notifications/toastify';
import { ErrorDialog, handleError, useErrorState } from 'error/HandleErrors';
import { Mode } from 'models/types';
import { WriteSFSDataCenter } from 'views/Common/ViewForm/Common';
import DataCenter from './DataCenter';
import {IError, IPreviewPanel, IPreviewPanelState} from "models/Interfaces";

const OrganisationTenantView = (props: PropsWithChildren<any>): ReactElement => {
  const { minWidth, Tenant, setSummary, editMode } = props;
  const { id, refreshPanel } = Redux.useSelector<IPreviewPanelState>((state) => state.PreviewPanelReducer) as IPreviewPanel;
  const { authState } = useOktaAuth() || {};
  const initState = {
    tenantStatus: TenantStatus.Pending,
    assignedByName: authState?.idToken?.claims?.name,
    assignedByEmail: authState?.idToken?.claims?.email,
  };
  const [UnassignSite, setUnassignSite] = React.useState(initState);
  const dispatch = Redux.useDispatch();
  const context = React.useContext(ApiHelperContext);
  const apiHelper = context?.state.apiHelper;
  const { errorDialog, errorMessage, errorTitle, setErrorDialog, setErrorMessage, setErrorTitle } =
    useErrorState();

  React.useEffect(() => {
    if (Tenant?.url) {
      setSummary && setSummary(`Site - ${Tenant.url}`);
    }
  }, [setSummary, Tenant]);

  const [openFormDialog, setOpenFormDialog] = React.useState(false);

  const handleOpenDialog = (): void => {
    setOpenFormDialog(true);
  };

  const handleCloseDialog = (): void => {
    setOpenFormDialog(false);
    setErrorDialog(false);
  };

  function handleUnassignSite(): void {
    if (apiHelper) {
      apiHelper.post(
        `Organisations/${id}/tenant/${Tenant.tenantId}/unassign`,
        UnassignSite,
        callbackSuccessFunc,
        callbackErrorFunc,
      );
    }
    setOpenFormDialog(false);
    setUnassignSite(initState);
  }

  function callbackErrorFunc(error: IError): void {
    handleError(error, setErrorTitle, setErrorMessage, setErrorDialog);
  }

  const callbackSuccessFunc = (): void => {
    dispatch(setupPreviewPanel(Route.Clients, Mode.View, id, !refreshPanel));
    dispatch(refreshSearchResults(!refreshPanel));
    notify(Message.SiteAssignedPendingFromUnassigned, MessageType.Success);
  };

  const canUnassign = hasAccessGroup([ADMIN, PRODOPS, SALES, IMPLEMENTATION], authState);

  const [AdminUser, setAdminUser] = useState('');  

  useEffect(() => {
    if (Tenant) {
      const adminUser = Tenant?.adminUser;
      const adminUserName = Tenant?.adminUserName;
      const details = adminUserName ? adminUserName + ' (' + adminUser + ')' : adminUser; 
      setAdminUser(details);
    }
  }, [Tenant]);

  return (
    <>
      <ContainerRow className="item-container">
        {canUnassign && !editMode && Tenant?.status === 'Live' && (
          <div className="item-name" style={{ minWidth: minWidth }}>
            <Component.ButtonColoured
              handleClick={handleOpenDialog}
              color="primary"
              label="Unassign Site"
            />
          </div>
        )}
        <div
          className="item-name"
          style={{
            minWidth: minWidth,
            paddingTop: !editMode && Tenant?.status === 'Live' ? '15px' : undefined,
          }}
        >
          Site Status
        </div>
        <div
          className="values-container"
          style={{ paddingTop: !editMode && Tenant?.status === 'Live' ? '6px' : undefined }}
        >
          <div className="item-value">{WriteTenantStatus(Tenant?.status)}</div>
        </div>
      </ContainerRow>
      <ContainerRow className="item-container">
        {Tenant?.status === 'Pending' && (
          <>
            <div className="item-name" style={{ minWidth: minWidth }}>
              Admin User
            </div>
            <div className="values-container">
              <div className="item-value">{AdminUser || 'None'}</div>
            </div>
          </>
        )}
      </ContainerRow>
      <Component.Accordion
        title="License"
        itemId={Tenant?.tenantId}
        items="LicenseWithUsage"
        minWidth={minWidth}
      />
      <Component.Accordion
        title="Package"
        itemId={Tenant?.packageId}
        nolastItemBorder={true}
        items="Package"
        minWidth={minWidth}
      />
      <DataCenter
        targetName="dataCenterId"
        id={Tenant?.dataCenterId}
        minWidth={minWidth}
      />
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          SFS Data Center
        </div>
        <div className="values-container">
          <div className="item-value">{WriteSFSDataCenter(Tenant?.sfsDataCenter)}</div>
        </div>
      </ContainerRow>
      <FeatureAddOns Tenant={Tenant} minWidth={minWidth} />
      <ContainerRow className="item-container no-last-border">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Last Updated
        </div>
        <div className="values-container">
          <div className="item-value">{dateHelper.formatDateTime(Tenant?.lastUpdated)}</div>
        </div>
      </ContainerRow>
      <FormDialog
        open={openFormDialog}
        handleClose={handleCloseDialog}
        handleConfirm={handleUnassignSite}
        title="Unassign Site"
        formComponent="unassignTenant"
        state={UnassignSite}
        setState={setUnassignSite}
        height="200px"
        maxwidth="sm"
      />
      <ErrorDialog
        errorDialog={errorDialog}
        handleCloseDialog={handleCloseDialog}
        errorTitle={errorTitle}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default OrganisationTenantView;
