import React, {PropsWithChildren, ReactElement} from 'react';
import { useFetch } from 'hooks';
import * as ViewComponent from './ViewIndex';
import { VerbType } from 'models/Routes';

const SitesForIdsSite = (props: PropsWithChildren<any>): ReactElement => {
  const { idsSiteId, minWidth, nolastItemBorder } = props;
  const [Sites, setSites] = React.useState<any>();
  const { data } = useFetch(
    VerbType.GET,
    idsSiteId ? `IdentityServerSites/${idsSiteId}/tenants` : '',
  );
  React.useEffect(() => {
    setSites(data);
  }, [data]);

  return (
    <>
      {Sites && Sites.length > 0 ? (
        Sites.map((Site: any, index: any) => (
          <ViewComponent.TenantSummaryView
            key={index}
            minWidth={minWidth}
            Tenant={Site}
            nolastItemBorder={Sites.length - 1 === index}
          />
        ))
      ) : (
        <ViewComponent.PageItem
          value="No Sites assigned"
          minWidth={minWidth}
          nolastItemBorder={nolastItemBorder}
        />
      )}
    </>
  );
};
export default SitesForIdsSite;
