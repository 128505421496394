import React from 'react';
import { Link } from 'react-router-dom';
import './error.css';
import Button from '@material-ui/core/Button';
import { useGetDefaultRoute } from 'layout/Sidebar/SidebarHelper';

const PageNotFound = (): React.ReactElement => {
  const Path = useGetDefaultRoute();
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="error-template">
            <h2>Page Not Found</h2>
            <div className="error-details">
              Sorry, an error has occurred, requested page not found.
            </div>
            <div className="error-actions">
              <Link to={Path}>
                <Button variant="contained" color="primary">
                  Home
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PageNotFound;