import React from 'react';
import { useFetch } from 'hooks';
import { VerbType } from 'models/Routes';
import { IFeature } from 'models/Interfaces';

const EMPTY_DESC = "None";

function featuresToArray(featuresArray: []): IFeature[] {
  if (!featuresArray || featuresArray.length <= 0) return [];
  let features: any = [];
  if (Array.isArray(featuresArray) && featuresArray.length > 0 ) {
    features = featuresArray.map((Feature: IFeature) => ({
      label: Feature.label || Feature.Label,
      value: Feature.value || Feature.Value,
    }))
  }
  return features;
}

export const SetValue = (value: string | number | undefined, type: string): string | number => {
  if (!value && type === 'string') return value || EMPTY_DESC;
  if (!value && (type === 'object' || type === 'boolean')) return value || "0";
  if (!value && typeof value === 'number') return value || 0;
  return value || EMPTY_DESC;
}

export const GetPackageDescription = (code : string | number | undefined): string => {
  const { data } = useFetch(VerbType.GET, code && code !== EMPTY_DESC ? `Packages/${code}` : '' );
  const [Description, setDescription] = React.useState<string>();

  React.useEffect(() => {
    setDescription(data ? data?.name + ' (' + data?.code + ')' : '');
  }, [data]);

  return Description || EMPTY_DESC;
}

export const NewAddonFeatures = (CurrentAddOnFeatures: string, NewAddOnFeatures: string): IFeature[] => {
  if (!CurrentAddOnFeatures) return JSON.parse(NewAddOnFeatures) || [];
  let CurrentAddOnFeaturesArr = JSON.parse(CurrentAddOnFeatures);
  const NewAddOnFeaturesArr = NewAddOnFeatures ? JSON.parse(NewAddOnFeatures) : [];

  // Check for any double encoded corrupt strings
  if (typeof CurrentAddOnFeaturesArr === 'string') CurrentAddOnFeaturesArr = JSON.parse(CurrentAddOnFeatures);

  const NewFeaturesArray = NewAddOnFeaturesArr && NewAddOnFeaturesArr.length > 0 && NewAddOnFeaturesArr
    .filter(({ value: newFeatureId }: any) => !CurrentAddOnFeaturesArr
      .some(({ value: oldFeatureId }: any) => oldFeatureId === newFeatureId));

  return featuresToArray(NewFeaturesArray);
}

export const RemoveAddonFeatures = (CurrentAddOnFeatures: string, NewAddOnFeatures: string): IFeature[] => {
  if (!CurrentAddOnFeatures) return [];
  let CurrentAddOnFeaturesArr = JSON.parse(CurrentAddOnFeatures);
  const NewAddOnFeaturesArr = NewAddOnFeatures ? JSON.parse(NewAddOnFeatures) : [];
  if (!NewAddOnFeaturesArr || NewAddOnFeaturesArr.length <= 0) return featuresToArray(CurrentAddOnFeaturesArr);

  // Check for any double encoded corrupt strings
  if (typeof CurrentAddOnFeaturesArr === 'string') CurrentAddOnFeaturesArr = JSON.parse(CurrentAddOnFeatures);

  const RemovedFeatures =  CurrentAddOnFeaturesArr && CurrentAddOnFeaturesArr.length > 0 && CurrentAddOnFeaturesArr
    .filter(({ value: newFeatureId }: any) => !NewAddOnFeaturesArr
      .some(({ value: oldFeatureId }: any) => oldFeatureId === newFeatureId));

  return featuresToArray(RemovedFeatures);
}