import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: '#333',
    flex: 1,
    width: '100%',
    border: '1px #A9A9A9 solid',
    padding: '0.5rem',
    fontSize: '14px',
    fontFamily: theme.typography.fontFamily,
  },
}));

export default function WriteTooltip(props) {
  const classes = useStyles();
  return (
    <Tooltip
      title={props.title}
      onClose={props.onClose || undefined}
      classes={{
        tooltip: classes.tooltip,
      }}
    >
      {props.children}
    </Tooltip>
  );
}
