import React, {PropsWithChildren, ReactElement} from 'react';
import { useFetch } from 'hooks';
import * as Component from 'components/ComponentsIndex';
import { ContainerColumn } from 'styles/styledComponents/styledComponents';
import PageItemList from './PageItemList';
import DataNotFound from 'error/DataNotFound';
import { VerbType } from 'models/Routes';

const PackagesForFeature = (props: PropsWithChildren<any>): ReactElement => {
  const { featureCode, id, nolastItemBorder, minWidth = '10rem' } = props;
  const { data, error, loading } = useFetch(
    VerbType.GET,
    featureCode ? `Features/${featureCode}/Packages` : '',
  );
  const [Packages, setPackages] = React.useState();

  React.useEffect(() => {
    setPackages(data);
  }, [data, id]);

  if (loading) return <Component.Loading />;
  if (error) return <DataNotFound />;

  return (
    <>
      <ContainerColumn style={{ height: 'calc(100% - 3rem)' }}>
        <PageItemList
          name="Packages"
          itemValue="name"
          items={Packages}
          nolastItemBorder={nolastItemBorder}
          minWidth={minWidth}
        />
      </ContainerColumn>
    </>
  );
};

export default PackagesForFeature;
