import { REFRESH_RESULTS, SET_PATH } from '../ActionTypes/SearchResults';

const initialState = {
  reloadSearch: false,
  startTab: 0,
};

function SearchResultsReducer(state = initialState, action) {
  switch (action.type) {
  case REFRESH_RESULTS:
    return {
      ...state,
      reloadSearch: action.payload.reloadSearch,
      startTab: action.payload.startTab,
    };
  case SET_PATH: {
    return {
      ...state,
      path: action.payload.path,
      pathName: action.payload.pathName,
    };
  }
  default:
    return state;
  }
}

export default SearchResultsReducer;
