import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogActions from '@material-ui/core/DialogActions';

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    '& .MuiPaper-root': {
      borderRadius: '0px',
    },
  },
  formRoot: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  titleBar: {
    margin: 0,
    fontSize: '16px',
    height: '3rem',
    backgroundColor: '#f2f2f2',
    padding: '12px 16px',
    display: 'flex',
    flexDirection: 'row',
    flexShrink: '0',
    alignItems: 'center',
  },
  content: {
    padding: theme.spacing(2),
    '& .MuiTypography-body1': {
      fontSize: '14px',
      color: '#333',
    },
  },
  paper: {
    width: '80%',
  },
  closeButton: {
    position: 'absolute',
    right: '3px',
    top: '5px',
    color: theme.palette.grey[500],
  },
}));

export const DialogTitleBar = (props) => {
  const { children, onClose } = props;
  const classes = useStyles();
  return (
    <DialogTitle disableTypography className={classes.titleBar}>
      {children}
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <i className="i-bpow-cross" style={{ fontSize: '1rem' }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    '& .MuiTypography-body1': {
      fontSize: '14px',
      color: '#333',
    },
  },
}))(MuiDialogContent);

export const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
