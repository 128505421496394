import React from 'react';
import * as Redux from 'react-redux';
import * as Component from 'components/ComponentsIndex';
import {
  SidebarContainer,
  ContainerColumn,
  SidebarItem,
} from 'styles/styledComponents/styledComponents';
import SidebarIcon from './Icons/Icon';
import SidebarText from './SlidingText/SidebarText';
import AboutPanel from './PopOutPanel/About/AboutPanel';
import { Link } from 'react-router-dom';
import { openMenu, openSidebarPanel, hideMenu } from 'store/Actions/Sidebar';
import { useOktaAuth } from '@okta/okta-react';
import shortid from 'shortid';
import { setPath } from 'store/Actions/SearchResults';
import { hasAccessGroup } from 'okta/HasAccess';
import DiligentIcon from './Icons/Diligent/DiligentIcon';
//import SlackIcon from './Icons/Feedback/FeedbackIcon';
import useSidebarItems from './SidebarItems';
import { useGetRouteName } from './SidebarHelper';

const Sidebar = (props: React.PropsWithChildren<any>): React.ReactElement => {
  const { isSidebarOpen, isPanelOpen } = Redux.useSelector((state: any) => state.SidebarReducer);
  const [ActivePath, setActivePath] = React.useState<string>();
  const { icons } = useSidebarItems();
  const { authState } = useOktaAuth() || {};
  const dispatch = Redux.useDispatch();
  const Path = props.history.location.pathname;
  const PathName = useGetRouteName(Path);

  React.useEffect(() => {
    setActivePath(PathName);
    dispatch(setPath(Path, PathName));
  }, [Path, PathName, authState, dispatch]);

  return (
    <>
      <SidebarContainer className={`main-nav ${isSidebarOpen && 'expanded has-shadow'}`}>
        <ContainerColumn className="full-size menu-items-outer-container">
          <div className="full-width">
            <SidebarItem
              key={shortid.generate()}
              className={`nav-item-wrapper ${isSidebarOpen && 'expanded'}`}
              onClick={(): void => {
                !isSidebarOpen ? dispatch(openMenu()) : dispatch(hideMenu());
              }}
            >
              <SidebarIcon icon="i-bpow-burger-menu" marked={false} />
              <SidebarText text="" />
            </SidebarItem>
            {icons &&
              icons.length > 0 &&
              icons.map((item) => {
                return (
                  authState?.isAuthenticated &&
                  hasAccessGroup(item.accessOnly, authState) && (
                    <SidebarItem
                      key={shortid.generate()}
                      className="nav-item-wrapper"
                      onClick={(): void => {
                        dispatch(hideMenu());
                      }}
                    >
                      <Component.Tooltip title={item.name}>
                        <Link
                          to={item.route}
                          className={`nav-item-wrapper ${isSidebarOpen && 'expanded'} ${
                            item.name === ActivePath && 'current'
                          }`}
                        >
                          <SidebarIcon
                            icon={item.icon}
                            marked={item.name === ActivePath}
                          />
                          <SidebarText text={item.name} 
                            onClick={(): void => {
                              dispatch(setPath(item.route, item.name));
                            }}
                          />
                        </Link>
                      </Component.Tooltip>
                    </SidebarItem>
                  )
                );
              })}
          </div>
          <div className="full-width">
            {//authState?.isAuthenticated && (
            //<SlackIcon hideMenu={hideMenu} isSidebarOpen={isSidebarOpen} />
            //)
            }
            <DiligentIcon
              isPanelOpen={isPanelOpen}
              hideMenu={hideMenu}
              isSidebarOpen={isSidebarOpen}
              openSidebarPanel={openSidebarPanel}
            />
          </div>
        </ContainerColumn>
      </SidebarContainer>
      <AboutPanel isPanelOpen={isPanelOpen} />
    </>
  );
}

export default Sidebar;
