import React, {PropsWithChildren} from 'react';
import * as Redux from 'react-redux';
import { setupPreviewPanel, hidePreviewPanel } from 'store/Actions/PreviewPanel';
import { refreshSearchResults } from 'store/Actions/SearchResults';
import PanelSubHeader from 'views/Common/PanelSubHeader/PanelSubHeader';
import { useFetch } from 'hooks';
import * as Component from 'components/ComponentsIndex';
import DataNotFound from 'error/DataNotFound';
import { ContainerColumnPanelView } from 'styles/styledComponents/styledComponents';
import 'views/Common/PanelHeader/panelHeader.css';
import { AlertDialog } from 'components/Dialog/DialogIndex';
import PackageView from 'views/Common/ViewForm/PackageView';
import { ADMIN } from 'okta/HasAccess';
import { ErrorDialog, handleError, useErrorState } from 'error/HandleErrors';
import { ApiHelperContext } from 'context/ApiHelperContext';
import notify, { MessageType, Message } from 'components/Notifications/toastify';
import { Props , Mode } from "models/types";
import { IPreviewPanelState } from "models/Interfaces";
import { IError, IPreviewPanel } from "models/Interfaces";
import { VerbType } from 'models/Routes';

const ViewPackage: React.FC<Props> = (props: PropsWithChildren<any>) => {
  const { minWidth = '10rem', nolastItemBorder } = props;
  const { id, section, refreshPanel } = Redux.useSelector<IPreviewPanelState>((state) => state.PreviewPanelReducer) as IPreviewPanel;
  const { data, error, loading } = useFetch(VerbType.GET, `packages/${id}`, refreshPanel);
  const [Package, setPackage] = React.useState();
  const [openDialog, setOpenDialog] = React.useState(false);
  const dispatch = Redux.useDispatch();
  const context = React.useContext(ApiHelperContext)
  const apiHelper = context?.state.apiHelper

  const { errorDialog, errorMessage, errorTitle, setErrorDialog, setErrorMessage, setErrorTitle } = useErrorState();

  React.useEffect(() => {
    setPackage(data);
  }, [data, id, refreshPanel]);

  if (loading) return <Component.Loading />;
  if (id > 0 && !Package) return <DataNotFound />;
  if (error) return <DataNotFound />;

  function handleEdit(): void {
    dispatch(setupPreviewPanel(section, Mode.Edit, id));
  }

  function handleDeleteItem(): void {
    if(apiHelper) {
      apiHelper.delete(`Packages/delete/${id}`, handleDeleteCallBack, callbackErrorFunc);
    }
  }

  function callbackErrorFunc(error: IError): void {
    handleError(error, setErrorTitle, setErrorMessage, setErrorDialog);
  }

  function handleDeleteCallBack(): void {
    dispatch(hidePreviewPanel());
    dispatch(refreshSearchResults(true));
    notify(Message.PackageDeleted, MessageType.Info);
  }

  const handleOpenDialog = (): void => {
    setOpenDialog(true);
  };

  const handleCloseDialog = (): void => {
    setOpenDialog(false);
  };

  const deleteAccess = [ADMIN];
  const editAccess = [ADMIN];

  return (
    <>
      <div className="search-title-nav">
        <PanelSubHeader
          handleEdit={handleEdit}
          handleDelete={handleOpenDialog}
          deleteAccess={deleteAccess}
          editAccess={editAccess}
          description='Details'
        />
      </div>
      <ContainerColumnPanelView style={{ height: 'calc(100% - 3rem)', overflowY: 'auto' }}>
        <div className="entity-details-content full-width">
          <PackageView minWidth={minWidth} Package={Package} nolastItemBorder={nolastItemBorder} />
        </div>
      </ContainerColumnPanelView>
      <AlertDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        handleConfirm={handleDeleteItem}
        title="Delete Package"
        body="Are you sure you wish to delete this item?"
      />
      <ErrorDialog
        errorDialog={errorDialog} 
        handleCloseDialog={handleCloseDialog}
        errorTitle={errorTitle}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default ViewPackage;
