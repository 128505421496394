import React from 'react';
import { ContainerRow } from 'styles/styledComponents/styledComponents';
import { TextField, CheckBox, DatePicker } from './EditIndex';
import { ContractStatus } from './Lists/ListsIndex';
import { IOrganisationEdit } from 'models/Interfaces';

const OrganisationEdit = (props: IOrganisationEdit) : React.ReactElement => {
  const { id, minWidth, Organisation, status, setOrganisation, nolastItemBorder } = props;
  return (
    <>
      <ContainerRow className="item-container">
        <div className="item-name fieldRequired" style={{ minWidth: minWidth }}>
          Client Name
        </div>
        <TextField
          disabled={!(id <= 0 || !id)}
          required={id <= 0 || !id}
          status={status}
          value={Organisation?.name}
          setState={setOrganisation}
          targetName="name"
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Organisation Id
        </div>
        <TextField
          value={Organisation?.salesforceOrgId}
          disabled={!(id <= 0 || !id)}
          setState={setOrganisation}
          targetName="salesforceOrgId"
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Sub-Region
        </div>
        <TextField
          value={Organisation?.subregion}
          disabled={!(id <= 0 || !id)}
          setState={setOrganisation}
          targetName="subregion"
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Contract Status
        </div>
        <ContractStatus
          status={Organisation?.contractStatus}
          setState={setOrganisation}
          targetName="contractStatus"
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Contract Number
        </div>
        <TextField
          value={Organisation?.contractNumber}
          disabled={!(id <= 0 || !id)}
          setState={setOrganisation}
          targetName="contractNumber"
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name fieldRequired" style={{ minWidth: minWidth }}>
          Contract Start
        </div>
        <DatePicker
          required={id <= 0 || !id}
          disabled={!(id <= 0 || !id)}
          status={status}
          value={Organisation?.contractStart}
          setState={setOrganisation}
          targetName="contractStart"
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Contract End
        </div>
        <DatePicker
          value={Organisation?.contractEnd}
          disabled={!(id <= 0 || !id)}
          setState={setOrganisation}
          targetName="contractEnd"
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Account Manager
        </div>
        <TextField
          value={Organisation?.accountManager}
          disabled={!(id <= 0 || !id)}
          setState={setOrganisation}
          targetName="accountManager"
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          CSM
        </div>
        <TextField
          value={Organisation?.csmManager}
          disabled={!(id <= 0 || !id)}
          setState={setOrganisation}
          targetName="csmManager"
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          CSM Team
        </div>
        <TextField
          value={Organisation?.csmTeam}
          disabled={!(id <= 0 || !id)}
          setState={setOrganisation}
          targetName="csmTeam"
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Self-Hosted
        </div>
        <CheckBox
          value={Organisation?.isSelfHosted}
          setState={setOrganisation}
          targetName="isSelfHosted"
        />
      </ContainerRow>
      <ContainerRow className={`item-container ${nolastItemBorder && 'no-last-border'}`}>
        <div className="item-name" style={{ minWidth: minWidth }}>
          Enterprise Site
        </div>
        <CheckBox
          value={Organisation?.hasEnterpriseSite}
          setState={setOrganisation}
          targetName="hasEnterpriseSite"
        />
      </ContainerRow>
    </>
  );
};

export default OrganisationEdit;
