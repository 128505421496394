// Tenants
export const Tenant_DSN = 'dsn';
export const Tenant_URL = 'url';
export const Tenant_Organisation = 'assignedOrganisation.organisationName';
export const Tenant_Purpose = 'purpose';
export const Tenant_Status = 'status';
export const Tenant_Package = 'assignedOrganisation.packageName';
export const Tenant_License = 'assignedOrganisation.licenseSummary';
export const Tenant_Database = 'databaseName';
export const Tenant_DatabaseServer = 'databaseServer';
export const Tenant_WebServer = 'webServer';
export const Tenant_IpRestrictions = 'ipRestrictions';
export const Tenant_HasIpRestrictions = 'isIpRestrictions';
export const Tenant_AutoReminder = 'isAutoReminderEnabled';
export const Tenant_EFAutoSend = 'isEfAutoSendReceiveEnabled';
export const Tenant_ESignature = 'isESignaturesEnabled';
export const Tenant_SSO = 'isSsoEnabled';
export const Tenant_JobServer = 'jobsServer';
export const Tenant_ReportIt = 'reportItServer';
export const Tenant_Notes = 'notes';

// Auditing
export const Audit_AuditOn = 'auditOn';
export const Audit_Description = 'description';
export const Audit_UserName = 'userName';
export const Audit_Category = 'category';

// Organisations
export const Organisation_Name = 'name';
export const Organisation_SalesforceId = 'salesforceOrgId';
export const Organisation_Region = 'subregion';
export const Organisation_Status = 'contractStatus';
export const Organisation_Number = 'contractNumber';
export const Organisation_Start = 'contractStart';
export const Organisation_End = 'contractEnd';
export const Organisation_AccountManager = 'accountManager';
export const Organisation_CsmTeam = 'csmTeam';
export const Organisation_CsmManager = 'csmManager';
export const Organisation_SelfHosted = 'isSelfHosted';
export const Organisation_EnterpriseSite = 'hasEnterpriseSite';

// Packages
export const Package_Code = 'code';
export const Package_Name = 'name';
export const Package_Description = 'description';
export const Package_Start = 'availableFrom';
export const Package_End = 'availableUntil';
export const Package_Features = 'features';
export const Package_Profile = 'profile';

// Features
export const Feature_Code = 'code';
export const Feature_Description = 'description';
export const Feature_Start = 'availableFrom';
export const Feature_End = 'availableUntil';
export const Feature_Packages = 'packages';

// Licenses
export const License_Name = 'name';
export const License_Edition = 'edition';
export const License_Companies = 'companies';
export const License_People = 'people';
export const License_Incorps = 'incorporations';
export const License_Start = 'availableFrom';
export const License_End = 'availableUntil';
export const License_Users = 'users';
export const License_AdminUsers = 'adminUsers';
export const License_EditUsers = 'editUsers';
export const License_DL = 'dataLibrary';
export const License_DLDeveloper = 'dataLibraryDeveloper';
export const License_AssignedOn = 'assignedOn';

// License Usage
export const LicenseUsage_AdminUsers = 'licenseUsage_AdminUsers';
export const LicenseUsage_EditUsers = 'licenseUsage_EditUsers';
export const LicenseUsage_ReadOnlyUsers = 'licenseUsage_ReadOnlyUsers';
export const LicenseUsage_TotalUsers = 'licenseUsage_TotalUsers';
export const LicenseUsage_ActiveCompanies = 'licenseUsage_ActiveCompanies';
export const LicenseUsage_ActiveOnlyCompanies = 'licenseUsage_ActiveOnlyCompanies';
export const LicenseUsage_MaxCompaniesUsed = 'licenseUsage_MaxCompaniesUsed';
export const LicenseUsage_TotalCompanies = 'licenseUsage_TotalCompanies';
export const LicenseUsage_AuditedLoginsFor3Months = 'licenseUsage_AuditedLoginsFor3Months';
export const LicenseUsage_UniqueLoginsFor3Months = 'licenseUsage_UniqueLoginsFor3Months';
export const LicenseUsage_TotalCompaniesOverLicense = 'licenseUsage_TotalCompaniesOverLicense';
export const LicenseUsage_TotalsUsersOverLicense = 'licenseUsage_TotalsUsersOverLicense';

// IDS Sites
export const IDS_Url = 'url';
export const IDS_Description = 'description';
export const IDS_Location = 'location';
export const IDS_IP = 'ip';
export const IDS_VAULT = 'vault';
export const IDS_CONSUL = 'consul';

// Misc
export const ID = 'id';
export const Name = 'name';
export const TenantUrl = 'tenantUrl';
export const TenantDsn = 'tenantDsn';
export const PackageCode = 'packageCode';
export const PackageName = 'packageName';
export const PackageDescription = 'packageDescription';
export const LicenseName = 'licenseName';
export const LicenseSummary = 'licenseSummary';
export const AddOnFeatures = 'addOnFeatures';
export const AssignedOn = 'assignedOrganisation.assignedOn';
