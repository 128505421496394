import React, {PropsWithChildren} from 'react';
import Select from '../Select';
import { IReactSelect } from 'models/Lists/Common';
import TenantStatus from 'models/Lists/TenantStatus';

interface ITenantStatus { 
  status?: string;
  setState: React.Dispatch<React.SetStateAction<unknown>>;
  targetName: string;
  required?: boolean;
  disabled?: boolean;
  hasError?: boolean;
}

const TenantStatuses = (props: PropsWithChildren<any>): React.ReactElement => {
  const { status, setState, targetName, required, disabled, hasError } = props as ITenantStatus;

  const handleChange = ((e: IReactSelect): void => {
    setState((state: string[]) => ({
      ...state,
      [targetName]: e?.value || '',
    }));
  });

  const options = [
    { value: TenantStatus.New, label: 'New' },
    { value: TenantStatus.Pending, label: 'Pending' },
    { value: TenantStatus.PendingUnassign, label: 'Pending (Unassign)' },    
    { value: TenantStatus.Live, label: 'Live' },
  ];

  return (
    <Select
      value={status}
      required={required}
      name={targetName}
      handleChange={handleChange}
      options={options}
      disabled={disabled}
      hasError={hasError}
    />
  );
};

export default TenantStatuses;
