import React, {ForwardedRef, PropsWithChildren} from 'react';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';

const tableIcons = {
  Add: React.forwardRef((props: PropsWithChildren<any>, ref: ForwardedRef<any>) => (
    <i className="i-bpow-add" {...props} ref={ref} style={{ fontSize: '1rem', color: '#333' }} />
  )),
  AddBoxIcon: React.forwardRef((props: PropsWithChildren<any>, ref: ForwardedRef<any>) => (
    <i
      className="i-bpow-add-circle"
      {...props}
      ref={ref}
      style={{ fontSize: '2rem', color: '#333' }}
    />
  )),
  Check: React.forwardRef((props: PropsWithChildren<any>, ref: ForwardedRef<any>) => <Check {...props} ref={ref} />),
  Clear: React.forwardRef((props: PropsWithChildren<any>, ref: ForwardedRef<any>) => <Clear {...props} ref={ref} />),
  Delete: React.forwardRef((props: PropsWithChildren<any>, ref: ForwardedRef<any>) => (
    <i className="i-bpow-cross" {...props} ref={ref} style={{ fontSize: '1rem', color: '#333' }} />
  )),
  DetailPanel: React.forwardRef((props: PropsWithChildren<any>, ref: ForwardedRef<any>) => (
    <i
      className="i-bpow-chevron-right"
      {...props}
      ref={ref}
      style={{ fontSize: '1rem', color: '#333' }}
    />
  )),
  Edit: React.forwardRef((props: PropsWithChildren<any>, ref: ForwardedRef<any>) => (
    <i className="i-bpow-pencil" {...props} ref={ref} style={{ fontSize: '1rem', color: '#333' }} />
  )),
  Export: React.forwardRef((props: PropsWithChildren<any>, ref: ForwardedRef<any>) => (
    <i
      className="i-bpow-download"
      {...props}
      ref={ref}
      style={{ fontSize: '1rem', color: '#333' }}
    />
  )),
  Filter: React.forwardRef(() => <div />),
  FirstPage: React.forwardRef((props: PropsWithChildren<any>, ref: ForwardedRef<any>) => <FirstPage {...props} ref={ref} />),
  LastPage: React.forwardRef((props: PropsWithChildren<any>, ref: ForwardedRef<any>) => <LastPage {...props} ref={ref} />),
  NextPage: React.forwardRef((props: PropsWithChildren<any>, ref: ForwardedRef<any>) => (
    <i
      className="i-bpow-chevron-right"
      {...props}
      ref={ref}
      style={{ fontSize: '1.4rem', color: '#333' }}
    />
  )),
  PreviousPage: React.forwardRef((props: PropsWithChildren<any>, ref: ForwardedRef<any>) => (
    <i
      className="i-bpow-chevron-left"
      {...props}
      ref={ref}
      style={{ fontSize: '1.4rem', color: '#333' }}
    />
  )),
  ResetSearch: React.forwardRef((props: PropsWithChildren<any>, ref: ForwardedRef<any>) => <Clear {...props} ref={ref} />),
  Search: React.forwardRef((props: PropsWithChildren<any>, ref: ForwardedRef<any>) => (
    <i className="i-bpow-search" {...props} ref={ref} style={{ fontSize: '1rem' }} />
  )),
  SortArrow: React.forwardRef((props: PropsWithChildren<any>, ref: ForwardedRef<any>) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: React.forwardRef((props: PropsWithChildren<any>, ref: ForwardedRef<any>) => <Remove {...props} ref={ref} />),
  ViewColumn: React.forwardRef((props: PropsWithChildren<any>, ref: ForwardedRef<any>) => (
    <i
      className="i-bpow-column-picker"
      {...props}
      ref={ref}
      style={{ fontSize: '1rem', color: '#333' }}
    />
  )),
};

export default tableIcons;
