import React, {PropsWithChildren, ReactElement} from 'react';
import * as ViewComponent from './ViewIndex';
import { logger } from 'lib/logger';

const FeatureAddOns = (props: PropsWithChildren<any>): ReactElement => {
  const { minWidth, Tenant, nolastItemBorder } = props;
  let items = [];
  if (
    Tenant &&
    Tenant.assignedOrganisation &&
    Tenant.assignedOrganisation.addOnFeatures &&
    Tenant.assignedOrganisation.addOnFeatures.length > 0
  )
    try {
      items = JSON.parse(Tenant.assignedOrganisation.addOnFeatures);
    } catch (e) {
      logger.error(e, 'Parse error on FeatureAddons');
    }
  if (Tenant && Tenant.addOnFeatures && Tenant.addOnFeatures.length > 0)
    try {
      items = JSON.parse(Tenant.addOnFeatures);
    } catch (e) {
      logger.error(e, 'Parse error on FeatureAddons');
    }

  return (
    <ViewComponent.PageItemList
      name="Feature Add-ons"
      itemValue="label"
      items={items}
      nolastItemBorder={nolastItemBorder}
      minWidth={minWidth}
    />
  );
};

export default FeatureAddOns;
