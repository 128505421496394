import React from 'react';
import { Route } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import Login from 'okta/Login';

const DoRoute = ({ component: Component, oktaSignInConfig, ...rest }: React.PropsWithChildren<any>): React.ReactElement => {
  const { authState } = useOktaAuth() || {};
  return (
    <Route
      {...rest}
      render={(matchProps): React.ReactElement => (
        <>
          {!authState || !authState?.isAuthenticated ? (
            <Login config={oktaSignInConfig} />
          ) : (
            <Component {...matchProps} />
          )}
        </>
      )}
    />
  );
}
export default DoRoute;
