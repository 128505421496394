import { useMemo } from 'react';
import { ADMIN, CSM, IMPLEMENTATION, MARKETING, PRODOPS, SALES, SUPPORT } from 'okta/HasAccess';
import Route, { RouteNames } from 'models/Routes';

interface Icon {
  name: string;
  route: string;
  icon: string;
  accessOnly?: string[];
}
interface ISidebarItems {
  icons: Array<Icon>;
}
const useSidebarItems = (): ISidebarItems => {
  const icons = useMemo(
    () => [
      {
        name: RouteNames.Packages,
        route: '/' + Route.Packages,
        icon: 'i-bpow-products',
      },
      {
        name: RouteNames.Sites,
        route: '/' + Route.Sites,
        icon: 'i-bpow-database',
      },
      {
        name: RouteNames.Clients,
        route: '/' + Route.Clients,
        icon: 'i-bpow-cs-board-succession-planning',
      },
      {
        name: RouteNames.Reporting,
        route: '/' + Route.Reporting,
        icon: 'i-bpow-graph',
        accessOnly: [ADMIN, CSM, IMPLEMENTATION, MARKETING, PRODOPS, SALES],
      },
      {
        name: RouteNames.Audit,
        route: '/' + Route.Audit,
        icon: 'i-bpow-compliance-assessments',
        accessOnly: [ADMIN, CSM, IMPLEMENTATION, PRODOPS, SALES, SUPPORT],
      },
      {
        name: RouteNames.Salesforce,
        route: '/' + Route.Salesforce,
        icon: 'i-bpow-compliance-explorer',
        accessOnly: [ADMIN, SALES, IMPLEMENTATION],
      },
    ],
    [],
  );
  return { icons };
};

export default useSidebarItems;
