import Route, { RouteNames } from 'models/Routes';
import { hasAccessGroup, ADMIN, CSM, IMPLEMENTATION, PRODOPS, SALES, SUPPORT, MARKETING } from 'okta/HasAccess';
import { useOktaAuth } from '@okta/okta-react';
import useSidebarItems from './SidebarItems';

const DEFAULT_PATH = '/';

function DefaultPath(): string {
  const { authState } = useOktaAuth() || {};
  if (!authState) return DEFAULT_PATH;

  switch (true) {
  case hasAccessGroup([ADMIN, PRODOPS, IMPLEMENTATION, SUPPORT], authState):
    return DEFAULT_PATH + Route.Sites;
  case hasAccessGroup([CSM, SALES], authState):
    return DEFAULT_PATH + Route.Clients;
  case hasAccessGroup(MARKETING, authState):
    return DEFAULT_PATH + Route.Reporting;
  default:
    return DEFAULT_PATH;
  }
}

export const useGetDefaultRoute = (route?: string): string => {
  const defaultPath = DefaultPath();
  if ((route === DEFAULT_PATH) || (!route)) route = defaultPath;
  return route || RouteNames.AppName;
}

export const useGetRouteName = (route: string): string => {
  const defaultPath = DefaultPath();
  const { icons } = useSidebarItems();
  if (route === DEFAULT_PATH) route = defaultPath;
  const icon = icons?.filter(icon => icon.route === route);
  return (icon && icon.length > 0 && icon[0].name) || DefaultRouteName(route);
}

function DefaultRouteName(route: string): string {
  if (route === DEFAULT_PATH + Route.CheckHealth) return route = RouteNames.System;
  return RouteNames.AppName;
}
 