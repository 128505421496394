import React, {useContext} from 'react';
import IconButton from '@material-ui/core/IconButton';
import {Tooltip} from 'components/ComponentsIndex';
import {ISelectedSiteContext} from "models/Interfaces";
import {SiteSelectedContext} from "context/SiteSelectedContext";
import {useHistory} from "react-router-dom";
import Route from "models/Routes";
import {setupPreviewPanel} from "store/Actions/PreviewPanel";
import {refreshSearchResults} from "store/Actions/SearchResults";
import * as Redux from "react-redux";

const ViewClientButton = (props: any): React.ReactElement | null => {
  const context: ISelectedSiteContext | undefined  = useContext(SiteSelectedContext)
  const history = useHistory();
  const dispatch = Redux.useDispatch();

  const {showButton} = props

  if (!showButton) {
    return null
  }

  const handleViewSite = (e: any): void => {
    e?.stopPropagation()
    const setSelectedClient = context?.setSelectedClient
    setSelectedClient({
      organisationName: props.copyText,
      organisationId: props.itemId
    })
    dispatch(setupPreviewPanel(Route.Clients, 'view', props.itemId, true));
    dispatch(refreshSearchResults(true, 2));
    history.push('/' + Route.Clients);
  }
  return (
    <Tooltip title="View Client Details">
      <IconButton onClick={handleViewSite}>
        <i className="i-bpow-open-external" style={{fontSize: '1rem', color: '#333'}}/>
      </IconButton>
    </Tooltip>
  )
};

export default ViewClientButton;
