import React, {PropsWithChildren, ReactElement} from 'react';
import { useFetch } from 'hooks';
import PageItem from './PageItem';
import { VerbType } from 'models/Routes';

const IdsSiteItem = (props: PropsWithChildren<any>): ReactElement => {
  const { idsSiteId, minWidth } = props;
  const [IdsSite, setIdsSite] = React.useState<any>();
  const { data } = useFetch(VerbType.GET, idsSiteId ? `IdentityServerSites/${idsSiteId}` : '');
  React.useEffect(() => {
    setIdsSite(data);
  }, [data]);
  return (
    <PageItem name="IDS Site" value={IdsSite?.url} minWidth={minWidth} copyToClipboard={true} />
  );
};
export default IdsSiteItem;
