enum TenantStatus {
  New = 'New',
  Pending = 'Pending',
  PendingUpdate = 'PendingUpdate',
  PendingUnassign = 'PendingUnassign',
  Live = 'Live',
  Unassigned = 'Unassigned',
}

export default TenantStatus;
