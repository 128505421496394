import { ILicense, ITenantLicenseUpdate } from 'models/Interfaces';
import * as yup from 'yup';
import { computeMaxUsers } from './licenseHelper';

export const getEditLicenceError = (License: ILicense): any => {
  const licenseSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    companies: yup
      .number()
      .typeError('Companies must be a number')
      .required('Companies is required')
      .min(1, 'Companies must be greater than 0'),
    adminUsers: yup
      .number()
      .typeError('Admin Users must be a number')
      .required('Admin Users is required')
      .min(1, 'Admin Users must be greater than 0'),
    availableFrom: yup.string().required('Start Date is required'),
    users: yup
      .number()
      .typeError('Max Users must be a number')
      .required('Max Users is required')
      .min(1, 'Max Users must be greater than 0')
  });

  const users = computeMaxUsers(License);
  License.users = parseInt(users);

  try {
    licenseSchema.validateSync(License, { abortEarly: false, stripUnknown: true });
    return [];
  } catch (err: any) {
    return err.errors;
  }
};

export const getAssignSiteErrors = (AssignSite: ILicense): any => {
  const licenseSchema = yup.object().shape({
    tenantId: yup.string().required('Site is required'),
    packageId: yup.string().required('Package is required').nullable(),
    companies: yup
      .number()
      .typeError('Companies must be a number')
      .required('Companies is required')
      .min(1, 'Companies must be greater than 0'),
    adminUsers: yup
      .number()
      .typeError('Admin Users must be a number')
      .required('Admin Users is required')
      .min(1, 'Admin Users must be greater than 0'),
    users: yup
      .number()
      .typeError('Max Users must be a number')
      .required('Max Users is required')
      .min(1, 'Max Users must be greater than 0')
  });

  const users = computeMaxUsers(AssignSite);
  AssignSite.users = parseInt(users);

  try {
    licenseSchema.validateSync(AssignSite, { abortEarly: false, stripUnknown: true });
    return [];
  } catch (err: any) {
    return err.errors;
  }
};


export const getSiteUpdateErrors = (AssignSite: ITenantLicenseUpdate): any => {
  const licenseSchema = yup.object().shape({
    packageId: yup.string().required('Package is required').nullable(),
    companies: yup
      .number()
      .typeError('Companies must be a number')
      .required('Companies is required')
      .min(1, 'Companies must be greater than 0'),
    adminUsers: yup
      .number()
      .typeError('Admin Users must be a number')
      .required('Admin Users is required')
      .min(1, 'Admin Users must be greater than 0'),
    users: yup
      .number()
      .typeError('Max Users must be a number')
      .required('Max Users is required')
      .min(1, 'Max Users must be greater than 0')
  });

  const users = computeMaxUsers(AssignSite);
  AssignSite.users = parseInt(users);
  AssignSite.maxUsers = parseInt(users);
  
  try {
    licenseSchema.validateSync(AssignSite, { abortEarly: false, stripUnknown: true });
    return [];
  } catch (err: any) {
    return err.errors;
  }
};

export const getCreateLicenseErrors = (AssignSite: ILicense | undefined): any => {
  AssignSite = AssignSite || ({} as ILicense);
  const licenseSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    availableFrom: yup.string().required('Start Date is required'),
    companies: yup
      .number()
      .typeError('Companies must be a number')
      .required('Companies is required')
      .min(1, 'Companies must be greater than 0'),
    adminUsers: yup
      .number()
      .typeError('Admin Users must be a number')
      .required('Admin Users is required')
      .min(1, 'Admin Users must be greater than 0'),
    users: yup
      .number()
      .typeError('Max Users must be a number')
      .required('Max Users is required')
      .min(1, 'Max Users must be greater than 0')
  });

  const users = computeMaxUsers(AssignSite);
  AssignSite.users = parseInt(users);

  try {
    licenseSchema.validateSync(AssignSite, { abortEarly: false, stripUnknown: true });
    return [];
  } catch (err: any) {
    return err.errors;
  }
};
