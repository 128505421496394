import useSWR from 'swr';
import { useContext } from 'react';
import { ApiHelperContext } from 'context/ApiHelperContext';

const useStaleWhileRevalidating = (url) => {
  const context = useContext(ApiHelperContext);
  const apiHelper = context.state.apiHelper;
  const fetcher = () => apiHelper.get(url);

  const { data, error } = useSWR(url, fetcher);
  return { data, error, loading: !data };
};

export default useStaleWhileRevalidating;
