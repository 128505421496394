import React, { useEffect, useState, ReactElement } from 'react';
import { useFetch } from 'hooks';
import { VerbType } from 'models/Routes';
import LicenceUpdateComparison from './UpdateComparison';

interface UpdateLicenceProps {
  TenantId: string | number | undefined;
  OrgId: string | number | undefined;
  indentTitle?: boolean;
  minWidth?: string;
}

const UpdateLicence = (props: UpdateLicenceProps): ReactElement => {
  const { TenantId, OrgId, minWidth, indentTitle } = props;

  // Get and set state for new Licence data request for TenantId and Organisation Id
  const [NewLicence, setNewLicence] = useState();
  const { data: NewLicenceData } = useFetch(VerbType.GET, TenantId && OrgId ? `Tenants/${TenantId}/${OrgId}/siteupdatedetails` : '');

  useEffect(() => {
    setNewLicence(NewLicenceData);
  }, [NewLicenceData]);

  // Get and set state for existing Licence data for TenantId
  const { data: CurrentLicenceData } = useFetch(VerbType.GET, TenantId ? `Tenants/${TenantId}/organisations` : '');
  const [CurrentLicence, setCurrentLicence] = React.useState();

  React.useEffect(() => {
    setCurrentLicence(CurrentLicenceData && CurrentLicenceData[0]);
  }, [CurrentLicenceData]);

  return (
    <>
      {
        NewLicence && CurrentLicence &&
          <LicenceUpdateComparison
            NewLicenceData={NewLicence} 
            CurrentLicenceData={CurrentLicence} 
            minWidth={minWidth}
            indentTitle={indentTitle}
          />
      }
    </>
  );
};

export default UpdateLicence;
