import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useStyles } from './Common';

interface IDataFeed { 
  name : string | undefined;
  server?: string | undefined;
  enabled?: boolean | undefined;  
}

interface IDataFeeds { 
  dataFeeds: Array<IDataFeed> | undefined;
}

const DataFeeds = (props : IDataFeeds) : React.ReactElement => {
  const { dataFeeds } = props;
  const classes = useStyles();
  return (
    <div
      style={{
        padding: '1rem',
        backgroundColor: '#f6f6f6',
      }}
    >
      <TableContainer>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Server</TableCell>
              <TableCell align="center">Enabled</TableCell>
              <TableCell>Task Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataFeeds &&
              dataFeeds.length > 0 &&
              dataFeeds.map((Feed) => (
                <TableRow key={Feed.name}>
                  <TableCell component="th" scope="row">
                    {Feed.server}
                  </TableCell>
                  <TableCell align="center">
                    <input type="checkbox" checked={Feed.enabled} disabled className="checkbox" />
                  </TableCell>
                  <TableCell>{Feed.name}</TableCell>
                </TableRow>
              ))}
            {!dataFeeds ||
              (dataFeeds && dataFeeds.length === 0 && (
                <TableRow key="none">
                  <TableCell component="th" colSpan={3} scope="row">
                    No records found
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DataFeeds;
