import React, { PropsWithChildren, ReactElement } from 'react';
import * as Redux from 'react-redux';
import { useFetch } from 'hooks';
import Select from '../Select';
import { VerbType } from 'models/Routes';
import { ITenant } from "models/Interfaces";

const Tenants = (props: PropsWithChildren<any>): ReactElement => {
  const {
    id,
    state,
    setState,
    targetName,
    tenantStatus,
    tenantSelector,
    dataCenterFilter,
    hasError,
    required
  } = props;
  const { dataCenterId } = Redux.useSelector((state: any) => state.DataCenterReducer);
  const filterId = dataCenterFilter ? dataCenterFilter : dataCenterId;
  let url = 'Tenants?';
  if (filterId) url += `&dataCenterId=${filterId}`;
  if (tenantStatus) url += `&tenantStatus=${tenantStatus}`;
  if (tenantSelector) url += `&tenantSelector=${tenantSelector}`;

  const { data } = useFetch(VerbType.GET, url);

  const handleChange = (e: any): void => {
    setState({
      ...state,
      [targetName]: e ? e.value : '',
    });
  };

  const options =
    data?.tenants ?
      data?.tenants?.map((Tenant: ITenant): any => ({
        label: Tenant.url,
        value: Tenant.id,
      })) :
      data?.map((Tenant: ITenant): any => ({
        label: Tenant.url,
        value: Tenant.id,
      }))
    ;

  return (
    <>
      <Select
        value={id}
        name={targetName}
        handleChange={handleChange}
        options={options}
        placeholder={options?.length === 0 ? 'No Sites found': ''}
        hasError={hasError}
        required={required}
      />
    </>
  );
};

export default Tenants;
