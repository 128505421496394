import { combineReducers } from 'redux';
import SidebarReducer from './Reducers/Sidebar';
import PreviewPanelReducer from './Reducers/PreviewPanel';
import SearchResultsReducer from './Reducers/SearchResults';
import DataCenterReducer from './Reducers/DataCenter';

const rootReducer = combineReducers({
  PreviewPanelReducer,
  SidebarReducer,
  SearchResultsReducer,
  DataCenterReducer,
});

export default rootReducer;
