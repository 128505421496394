enum LicenseEdition {
  Blueprint2000 = 'Blueprint2000',
  CoAct = 'CoAct',
  BlueprintExpress = 'BlueprintExpress',
  BoardPadConnect = 'BoardPadConnect',
  BlueprintOneWorld = 'BlueprintOneWorld',
  EntitiesHostedServices = 'EntitiesHostedServices',
}

export default LicenseEdition;
