import { useMemo } from 'react';
import { ADMIN } from 'okta/HasAccess';
import Route from 'models/Routes';
import {ITabs, TabType} from "models/types";

const useHeaderTabs = (): ITabs  => {
  const tabs: TabType[] = useMemo(
    (): TabType[] => [
      {
        text: 'Companies',
        tooltip: 'Download Salesforce companies data',
        url: Route.SalesforceSOAP,
      },
      {
        text: 'Companies REST',
        tooltip: 'REST Download Salesforce companies data',
        url: Route.SalesforceREST,
        accessOnly: [ADMIN],
      },
    ],
    [],
  );
  return { tabs };
};

export default useHeaderTabs;
