import React, { PropsWithChildren } from 'react';
import * as Redux from 'react-redux';
import { hidePreviewPanel, setupPreviewPanel } from 'store/Actions/PreviewPanel';
import { refreshSearchResults } from 'store/Actions/SearchResults';
import PanelSubHeader from 'views/Common/PanelSubHeader/PanelSubHeader';
import { ContainerColumn } from 'styles/styledComponents/styledComponents';
import { FormStatus } from 'views/Common/EditForm/Common';
import * as EditComponent from 'views/Common/EditForm/EditIndex';
import { WriteErrors } from 'views/Common/EditForm/EditIndex';
import { ErrorDialog, handleError, useErrorState } from 'error/HandleErrors';
import { ApiHelperContext } from 'context/ApiHelperContext';
import Route from 'models/Routes';
import notify, { MessageType, Message } from 'components/Notifications/toastify';
import { Props, Mode } from "models/types";
import {IError, IIdnSite, ISearchResultReducerState} from "models/Interfaces";
import { getIDSSiteErrors } from 'lib/IDSSiteHelper';

const IdsSite: React.FC<Props> = (props: PropsWithChildren<any>) => {
  const {minWidth = '11rem'} = props;
  const [IdsSite, setIdsSite] = React.useState<IIdnSite | undefined>();
  const {reloadSearch} = Redux.useSelector<any>((state) => state.SearchResultsReducer) as ISearchResultReducerState;
  const [status, setStatus] = React.useState(FormStatus.INITIAL);
  const {errorDialog, errorMessage, errorTitle, setErrorDialog, setErrorMessage, setErrorTitle} = useErrorState();
  const dispatch = Redux.useDispatch();
  const errors: any = getIDSSiteErrors(IdsSite);
  const isValid = Object.keys(errors).length === 0;
  const context = React.useContext(ApiHelperContext)
  const apiHelper = context?.state.apiHelper

  function handleSave(): void {
    if (isValid && IdsSite && apiHelper) {
      apiHelper.post('IdentityServerSites/add', IdsSite, handleSaveCallBack, callbackErrorFunc);
      dispatch(refreshSearchResults(!reloadSearch))
    }
    setStatus(FormStatus.DIRTY);
  }

  function handleSaveCallBack(data: IError): void {
    if (data && data.status >= 400) {
      callbackErrorFunc(data);
    } else {
      handleCallBack(data?.id);
      notify(Message.IDSSiteAdded, MessageType.Success);
    }
  }

  function callbackErrorFunc(error: IError): void {
    handleError(error, setErrorTitle, setErrorMessage, setErrorDialog);
  }

  const handleCloseDialog = (): void => {
    setErrorDialog(false);
  };

  function handleCancel(): void {
    dispatch(hidePreviewPanel())
  }

  function handleCallBack(id: any): void {
    dispatch(setupPreviewPanel(Route.IDS, Mode.View, id));
    dispatch(refreshSearchResults(!reloadSearch))
  }

  return (
    <>
      <div className="search-title-nav">
        <PanelSubHeader handleCancel={handleCancel} handleSave={handleSave} description='Add Details'/>
      </div>
      {!isValid && FormStatus && (status === FormStatus.DIRTY) && <WriteErrors errors={errors} />}
      <ContainerColumn style={{height: 'calc(100% - 3rem)', overflowY: 'auto'}}>
        <div className="entity-details-content full-width">
          <EditComponent.IdsSiteEdit
            minWidth={minWidth}
            IdsSite={IdsSite}
            status={status}
            setState={setIdsSite}
          />
        </div>
      </ContainerColumn>
      <ErrorDialog
        errorDialog={errorDialog}
        handleCloseDialog={handleCloseDialog}
        errorTitle={errorTitle}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default IdsSite;
