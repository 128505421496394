import React, {PropsWithChildren, ReactElement} from 'react';
import * as Redux from 'react-redux';
import { useFetch } from 'hooks';
import { setupPreviewPanel, hidePreviewPanel } from 'store/Actions/PreviewPanel';
import { refreshSearchResults } from 'store/Actions/SearchResults';
import OrganisationView from 'views/Common/ViewForm/OrganisationView';
import { ContainerColumnPanelView } from 'styles/styledComponents/styledComponents';
import PanelSubHeader from 'views/Common/PanelSubHeader/PanelSubHeader';
import { AlertDialog } from 'components/Dialog/DialogIndex';
import OrganisationTenants from './OrganisationTenants';
import { ADMIN } from 'okta/HasAccess';
import * as Component from 'components/ComponentsIndex';
import { ErrorDialog, handleError, useErrorState } from 'error/HandleErrors';
import informationIcons from 'lib/InformationIcons';
import { ApiHelperContext } from 'context/ApiHelperContext';
import Route from 'models/Routes';
import {Props} from "models/types";
import {IPreviewPanelState, ISearchResultReducerState} from "models/Interfaces";
import {IError, IOrganisation, IPreviewPanel} from "models/Interfaces";

const ViewOrganisation: React.FC<Props> = (props: PropsWithChildren<any>) => {
  const { itemId, minWidth = '10rem' } = props;
  const {reloadSearch} = Redux.useSelector<any>((state) => state.SearchResultsReducer) as ISearchResultReducerState;
  const { id, refreshPanel } = Redux.useSelector<IPreviewPanelState>((state) => state.PreviewPanelReducer) as IPreviewPanel;
  const { data } = useFetch(
    'GET',
    itemId ? `Organisations/${itemId}` : id ? `Organisations/${id}` : Route.Clients,
    refreshPanel,
  );
  const [Organisation, setOrganisation] = React.useState<IOrganisation>();
  const [openDialog, setOpenDialog] = React.useState(false);
  const { errorDialog, errorMessage, errorTitle, setErrorDialog, setErrorMessage, setErrorTitle } = useErrorState();
  const dispatch = Redux.useDispatch();
  const context = React.useContext(ApiHelperContext)
  const apiHelper = context?.state.apiHelper
  
  React.useEffect(() => {
    setOrganisation(data);
  }, [data, itemId, refreshPanel]);

  function handleEdit(): void {
    dispatch(setupPreviewPanel(Route.Clients, 'edit', id, false));
  }

  const handleOpenDialog = (): void => {
    setOpenDialog(true);
  };

  const handleCloseDialog = (): void => {
    setOpenDialog(false);
  };

  function handleDeleteItem(): void {
    if(apiHelper) {
      apiHelper.delete(`Organisations/${id}`, handleDeleteCallBack, callbackErrorFunc);
    }
  }

  function handleDeleteCallBack(): void {
    dispatch(hidePreviewPanel());
    dispatch(refreshSearchResults(!reloadSearch));
  }

  function callbackErrorFunc(error: IError): void {
    handleError(error, setErrorTitle, setErrorMessage, setErrorDialog);
  }

  const editAccess = [ADMIN];
  const deleteAccess = [ADMIN];

  const ClientDetails = (): ReactElement => {
    return (
      <OrganisationView
        Organisation={Organisation}
        minWidth={minWidth}
        nolastItemBorder={true}
      />
    )
  }

  const orgAttribute = {orgId:Organisation?.id} as PropsWithChildren<any>

  return (
    <>
      <div className="search-title-nav">
        <PanelSubHeader
          handleEdit={handleEdit}
          handleDelete={handleOpenDialog}
          editAccess={editAccess}
          deleteAccess={deleteAccess}
          description='Details'
          icons={informationIcons.WriteIcons('SL')}
        />
      </div>
      <ContainerColumnPanelView style={{ height: 'calc(100% - 3rem)', overflowY: 'auto' }}>
        <div className="entity-details-content full-width" id="viewcontainer">
          <Component.Accordion
            title="Client Details - (SF)"
            Component={ClientDetails}
            defaultExpanded={true}
          />
          {Organisation?.id && (
            <OrganisationTenants {...orgAttribute} />
          )}
        </div>
      </ContainerColumnPanelView>
      <AlertDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        handleConfirm={handleDeleteItem}
        title="Delete Organisation"
        body="Are you sure you wish to delete this item?"
      />
      <ErrorDialog
        errorDialog={errorDialog} 
        handleCloseDialog={handleCloseDialog}
        errorTitle={errorTitle}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default ViewOrganisation;
