import React, {PropsWithChildren, ReactElement} from 'react';
import { ContainerRow } from 'styles/styledComponents/styledComponents';
import { DatePicker, TextField } from './EditIndex';
import { PackagesForFeature } from './Lists/ListsIndex';
import * as FieldLengths from '../Constants/FieldLengths';

const FeatureEdit = (props: PropsWithChildren<any>): ReactElement => {
  const {
    id,
    minWidth,
    Feature,
    status,
    setFeature,
    selectedPackages,
    setSelectedPackages,
    initialPackages,
  } = props;

  return (
    <>
      <ContainerRow className="item-container">
        <div className="item-name fieldRequired" style={{ minWidth: minWidth }}>
          Feature Code
        </div>
        <TextField
          disabled={!(id <= 0 || !id)}
          required={id <= 0 || !id}
          status={status}
          value={Feature?.code}
          setState={setFeature}
          upperCase
          targetName="code"
          maxLength={FieldLengths.Feature_Code}
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name fieldRequired" style={{ minWidth: minWidth }}>
          Feature Name
        </div>
        <TextField
          required={true}
          status={status}
          value={Feature?.description}
          setState={setFeature}
          targetName="description"
          maxLength={FieldLengths.Feature_Description}
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name fieldRequired" style={{ minWidth: minWidth }}>
          Start Date
        </div>
        <DatePicker
          value={Feature?.availableFrom}
          required={true}
          status={status}
          setState={setFeature}
          targetName="availableFrom"
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Retired
        </div>
        <DatePicker
          value={Feature?.availableUntil}
          setState={setFeature}
          targetName="availableUntil"
        />
      </ContainerRow>
      <ContainerRow className="item-container">
        <div className="item-name" style={{ minWidth: minWidth }}>
          Packages
        </div>
        <PackagesForFeature
          featureCode={Feature?.code}
          {...{state: selectedPackages, initialPackages}}
          setState={setSelectedPackages}
          targetName="packagesForFeature"
          isMulti="true"
        />
      </ContainerRow>
    </>
  );
};

export default FeatureEdit;
