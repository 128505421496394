import React from 'react';
import {editFormStyles, FormStatus} from './Common';
import Input from '@material-ui/core/Input';

interface INumberField {
  value?: string | number;
  min?: number;
  max?: number;
  setState: React.Dispatch<React.SetStateAction<unknown>>;
  targetName: string;
  required?: boolean;
  status?: string;
  disabled?: boolean;
  placeholder?: string;
  handleChangeFunc?: (name: string, value: string) => string
}

const NumberField = (props: INumberField): React.ReactElement => {

  const enforceMinMax = (value: any, min: number, max: number): any => {
    value = parseInt(value);
    if (value || value === 0) {
      if (value < min) {
        value = min;
      }
      if (value > max) {
        value = max
      }
    }
    return value
  }

  const {
    value,
    setState,
    targetName,
    required,
    status,
    disabled,
    placeholder,
    handleChangeFunc,
    min = 0,
    max = 99999,
  } = props;
  const classes = editFormStyles();

  const handleChange = ((e: React.ChangeEvent<HTMLInputElement>): void => {
    e.persist();
    const value = enforceMinMax(e.target.value, min, max);
    setState((state: string[]) => {
      return {
        ...state,
        [e.target.name]: value,
      };
    });
    handleChangeFunc && handleChangeFunc(e.target.name, value);
  });

  const inputProps = {min, max, pattern: '[0-9]+', required: required || undefined,};
  const inValid = !value || (value && (value < min || value > max));
  const numValue = enforceMinMax(value, min, max)

  return (
    <>
      {required ? (
        <Input
          name={targetName}
          type="number"
          inputProps={inputProps}
          className={`${classes.textField} ${inValid && (status === FormStatus.DIRTY) ? classes.error : ''}`}
          onChange={handleChange}
          value={numValue || '' }
          disableUnderline={true}
          placeholder={placeholder}
        />
      ) : (
        <Input
          name={targetName}
          type="number"
          inputProps={inputProps}
          className={`${classes.textField}`}
          onChange={handleChange}
          disabled={!!disabled}
          value={numValue || ''}
          disableUnderline={true}
          placeholder={placeholder}
          style={{
            backgroundColor: disabled ? 'hsl(0, 0%, 95%)' : '',
          }}
        />
      )}
    </>
  );
};

export default NumberField;
