import { OPEN_MENU, OPEN_PANEL, COLLAPSE_ALL, UPDATE_APIVERSION } from '../ActionTypes/Sidebar';

export function openMenu(isSidebarOpen) {
  const payload = {
    isSidebarOpen: !isSidebarOpen,
  };
  return { type: OPEN_MENU, payload };
}

export function openSidebarPanel(isPanelOpen) {
  const payload = {
    isPanelOpen: !isPanelOpen,
  };
  return { type: OPEN_PANEL, payload };
}

export function hideMenu() {
  const payload = {
    isSidebarOpen: false,
  };
  return { type: COLLAPSE_ALL, payload };
}

export function updateApiVersion(ApiVersion) {
  const payload = {
    ApiVersion,
  };
  return { type: UPDATE_APIVERSION, payload };
}
