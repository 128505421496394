import React, {PropsWithChildren} from 'react';
import * as Redux from 'react-redux';
import { setupPreviewPanel, hidePreviewPanel } from 'store/Actions/PreviewPanel';
import { refreshSearchResults } from 'store/Actions/SearchResults';
import PanelSubHeader from 'views/Common/PanelSubHeader/PanelSubHeader';
import { ContainerColumn } from 'styles/styledComponents/styledComponents';
import { FormStatus } from 'views/Common/EditForm/Common';
import * as EditComponent from 'views/Common/EditForm/EditIndex';
import { ErrorDialog, handleError, useErrorState } from 'error/HandleErrors';
import { ApiHelperContext } from 'context/ApiHelperContext';
import Route from 'models/Routes';
import { getCreateLicenseErrors } from 'lib/ErrorsHelper'
import notify, { MessageType, Message } from 'components/Notifications/toastify';
import { Props, Mode } from "models/types";
import { IApiHelperContext, IError, IFormStatus, IPreviewPanel} from "models/Interfaces";
import { IPreviewPanelState } from "models/Interfaces";

const formStatus: IFormStatus = FormStatus

const EditLicense: React.FC<Props>  = (props: PropsWithChildren<any>) => {
  const { minWidth = '10.75rem' } = props;
  const { id } = Redux.useSelector<IPreviewPanelState>((state) => state.PreviewPanelReducer) as IPreviewPanel;
  const context: IApiHelperContext | undefined = React.useContext(ApiHelperContext)
  const apiHelper = context?.state.apiHelper
  const [License, setLicense] = React.useState();
  const [status, setStatus] = React.useState(formStatus.INITIAL);
  const { errorDialog, errorMessage, errorTitle, setErrorDialog, setErrorMessage, setErrorTitle } = useErrorState();
  const dispatch = Redux.useDispatch();
  const errors = getCreateLicenseErrors(License);
  const isValid = Object.keys(errors).length === 0;

  function handleSave(): void {
    if (isValid && License && apiHelper) {
      apiHelper.post('Licenses/add', License, handleSaveCallBack, callbackErrorFunc);
    }
    setStatus(FormStatus.DIRTY);
  }

  function handleSaveCallBack(data: IError): void {
    if (data && data.status >= 400) {
      callbackErrorFunc(data);
    } else {
      dispatch(setupPreviewPanel(Route.Licenses, Mode.View, data && data.id));
      dispatch(refreshSearchResults(true));
      notify(Message.LicenseAdded, MessageType.Success);
    }
  }

  function callbackErrorFunc(error: IError): void {
    handleError(error, setErrorTitle, setErrorMessage, setErrorDialog);
  }

  const handleCloseDialog = (): void => {
    setErrorDialog(false);
  };

  function handleCancel(): void {
    dispatch(hidePreviewPanel());
  }

  const attributes = {
    id,
    minWidth,
    License,
    status,
    setLicense
  } as PropsWithChildren<any>

  const errorParams = { errors } as PropsWithChildren<any>
  return (
    <>
      <div className="search-title-nav">
        <PanelSubHeader handleCancel={handleCancel} handleSave={handleSave} description='Add Details' />
      </div>
      {!isValid && formStatus && (status === formStatus.DIRTY) && <EditComponent.WriteErrors {...errorParams} />}
      <ContainerColumn style={{ height: 'calc(100% - 3rem)', overflowY: 'auto' }}>
        <div className="entity-details-content full-width">
          <EditComponent.LicenseEdit {...attributes} />
        </div>
      </ContainerColumn>
      <ErrorDialog
        errorDialog={errorDialog} 
        handleCloseDialog={handleCloseDialog}
        errorTitle={errorTitle}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default EditLicense;
