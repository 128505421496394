import React from 'react';
import * as ViewComponent from './ViewIndex';
import { IIdnSite } from 'models/Interfaces';

interface ISiteView { 
  minWidth?: string | undefined;
  Tenant?: IIdnSite | undefined;
  nolastItemBorder?: boolean;
  indentTitle?: boolean;
}

const IdnTenantView = (props : ISiteView) : React.ReactElement => { 
  const { minWidth, Tenant, nolastItemBorder, indentTitle } = props; 
  return (
    <>
      <ViewComponent.PageItem
        name="Alpha Task Ref"
        minWidth={minWidth}
        value={Tenant?.alphaTaskRef}
        indentTitle={indentTitle}
      />
      <ViewComponent.PageItem
        name="Business Consultant"
        minWidth={minWidth}
        value={Tenant?.businessConsultant}
        indentTitle={indentTitle}
      />
      <ViewComponent.PageItem
        name="Project Manager"
        minWidth={minWidth}
        value={Tenant?.projectManager}
        indentTitle={indentTitle}
      />
      <ViewComponent.PageItemDate
        name="Setup Date"
        minWidth={minWidth}
        value={Tenant?.setupDate}
        indentTitle={indentTitle}
      />
      <ViewComponent.PageItem
        name="Duration of Site"
        minWidth={minWidth}
        value={Tenant?.duration}
        indentTitle={indentTitle}
      />
      <ViewComponent.PageItemDate
        name="Close Down Date"
        minWidth={minWidth}
        value={Tenant?.closeDownDate}
        nolastItemBorder={nolastItemBorder}
        indentTitle={indentTitle}
      />      
    </>
  );
};

export default IdnTenantView;
