import React from 'react';
import { ContainerRow } from 'styles/styledComponents/styledComponents';
import './viewForm.css';
import dateHelper from 'lib/dateHelper';
import CopyToClipboard from './CopyToClipboard';

interface IPageItemDate { 
  nolastItemBorder?: boolean;
  value: string | undefined;
  name: string | undefined;
  minWidth?: string | undefined;
  format?: string | undefined;
  indentTitle?: boolean;
  copyToClipboard?: boolean;
}

const PageItemDate = (props : IPageItemDate) : React.ReactElement => {
  const { nolastItemBorder, value, name, minWidth, format = 'date', indentTitle = false, copyToClipboard } = props;
  const formattedDate = format === 'datetime' ? dateHelper.formatDateTime(value) : dateHelper.formatDate(value);
  return (
    <ContainerRow className={`item-container ${nolastItemBorder && 'no-last-border'}`}>
      <div className={`item-name ${indentTitle && 'item-name-indent'} 
        ${copyToClipboard && 'displayFlex'}`} style={{ minWidth: minWidth }}>
        <div style={{width: '100%'}}>{name}</div>
        {copyToClipboard && (typeof value === 'string') && <CopyToClipboard value={formattedDate} />}
      </div>
      <div className={`values-container ${copyToClipboard && 'displayFlex'}`} >
        <div className="item-value">
          {formattedDate}          
        </div>     
      </div>
    </ContainerRow>
  );
};

export default PageItemDate;
