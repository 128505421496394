import * as yup from 'yup';
import {IFeature} from "models/Interfaces";

export const validateFeature = (Feature?: IFeature): any => {
  const featureSchema = yup.object().shape({
    code: yup.string().required('Feature Code is required'),
    description: yup.string().required('Feature Description is required'),
    availableFrom: yup.string().required('Start Date is required'),
  });

  try {
    featureSchema.validateSync(Feature, {abortEarly: false, stripUnknown: true});
    return [];
  } catch (err: any) {
    return err.errors;
  }
}