import { useMemo } from 'react';
import { ADMIN } from 'okta/HasAccess';
import Route from 'models/Routes';
import { ITabs, TabType } from 'models/types';

const useHeaderTabs = (): ITabs => {
  const tabs: TabType[] = useMemo(
    (): TabType[] => [
      {
        text: 'Packages',
        tooltip: 'View Packages',
        url: Route.Packages,
        selector: 'current',
        searchTitle: 'Packages',
        addAccessOnly: [ADMIN],
        paginatedResultsOnly: true,
      },
      {
        text: 'Discontinued',
        tooltip: 'View Discontinued Packages',
        url: Route.Packages,
        selector: 'discontinued',
        searchTitle: 'Discontinued Packages',
        addAccessOnly: [ADMIN],
        paginatedResultsOnly: true,
      },
      {
        text: 'Features',
        tooltip: 'View Features',
        url: Route.Features,
        searchTitle: 'Features',
        addAccessOnly: [ADMIN],
        paginatedResultsOnly: true,
      },
      {
        text: 'Licenses',
        tooltip: 'View Licenses',
        url: Route.Licenses,
        searchTitle: 'Licenses',
        addAccessOnly: [ADMIN],
        paginatedResultsOnly: true,
      },
    ],
    [],
  );
  return { tabs };
};

export default useHeaderTabs;
