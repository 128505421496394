import React, {PropsWithChildren} from 'react';
import * as Redux from 'react-redux';
import { setupPreviewPanel } from 'store/Actions/PreviewPanel';
import { refreshSearchResults } from 'store/Actions/SearchResults';
import { useFetch } from 'hooks';
import PanelSubHeader from 'views/Common/PanelSubHeader/PanelSubHeader';
import * as Component from 'components/ComponentsIndex';
import DataNotFound from 'error/DataNotFound';
import { ContainerColumn } from 'styles/styledComponents/styledComponents';
import { FormStatus } from 'views/Common/EditForm/Common';
import * as EditComponent from 'views/Common/EditForm/EditIndex';
import { ErrorDialog, handleError, useErrorState } from 'error/HandleErrors';
import { ApiHelperContext } from 'context/ApiHelperContext';
import Route, { VerbType } from 'models/Routes';
import notify, { MessageType, Message } from 'components/Notifications/toastify';
import { Props, Mode } from "models/types";
import {IPreviewPanelState, ISearchResultReducerState} from "models/Interfaces";
import { IError, IPreviewPanel } from "models/Interfaces";
import { getIDSSiteErrors } from 'lib/IDSSiteHelper';

const EditIdsSite: React.FC<Props>  = (props: PropsWithChildren<any>) => {
  const { minWidth = '11rem' } = props;
  const {reloadSearch} = Redux.useSelector<any>((state) => state.SearchResultsReducer) as ISearchResultReducerState;
  const { id } = Redux.useSelector<IPreviewPanelState>((state) => state.PreviewPanelReducer) as IPreviewPanel;
  const { data, error, loading } = useFetch(VerbType.GET, id ? `IdentityServerSites/${id}` : '');
  const [IdsSite, setIdsSite] = React.useState();
  const [status, setStatus] = React.useState(FormStatus.INITIAL);
  const { errorDialog, errorMessage, errorTitle, setErrorDialog, setErrorMessage, setErrorTitle } = useErrorState();
  const dispatch = Redux.useDispatch();
  const context = React.useContext(ApiHelperContext)
  const apiHelper = context?.state.apiHelper
  
  React.useEffect(() => {
    setIdsSite(data);
  }, [data]);

  if (loading) return <Component.Loading />;
  if (id > 0 && !IdsSite) return <DataNotFound />;
  if (error) return <DataNotFound />;

  const errors: any = getIDSSiteErrors(IdsSite);
  const isValid = errors.length === 0;

  function handleSave(): void {
    if (isValid && IdsSite && apiHelper) {
      apiHelper.put('IdentityServerSites/update', IdsSite, handleSaveCallBack, callbackErrorFunc);
      dispatch(refreshSearchResults(!reloadSearch));
      console.log('saving details')
    }
    setStatus(FormStatus.DIRTY);
  }

  function handleSaveCallBack(data: IError): void {
    if (data && data.status >= 400) {
      callbackErrorFunc(data);
    } else {
      handleCallBack();
      notify(Message.IDSSiteUpdated, MessageType.Success);
    }
  }

  function callbackErrorFunc(error: IError): void {
    handleError(error, setErrorTitle, setErrorMessage, setErrorDialog);
  }

  const handleCloseDialog = (): void => {
    setErrorDialog(false);
  };

  function handleCancel(): void{
    dispatch(setupPreviewPanel(Route.IDS, Mode.View, id));
  }

  function handleCallBack(): void {
    dispatch(setupPreviewPanel(Route.IDS, Mode.View, id));
    dispatch(refreshSearchResults(!reloadSearch));
  }

  return (
    <>
      <div className="search-title-nav">
        <PanelSubHeader handleCancel={handleCancel} handleSave={handleSave} description='Edit Details' />
      </div>
      {!isValid && FormStatus && (status === FormStatus.DIRTY) && <EditComponent.WriteErrors errors={errors} />}
      <ContainerColumn style={{ height: 'calc(100% - 3rem)', overflowY: 'auto' }}>
        <div className="entity-details-content full-width">
          <EditComponent.IdsSiteEdit 
            minWidth={minWidth}
            IdsSite={IdsSite}
            status={status}
            setState={setIdsSite}
          />
        </div>
      </ContainerColumn>
      <ErrorDialog
        errorDialog={errorDialog} 
        handleCloseDialog={handleCloseDialog}
        errorTitle={errorTitle}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default EditIdsSite;
