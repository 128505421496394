import { makeStyles } from '@material-ui/core/styles';
import TenantStatus from 'models/Lists/TenantStatus';
import SfsDataCentre, { SfsDataCentreLabel } from 'models/Lists/SfsDataCentre';

export function WriteTenantStatus(status: string | undefined): string {
  switch (status) {
  case TenantStatus.PendingUpdate:
    return 'Pending (License Update)';
  case TenantStatus.Pending:
    return 'Pending';
  case TenantStatus.PendingUnassign:
    return 'Pending (Unassign)';
  default:
    return status || '';
  }
}

export function WriteSFSDataCenter(DataCenter: string | undefined): string {
  switch (DataCenter) {
  case SfsDataCentre.PROD1:
    return SfsDataCentreLabel.PROD1;
  case SfsDataCentre.PROD2:
    return SfsDataCentreLabel.PROD2;
  case SfsDataCentre.PROD3:
    return SfsDataCentreLabel.PROD3;
  case SfsDataCentre.PROD4:
    return SfsDataCentreLabel.PROD4;
  case SfsDataCentre.PROD5:
    return SfsDataCentreLabel.PROD5;
  default:
    return DataCenter || '';
  }
}

export const useStyles = makeStyles(() => ({
  table: {
    border: 'thin solid #ababab',
    '& .MuiTableRow-root': {
      backgroundColor: '#f2f2f4',
    },
    '& .MuiTableRow-head > th': {
      backgroundColor: '#f2f2f2',
      border: '1px solid #ababab',
      fontWeight: '600',
      padding: '0 0.5rem',
      height: '2rem',
    },
    '& .MuiTableCell-root': {
      padding: '.5rem',
      borderRight: '1px solid #ababab',
      borderBottom: '1px solid #ababab',
      backgroundColor: '#fff',
      lineHeight: '1',
      fontSize: '14px',
    },
  },
  tableSplit: {
    '& .MuiTableRow-root': {
      backgroundColor: '#f2f2f4',
    },
    '& .MuiTableRow-head > th': {
      backgroundColor: '#f2f2f2',
      fontWeight: '600',
      lineHeight: '1.42857143rem',
      padding: '0.6rem 0.5rem',
      borderBottom: '1px solid #ababab',
    },
    '& .MuiTableRow-head > th:not(:first-child)': {
      borderLeft: '1px solid #ababab',
    },
    '& .MuiTableCell-root': {
      backgroundColor: '#fff',
      lineHeight: '1rem',
      fontSize: '14px',
      padding: '0rem',
      verticalAlign: 'top',
      borderBottom: 'none',
    },
    '& .MuiTableCell-root:not(:first-child)': {
      borderLeft: '1px solid #ababab',
    },
  },
}));
