import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Source Sans Pro',
    fontSize: 14,
    primaryColor: 'var(--button-colour)',
    primaryHoverColor: 'var(--link-hover)',
    successColor: 'var(--status-success)',
    successHoverColor: 'var(--link-hover)',
  },
  palette: {
    primary: {
      contrastText: '#FFFFFF',
      dark: '#1e1e1e',
      main: '#1e1e1e',
      light: '#1e1e1e',
    },
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: { backgroundColor: 'var(--primary)' },
    },
    MuiFormLabel: {
      root: {
        color: 'var(--primary)',
      },
    },
    MuiPickersDay: {
      day: {
        color: 'var(--button-colour)',
      },
      daySelected: {
        backgroundColor: 'var(--button-colour)',
      },
      dayDisabled: {
        color: 'var(--action-primary-disabled)',
      },
      current: {
        color: 'var(--button-colour)',
      },
    },
  },
});

export default theme;
