import { useMemo } from 'react';
import Route from 'models/Routes';
import { IReport, ReportType } from 'models/types';

const useReports = (): IReport => {
  const reports: ReportType[] = useMemo(
    (): ReportType[] => [
      {
        reportId: 'PACKAGES',
        label: 'Package and Feature details per Client',
        url: 'organisations/report',
        selector: 'organisationSelector=Assigned',
        columnSetup: 'organisations-packages',
        paginatedResultsOnly: true,
      },
      {
        reportId: 'LICENSES',
        label: 'License details per Client',
        url: 'organisations/report',
        selector: 'organisationSelector=Assigned',
        columnSetup: 'organisations-license',
        paginatedResultsOnly: true,
      },
      {
        reportId: 'QUARTER',
        label: 'Live sites in the last quarter',
        url: Route.Sites,
        selector: 'tenantSelector=LiveSitesLastQuarter',
        columnSetup: 'tenants-quarter',
        paginatedResultsOnly: true,
      },
      {
        reportId: 'HOSTED',
        label: 'Hosted v Self-Hosted Clients',
        url: 'organisations/report',
        selector: '',
        columnSetup: 'organisations-selfhosted',
        paginatedResultsOnly: true,
      },
      {
        reportId: 'ALLINFO',
        label: 'Site, Package, Feature and License details per Client',
        url: 'organisations/report',
        selector: 'organisationSelector=Assigned',
        columnSetup: 'tenants-allinfo',
        paginatedResultsOnly: true,
      },
      {
        reportId: 'OVERUSAGE',
        label: 'Exceeded License Report',
        url: 'organisations/report/overusage',
        columnSetup: 'license-overusage',
        paginatedResultsOnly: true,
      },
    ],
    [],
  );
  return { reports };
};

export default useReports;
