import React from 'react';
import * as ViewComponent from './ViewIndex';
import { IPackage } from 'models/Interfaces';

interface IPackageView { 
  minWidth?: string | undefined;
  Package?: IPackage;
  summaryOnly?: boolean;
  nolastItemBorder?: boolean;
}

const PackageView = (props : IPackageView) : React.ReactElement => {
  const { minWidth, Package, summaryOnly, nolastItemBorder } = props;
  return (
    <>
      <ViewComponent.PageItem name="Package Code" value={Package?.code} minWidth={minWidth} />
      <ViewComponent.PageItem name="Package Name" value={Package?.name} minWidth={minWidth} />
      {!summaryOnly && (
        <>
          <ViewComponent.PageItem
            name="Package Description"
            value={Package?.description}
            minWidth={minWidth}
          />
          <ViewComponent.PageItemDate
            name="Start Date"
            value={Package?.availableFrom}
            minWidth={minWidth}
          />
          <ViewComponent.PageItemDate
            name="Discontinued"
            value={Package?.availableUntil}
            minWidth={minWidth}
            nolastItemBorder={!!summaryOnly}
          />
          <ViewComponent.PageItem
            name="Profile"
            value={Package?.profile}
            minWidth={minWidth}
            nolastItemBorder={nolastItemBorder}
          />
        </>
      )}
      <div className="entity-details-content full-width">
        <ViewComponent.FeaturesForPackage
          packageCode={Package?.code}
          minWidth={minWidth}
          nolastItemBorder={nolastItemBorder}
        />
      </div>
    </>
  );
};

export default PackageView;
