import { useMemo } from 'react';

interface Item {
  name: string;
  link: string;
  accessOnly?: string[];
  icon?: string;
}
interface IItems {
  items: Array<Item>;
}
const usePanelItems = (): IItems => {
  const items = useMemo(
    () => [
      {
        name: 'Check Health',
        link: '/check/health',
      },
    ],
    [],
  );
  return { items };
};

export default usePanelItems;
