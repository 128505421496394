import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import OrganisationItems from 'views/ClientManagement/View/OrganisationItems';
import OrganisationTenantItems from 'views/ClientManagement/View/OrganisationTenantItems';
import TenantItems from 'views/SiteManagement/View/TenantItems';
import PackageItems from 'views/PackageManagement/View/Package/PackageItems';
import LicenseItems from 'views/PackageManagement/View/License/LicenseItems';
import LicenseItemsWithUsage from 'views/PackageManagement/View/License/LicenseItemsWithUsage';
import FeatureCodesById from 'views/PackageManagement/View/Feature/FeatureCodesById';
import { useStyles } from './Styles';
import * as ViewComponent from 'views/Common/ViewForm/ViewIndex';
import CopyToClipboard from 'views/Common/ViewForm/CopyToClipboard';
import IconButton from '@material-ui/core/IconButton';
import ViewSiteButton from 'views/Common/ViewForm/ViewSiteButton'
import ViewClientButton from 'views/Common/ViewForm/ViewClientButton'

const loadComponent = (items, itemId, state, minWidth, setSummary, editMode, dataArray) => {
  switch (items) {
  case 'DataFeeds':
    return <ViewComponent.DataFeeds dataFeeds={dataArray} />;
  case 'AutoReminder':
    return <ViewComponent.AutoReminderAlerts alerts={dataArray} />;
  case 'Organisation':
    return (
      <OrganisationItems
        itemId={itemId}
        nolastItemBorder={true}
        minWidth={minWidth}
        setSummary={setSummary}
        summaryOnly={true}
      />
    );
  case 'OrganisationTenants':
    return (
      <OrganisationTenantItems
        itemId={itemId}
        state={state}
        nolastItemBorder={true}
        minWidth={minWidth}
        setSummary={setSummary}
        summaryOnly={true}
        editMode={editMode}
      />
    );
  case 'Tenant':
    return (
      <TenantItems
        tenantId={itemId}
        nolastItemBorder={true}
        summaryOnly={true}
        minWidth={minWidth}
        setSummary={setSummary}
      />
    );
  case 'Package':
    return (
      <PackageItems
        packageId={itemId}
        nolastItemBorder={true}
        summaryOnly={true}
        minWidth={minWidth}
        setSummary={setSummary}
      />
    );
  case 'License':
    return (
      <LicenseItems
        licenseId={itemId}
        nolastItemBorder={true}
        summaryOnly={true}
        minWidth={minWidth}
        setSummary={setSummary}
      />
    );
  case 'LicenseWithUsage':
    return (
      <LicenseItemsWithUsage
        Id={itemId}
        nolastItemBorder={true}
        summaryOnly={true}
        minWidth={minWidth}
        setSummary={setSummary}
      />
    );
  case 'FeatureCodes':
    return (
      <FeatureCodesById
        packageId={itemId}
        nolastItemBorder={true}
        summaryOnly={true}
        minWidth={minWidth}
        setSummary={setSummary}
      />
    );
  default:
    return null;
  }
};

export default function WriteAccordion(props) {
  const classes = useStyles();
  const {
    title,
    items,
    dataArray,
    icon,
    itemId,
    state,
    minWidth,
    noBottomBorder,
    editMode,
    Component,
    defaultExpanded,
    copyText,
  } = props;
  const [Summary, setSummary] = React.useState();

  return (
    <div className={classes.root}>
      <Accordion
        square={true}
        className={noBottomBorder && 'noBottomBorder'}
        defaultExpanded={defaultExpanded}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.icon}>
          <div className={classes.wrapper}>
            {icon && (
              <FormControlLabel
                className={classes.heading}
                control={icon}
                label={Summary ? Summary : title}
              />
            )}
            {!icon && (
              <Typography className={classes.heading} component="div">
                {Summary ? Summary : title}
                {copyText && (
                  <IconButton onClick={(e) => e.stopPropagation()}>
                    <CopyToClipboard value={copyText} handleClick={(e) => e.stopPropagation()} />
                  </IconButton>
                )}
              </Typography>
            )}
            <ViewClientButton showButton={items === 'Organisation'} {...props} />
            <ViewSiteButton showButton={items === 'OrganisationTenants'} {...props} />
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.body} component="div">
            {!Component &&
              loadComponent(items, itemId, state, minWidth, setSummary, editMode, dataArray)}
            {Component && <Component {...props} />}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
