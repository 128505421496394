import dateHelper from './dateHelper';
import { getProperty, removeNewlines, slugify } from './stringHelper';
import { logger } from './logger';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import notify, { MessageType } from 'components/Notifications/toastify';

const FileSaver = require('file-saver');
const BLOB_TYPE = 'text/csv';
const COLUMN_DELIMITER = ',';
const ROW_DELIMITER = '\n';

const dataExporter = {
  exportCsv: (columns, results, filename) => {
    try {
      //const csv = '\ufeff' & '';
      const visibleColumns = columns && columns.length > 0 && columns.filter((col) => !col.hidden);
      const records = [];
      const headers =
        visibleColumns &&
        visibleColumns.length > 0 &&
        visibleColumns.map((col) => dataExporter.formatData(col.title));
      records.push(headers.join(COLUMN_DELIMITER));

      results &&
        results.length > 0 &&
        results.forEach((row) => {
          const rowData = visibleColumns.map((col) => {
            const propertyValue = getProperty(col.field, row);
            return dataExporter.formatData(propertyValue, col.type);
          });
          records.push(rowData.join(COLUMN_DELIMITER));
        });

      const csvString = /*csv +*/ records.join(ROW_DELIMITER);
      const blob = new Blob([csvString], { type: BLOB_TYPE });
      const slugifyName = slugify(filename);

      FileSaver.saveAs(blob, slugifyName + '.csv');
      notify(`${slugifyName} exported to CSV`, MessageType.Success);
    } catch (e) {
      logger.error(e, 'dataExporter: Error exporting CSV data');
    }
  },
  exportPdf: (columns, results, filename) => {
    try {
      const doc = new jsPDF('l');
      const visibleColumns = columns && columns.length > 0 && columns.filter((col) => !col.hidden);
      const columnTitles =
        visibleColumns &&
        visibleColumns.length > 0 &&
        visibleColumns.map((columnDef) => columnDef.title);
      const pdfData =
        results &&
        results.length > 0 &&
        results.map((rowData) =>
          visibleColumns.map((columnDef) => getProperty(columnDef.field, rowData)),
        );

      doc.autoTable({
        head: [columnTitles],
        body: pdfData,
      });

      const slugifyName = slugify(filename);
      doc.save(slugifyName + '.pdf');
      notify(`${slugifyName} exported to PDF`, MessageType.Success);
    } catch (e) {
      logger.error(e, 'dataExporter: Error exporting PDF data');
    }
  },
  formatData: (data, type) => {
    if ([false, true].includes(data)) return data ? 'true' : 'false';
    if (!data) return '';

    data = removeNewlines(data);

    if (type === 'datetime') {
      data = dateHelper.formatDateTime(data);
    } else if (type === 'date') {
      data = dateHelper.formatDate(data);
    } else if (Array.isArray(data)) {
      data = dataExporter.arrayToCsv(data);
    }

    return `"${data}"`;
  },
  arrayToCsv: (data) => {
    if (Array.isArray(data)) {
      return data.join(', ');
    }
    return data;
  },
};

export default dataExporter;
