import React from 'react';
import * as Redux from 'react-redux';
import { AppContainer } from 'styles/styledComponents/styledComponents';
import Sidebar from './Sidebar/Sidebar';
import Header from './Header/Header';
import PreviewPanel from 'views/Common/PreviewPanel/PreviewPanel';
import { hideMenu } from 'store/Actions/Sidebar';
import './layout.css';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { oktaAuthConfig } from 'okta/config';
import { useHistory } from 'react-router-dom';

const Layout = (props: React.PropsWithChildren<any>): React.ReactElement => {
  const dispatch = Redux.useDispatch();
  const oktaAuth = new OktaAuth(oktaAuthConfig);
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string | undefined): Promise<void> => {
    originalUri = originalUri || '';
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };
  const { isPreviewPanelOpen, isPreviewPanelExtended } = Redux.useSelector(
    (state: any) => state.PreviewPanelReducer,
  );
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <AppContainer className="full-size app-container responsive-search-panels-container">
        <Sidebar history={history} />
        <div className="app-content-container short-transition position-relative">
          <div
            onClick={(): void => {
              dispatch(hideMenu());
            }}
            className="app-header-marker branded-banner position-absolute"
          />
          <div
            onClick={(): void => {
              dispatch(hideMenu());
            }}
            className="full-width position-relative short-transition top-bar-container"
          >
            <Header />
          </div>
          <div
            onClick={(): void => {
              dispatch(hideMenu());
            }}
            className="app-content position-relative"
          >
            <div
              className={`body-panel ${isPreviewPanelOpen && 'slideout-open'} ${
                isPreviewPanelExtended && 'extended'
              }`}
            >
              {props.children}
            </div>
            <div
              className={`slideout-panel-container ${isPreviewPanelOpen && 'open'} ${
                isPreviewPanelExtended && 'extended'
              }`}
              style={{ right: '1rem' }}
            >
              <PreviewPanel />
            </div>
          </div>
        </div>
      </AppContainer>
    </Security>
  );
}

export default Layout;
