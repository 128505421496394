import React, {PropsWithChildren} from 'react';
import * as Redux from 'react-redux';
import { useFetch } from 'hooks';
import PackageView from 'views/Common/ViewForm/PackageView';
import {Props} from "models/types";
import {IPreviewPanelState} from "models/Interfaces";
import {IPreviewPanel} from "models/Interfaces";
import { VerbType } from 'models/Routes';

const PackageItems: React.FC<Props> = (props: PropsWithChildren<any>) => {
  const {
    packageId,
    nolastItemBorder,
    summaryOnly,
    setSummary,
    minWidth = '10rem',
  } = props;
  const { refreshPanel } = Redux.useSelector<IPreviewPanelState>((state) => state.PreviewPanelReducer) as IPreviewPanel;
  const { data } = useFetch(VerbType.GET, packageId ? `Packages/${packageId}` : '', refreshPanel);
  const [Package, setPackage] = React.useState();

  React.useEffect(() => {
    setPackage(data);
    data && data.name && setSummary(`Package - ${data.name}`);
  }, [data, refreshPanel, setSummary]);

  return (
    <>
      <PackageView
        Package={Package}
        minWidth={minWidth}
        nolastItemBorder={nolastItemBorder}
        summaryOnly={summaryOnly}
      />
    </>
  );
};

export default PackageItems;
